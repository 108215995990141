import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import LanguageTwoToneIcon from "@mui/icons-material/LanguageTwoTone";
import LocationSearchingTwoTone from "@mui/icons-material/LocationSearchingTwoTone";

export const OnboardingContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
});

export const SelectInputsContainer = styled(Box)({
  minWidth: 300,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
});

export const TextDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginTop: 40,
  textAlign: "center",
}));

const iconStyle = {
  width: 64,
  height: 64,
};

export const LanguageIcon = styled(LanguageTwoToneIcon)(({ theme }) => ({
  ...iconStyle,
  color: theme.palette.secondary.contrastText,
}));

export const LocationSearchingIcon = styled(LocationSearchingTwoTone)(
  ({ theme }) => ({
    ...iconStyle,
    color: theme.palette.secondary.contrastText,
  })
);

export const PrimaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
