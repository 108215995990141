import { MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const MenuItemContainer = styled(MenuItem)({
  display: "flex",
  justifyContent: "space-between",
});

export const PreferenceItemName = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
