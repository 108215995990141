import {
  BACKEND_URL,
  resource_images_update_and_delete_url,
  resource_images_url,
} from "../../../Constants";
import KeycloakService from "../../../services/KeycloakService";
import { baseApiSlice } from "./baseApiSlice";

export const resourceImagesApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getResourceImages: builder.query({
      query: ({ resourceid, organizationId }) => ({
        url: resource_images_url
          .replace(":resourceid", resourceid)
          .replace(":organizationId", organizationId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["ResourceImage"],
    }),
    getResourceImage: builder.query({
      query: ({ imageUri }) => ({
        url: BACKEND_URL + imageUri,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
          accept: "image/png",
        },
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: async (response) => {
        // Use response.blob() to create a Blob object
        const blob = await response;
        const objectURL = URL.createObjectURL(blob);
        return objectURL;
      },
      providesTags: ["ResourceImage"],
    }),
    uploadResourceImage: builder.mutation({
      query: ({ formData, resourceid, organizationId }) => ({
        url: resource_images_url
          .replace(":resourceid", resourceid)
          .replace(":organizationId", organizationId),
        method: "POST",
        body: formData,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      // "LIST" because of graphical rack view
      invalidatesTags: ["ResourceImage", "DETAILS", "LIST"],
    }),
    updateResourceImage: builder.mutation({
      query: ({
        formData,
        resourceid,
        organizationId,
        resourceImageId,
        updateImage,
      }) => ({
        url:
          resource_images_update_and_delete_url
            .replace(":resourceid", resourceid)
            .replace(":resourceImageId", resourceImageId)
            .replace(":organizationId", organizationId) +
          `?update_image=${updateImage}`,
        method: "PUT",
        body: formData,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      // "LIST" because of graphical rack view
      invalidatesTags: ["ResourceImage", "DETAILS", "LIST"],
    }),
    deleteResourceImage: builder.mutation({
      query: ({ resourceid, organizationId, resourceImageId }) => ({
        url: resource_images_update_and_delete_url
          .replace(":resourceid", resourceid)
          .replace(":resourceImageId", resourceImageId)
          .replace(":organizationId", organizationId),
        method: "DELETE",
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      // "LIST" because of graphical rack view
      invalidatesTags: ["DETAILS", "LIST", "ResourceImage"],
    }),
  }),
});

export const {
  useGetResourceImagesQuery,
  useGetResourceImageQuery,
  useUploadResourceImageMutation,
  useUpdateResourceImageMutation,
  useDeleteResourceImageMutation,
} = resourceImagesApiSlice;
