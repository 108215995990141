import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setShouldExecute } from "../../../../store/slices/reportsSlice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getTranslation } from "../../../../util/utils";

const RuleFieldSelector = ({ handleOnChange, options, value }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Handlers
  const handleChange = (e) => {
    handleOnChange(e.target.value);
    dispatch(setShouldExecute(false));
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="field-select-label">
        {getTranslation("FIELD_SELECT_LABEL", t, i18n)}
      </InputLabel>

      <Select
        labelId="field-select-label"
        id="field-select"
        label={getTranslation("FIELD_SELECT_LABEL", t, i18n)}
        value={value}
        onChange={handleChange}
        error={!Boolean(value)}
      >
        {options.map((option) => (
          <MenuItem key={option.name} value={option.name}>
            {getTranslation(option.label, t, i18n)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RuleFieldSelector;
