import { styled } from "@mui/system";
import ImageTwoToneIcon from "@mui/icons-material/ImageTwoTone";

import { OnboardingUserCredentialContainer } from "./OnboardingUserCredential.styles";
import { Typography } from "@mui/material";

export const OnboardingOrganizationPageContainer = styled(
  OnboardingUserCredentialContainer
)({
  alignItems: "unset",
});

export const LogoIcon = styled(ImageTwoToneIcon)({
  width: 64,
  height: 64,
  color: "gray",
});

export const LogoText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: 10,
}));
