import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  ActionTitle,
  HeaderGridContainer,
  HeaderWrapper,
  PageTitle,
} from "../../../components/styles/header/Header.styles";
import {
  selectHaveChanges,
  selectInputValue,
} from "../../../store/slices/tagsSlice";
import { getTranslation } from "../../../util/utils";

const EditTagsHeader = ({ handleCancelAction, handleAction }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const inputValue = useSelector(selectInputValue);
  const haveChanges = useSelector(selectHaveChanges);

  // Other variables
  const isDisabled = Boolean(inputValue) || !haveChanges ? 1 : 0;

  return (
    <HeaderWrapper id="sticky-header">
      <HeaderGridContainer container>
        <Grid item xs={3}>
          <ActionTitle
            tabIndex="0"
            data-testid="action-title-cancel"
            id="action-title-cancel"
            onClick={handleCancelAction}
          >
            {getTranslation("CANCEL", t, i18n)}
          </ActionTitle>
        </Grid>
        <Grid item xs={6}>
          <PageTitle variant="h6" id="title">
            {getTranslation("EDIT_TAGS", t, i18n)}
          </PageTitle>
        </Grid>
        <Grid display="flex" justifyContent="end" item xs={3}>
          <ActionTitle
            tabIndex="0"
            align="right"
            onClick={!isDisabled ? handleAction : null}
            id="action-title-done"
            data-testid="action-title-done"
            disabled={isDisabled}
          >
            {getTranslation("DONE", t, i18n)}
          </ActionTitle>
        </Grid>
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default EditTagsHeader;
