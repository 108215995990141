import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import {
  selectWizardStep,
  setWizardStep,
} from "../../../store/slices/reportsSlice";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import {
  StyledReportsWizardTimeline,
  StyledTimelineConnector,
  StyledTimelineContent,
  StyledTimelineDot,
} from "../../styles/reports/Reports.styles";

const ReportsWizardTimeline = ({ isEdit }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const wizardStep = useSelector(selectWizardStep);

  const handleClick = (step) => {
    if (isEdit) {
      dispatch(setWizardStep(step));
    }
  };

  return (
    <StyledReportsWizardTimeline>
      <TimelineItem>
        <TimelineSeparator>
          <StyledTimelineDot
            wizardStep={wizardStep}
            targetWizardStep={1}
            isEdit={isEdit}
            id="first-step"
            role={isEdit ? "button" : ""}
            onClick={() => handleClick(1)}
          >
            <Typography align="center">1</Typography>
          </StyledTimelineDot>

          <StyledTimelineConnector />
        </TimelineSeparator>

        <StyledTimelineContent wizardStep={wizardStep} targetWizardStep={1}>
          {getTranslation("BASIC_DATA", t, i18n)}
        </StyledTimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <StyledTimelineDot
            wizardStep={wizardStep}
            targetWizardStep={2}
            isEdit={isEdit}
            id="second-step"
            onClick={() => handleClick(2)}
            role={isEdit ? "button" : ""}
          >
            <Typography align="center">2</Typography>
          </StyledTimelineDot>

          <StyledTimelineConnector />
        </TimelineSeparator>

        <StyledTimelineContent wizardStep={wizardStep} targetWizardStep={2}>
          {getTranslation("DEFINE_QUERY", t, i18n)}
        </StyledTimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <StyledTimelineDot
            wizardStep={wizardStep}
            targetWizardStep={3}
            isEdit={isEdit}
            id="third-step"
            onClick={() => handleClick(3)}
            role={isEdit ? "button" : ""}
          >
            <Typography align="center">3</Typography>
          </StyledTimelineDot>

          {/* <TimelineConnector
            sx={{
              "&.MuiTimelineConnector-root": {
                backgroundColor: "#7D8182",
                height: "50px",
              },
            }}
          /> */}
        </TimelineSeparator>

        <StyledTimelineContent wizardStep={wizardStep} targetWizardStep={3}>
          {getTranslation("FORMAT_RESULT", t, i18n)}
        </StyledTimelineContent>
      </TimelineItem>

      {/* <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            sx={{
              "&.MuiTimelineDot-root": {
                backgroundColor: wizardStep === 4 ? "#1976D2" : "#7D8182",
                height: "15px",
                width: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            }}
          >
            <Typography align="center">4</Typography>
          </TimelineDot>
        </TimelineSeparator>

        <TimelineContent
          sx={{
            marginTop: "5px",
            color: wizardStep === 4 ? "#1976D2" : theme.palette.primary.main,
          }}
        >
          Add widget
        </TimelineContent>
      </TimelineItem> */}
    </StyledReportsWizardTimeline>
  );
};

export default ReportsWizardTimeline;
