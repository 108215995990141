import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetAllCharacteristicsQuery,
  useGetCharacteristicSetTemplatesQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  selectOriginalTypeInput,
  selectTypeInput,
  setTypeInput,
} from "../../../store/slices/typeInputSlice";
import { groupCharacteristics } from "../../../util/asset-utils";
import { measurementDisplay } from "../../../util/utils";
import ErrorHandling from "../../common/ErrorHandling";
import TypeCharacteristicSetTemplateAccordion from "./TypeCharacteristicSetTemplateAccordion";

const TypeCharacteristicsInputGroup = ({ mode }) => {
  // General hooks
  const dispatch = useDispatch();
  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default).id;
  const typeInput = useSelector(selectTypeInput);
  const originalTypeInput = useSelector(selectOriginalTypeInput);

  // Queries
  const {
    data: setTemplatesData,
    isLoading: isLoadingSetTemplates,
    isError: isErrorSetTemplates,
  } = useGetCharacteristicSetTemplatesQuery(
    {
      resourceFunctionId: typeInput.functionId,
      organizationId,
    },
    { skip: !typeInput.functionId }
  );

  const {
    data: characteristicDefinitions,
    isLoading: isLoadingCharacteristicDefinitions,
    isError: isErrorCharacteristicDefinitions,
  } = useGetAllCharacteristicsQuery({
    organizationId,
  });

  useEffect(() => {
    let allCharacteristics = [];
    // Make sure request finished otherwise don't change characteristics
    if (!(setTemplatesData && characteristicDefinitions)) return;
    const setTemplatesForType = setTemplatesData.filter(
      (setTemplate) => setTemplate.forType
    );

    const setTemplatesCharacteristicIds = setTemplatesForType.reduce(
      (acc, setTemplate) => {
        return [...acc, ...setTemplate.characteristics];
      },
      []
    );

    // we want on create to have

    setTemplatesCharacteristicIds.forEach((id) => {
      const characteristicDefinition = characteristicDefinitions.find(
        (charDef) => charDef.id === id
      );

      const typeCharacteristic = originalTypeInput.characteristics.find(
        (char) => char.id === id
      );

      if (!typeCharacteristic || typeInput.hasFunctionIdChanged) {
        allCharacteristics.push({
          ...characteristicDefinition,
          editable: true,
        });
      } else {
        const characteristicValue =
          typeCharacteristic.value && characteristicDefinition.measurementUnit
            ? measurementDisplay({
                value: typeCharacteristic.value,
                unit: characteristicDefinition.measurementUnit,
                region: user?.region,
                reverse: false,
              }).value
            : typeCharacteristic.value;

        allCharacteristics.push({
          ...characteristicDefinition,
          ...typeCharacteristic,
          value: characteristicValue,
        });
      }
    });

    dispatch(
      setTypeInput({
        ...typeInput,
        characteristics: allCharacteristics,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTemplatesData, characteristicDefinitions]);

  if (isLoadingSetTemplates || isLoadingCharacteristicDefinitions) {
    return <></>;
  }

  // Grouping characteristics to there setTemplates
  const characteristicGroup = groupCharacteristics(
    typeInput.characteristics,
    setTemplatesData?.filter((setTemplate) => setTemplate.forType)
  );

  return (
    <ErrorHandling
      isLoading={isLoadingSetTemplates || isLoadingCharacteristicDefinitions}
      isError={isErrorSetTemplates || isErrorCharacteristicDefinitions}
    >
      {typeInput.functionId && characteristicGroup.setTemplates?.length > 0 && (
        <Box>
          {characteristicGroup.setTemplates
            .sort((a, b) => {
              if (a.orderPriority > b.orderPriority) {
                return 1;
              } else {
                return -1;
              }
            })
            .map((setTemplate) => {
              const { id, characteristics, name } = setTemplate;

              return (
                <TypeCharacteristicSetTemplateAccordion
                  key={id}
                  name={name}
                  characteristics={characteristics}
                />
              );
            })}
        </Box>
      )}
    </ErrorHandling>
  );
};

export default TypeCharacteristicsInputGroup;
