import { Stack } from "@mui/material";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useGetCharacteristicByIdQuery } from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  selectTypeInput,
  setTypeInput,
} from "../../../store/slices/typeInputSlice";
import { messageError } from "../../../util/notification";
import {
  datePickerFormat,
  getTranslation,
  measurementDisplay,
} from "../../../util/utils";
import ErrorHandling from "../../common/ErrorHandling";
import SelectInput from "../../SelectInput";
import { CharacteristicItemInput } from "../../styles/assets/asset-detail/AssetDetailCharacteristicInputGroup.styles";
import {
  StyledCheckBox,
  StyledFormControlLabel,
} from "../../styles/general/General.styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const NumericFormatCustom = forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

const dynamicCharacteristicProps = (dataType, characteristicName) => {
  let props = {};

  if (dataType === "STRING") {
    props.text = "text";
  } else {
    props.InputProps = {
      inputComponent: NumericFormatCustom,
    };

    props.name = "characteristics-" + characteristicName;
  }

  return props;
};

const TypeCharacteristicInput = ({ characteristic }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { id, name, value, dataType, measurementUnit, required, editable } =
    characteristic;

  // Selectors
  const typeInput = useSelector(selectTypeInput);
  const user = useSelector(selectUser);

  // Queries
  const {
    data: characteristicData,
    isLoading: isLoadingCharacteristic,
    isError,
  } = useGetCharacteristicByIdQuery({
    characteristicId: id,
    organizationId: user?.organizations?.find((o) => o.default).id,
  });

  // Handlers
  const handleChangeCharacteristic = (value) => {
    const newCharacteristics = typeInput.characteristics.map(
      (characteristic) => {
        if (id !== characteristic.id) return characteristic;
        if (!Boolean(value)) {
          return {
            ...characteristic,
            value,
            editable: true,
          };
        } else {
          return {
            ...characteristic,
            value,
          };
        }
      }
    );

    if (!Boolean(value)) {
    } else {
      dispatch(
        setTypeInput({
          ...typeInput,
          characteristics: newCharacteristics,
        })
      );
    }

    dispatch(
      setTypeInput({
        ...typeInput,
        characteristics: newCharacteristics,
      })
    );
  };

  const handleChangeCharacteristicToggleEditable = () => {
    const newCharacteristics = typeInput.characteristics.map(
      (characteristic) => {
        if (id !== characteristic.id) return characteristic;

        return {
          ...characteristic,
          editable: !characteristic.editable,
        };
      }
    );

    dispatch(
      setTypeInput({
        ...typeInput,
        characteristics: newCharacteristics,
      })
    );
  };

  const handleChangeCharacteristicValueDropdown = (value) => {
    const newCharacteristicValue = value;

    const newCharacteristics = typeInput.characteristics.map((c) => {
      if (id !== c.id) return c;

      return {
        ...c,
        value: newCharacteristicValue,
      };
    });

    dispatch(
      setTypeInput({
        ...typeInput,
        characteristics: newCharacteristics,
      })
    );
  };

  // Other variables
  const characteristicLabel =
    getTranslation(name, t, i18n) +
    `${
      measurementUnit
        ? " (" +
          measurementDisplay({
            value: value,
            unit: measurementUnit,
            region: user.region,
          }).unit +
          ")"
        : ""
    }`;

  const { characteristicValues } = characteristicData ?? {};
  const characteristicValueSelectList = characteristicValues?.map((cv) => ({
    label: cv,
    value: cv,
  }));

  return (
    <ErrorHandling isLoading={isLoadingCharacteristic} isError={isError}>
      <>
        {characteristicValues?.length > 0 ? (
          <SelectInput
            selectLabelId={`characteristics-${id}-label`}
            value={value}
            handleChange={handleChangeCharacteristicValueDropdown}
            data={characteristicValueSelectList}
            label={getTranslation(name, t, i18n)}
            isSelect={false}
          />
        ) : id === 160 ||
          name === "LIFECYCLE_DATE" ||
          dataType === "DATETIME" ? (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={datePickerFormat(user.region)}
          >
            <DatePicker
              data-testid="lifecycle-date"
              label={characteristicLabel}
              value={value || dayjs()}
              onChange={(value) => handleChangeCharacteristic(value, id)}
            />
          </LocalizationProvider>
        ) : (
          <CharacteristicItemInput
            required={required}
            id={"characteristics-" + id}
            data-testid={"characteristic-" + id}
            label={characteristicLabel}
            value={value ?? ""}
            onChange={(event) => handleChangeCharacteristic(event.target.value)}
            {...dynamicCharacteristicProps(dataType, name)}
            InputProps={{
              ...(dynamicCharacteristicProps(dataType, name).InputProps ?? {}),
            }}
          />
        )}
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <StyledFormControlLabel
            disabled={!Boolean(value)}
            control={
              <StyledCheckBox
                checked={editable}
                data-testid={"characteristic-check-" + id}
                aria-checked={editable}
                onClick={(e) => {
                  // We don't allow the user to set characteristic to be not editable if it is required.
                  if (editable && required) {
                    messageError(
                      getTranslation(
                        "NOT_EDITABLE_ON_REQUIRED_CHARACTERISTIC",
                        t,
                        i18n
                      )
                    );

                    return;
                  }

                  handleChangeCharacteristicToggleEditable();
                }}
              />
            }
            label={getTranslation("IS_EDITABLE", t, i18n)}
          />
        </Stack>
      </>
    </ErrorHandling>
  );
};

export default TypeCharacteristicInput;
