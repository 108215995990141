import { Box, Tab, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { extractTextColor, userLocalStorageKey } from "../../util/utils";
import ColorPaletteTab from "./ColorPaletteTab";
import {
  ColorPaletteTabs,
  ColorPickerButtonContainer,
  ColorPickerButtonText,
  ColorPickerMenu,
  ColorPickerTabBoxContainer,
  ColorPickerWrapper,
  TabsContainer,
} from "../styles/color-picker/ColorPicker.styles";
import MoreTab from "./MoreTab";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setRecentColors } from "../../store/slices/tagsSlice";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <ColorPickerTabBoxContainer>{children}</ColorPickerTabBoxContainer>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index, value, theme, maxWidth) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: {
      textTransform: "initial",
      border: "none",
      backgroundColor: value === index ? "#ffffff" : "#f5f5f5",
      maxWidth,
      color: `${
        value === index
          ? theme.palette.secondary.contrastText
          : theme.palette.primary.main
      } !important`,
    },
  };
};

const ColorPicker = ({ setColor, color }) => {
  // General hooks
  const dispatch = useDispatch();
  const theme = useTheme();

  // State
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  // Other variables
  const open = Boolean(anchorEl);

  const menuListProps = {
    style: {
      paddingTop: 0,
    },
    "aria-labelledby": "basic-button",
  };

  const tabIndicatorProps = {
    style: {
      background: "#ffffff",
    },
  };

  // Handlers
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Effects
  useEffect(() => {
    const recentColors = JSON.parse(
      localStorage.getItem(userLocalStorageKey("recentColors"))
    );

    if (recentColors && recentColors.length > 0) {
      dispatch(setRecentColors(recentColors));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ColorPickerWrapper>
      <ColorPickerButtonContainer
        id="color-picker-toggle"
        color={color}
        onMouseDown={handleClick}
      >
        <ColorPickerButtonText color={extractTextColor(color)} align="center">
          Aa
        </ColorPickerButtonText>
      </ColorPickerButtonContainer>
      <ColorPickerMenu
        id="color-picker-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={menuListProps}
      >
        <TabsContainer>
          <ColorPaletteTabs
            TabIndicatorProps={tabIndicatorProps}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Color Palette"
              {...a11yProps(0, value, theme, "160px")}
            />
            <Tab label="More" {...a11yProps(1, value, theme, "90px")} />
          </ColorPaletteTabs>
        </TabsContainer>
        <CustomTabPanel value={value} index={0}>
          <ColorPaletteTab setColor={setColor} handleClose={handleClose} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <MoreTab setColor={setColor} color={color} />
        </CustomTabPanel>
      </ColorPickerMenu>
    </ColorPickerWrapper>
  );
};

export default ColorPicker;
