import {
  BACKEND_URL,
  resource_attachments_download_and_delete_url,
  resource_attachments_url,
} from "../../../Constants";
import KeycloakService from "../../../services/KeycloakService";
import { baseApiSlice } from "./baseApiSlice";

export const resourceAttachmentsApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getResourceAttachments: builder.query({
      query: ({ resourceid, organizationId }) => ({
        url: resource_attachments_url
          .replace(":resourceid", resourceid)
          .replace(":organizationId", organizationId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["ResourceAttachment"],
    }),
    getResourceAttachment: builder.query({
      query: ({ attachmentUri }) => ({
        url: BACKEND_URL + attachmentUri,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: async (response) => {
        // Use response.blob() to create a Blob object
        const blob = await response;
        const objectURL = URL.createObjectURL(blob);
        return objectURL;
      },
      providesTags: ["ResourceAttachment"],
    }),
    uploadResourceAttachment: builder.mutation({
      query: ({ formData, resourceid, organizationId }) => ({
        url: resource_attachments_url
          .replace(":resourceid", resourceid)
          .replace(":organizationId", organizationId),
        method: "POST",
        body: formData,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["ResourceAttachment"],
    }),
    updateResourceAttachment: builder.mutation({
      query: ({
        formData,
        resourceid,
        organizationId,
        resourceAttachmentId,
        updateAttachment,
      }) => ({
        url:
          resource_attachments_download_and_delete_url
            .replace(":resourceid", resourceid)
            .replace(":resourceAttachmentId", resourceAttachmentId)
            .replace(":organizationId", organizationId) +
          `?update_attachment=${updateAttachment}`,
        method: "PUT",
        body: formData,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["ResourceAttachment"],
    }),
    deleteResourceAttachment: builder.mutation({
      query: ({ resourceid, organizationId, resourceAttachmentId }) => ({
        url: resource_attachments_download_and_delete_url
          .replace(":resourceid", resourceid)
          .replace(":resourceAttachmentId", resourceAttachmentId)
          .replace(":organizationId", organizationId),
        method: "DELETE",
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["ResourceAttachment"],
    }),
  }),
});

export const {
  useGetResourceAttachmentsQuery,
  useGetResourceAttachmentQuery,
  useUploadResourceAttachmentMutation,
  useUpdateResourceAttachmentMutation,
  useDeleteResourceAttachmentMutation,
} = resourceAttachmentsApiSlice;
