import React from "react";
import { DoughnutStack, StyledDoughnut } from "../../../styles/reports/ChartWidgets.styles";

const DonutChartWidgetContent = ({ data }) => {
  return (
    <DoughnutStack>
      <StyledDoughnut
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
            },
          },
        }}
        data={data}
      />
    </DoughnutStack>
  );
};

export default DonutChartWidgetContent;
