import { Box, Paper, useTheme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation, transitionDirections } from "../../../util/utils";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import AddImageDialog from "../asset-form/AddImageDialog";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectParentId } from "../../../store/slices/columnViewSlice";

const AddImageButton = ({ resourceImages, imageCategory }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const { resourceid: resourceId } = useParams();
  const navigate = useNavigate();

  // Selectors
  const parentId = useSelector(selectParentId);

  // State
  const [openImageDialog, setOpenImageDialog] = useState(false);

  // Handlers
  const handleImageAddClick = () => {
    if (mobileMatches) {
      navigate(
        `/resources/${resourceId}/add-image?direction=${transitionDirections.RIGHT_TO_LEFT}&imageCategory=${imageCategory}`
      );
    } else {
      setOpenImageDialog(true);
    }
  };

  return (
    (imageCategory === "OTHER" ||
      !resourceImages?.some(
        (resourceImage) => resourceImage.imageCategory === imageCategory
      )) && (
      <>
        <Paper
          sx={{
            minWidth: "100px",
            width: "100px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "4px",
            paddingBlock: "15px",
            boxSizing: "border-box",
            cursor: "pointer",
          }}
          onClick={handleImageAddClick}
        >
          <Box>
            <AddIcon sx={{ color: theme.palette.secondary.contrastText }} />
          </Box>

          <Box>
            {
              <Typography sx={{ color: theme.palette.secondary.contrastText }}>
                {getTranslation(imageCategory, t, i18n)}
              </Typography>
            }
          </Box>
        </Paper>

        {openImageDialog && (
          <AddImageDialog
            open={openImageDialog}
            setOpen={setOpenImageDialog}
            imageCategory={imageCategory}
            resourceId={resourceId || parentId}
          />
        )}
      </>
    )
  );
};

export default AddImageButton;
