import { useTranslation } from "react-i18next";
import { Box, MenuList, useTheme } from "@mui/material";
import PreferenceItem from "../../components/PreferenceItem";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateUserLanguageMutation } from "../../store/slices/api/userManagementSlice";
import { refreshUser, selectUser } from "../../store/slices/authSlice";
import {
  supportedLanguages,
  getFallBackLanguage,
  getTranslation,
  transitionDirections,
} from "../../util/utils";
import { messageError, messageSuccess } from "../../util/notification";
import { StyledDivider } from "../../components/styles/profile/Profile.styles";
import { RouletteSpinnerOverlay } from "react-spinner-overlay";
import PageTransition from "../../components/PageTransition";
import { useNavigate, useSearchParams } from "react-router-dom";
import LanguageHeader from "../../navigation/header/profile/LanguageHeader";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";

const Language = () => {
  // General hooks
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Selectors
  const user = useSelector(selectUser);
  const { language } = user;

  // Mutations
  const [updateUserLanguage, { isLoading: isLoadingUserLanguage }] =
    useUpdateUserLanguageMutation();

  // Handlers
  const handleClick = async (languageCode) => {
    try {
      const data = await updateUserLanguage({
        userId: user.id,
        language: languageCode,
        organizationId: user.organizations[0].id,
      }).unwrap();

      if (data) {
        dispatch(refreshUser(data));
        i18n.changeLanguage(data.language);
        i18n.options.fallbackLng = getFallBackLanguage(data.language);
      }

      navigate(
        `/profile/preferences?direction=${transitionDirections.LEFT_TO_RIGHT}`
      );
      messageSuccess(
        `${getTranslation(`successfulUpdateLanguage_${languageCode}`, t, i18n)}`
      );
    } catch (error) {
      messageError(getTranslation("failedUpdateLanguage", t, i18n));
    }
  };

  const goBackHandler = () =>
    navigate(
      `/profile/preferences?direction=${transitionDirections.LEFT_TO_RIGHT}`
    );

  // Other variables
  const transitionDirection = searchParams.get("direction");

  return (
    <AppAccess>
      <ErrorHandling isLoading={isLoadingUserLanguage} isError={false}>
        <PageTransition direction={transitionDirection}>
          <LanguageHeader goBackHandler={goBackHandler} />
          <MenuList>
            <StyledDivider />
            {supportedLanguages.map((lang, index) => (
              <Box key={index + lang}>
                <PreferenceItem
                  handleClick={() => handleClick(lang.code)}
                  menuId={`btn-menu-item-language-${lang.code}`}
                  label={t(lang.code, { lng: lang.code })}
                  isChecked={language === lang.code}
                />
                <StyledDivider />
              </Box>
            ))}
          </MenuList>
        </PageTransition>
      </ErrorHandling>
    </AppAccess>
  );
};

export default Language;
