import { Box, Divider, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addTag,
  DEFAULT_COLOR,
  selectAllGlobalTags,
  selectAvailableTags,
  selectCurrentColor,
  selectError,
  selectInputValue,
  selectIsInDialog,
  setAutocompleteOpen,
  setCurrentColor,
  setInputValue,
  setMarkedTag,
} from "../../../store/slices/tagsSlice";
import { useLocation } from "react-router-dom";
import DefaultTagResults from "./DefaultTagResults";
import TopTagResults from "./TopTagResults";
import BottomTagResults from "./BottomTagResults";
import SingleTagItem from "./SingleTagItem";
import {
  RecentlyUsedText,
  TagsMenuPaper,
} from "../../styles/assets/tags/Tags.styles";
import { getTranslation, TAGS_MIN_CHARACTERS } from "../../../util/utils";
import { useTranslation } from "react-i18next";
import { messageError } from "../../../util/notification";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";

const TagsMenu = () => {
  // General hooks
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET) ? 1 : 0;
  const { t, i18n } = useTranslation();

  // Selectors
  const tags = useSelector(selectAllGlobalTags);
  const tagsForResource = useSelector(selectAvailableTags);
  const inputValue = useSelector(selectInputValue);
  const error = useSelector(selectError);
  const currentColor = useSelector(selectCurrentColor);
  const isInDialog = useSelector(selectIsInDialog);

  // Other variables
  const formattedValue =
    [...tags]
      ?.filter((option) => !tagsForResource.some((tag) => tag.id === option.id))
      ?.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        return nameA > nameB ? 1 : -1;
      })
      ?.map((option) => {
        return {
          ...option,
          label: option.name,
          value: option.id,
        };
      }) ?? [];

  const startingTags =
    [...formattedValue]
      ?.sort((a, b) => {
        const nameA = a.label.toLowerCase();
        const nameB = b.label.toLowerCase();

        return nameA > nameB ? 1 : -1;
      })
      ?.filter((tag) =>
        tag.label.toLowerCase().startsWith(inputValue.toLowerCase())
      ) ?? [];

  const [...includingTags] = formattedValue
    ?.sort((a, b) => {
      const nameA = a.label.toLowerCase();
      const nameB = b.label.toLowerCase();

      return nameA > nameB ? 1 : -1;
    })
    ?.filter(
      (tag) =>
        !tag.label.toLowerCase().startsWith(inputValue.toLowerCase()) &&
        tag.label.toLowerCase().includes(inputValue.toLowerCase())
    );

  const isNew =
    !formattedValue.some(
      (tag) => tag.label.toLowerCase() === inputValue.toLowerCase()
    ) &&
    !tagsForResource.some(
      (tag) => tag.name.toLowerCase() === inputValue.toLowerCase()
    );

  // Sorting logic not decided yet
  const formattedRecents =
    [...tags]
      ?.filter((option) => !tagsForResource.some((tag) => tag.id === option.id))
      ?.sort((a, b) => {
        const dateA = new Date(a.lastAssignment);
        const dateB = new Date(b.lastAssignment);

        return dateB > dateA ? 1 : -1;
      })
      ?.slice(0, 5)
      ?.map((option) => {
        return {
          ...option,
          label: option.name,
          value: option.id,
        };
      }) ?? [];

  const routeCheck = pathname.includes("tags") && pathname.includes("edit");

  // Handlers
  const handleAddTag = (tag) => {
    if (!Boolean(tag.color) && inputValue.length < TAGS_MIN_CHARACTERS) {
      messageError(getTranslation("TAGS_MIN_CHARACTERS", t, i18n));
      return;
    }

    if (Boolean(tag.color)) {
      dispatch(addTag({ name: tag.label, color: tag.color }));
    } else {
      dispatch(addTag({ name: inputValue, color: currentColor }));
    }

    dispatch(setInputValue(""));
    dispatch(setCurrentColor(DEFAULT_COLOR));
    dispatch(setMarkedTag(null));
    dispatch(setAutocompleteOpen(false));
  };

  const handleClickTag = (tag) => {
    if (isInDialog || routeCheck) {
      handleAddTag(tag);
      dispatch(setMarkedTag(null));
      dispatch(setAutocompleteOpen(false));
    }
  };

  return (
    <TagsMenuPaper istablet={tabletMatches}>
      {!inputValue
        ? !error &&
          // Default results
          (formattedValue.length <= 0 && formattedRecents.length <= 0 ? (
            <RecentlyUsedText>
              {getTranslation("NO_TAGS", t, i18n)}
            </RecentlyUsedText>
          ) : (
            <DefaultTagResults
              formattedValue={formattedValue}
              formattedRecents={formattedRecents}
              handleClickTag={handleClickTag}
            />
          ))
        : !error && (
            <Box>
              {startingTags.length <= 0 &&
                includingTags.length <= 0 &&
                !isNew && (
                  <RecentlyUsedText>
                    {getTranslation("NO_TAGS", t, i18n)}
                  </RecentlyUsedText>
                )}

              {/* Top results */}
              <TopTagResults
                startingTags={startingTags}
                handleClickTag={handleClickTag}
              />

              {/* Divider */}
              {startingTags.length > 0 && includingTags.length > 0 && (
                <Divider variant="middle" />
              )}

              {/* Bottom results */}
              <BottomTagResults
                includingTags={includingTags}
                handleClickTag={handleClickTag}
              />

              {/* New tag */}
              {isNew && (
                <SingleTagItem
                  color={currentColor}
                  isNew={true}
                  handler={handleAddTag}
                />
              )}
            </Box>
          )}
    </TagsMenuPaper>
  );
};

export default TagsMenu;
