import { styled } from "@mui/system";
import { PreferenceArrowIcon } from "./ProfilePreferenceItem.styles";

export const PreferenceRoleIcon = styled(PreferenceArrowIcon)(
  ({ user, userRoles, currentUser }) => ({
    visibility:
      userRoles.some((r) => r.name === "OWNER") || currentUser.id === user.id
        ? "hidden"
        : "visible",
  })
);
