import React from "react";
import { Stack } from "@mui/material";
import { getTranslation } from "../../../../util/utils";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { SecondaryText } from "../../../../components/styles/assets/ListInlineView.styles";
import { NavigationButton } from "../../../../components/styles/profile/ProfileDesktopHeader.styles";

const ProfileDesktopHeader = () => {
  // General hooks
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();

  // Other variables
  const isProfile = pathname === "/profile";
  const isOrganization = pathname.includes("/organization");
  const isPreferences = pathname.includes("/preferences");

  // Handlers
  const handleNavigateProfile = () => {
    navigate("/profile");
  };

  const handleNavigateOrganization = () => {
    navigate("/profile/organization");
  };

  const handleNavigatePreferences = () => {
    navigate("/profile/preferences");
  };

  return (
    <Stack
      direction="row"
      columnGap={2}
      sx={{ padding: "20px 30px" }}
      style={{ flexDirection: "row", flex: 0 }}
    >
      <NavigationButton active={isProfile} onClick={handleNavigateProfile}>
        <SecondaryText
          variant={isProfile ? "h6" : "body2"}
          fontWeight={isProfile ? "bolder" : ""}
        >
          {getTranslation("PERSONAL_DATA", t, i18n)}
        </SecondaryText>
      </NavigationButton>
      <NavigationButton
        active={isOrganization}
        onClick={handleNavigateOrganization}
      >
        <SecondaryText
          variant={isOrganization ? "h6" : "body2"}
          fontWeight={isOrganization ? "bolder" : ""}
        >
          {getTranslation("organization", t, i18n)}
        </SecondaryText>
      </NavigationButton>
      <NavigationButton
        active={isPreferences}
        onClick={handleNavigatePreferences}
      >
        <SecondaryText
          variant={isPreferences ? "h6" : "body2"}
          fontWeight={isPreferences ? "bolder" : ""}
        >
          {getTranslation("PREFERENCES", t, i18n)}
        </SecondaryText>
      </NavigationButton>
    </Stack>
  );
};

export default ProfileDesktopHeader;
