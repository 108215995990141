import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import { useGetTypeImageQuery } from "../../../store/slices/api/typesApiSlice";
import CanvasImage from "../../common/CanvasImage";
import ErrorHandling from "../../common/ErrorHandling";

const TypeCarouselImage = ({ image, currentIndex, index, slidesToShow }) => {
  // Selectors
  const user = useSelector(selectUser);

  // Queries
  const {
    data: typeImageUri,
    isLoading: isLoadingImage,
    isError,
  } = useGetTypeImageQuery(
    {
      imageUri: image.uri,
      organizationId: user?.organizations?.find((o) => o.default).id,
    },
    {
      skip:
        !image ||
        !(
          index >= currentIndex * slidesToShow &&
          index < slidesToShow * (currentIndex + 1)
        ),
    }
  );

  return (
    <ErrorHandling isLoading={isLoadingImage} isError={isError}>
      {typeImageUri && (
        <CanvasImage
          id="library-image-big"
          width={300}
          height={200}
          style={{ display: "block", margin: `0 auto`, paddingBlock: "40px" }}
          image={typeImageUri}
        />
      )}
    </ErrorHandling>
  );
};

export default TypeCarouselImage;
