import {
  Drawer as MuiDrawer,
  IconButton,
  List,
  styled,
  Backdrop,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  COLLAPSED_DRAWER_WIDTH,
  EXPANDED_DRAWER_WIDTH,
} from "../../util/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectIsDrawerOpen,
  setIsDrawerOpen,
} from "../../store/slices/appSlice";
import { useLocation, useNavigate } from "react-router-dom";
import useOrientation from "../../hooks/useOrientation";
import EventBus, { EVENT_DISCARD_CHANGES } from "../../util/EventBus";
import DrawerMenuItem from "./DrawerMenuItem";
import { NavigationActionIcon } from "../../components/styles/menu/Menu.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";

const drawerWidth = EXPANDED_DRAWER_WIDTH;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: COLLAPSED_DRAWER_WIDTH,
  [theme.breakpoints.up("sm")]: {
    width: COLLAPSED_DRAWER_WIDTH,
  },
});

const DrawerHeader = styled("div")(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: open ? "start" : "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MENUS = [
  {
    id: 1,
    name: "ASSETS",
    to: "/resources",
    icon: "file_copy",
    keyWords: ["resources", "tags-filter", "search"],
  },
  {
    id: 2,
    name: "REPORTS",
    to: "/reports",
    icon: "find_in_page",
    keyWords: ["reports"],
  },
  // TODO: Will be combined for assets and library
  {
    id: 3,
    name: "FAVORITES",
    to: "/favorites",
    icon: "star_rate",
    keyWords: ["favorites"],
  },
  {
    id: 4,
    name: "MODEL_LIBRARY",
    to: "/library",
    icon: "local_library",
    variant: "outlined",
    keyWords: ["library"],
  },
  // TODO: Will be combined for assets and library
  {
    id: 5,
    name: "LAST_USED",
    to: "/recents",
    icon: "timer_outlined",
    variant: "outlined",
    keyWords: ["recents"],
  },
];

const AppDrawer = () => {
  // General hooks
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Custom hooks
  const orientation = useOrientation();

  // Selectors
  const isDrawerOpen = useSelector(selectIsDrawerOpen);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // State
  const [currentMenuId, setCurrentMenuId] = useState(null);

  // Handlers
  const handleDrawerOpen = () => {
    dispatch(setIsDrawerOpen(true));
  };

  const handleDrawerClose = () => {
    dispatch(setIsDrawerOpen(false));
  };

  const handleNavigate = (to) => {
    if (orientation === "portrait") {
      dispatch(setIsDrawerOpen(false));
    }

    handleNavigation(to);
  };

  // Used for tracking for discard changes
  const handleNavigation = (route) => {
    if (EventBus.checkEventTypeExisting(EVENT_DISCARD_CHANGES)) {
      EventBus.dispatch(EVENT_DISCARD_CHANGES, { route });
    } else {
      navigate(route);
    }
  };

  // Effects
  useEffect(() => {
    const existingMenu = MENUS.find((menu) => {
      return menu.keyWords.some((keyWord) => pathname.includes(keyWord));
    });

    setCurrentMenuId(existingMenu?.id);
  }, [pathname]);

  useEffect(() => {
    if (desktopMatches) {
      dispatch(setIsDrawerOpen(true));
    }
  }, []);

  return (
    <>
      <Drawer variant="permanent" open={isDrawerOpen}>
        <DrawerHeader></DrawerHeader>
        <DrawerHeader open={isDrawerOpen}>
          {isDrawerOpen ? (
            <IconButton onClick={handleDrawerClose}>
              <NavigationActionIcon
                active={true}
                customfontsize={globalFontSize}
              >
                chevron_left
              </NavigationActionIcon>
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              <NavigationActionIcon
                active={true}
                customfontsize={globalFontSize}
              >
                chevron_right
              </NavigationActionIcon>
            </IconButton>
          )}
        </DrawerHeader>
        <List id="drawer-list">
          {MENUS.map((menu) => (
            <DrawerMenuItem
              key={menu.id}
              menu={menu}
              active={menu.id === currentMenuId}
              handleNavigate={() => handleNavigate(menu.to)}
              isDrawerOpen={isDrawerOpen}
            />
          ))}
        </List>
      </Drawer>
      {orientation === "portrait" && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={isDrawerOpen}
          onClick={handleDrawerClose}
        />
      )}
    </>
  );
};

export default AppDrawer;
