export const COLOR_PALETTES = [
  {
    foregroundColor: "white",
    backgroundColor: "#01af35",
  },
  {
    foregroundColor: "white",
    backgroundColor: "#3077ce",
  },
  {
    foregroundColor: "white",
    backgroundColor: "#9855d4",
  },
  {
    foregroundColor: "black",
    backgroundColor: "#ffdc07",
  },
  {
    foregroundColor: "black",
    backgroundColor: "#ff9434",
  },
  {
    foregroundColor: "white",
    backgroundColor: "#ee0000",
  },
  {
    foregroundColor: "black",
    backgroundColor: "#ffffff",
  },
  {
    foregroundColor: "white",
    backgroundColor: "#767676",
  },
  {
    foregroundColor: "white",
    backgroundColor: "#000000",
  },
];
