import { useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import DesktopThirdPartyNotices from "../components/about/DesktopThirdPartyNotices";
import ThirdPartyNoticesCard from "../components/about/ThirdPartyNoticesCard";
import PageTransition from "../components/PageTransition";
import LegalNoticesHeader from "../navigation/header/about/LegalNoticesHeader";
import { transitionDirections } from "../util/utils";
import { VIEWPORT_MEDIA_QUERIES } from "../util/viewport-utils";
import { NoticeCardsContainer } from "./styles/ThirdPartyNoticesPage.styles";
import AppAccess from "../components/common/AppAccess";

const ThirdPartyNoticesPage = () => {
  // General hooks
  const navigate = useNavigate();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const [searchParams] = useSearchParams();

  // Other variables
  const transitionDirection = searchParams.get("direction");

  // Handlers
  const goBackHandler = () =>
    navigate(`/about?direction=${transitionDirections.LEFT_TO_RIGHT}`);

  return (
    <AppAccess>
      {mobileMatches ? (
        <PageTransition direction={transitionDirection}>
          <LegalNoticesHeader goBackHandler={goBackHandler} />
          <NoticeCardsContainer>
            <ThirdPartyNoticesCard
              category="STATE_MANAGEMENT"
              name="Redux Toolkit"
              link="https://redux.js.org/"
            />

            <ThirdPartyNoticesCard
              category="NAVIGATION"
              name="React Router"
              link="https://reactrouter.com/en/main"
            />

            <ThirdPartyNoticesCard
              category="TRANSLATION"
              name="react-i18next"
              link="https://react.i18next.com/"
            />

            <ThirdPartyNoticesCard
              category="INFINITE_SCROLL"
              name="react-infinite-scroll-component"
              link="https://github.com/ankeetmaini/react-infinite-scroll-component#readme"
            />

            <ThirdPartyNoticesCard
              category="STYLING"
              name="Material UI"
              link="https://mui.com/"
            />

            <ThirdPartyNoticesCard
              category="CHARTS"
              name="react-chartjs-2"
              link="https://react-chartjs-2.js.org/"
            />

            <ThirdPartyNoticesCard
              category="TOASTS"
              name="React-Toastify"
              link="https://github.com/fkhadra/react-toastify#readme"
            />

            <ThirdPartyNoticesCard
              category="OVERLAY"
              name="React Spinner Overlay"
              link="https://arisaokasaka.github.io/react-spinner-overlay/"
            />

            <ThirdPartyNoticesCard
              category="CAROUSEL"
              name="React Responsive Carousel"
              link="https://www.npmjs.com/package/react-responsive-carousel"
            />

            <ThirdPartyNoticesCard
              category="TESTING"
              name="React Testing Library"
              link="https://testing-library.com/"
            />
          </NoticeCardsContainer>
        </PageTransition>
      ) : (
        <DesktopThirdPartyNotices />
      )}
    </AppAccess>
  );
};

export default ThirdPartyNoticesPage;
