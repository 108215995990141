import {
  BackTitle,
  HeaderGridContainer,
  HeaderWrapper,
  LeftActionButton,
  PageTitle,
  StyledAddIcon,
  StyledChevronLeftIcon,
} from "../../../components/styles/header/Header.styles";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";

const OrganizationHeader = ({ goBackHandler, ownerOrg, actionAddFunction }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  return (
    <HeaderWrapper id="sticky-header">
      <HeaderGridContainer container>
        <Grid item xs={3}>
          <LeftActionButton id="back-button" onClick={goBackHandler}>
            <StyledChevronLeftIcon
              id="header-back-button-icon"
              customfontsize={globalFontSize * 2}
            />
            <BackTitle component="span" id="previous_page-title">
              {getTranslation("user", t, i18n)}
            </BackTitle>
          </LeftActionButton>
        </Grid>
        <Grid item xs={6}>
          <PageTitle variant="h6" id="title">
            {getTranslation("organization", t, i18n)}
          </PageTitle>
        </Grid>
        <Grid display="flex" justifyContent="end" item xs={3}>
          {ownerOrg === undefined && (
            <StyledAddIcon
              customfontsize={globalFontSize * 2}
              id="header-logout-button-icon"
              onClick={actionAddFunction}
            ></StyledAddIcon>
          )}
        </Grid>
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default OrganizationHeader;
