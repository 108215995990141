import { Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { EllipseText } from "../../home/Home.styles";

export const HeadingContainer = styled(Box)({
  display: "flex",
});

export const ResourceNameWrapper = styled(Box)({
  flexGrow: 1,
  overflow: "hidden",
});

export const ResourceNameText = styled(EllipseText)({
  color: "inherit",
});

export const DynamicVisibilityIconButton = styled(IconButton)(
  ({ isvisible }) => ({
    visibility: isvisible ? "visible" : "hidden",
  })
);

export const LeftSpacedIconButton = styled(IconButton)({
  marginLeft: "16px",
});

export const FunctionNameText = styled(Typography)(({ theme }) => ({
  color:
    theme.palette.mode === "light" ? "#BFBFBF" : theme.palette.secondary.dark,
}));
