import { Box, Checkbox, Paper, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";
import CountWidgetContent from "../categorized-widgets/CountWidgetContent";
import { StyledFormControlLabel } from "../../../styles/general/General.styles";

const CountWidgetForm = () => {
  // States
  const [title, setTitle] = useState();
  const [displayHome, setDisplayHome] = useState(false);
  const [count, setCount] = useState(0);
  const [recentCount, setRecentCount] = useState(0);

  // Handlers
  const handleChangeTitle = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
  };

  const handleChangeCount = (e) => {
    const newCount = e.target.value;
    setCount(newCount);
  };

  const handleChangeRecentCount = (e) => {
    const newRecentCount = e.target.value;
    setRecentCount(newRecentCount);
  };

  const handleChangeDisplayHome = (e) => {
    const newDisplayHome = e.target.checked;
    setDisplayHome(newDisplayHome);
  };

  return (
    <Stack spacing={3}>
      <Box>
        <SecondaryText gutterBottom>Configure Count</SecondaryText>
        <Box sx={{ flexGrow: 1 }}>
          <TextField
            label="Title"
            id="title"
            value={title}
            size="small"
            onChange={handleChangeTitle}
          />
          <TextField
            label="Count"
            id="count"
            type="number"
            value={count}
            size="small"
            onChange={handleChangeCount}
          />
          <TextField
            label="Recent Count"
            id="recentCount"
            type="number"
            value={recentCount}
            size="small"
            onChange={handleChangeRecentCount}
          />
        </Box>
      </Box>
      <StyledFormControlLabel
        control={
          <Checkbox checked={displayHome} onChange={handleChangeDisplayHome} />
        }
        label="Show on homescreen"
      />
      <Box>
        <SecondaryText gutterBottom>Preview</SecondaryText>
        <Paper
          sx={{
            height: "180px",
            backgroundColor: "#F1F1F1",
          }}
        >
          <Stack sx={{ height: "100%" }}>
            <SecondaryText
              sx={{ padding: "8px" }}
              variant="body2"
              textAlign="center"
            >
              {title}
            </SecondaryText>
            <CountWidgetContent count={count} recentCount={recentCount} />
          </Stack>
        </Paper>
      </Box>
    </Stack>
  );
};

export default CountWidgetForm;
