import { Suspense } from "react";
import "dayjs/locale/de";
import "dayjs/locale/es-us";
import "dayjs/locale/en-gb";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import i18n from "i18next";
import { I18nextProvider } from "react-i18next";
import { addListener, launch } from "devtools-detector";
import {
  DISABLE_DEV_TOOLS,
  ENABLE_DEBUGGER_PROTECTION,
  ENABLE_REDIRECT_PROTECTION,
  REDIRECT_PROTECTION_URL,
} from "./Constants";
import { ErrorBoundary } from "react-error-boundary";
import ErrorModal from "./components/ErrorModal";

const root = ReactDOM.createRoot(document.getElementById("root"));

const renderApp = () => {
  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <Suspense>
            <ErrorBoundary fallback={<ErrorModal />}>
              <App />
            </ErrorBoundary>
          </Suspense>
        </I18nextProvider>
      </Provider>
    </BrowserRouter>
  );
};

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Hiding dev tools
function disableDevtool() {
  // Disable right-click
  document.addEventListener("contextmenu", (e) => e.preventDefault());

  function ctrlShiftKey(e, key) {
    return e.ctrlKey && e.shiftKey && e.key === key;
  }

  document.addEventListener("keydown", (e) => {
    // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
    if (
      e.key === "F12" ||
      ctrlShiftKey(e, "I") ||
      ctrlShiftKey(e, "J") ||
      (e.ctrlKey && e.key === "u")
    ) {
      e.preventDefault();
    }
  });
}

function devtoolTrap(isEnabled) {
  function recursiveFunction(counter) {
    if (typeof isEnabled === "string") {
      while (true) {}
    }

    if (`${isEnabled / isEnabled}`.length !== 1 || isEnabled % 20 === 0) {
      (() => true).constructor("debugger").call("action");
    } else {
      (() => false).constructor("debugger").apply("stateObject");
    }
    recursiveFunction(++counter);
  }

  try {
    if (isEnabled) {
      return recursiveFunction;
    }

    recursiveFunction(0);
  } catch (error) {}
}

if (ENABLE_REDIRECT_PROTECTION === "true") {
  // 1. add listener
  addListener((isOpen) =>
    isOpen
      ? window.location.assign(REDIRECT_PROTECTION_URL)
      : console.log("devtools status: close")
  );
  // 2. launch detect
  launch();
}

if (DISABLE_DEV_TOOLS === "true") {
  // Disable keyboard shortcuts
  disableDevtool();
}

if (ENABLE_DEBUGGER_PROTECTION === "true") {
  // Initialize the devtools trap
  (() => {
    let devtoolsWindow;
    try {
      devtoolsWindow = {}.constructor("return this")();
    } catch (error) {
      devtoolsWindow = window;
    }

    devtoolsWindow.setInterval(devtoolTrap, 4000);
  })();
}
