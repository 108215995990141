import { styled } from "@mui/system";
import { Typography, Box, Stack } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import InfoIcon from "@mui/icons-material/InfoOutlined";

export const AssetImage = styled("img")({
  width: "100%",
  height: "auto",
});

export const AssetInfoDetailsContainer = styled(Stack)({
  marginLeft: "16px",
});

export const AssetActions = styled(Box)({
  marginLeft: "10px",
  display: "flex",
  alignItems: "center",
});

export const AssetName = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const AssetCategory = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const FavoriteIcon = styled(StarIcon)(
  ({ theme, isfavorite, customfontsize }) => ({
    color:
      isfavorite === 1
        ? theme.palette.secondary.contrastText
        : theme.palette.primary.main,
    fontSize: `${customfontsize}px`,
    marginRight: "4px",
  })
);

export const StyledInfoIcon = styled(InfoIcon)(({ theme, customfontsize }) => ({
  color: theme.palette.primary.main,
  marginRight: "10px",
  fontSize: `${customfontsize}px`,
}));
