import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@react-three/drei";
import React, { useMemo, useState } from "react";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";
import { StyledFormControlLabel } from "../../../styles/general/General.styles";
import PieChartWidgetContent from "../categorized-widgets/PieChartWidgetContent";
import {
  selectAggregations,
  selectGroupFields,
} from "../../../../store/slices/reportsSlice";
import { useSelector } from "react-redux";
import {
  getReasonablePercentageDistribution,
  handleMapDynamicAggregationResult,
} from "../../../../util/reports-utils";
import { stringToColor } from "../../../../util/string-to-color";
import { getTranslation } from "../../../../util/utils";
import { useTranslation } from "react-i18next";

const PieChartWidgetForm = ({ rows }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const aggregations = useSelector(selectAggregations);
  const groupFields = useSelector(selectGroupFields);

  // Other variables
  const resultAggregations = useMemo(
    () => handleMapDynamicAggregationResult(rows, groupFields, aggregations),
    [rows, groupFields, aggregations]
  );

  // States
  const [title, setTitle] = useState();
  const [displayHome, setDisplayHome] = useState(false);
  const [groupField, setGroupField] = useState(groupFields[0].field);
  const [aggregationId, setAggregationId] = useState(resultAggregations[0].id);

  // Handlers
  const handleChangeTitle = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
  };

  const handleChangeDisplayHome = (e) => {
    const newDisplayHome = e.target.checked;
    setDisplayHome(newDisplayHome);
  };

  const handleChangeGroupField = (e) => {
    const newGroupField = e.target.value;
    setGroupField(newGroupField);
  };

  const handleChangeAggregationId = (e) => {
    const newAggregationId = e.target.value;
    setAggregationId(newAggregationId);
  };

  // Other variables
  const groups = rows.map((row) => {
    const fieldValue = row[groupField];
    const value = row[aggregationId];

    return {
      label: fieldValue,
      count: value,
    };
  });

  const portions = useMemo(
    () => getReasonablePercentageDistribution(groups),
    [groups]
  );

  const data = {
    labels: portions.map((portion) => portion.label),
    datasets: [
      {
        label: "Test",
        data: portions.map((portion) => portion.count),
        backgroundColor: portions.map((portion) =>
          stringToColor(portion.label)
        ),
      },
    ],
  };

  return (
    <Stack spacing={3}>
      <SecondaryText gutterBottom>Configure Pie Chart</SecondaryText>
      <TextField
        label="Title"
        id="title"
        value={title}
        size="small"
        onChange={handleChangeTitle}
      />

      <FormControl fullWidth>
        <InputLabel id="group-field-select-label">Group field</InputLabel>
        <Select
          labelId="group-field-select-label"
          id="group-field-select"
          value={groupField}
          label="Group field"
          onChange={handleChangeGroupField}
        >
          {groupFields.map((field) => (
            <MenuItem value={field.field}>
              {getTranslation(field.field, t, i18n)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="aggregation-select-label">Aggregation</InputLabel>
        <Select
          labelId="aggregation-select-label"
          id="aggregation-select"
          value={aggregationId}
          label="Aggregation"
          onChange={handleChangeAggregationId}
        >
          {resultAggregations.map((a) => (
            <MenuItem value={a.id}>{getTranslation(a.id, t, i18n)}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <StyledFormControlLabel
        control={
          <Checkbox checked={displayHome} onChange={handleChangeDisplayHome} />
        }
        label="Show on homescreen"
      />
      <Box>
        <SecondaryText gutterBottom>Preview</SecondaryText>
        <Paper
          sx={{
            height: "180px",
            backgroundColor: "#F1F1F1",
          }}
        >
          <Stack sx={{ height: "100%" }}>
            <SecondaryText
              sx={{ padding: "8px" }}
              variant="body2"
              textAlign="center"
            >
              {title}
            </SecondaryText>
            <PieChartWidgetContent data={data} />
          </Stack>
        </Paper>
      </Box>
    </Stack>
  );
};

export default PieChartWidgetForm;
