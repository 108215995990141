import {
  get_reports,
  get_report_locations_by_category,
  get_report_widget,
  reporting_fields_by_type_url,
  reports_execution_base_url,
  reports_execution_url,
  get_report_definition_by_id_url,
  report_definition_fields_url,
  report_definition_filter_url,
  get_report_definition_filter_url,
  report_definition_url,
  report_definition_aggregations_url,
} from "../../../Constants";
import KeycloakService from "../../../services/KeycloakService";
import { baseApiSlice } from "./baseApiSlice";

export const reportsApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReports: builder.query({
      query: ({ organizationId, reportType, parentId }) => ({
        url: get_reports.replace(":organizationId", organizationId),
        params: {
          reportType,
          parentId,
        },
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["Reports"],
    }),
    getReportWidgetByCategory: builder.query({
      query: ({ category, organizationId }) => ({
        url: get_report_widget.replace(":organizationId", organizationId),
        params: {
          category,
        },
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["REPORT_WIDGET"],
    }),
    getReportWidgetManufacturers: builder.query({
      query: (organizationId) => ({
        url: get_report_widget.replace(":organizationId", organizationId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["REPORT_WIDGET"],
    }),
    getReportLocationsByCategory: builder.query({
      query: ({ organizationId, category }) => ({
        url: get_report_locations_by_category.replace(
          ":organizationId",
          organizationId
        ),
        params: {
          category,
        },
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["REPORT_LOCATIONS"],
    }),
    reportExecution: builder.query({
      query: ({
        organizationId,
        reportDefinitionId,
        parentId,
        aggregationResultContent,
      }) => ({
        url:
          reports_execution_url
            .replace(":organizationId", organizationId)
            .replace(":reportDefinitionId", reportDefinitionId) +
          `${aggregationResultContent ?? ""}`,
        params: {
          "parent-id": parentId ?? undefined,
        },
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: [
        "REPORT_WIDGET",
        "REPORT_LOCATIONS",
        "ReportDefinitionExecute",
      ],
    }),
    reportExecutionBase: builder.query({
      query: ({ organizationId, typeId, fields, filters, aggregations }) => ({
        url: reports_execution_base_url.replace(
          ":organizationId",
          organizationId
        ),
        method: "POST",
        body: {
          typeId,
          fields,
          filters,
          aggregations: aggregations ?? [],
        },
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: [],
    }),
    reportingFieldsByType: builder.query({
      query: ({ organizationId, typeId }) => ({
        url: reporting_fields_by_type_url
          .replace(":organizationId", organizationId)
          .replace(":typeId", typeId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: [],
    }),
    getReportDefinitions: builder.query({
      query: ({ organizationId }) => ({
        url: report_definition_url.replace(":organizationId", organizationId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["ReportDefinition"],
    }),
    getReportDefinitionById: builder.query({
      query: ({ organizationId, reportDefinitionId }) => ({
        url: get_report_definition_by_id_url
          .replace(":organizationId", organizationId)
          .replace(":reportDefinitionId", reportDefinitionId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["ReportDefinition"],
    }),
    getReportDefinitionFields: builder.query({
      query: ({ organizationId, reportDefinitionId }) => ({
        url: report_definition_fields_url
          .replace(":organizationId", organizationId)
          .replace(":reportDefinitionId", reportDefinitionId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["ReportDefinitionField"],
    }),
    getReportDefinitionFilters: builder.query({
      query: ({ organizationId, reportDefinitionId }) => ({
        url: report_definition_filter_url
          .replace(":organizationId", organizationId)
          .replace(":reportDefinitionId", reportDefinitionId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["ReportDefinitionFilter"],
    }),
    getReportDefinitionAggregations: builder.query({
      query: ({ organizationId, reportDefinitionId }) => ({
        url: report_definition_aggregations_url
          .replace(":organizationId", organizationId)
          .replace(":reportDefinitionId", reportDefinitionId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["ReportDefinitionAggregations"],
    }),
    createReportDefinition: builder.mutation({
      query: ({ organizationId, reportDefinition }) => ({
        url: report_definition_url.replace(":organizationId", organizationId),
        method: "POST",
        body: reportDefinition,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["ReportDefinition"],
    }),
    createReportDefinitionFilter: builder.mutation({
      query: ({
        organizationId,
        reportDefinitionId,
        reportDefinitionFilter,
      }) => ({
        url: report_definition_filter_url
          .replace(":organizationId", organizationId)
          .replace(":reportDefinitionId", reportDefinitionId),
        method: "POST",
        body: reportDefinitionFilter,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["ReportDefinitionFilter", "ReportDefinitionExecute"],
    }),
    createReportDefinitionFields: builder.mutation({
      query: ({
        organizationId,
        reportDefinitionId,
        reportDefinitionFields,
      }) => ({
        url: report_definition_fields_url
          .replace(":organizationId", organizationId)
          .replace(":reportDefinitionId", reportDefinitionId),
        method: "POST",
        body: reportDefinitionFields,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["ReportDefinitionField", "ReportDefinitionExecute"],
    }),
    createReportDefinitionAggregations: builder.mutation({
      query: ({
        organizationId,
        reportDefinitionId,
        reportDefinitionAggregations,
      }) => ({
        url: report_definition_aggregations_url
          .replace(":organizationId")
          .replace(":reportDefinitionId"),
        method: "POST",
        body: reportDefinitionAggregations,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: [
        "ReportDefinitionAggregations",
        "ReportDefinitionExecute",
      ],
    }),
    updateReportDefinition: builder.mutation({
      query: ({ organizationId, reportDefinitionId, reportDefinition }) => ({
        url: get_report_definition_by_id_url
          .replace(":organizationId", organizationId)
          .replace(":reportDefinitionId", reportDefinitionId),
        method: "PUT",
        body: reportDefinition,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["ReportDefinition"],
    }),
    updateReportDefinitionFields: builder.mutation({
      query: ({
        organizationId,
        reportDefinitionId,
        reportDefinitionFields,
      }) => ({
        url: report_definition_fields_url
          .replace(":organizationId", organizationId)
          .replace(":reportDefinitionId", reportDefinitionId),
        method: "PUT",
        body: reportDefinitionFields,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["ReportDefinitionField", "ReportDefinitionExecute"],
    }),
    updateReportDefinitionFilter: builder.mutation({
      query: ({
        organizationId,
        reportDefinitionId,
        reportDefinitionFilterId,
        reportDefinitionFilter,
      }) => ({
        url: get_report_definition_filter_url
          .replace(":organizationId", organizationId)
          .replace(":reportDefinitionId", reportDefinitionId)
          .replace(":reportDefinitionFilterId", reportDefinitionFilterId),
        method: "PUT",
        body: reportDefinitionFilter,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["ReportDefinitionFilter", "ReportDefinitionExecute"],
    }),
    updateReportDefinitionAggregations: builder.mutation({
      query: ({
        organizationId,
        reportDefinitionId,
        reportDefinitionAggregations,
      }) => ({
        url: report_definition_aggregations_url
          .replace(":organizationId", organizationId)
          .replace(":reportDefinitionId", reportDefinitionId),
        method: "PUT",
        body: reportDefinitionAggregations,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: [
        "ReportDefinitionAggregations",
        "ReportDefinitionExecute",
      ],
    }),
    deleteReportDefinition: builder.mutation({
      query: ({ organizationId, reportDefinitionId }) => ({
        url: get_report_definition_by_id_url
          .replace(":reportDefinitionId", reportDefinitionId)
          .replace(":organizationId", organizationId),
        method: "DELETE",
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["Reports", "ReportDefinition"],
    }),
  }),
});

export const {
  useGetReportsQuery,
  useGetReportWidgetByCategoryQuery,
  useGetReportWidgetManufacturersQuery,
  useGetReportLocationsByCategoryQuery,
  useReportExecutionQuery,
  useReportExecutionBaseQuery,
  useReportingFieldsByTypeQuery,
  useGetReportDefinitionsQuery,
  useGetReportDefinitionByIdQuery,
  useGetReportDefinitionFieldsQuery,
  useGetReportDefinitionFiltersQuery,
  useGetReportDefinitionAggregationsQuery,
  useCreateReportDefinitionMutation,
  useCreateReportDefinitionFieldsMutation,
  useCreateReportDefinitionFilterMutation,
  useCreateReportDefinitionAggregationsMutation,
  useUpdateReportDefinitionMutation,
  useUpdateReportDefinitionFieldsMutation,
  useUpdateReportDefinitionFilterMutation,
  useUpdateReportDefinitionAggregationsMutation,
  useDeleteReportDefinitionMutation,
} = reportsApiSlice;
