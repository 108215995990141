import { createSlice } from "@reduxjs/toolkit";

export const DEFAULT_RESOURCE_IMAGE = {
  imageCategory: "FRONT",
  mimeType: "image/png",
  selectedFile: null,
  file: null,
  resourceid: "",
  id: "",
  description: "",
  name: "",
  isFromDb: false,
};

// Initial state
const initialState = {
  list: [],
};

// Slice
const resourceImageSlice = createSlice({
  name: "resourceImage",
  initialState,
  reducers: {
    addResourceImage: (state, action) => {
      const newResourceImage = action.payload;
      state.list.push(newResourceImage);
    },
    editResourceImage: (state, action) => {
      const { index, resourceImage } = action.payload;
      state.list[index] = { ...resourceImage };
    },
    deleteResourceImage: (state, action) => {
      const index = Number(action.payload);
      state.list.splice(index, 1);
    },
    setList: (state, action) => {
      const newList = action.payload;
      state.list = newList;
    },
    clearList: (state) => {
      state.list = [];
    },
  },
});

// Export slice and its corresponding actions
export default resourceImageSlice.reducer;
export const {
  addResourceImage,
  editResourceImage,
  deleteResourceImage,
  setList,
  clearList,
} = resourceImageSlice.actions;

// selectors
export const selectResourceImages = (state) => state.resourceImage.list;
