import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { FavoriteIcon } from "./ListInlineView.styles";

export const ListGridViewContainer = styled(Grid)({
  marginTop: "16px",
});

export const ListGridViewCard = styled(Card)({
  maxWidth: 350,
  cursor: "pointer",
});

export const ListGridViewCardMedia = styled(CardMedia)({
  height: "100px",
  width: "100%",
  objectFit: "contain",
});

export const ListGridViewCardContent = styled(CardContent)({
  display: "flex",
  justifyContent: "start",
  padding: "5px !important",
});

export const GridFavoriteIcon = styled(FavoriteIcon)({
  marginRight: 10,
});

export const PrimaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

export const ResourceImageNameText = styled(PrimaryText)(({ theme }) => ({
  color: theme.palette.primary.main,
  flexGrow: 1,
  textAlign: "center",
}));
