import ResourceImageForm from "../../components/assets/asset-form/ResourceImageForm";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { messageError, messageSuccess } from "../../util/notification";
import PageTransition from "../../components/PageTransition";
import {
  transitionDirections,
  getTranslation,
  showValidationError,
} from "../../util/utils";
import ChooseAssetImageHeader from "../../navigation/header/assets/ChooseAssetImageHeader";
import { useDispatch } from "react-redux";
import {
  editResourceImage,
  selectResourceImages,
} from "../../store/slices/resourceImageSlice";
import ErrorHandling from "../../components/common/ErrorHandling";
import { selectUser } from "../../store/slices/authSlice";
import {
  useGetResourceImageQuery,
  useUpdateResourceImageMutation,
} from "../../store/slices/api/resourceImagesApiSlice";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const EditLocalResourceImagePage = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { resourceId } = useParams();

  // Selectors
  const user = useSelector(selectUser);
  const localResourceImages = useSelector(selectResourceImages);

  // Mutations
  const [updateResourceImage, { isLoading: isLoadingUpdate }] =
    useUpdateResourceImageMutation();

  // Other variables
  const direction = searchParams.get("direction");
  const index = Number(searchParams.get("index"));
  const singleResourceImage = localResourceImages[index];

  // Queries
  const {
    data: resourceImageUri,
    isLoading,
    isError,
    error,
  } = useGetResourceImageQuery(
    {
      imageUri: singleResourceImage ? singleResourceImage?.selectedFile : "",
      organizationId: user?.organizations?.find((o) => o.default).id,
    },
    {
      skip: !singleResourceImage || !singleResourceImage?.isFromDb,
    }
  );

  const transformedImage = {
    imageCategory: singleResourceImage?.imageCategory,
    selectedFile: resourceImageUri || singleResourceImage?.selectedFile,
    resourceid: "",
    id: singleResourceImage?.id,
    name: singleResourceImage?.name,
    mimeType: singleResourceImage?.name?.substring(
      singleResourceImage?.name?.lastIndexOf(".")
    ),
    description: singleResourceImage?.description,
    file: singleResourceImage?.file,
  };

  // State
  const [resourceImageInput, setResourceImageInput] =
    useState(transformedImage);

  const [name, setName] = useState(
    resourceImageInput?.name?.substring(
      0,
      resourceImageInput?.name?.lastIndexOf(".")
    )
  );

  const [extension, setExtension] = useState(
    resourceImageInput?.name?.substring(
      resourceImageInput?.name?.lastIndexOf(".")
    )
  );

  // Handlers
  const cancelHandler = () => {
    if (resourceId) {
      navigate(
        `/resources/${resourceId}/edit?direction=${transitionDirections.TOP_TO_BOTTOM}&previousPage=edit-local-image`
      );
    } else {
      navigate(
        `/resources/add-new?direction=${transitionDirections.TOP_TO_BOTTOM}&previousPage=edit-local-image`
      );
    }
  };

  // Other variables
  const isValid =
    (resourceImageInput.file || resourceImageInput.selectedFile) && name;

  const isDisabled = !isValid;

  const handleSubmit = useCallback(async () => {
    if (!resourceImageInput.selectedFile) {
      messageError(getTranslation("UPLOAD_IMAGE", t, i18n));
      return;
    }

    try {
      if (resourceId) {
        const formData = new FormData();

        const { id, file, imageCategory, description } = resourceImageInput;

        formData.append("file", file);
        formData.append("fileName", name + extension);
        formData.append("imageCategory", imageCategory);
        formData.append("description", description);

        await updateResourceImage({
          formData,
          resourceid: resourceId,
          organizationId: user?.organizations?.find((o) => o.default)?.id,
          updateImage: Boolean(file),
          resourceImageId: id,
        }).unwrap();

        messageSuccess(
          getTranslation("RESOURCE_IMAGE_UPDATED_SUCCESSFULLY", t, i18n)
        );
      } else {
        const resourceImage = {
          ...resourceImageInput,
          name: name + extension,
        };

        dispatch(editResourceImage({ index, resourceImage }));
      }

      cancelHandler();
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to update resource image", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceImageInput, name]);

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling
          isLoading={isLoadingUpdate || isLoading}
          isError={error?.status !== 404 && isError}
        >
          <PageTransition direction={direction}>
            <ChooseAssetImageHeader
              handleAction={handleSubmit}
              handleCancelAction={cancelHandler}
              isDisabled={isDisabled}
            />

            <HomePagePadding>
              <ResourceImageForm
                resourceImageInput={resourceImageInput}
                setResourceImageInput={setResourceImageInput}
                name={name}
                setName={setName}
                setExtension={setExtension}
              />
            </HomePagePadding>
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default EditLocalResourceImagePage;
