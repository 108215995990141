import React from "react";
import { List } from "@mui/material";
import { getTranslation } from "../../../util/utils";
import InfiniteScroll from "react-infinite-scroll-component";
import InfiniteScrollLoader from "../../InfiniteScrollLoader";
import { useTranslation } from "react-i18next";
import {
  HorizontalLineIcon,
  Indicator,
  IndicatorsContainer,
  IndicatorText,
  PagingText,
} from "../../styles/types/type-list/TypeListInlineView.styles";
import TypeItem from "./TypeItem";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { useSelector } from "react-redux";
import { NavigationActionIcon } from "../../styles/menu/Menu.styles";
import { NoDataTextContainer } from "../../styles/general/General.styles";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";

const TypeListInlineView = ({
  list,
  index,
  pageSize,
  fetchMoreData,
  totalRecord,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selector
  const globalFontSize = useSelector(selectGlobalFontSize);

  return (
    <List id="type-list">
      <InfiniteScroll
        dataLength={list?.length}
        next={fetchMoreData}
        hasMore={index < Math.ceil(totalRecord / pageSize)}
        loader={
          list?.length > 0 ? (
            <InfiniteScrollLoader />
          ) : (
            <NoDataTextContainer>
              <SecondaryText align="center">
                {getTranslation("NO_RESULTS_FOUND", t, i18n)}
              </SecondaryText>
            </NoDataTextContainer>
          )
        }
      >
        <IndicatorsContainer rowGap={1}>
          <Indicator>
            <NavigationActionIcon
              className="material-icons material-icons-outlined"
              customfontsize={globalFontSize}
            >
              person_outlined_outlined
            </NavigationActionIcon>

            <HorizontalLineIcon />
            <IndicatorText>
              {getTranslation("USER_CREATED_TYPE", t, i18n)}
            </IndicatorText>
          </Indicator>
          <Indicator>
            <NavigationActionIcon
              className="material-icons material-icons-outlined"
              customfontsize={globalFontSize}
            >
              local_library_outlined
            </NavigationActionIcon>
            <HorizontalLineIcon />
            <IndicatorText>
              {getTranslation("SYSTEM_CREATED_TYPE", t, i18n)}
            </IndicatorText>
          </Indicator>
        </IndicatorsContainer>
        <PagingText variant="h6">
          {getTranslation("ITEMS", t, i18n)
            .replace("{0}", list?.length)
            .replace("{1}", totalRecord)}
        </PagingText>
        {list?.map((row, i) => (
          <TypeItem type={row} />
        ))}
        <div id="end"></div>
      </InfiniteScroll>
    </List>
  );
};

export default TypeListInlineView;
