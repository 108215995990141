import { useState, useEffect } from "react";
import {
  useParams,
  useSearchParams,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "./FormValidation.css";
import AssetDetailCard from "../../components/assets/asset-detail/AssetDetailCard";
import { useDispatch, useSelector } from "react-redux";
import AssetDetailList from "../../components/assets/asset-detail/AssetDetailList";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import {
  useAddRecentMutation,
  useGetFavoritesQuery,
  useGetResourceDetailsQuery,
} from "../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../store/slices/authSlice";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import PageTransition from "../../components/PageTransition";
import { clearList, setList } from "../../store/slices/resourceImageSlice";
import {
  clearAttachmentList,
  setAttachmentList,
} from "../../store/slices/resourceAttachmentSlice";
import AssetDetailsHeader from "../../navigation/header/assets/AssetDetailsHeader";
import { transitionDirections } from "../../util/utils";
import { useGetResourceAttachmentsQuery } from "../../store/slices/api/resourceAttachmentsApiSlice";
import {
  useGetResourceTagsQuery,
  useGetTagsQuery,
} from "../../store/slices/api/tagsSlice";
import {
  setGlobalTags,
  setInitialTags,
  setTags,
} from "../../store/slices/tagsSlice";
import { OverflowContainer } from "../../components/styles/assets/asset-detail/AssetDetail.styles";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const AssetDetailPage = () => {
  // Global hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resourceid } = useParams();
  const [searchParams] = useSearchParams();
  const direction = searchParams.get("direction");
  const view = searchParams.get("view");

  // State
  const [bigImage] = useState(true);

  // Selectors
  const user = useSelector(selectUser);

  // Queries
  const {
    data: resourceData,
    isLoading: isLoadingResource,
    isError: isErrorResource,
    error,
  } = useGetResourceDetailsQuery(
    {
      resourceid,
      organizationId: user?.organizations?.find((o) => o.default).id,
    },
    {
      skip: !Boolean(resourceid) || resourceid === "undefined",
    }
  );

  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery({
    userId: user.id,
    organizationId: user?.organizations?.find((o) => o.default).id,
  });

  const {
    data: resourceAttachments,
    isLoading: isLoadingAttachments,
    isError: isErrorAttachments,
  } = useGetResourceAttachmentsQuery({
    resourceid,
    organizationId: user?.organizations?.find((o) => o.default).id,
  });

  const {
    data: favoritesData,
    isLoading: isLoadingFavorites,
    isError: isErrorFavorites,
  } = useGetFavoritesQuery({
    organizationId: user?.organizations?.find((o) => o.default).id,
  });

  const {
    data: tags,
    isLoading: isLoadingTags,
    isError: isErrorTags,
  } = useGetTagsQuery({
    organizationId: user?.organizations?.find((o) => o.default).id,
  });

  const {
    data: resourceTags,
    isLoading: isLoadingResourceTags,
    isErrorResourceTags,
  } = useGetResourceTagsQuery({
    resourceid,
    organizationId: user?.organizations?.find((o) => o.default).id,
  });

  // Mutations
  const [addRecent] = useAddRecentMutation();

  // Other variables
  const typeImages =
    resourceData?.type?.images.map((img) => {
      return {
        ...img,
        uri: `/organizations/${user?.organizations?.find((o) => o.default).id}${
          img.uri
        }`,
      };
    }) ?? [];

  const resourceImages = [...(resourceData?.images ?? []), ...typeImages];

  // Handlers
  const handleEditNavigate = () => {
    navigate(
      `/resources/${resourceData?.id}/edit?direction=${transitionDirections.RIGHT_TO_LEFT}`
    );
  };

  const handleAddToRecents = async () => {
    try {
      await addRecent({
        resourceid,
        organizationId: user?.organizations?.find((o) => o.default).id,
      }).unwrap();
    } catch (error) {
      console.error("Failed to add to recents", error);
    }
  };

  // Here, we will fetch the tags and put them into the Redux store (similar to resource images and attachments)
  useEffect(() => {
    if (tags) {
      const mappedTags = tags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setGlobalTags(mappedTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  useEffect(() => {
    if (resourceTags) {
      const mappedResourceTags = resourceTags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setInitialTags(mappedResourceTags));
      dispatch(setTags(mappedResourceTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceTags]);

  useEffect(() => {
    if (resourceid) {
      handleAddToRecents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceid]);

  if (error?.status === 404) {
    return <Navigate to="*" />;
  }

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling
          isLoading={
            isLoadingAttachments ||
            isLoadingFavorites ||
            isLoadingResource ||
            isLoadingResourceTags ||
            isLoadingTags ||
            isLoadingUserRoles
          }
          isError={
            isErrorAttachments ||
            isErrorFavorites ||
            isErrorResource ||
            isErrorResourceTags ||
            isErrorTags ||
            isErrorUserRoles
          }
        >
          <PageTransition direction={direction}>
            <AssetDetailsHeader
              userRoles={userRoles}
              actionEditFunction={handleEditNavigate}
              currentResourceData={resourceData}
              resourceid={resourceid}
              view={view}
              favorites={favoritesData}
            />
            <HomePagePadding id="asset-details-container">
              <OverflowContainer>
                <AssetDetailCard
                  bigImage={bigImage}
                  resourceData={resourceData}
                  mode=""
                  resourceImages={resourceImages}
                  userRoles={userRoles}
                />

                <AssetDetailList resourceData={resourceData} />

                <div id="end"></div>
              </OverflowContainer>
            </HomePagePadding>
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default AssetDetailPage;
