import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { HomePagePadding } from "../../general/General.styles";

export const DynamicPagePadding = styled(HomePagePadding)(
  ({ iscolumn, mobileMatches }) => ({
    padding: iscolumn ? 0 : "",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: !mobileMatches ? "space-between" : "",
  })
);

export const AssetListContainer = styled(Box)(({ isdesktop, isappview }) => ({
  width: "100%",
  maxWidth: isdesktop && !isappview ? "800px" : "100%",
}));
