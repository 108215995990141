import { useNavigate, useParams } from "react-router-dom";
import ErrorHandling from "../../components/common/ErrorHandling";
import {
  SecondaryTextOverflowWrap,
  StackContainer,
  TitleSection,
} from "../../components/styles/assets/reports/ReportDetails.styles";
import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  RESOURCE_CATEGORIES,
  THEME,
} from "../../util/utils";
import { useTranslation } from "react-i18next";
import SyntaxHighlighter from "react-syntax-highlighter";
import { xcode, a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { SecondaryText } from "../../components/styles/assets/ListInlineView.styles";
import {
  ASSET_LIST_PER_LOCATION_REPORT_DEFINITION_ID,
  ASSET_LIST_PER_RACK_REPORT_DEFINITION_ID,
} from "../../Constants";
import {
  ReportExecutionWrapper,
  StyledArrowDropDownIcon,
} from "../../components/styles/reports/Reports.styles";
import ReportExecutionResultTable from "../../components/reports/ReportExecutionResultTable";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { selectUser } from "../../store/slices/authSlice";
import { selectGlobalFontSize, selectTheme } from "../../store/slices/appSlice";
import {
  useDeleteReportDefinitionMutation,
  useGetReportDefinitionAggregationsQuery,
  useGetReportDefinitionByIdQuery,
  useGetReportDefinitionFieldsQuery,
  useGetReportDefinitionFiltersQuery,
  useReportExecutionQuery,
  useReportingFieldsByTypeQuery,
} from "../../store/slices/api/reportsApiSlice";
import { useGetResourcesByCategoryQuery } from "../../store/slices/api/assetManagementSlice";
import { generateSQLWhereClause } from "../../util/reports-utils";
import {
  ActionTitle,
  PageTitle,
} from "../../components/styles/header/Header.styles";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { NavigationActionIcon } from "../../components/styles/menu/Menu.styles";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import { resetWizard } from "../../store/slices/reportsSlice";
import { useDispatch } from "react-redux";
import MobileUpdateReportDialog from "../../components/reports/mobile-view/MobileUpdateReportDialog";
import { messageSuccess } from "../../util/notification";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const ReportDetailsPage = () => {
  // General hooks
  const dispatch = useDispatch();
  const { reportId } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const currentTheme = useSelector(selectTheme);

  // States
  const [executionFurtherFields, setExecutionFurtherFields] = useState([]);
  const [executionGroupFields, setExecutionGroupFields] = useState([]);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  // Queries
  const { data: userRoles, isLoading: isLoadingUserRoles } =
    useUserRolePermissionsQuery({
      userId: user.id,
      organizationId,
    });

  const { data: reportDefinitionData, isLoading: isLoadingReportDefinition } =
    useGetReportDefinitionByIdQuery(
      { organizationId, reportDefinitionId: reportId },
      {
        skip: !reportId,
      }
    );

  const { data: locationsData, isLoading: isLoadingLocations } =
    useGetResourcesByCategoryQuery(
      {
        organizationId: user?.organizations?.find((o) => o.default)?.id,
        category: RESOURCE_CATEGORIES.LOCATION,
      },
      {
        skip:
          reportDefinitionData?.id !==
          ASSET_LIST_PER_LOCATION_REPORT_DEFINITION_ID,
      }
    );

  const { data: racksData, isLoading: isLoadingRacks } =
    useGetResourcesByCategoryQuery(
      {
        organizationId: user?.organizations?.find((o) => o.default)?.id,
        category: RESOURCE_CATEGORIES.RACK,
      },
      {
        skip:
          reportDefinitionData?.id !== ASSET_LIST_PER_RACK_REPORT_DEFINITION_ID,
      }
    );

  // Mutations
  const [deleteReportDefinition] = useDeleteReportDefinitionMutation();

  // States
  const [selectedLocationId, setSelectedLocationId] = useState(
    locationsData?.length > 0 ? locationsData[0]?.id : null
  );

  const [selectedRackId, setSelectedRackId] = useState(
    racksData?.length > 0 ? racksData[0]?.id : null
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileUpdateDialogOpen, setMobileUpdateDialogOpen] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  // Other variables
  const parentId =
    reportDefinitionData?.id === ASSET_LIST_PER_LOCATION_REPORT_DEFINITION_ID
      ? selectedLocationId
      : reportDefinitionData?.id === ASSET_LIST_PER_RACK_REPORT_DEFINITION_ID
      ? selectedRackId
      : null;

  const open = Boolean(anchorEl);

  // Queries
  const { data: reportExecutionData, isLoading: isLoadingReportExecution } =
    useReportExecutionQuery(
      {
        organizationId,
        reportDefinitionId: reportId,
        parentId,
        aggregationResultContent:
          "?aggregation-result-content=GROUP_DETAIL&aggregation-result-content=AGGREGATION",
      },
      { skip: !reportId }
    );

  const { data: reportDefinitionFieldsData, isLoading: isLoadingReportFields } =
    useGetReportDefinitionFieldsQuery(
      { organizationId, reportDefinitionId: reportId },
      { skip: !reportId }
    );

  const {
    data: reportDefinitionFilterData,
    isLoading: isLoadingReportFilters,
  } = useGetReportDefinitionFiltersQuery(
    {
      organizationId,
      reportDefinitionId: reportId,
    },
    {
      skip: !reportId,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: reportDefinitionAggregationsData,
    isLoading: isLoadingReportAggregations,
  } = useGetReportDefinitionAggregationsQuery(
    {
      organizationId,
      reportDefinitionId: reportId,
    },
    { skip: !reportId }
  );

  const {
    data: reportFieldsByTypeData,
    isLoading: isLoadingReportFieldsByType,
  } = useReportingFieldsByTypeQuery(
    { organizationId, typeId: reportDefinitionData?.reportTypeId },
    {
      skip: !reportDefinitionData?.reportTypeId,
    }
  );

  // Other variables
  const rows = reportExecutionData?.data ?? [];

  const whereClause = generateSQLWhereClause(
    reportDefinitionFilterData ?? [],
    t,
    i18n,
    user?.region
  );

  const alert = {
    content: getTranslation("DELETE_REPORT_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DELETE", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  // Handlers
  const handleSelectLocation = (e) => {
    setSelectedLocationId(e.target.value);
  };

  const handleSelectRack = (e) => {
    setSelectedRackId(e.target.value);
  };

  const handleGoBack = () => navigate("/reports");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      await deleteReportDefinition({
        organizationId,
        reportDefinitionId: reportId,
      }).unwrap();

      handleClose();
      handleGoBack();
      messageSuccess(getTranslation("successfulDeleteReport", t, i18n));
    } catch (error) {
      console.error("Faild to delete report", error);
    }
  };

  const handleOpenDeleteConfirm = () => {
    setOpenDeleteConfirm(true);
    handleClose();
  };

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
  };

  const handleDeleteConfirm = () => {
    handleDelete();
    handleCloseDeleteConfirm();
  };

  const handleOpenUpdateDialog = () => {
    setMobileUpdateDialogOpen(true);
  };

  const handleCloseUpdateDialog = () => {
    setMobileUpdateDialogOpen(false);
    dispatch(resetWizard());
    handleClose();
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    handleOpenUpdateDialog();
  };

  // Effects
  useEffect(() => {
    if (
      reportDefinitionFieldsData &&
      reportFieldsByTypeData &&
      reportDefinitionAggregationsData
    ) {
      const transformedFields =
        reportFieldsByTypeData
          .filter((field) =>
            reportDefinitionFieldsData.some(
              (reportDefinitionField) =>
                reportDefinitionField.field.id === field.id
            )
          )
          .map((field) => {
            const reportField = reportDefinitionFieldsData.find(
              (reportDefinitionField) =>
                reportDefinitionField.field.id === field.id
            );

            const aggregationTypes = reportDefinitionAggregationsData
              .filter(
                (aggregation) => aggregation.field.id === reportField.field.id
              )
              .map((a) => a.aggregationType);

            return {
              field: field.name,
              displayName: getTranslation(
                reportField.displayName ?? field.name,
                t,
                i18n
              ),
              usedForGrouping: reportField?.usedForGrouping,
              aggregationTypes,
              index: reportField.index,
            };
          }) ?? [];

      const furtherFields = transformedFields
        ?.slice()
        ?.sort((a, b) => a.index - b.index)
        ?.filter((field) => !field.usedForGrouping);

      const groupFields = transformedFields
        ?.slice()
        ?.sort((a, b) => a.index - b.index)
        ?.filter((field) => field.usedForGrouping);

      setExecutionFurtherFields(furtherFields);
      setExecutionGroupFields(groupFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportDefinitionFieldsData,
    reportFieldsByTypeData,
    reportDefinitionAggregationsData,
    reportDefinitionData?.organizationId,
  ]);

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling
          isLoading={
            isLoadingReportDefinition ||
            isLoadingReportExecution ||
            isLoadingReportFields ||
            isLoadingReportFilters ||
            isLoadingReportAggregations ||
            isLoadingReportFieldsByType ||
            isLoadingLocations ||
            isLoadingRacks ||
            isLoadingUserRoles
          }
          isError={false}
        >
          <ConfirmAlert
            isOpen={openDeleteConfirm}
            setIsOpen={setOpenDeleteConfirm}
            alert={alert}
            handleConfirm={handleDeleteConfirm}
            label="delete"
          />

          {mobileUpdateDialogOpen && (
            <MobileUpdateReportDialog
              open={mobileUpdateDialogOpen}
              handleClose={handleCloseUpdateDialog}
              reportId={reportId}
            />
          )}

          {reportId && (
            <Box sx={{ padding: "20px" }}>
              <Grid sx={{ marginBottom: "20px" }} container>
                <Grid display="flex" alignItems="center" item xs={3}>
                  <ActionTitle
                    tabIndex="0"
                    data-testid="action-title-cancel"
                    id="action-title-cancel"
                    onClick={handleGoBack}
                  >
                    {getTranslation("back", t, i18n)}
                  </ActionTitle>
                </Grid>

                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  item
                  xs={6}
                >
                  <PageTitle variant="h6" id="title">
                    {reportDefinitionData?.name}
                  </PageTitle>
                </Grid>

                <Grid
                  display="flex"
                  justifyContent="end"
                  alignItems="center"
                  item
                  xs={3}
                >
                  {reportDefinitionData?.organizationId && (
                    <IconButton
                      id="reports-action-button"
                      onClick={handleClick}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  )}

                  <Menu
                    id="report-actions-menu"
                    MenuListProps={{
                      "aria-labelledby": "report-actions-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    {reportDefinitionData?.organizationId &&
                      hasAccess(
                        "all",
                        [permissions["REPORTING_USER_DEFINED_EDIT"]],
                        getPermissionsFromUserRoles(userRoles)
                      ) && (
                        <MenuItem onClick={handleEdit}>
                          <ListItemIcon>
                            <NavigationActionIcon
                              active={true}
                              customfontsize={globalFontSize}
                            >
                              edit
                            </NavigationActionIcon>
                          </ListItemIcon>

                          <ListItemText>Edit</ListItemText>
                        </MenuItem>
                      )}

                    {reportDefinitionData?.organizationId &&
                      hasAccess(
                        "all",
                        [permissions["REPORTING_USER_DEFINED_DELETE"]],
                        getPermissionsFromUserRoles(userRoles)
                      ) && (
                        <MenuItem onClick={handleOpenDeleteConfirm}>
                          <ListItemIcon>
                            <NavigationActionIcon
                              sx={{ color: "red" }}
                              active={true}
                              customfontsize={globalFontSize}
                            >
                              delete
                            </NavigationActionIcon>
                          </ListItemIcon>
                          <ListItemText>Delete</ListItemText>
                        </MenuItem>
                      )}
                  </Menu>
                </Grid>
              </Grid>

              <StackContainer>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TitleSection variant="subtitle1">
                      {getTranslation("REPORT_NAME", t, i18n)}
                    </TitleSection>
                    <SecondaryTextOverflowWrap>
                      {reportDefinitionData?.name}
                    </SecondaryTextOverflowWrap>
                  </Grid>

                  <Grid item xs={6}>
                    <TitleSection variant="subtitle1">
                      {getTranslation("REPORT_DESCRIPTION", t, i18n)}
                    </TitleSection>

                    <Tooltip title={reportDefinitionData?.description}>
                      <SecondaryTextOverflowWrap>
                        {reportDefinitionData?.description}
                      </SecondaryTextOverflowWrap>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={12}>
                    <TitleSection variant="subtitle1">
                      {getTranslation("REPORT_QUERY", t, i18n)}
                    </TitleSection>
                    {Boolean(whereClause) ? (
                      <SyntaxHighlighter
                        language="sql"
                        style={currentTheme === THEME.LIGHT ? xcode : a11yDark}
                        customStyle={{ margin: 0, padding: 0 }}
                      >
                        {whereClause}
                      </SyntaxHighlighter>
                    ) : (
                      <SecondaryText>
                        {getTranslation("NO_FILTERS_APPLIED", t, i18n)}
                      </SecondaryText>
                    )}
                  </Grid>
                </Grid>

                {reportDefinitionData?.id ===
                  ASSET_LIST_PER_LOCATION_REPORT_DEFINITION_ID && (
                  <Select
                    fullWidth
                    labelId="select-location-label"
                    id="select-location"
                    value={selectedLocationId}
                    onChange={handleSelectLocation}
                    IconComponent={() => (
                      <StyledArrowDropDownIcon
                        globalFontSize={globalFontSize}
                      />
                    )}
                  >
                    {locationsData?.map((a) => (
                      <MenuItem value={a.id}>{a.name}</MenuItem>
                    ))}
                  </Select>
                )}

                {reportDefinitionData?.id ===
                  ASSET_LIST_PER_RACK_REPORT_DEFINITION_ID && (
                  <Select
                    fullWidth
                    labelId="select-rack-label"
                    id="select-rack"
                    value={selectedRackId}
                    onChange={handleSelectRack}
                    IconComponent={() => (
                      <StyledArrowDropDownIcon
                        globalFontSize={globalFontSize}
                      />
                    )}
                  >
                    {racksData?.map((a) => (
                      <MenuItem value={a.id}>{a.name}</MenuItem>
                    ))}
                  </Select>
                )}

                <ReportExecutionWrapper>
                  {reportDefinitionData?.organizationId ? (
                    reportDefinitionFieldsData?.length > 0 &&
                    reportDefinitionFilterData?.length ? (
                      hasAccess(
                        "all",
                        [permissions["REPORTING_USER_DEFINED_EXECUTE"]],
                        getPermissionsFromUserRoles(userRoles)
                      ) && (
                        <ReportExecutionResultTable
                          executionFurtherResultFields={executionFurtherFields}
                          executionGroupFields={executionGroupFields}
                          rows={rows}
                        />
                      )
                    ) : (
                      <Typography>
                        {getTranslation("NO_FIELDS", t, i18n)}
                      </Typography>
                    )
                  ) : (
                    hasAccess(
                      "all",
                      [permissions["REPORTING_SYSTEM_REPORT_EXECUTE"]],
                      getPermissionsFromUserRoles(userRoles)
                    ) && (
                      <ReportExecutionResultTable
                        executionFurtherResultFields={executionFurtherFields}
                        executionGroupFields={executionGroupFields}
                        rows={rows}
                      />
                    )
                  )}
                </ReportExecutionWrapper>
              </StackContainer>
            </Box>
          )}
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default ReportDetailsPage;
