import { Paper, TableCell, TableContainer } from "@mui/material";
import { styled } from "@mui/system";

export const TableReportPaper = styled(Paper)({
  width: "100%",
  overflow: "hidden",
});

export const TableReportContainer = styled(TableContainer)({
  maxHeight: 440,
});

export const StyledTableCell = styled(TableCell)(({ column }) => ({
  minWidth: column.minWidth,
}));
