import { useSelector } from "react-redux";
import { useUserRolePermissionsQuery } from "../store/slices/api/userManagementSlice";
import { selectUser } from "../store/slices/authSlice";
import OrganizationUsersListCard from "./OrganizationUsersListCard";
import { useGetOrganizationRolesQuery } from "../store/slices/api/organizationsApiSlice";
import { SORT_ORDER_NAME } from "../util/utils";
import ErrorHandling from "./common/ErrorHandling";

const OrganizationUsersList = ({
  organizationUsers,
  organizationId,
  sortOrder,
}) => {
  // Selectors
  const currentUser = useSelector(selectUser);

  // Queries
  const {
    data: roles,
    isLoading,
    isError,
  } = useGetOrganizationRolesQuery(organizationId);

  const {
    data: currentUserRoles,
    isLoading: isLoadingCurrentUserRoles,
    isError: isErrorCurrentUserRoles,
  } = useUserRolePermissionsQuery({
    organizationId,
    userId: currentUser.id,
  });

  // Other variables
  let sortedOrganizationUsers = [...organizationUsers].sort((a, b) => {
    const aFirstNameLowerCase = a.firstName.toLowerCase();
    const bFirstNameLowerCase = b.firstName.toLowerCase();

    const aLastNameLowerCase = a.lastName.toLowerCase();
    const bLastNameLowerCase = b.lastName.toLowerCase();

    if (sortOrder === SORT_ORDER_NAME.FIRST_NAME) {
      if (aFirstNameLowerCase > bFirstNameLowerCase) {
        return 1;
      } else if (aFirstNameLowerCase < bFirstNameLowerCase) {
        return -1;
      }

      if (aLastNameLowerCase > bLastNameLowerCase) {
        return 1;
      } else if (aLastNameLowerCase < bLastNameLowerCase) {
        return -1;
      }
    } else {
      if (aLastNameLowerCase > bLastNameLowerCase) {
        return 1;
      } else if (aLastNameLowerCase < bLastNameLowerCase) {
        return -1;
      }
      if (aFirstNameLowerCase > bFirstNameLowerCase) {
        return 1;
      } else if (aFirstNameLowerCase < bFirstNameLowerCase) {
        return -1;
      }
    }

    return 0;
  });

  return (
    <ErrorHandling
      isLoading={isLoading || isLoadingCurrentUserRoles}
      isError={isError || isErrorCurrentUserRoles}
    >
      <div>
        {sortedOrganizationUsers.map((user) => (
          <OrganizationUsersListCard
            key={user.id}
            user={user}
            roles={roles}
            currentUserRoles={currentUserRoles}
            organizationId={organizationId}
          />
        ))}
      </div>
    </ErrorHandling>
  );
};

export default OrganizationUsersList;
