import { Stack, alpha } from "@mui/material";
import { styled } from "@mui/system";
import { ListGridViewCard, PrimaryText } from "../ListGridView.styles";

export const AssetGridViewCard = styled(ListGridViewCard)(({ theme }) => ({
  minHeight: "125px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  backgroundColor: theme.palette.primary.contrastText,
  ":hover": {
    backgroundColor: alpha("#000", 0.04),
  },
}));

export const CardMediaContainer = styled(Stack)(({ theme, isicon }) => ({
  backgroundColor: isicon
    ? theme.palette.mode === "light"
      ? "#F5F5F5"
      : theme.palette.secondary.dark
    : "",
  minHeight: "48px",
  minWidth: "64px",
  justifyContent: "center",
  alignItems: "center",
}));

export const AssetText = styled(PrimaryText)(({ theme }) => ({
  textAlign: "center",
  width: "100%",
  maxWidth: theme.breakpoints.down("sm") ? "100px" : "",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  lineClamp: 2,
  WebkitBoxOrient: "vertical",
  whiteSpace: "break-spaces",
  wordBreak: "break-all",
  marginTop: "5px",
}));

export const ItemsNumberText = styled(PrimaryText)(({ theme }) => ({
  textAlign: "center",
  color:
    theme.palette.mode === "light" ? "#9C9C9C" : theme.palette.primary.dark,
  marginTop: "5px",
}));
