import {
  create_graphical_object_url,
  get_graphical_objects_url,
  get_single_graphical_object_url,
} from "../../../Constants";
import KeycloakService from "../../../services/KeycloakService";
import { baseApiSlice } from "./baseApiSlice";

export const graphicalObjectsApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGraphicalObjectsList: builder.query({
      query: ({ organizationId, resourceId }) => ({
        url: get_graphical_objects_url
          .replace(":organizationId", organizationId)
          .replace(":resourceId", resourceId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["GraphicalObjects"],
    }),
    getSingleGraphicalObject: builder.query({
      query: ({ organizationId, graphicalObjectId }) => ({
        url: get_single_graphical_object_url
          .replace(":organizationId", organizationId)
          .replace(":graphicalObjectId", graphicalObjectId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["GraphicalObjects"],
    }),
    createGraphicalObject: builder.mutation({
      query: ({ organizationId, graphicalObjectDto }) => ({
        url: create_graphical_object_url.replace(
          ":organizationId",
          organizationId
        ),
        method: "POST",
        body: graphicalObjectDto,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["GraphicalObjects"],
    }),
    updateGraphicalObject: builder.mutation({
      query: ({ organizationId, graphicalObjectId, graphicalObjectDto }) => ({
        url: get_single_graphical_object_url
          .replace(":organizationId", organizationId)
          .replace(":graphicalObjectId", graphicalObjectId),
        method: "PUT",
        body: graphicalObjectDto,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["GraphicalObjects"],
    }),
    patchGraphicalObject: builder.mutation({
      query: ({ organizationId, graphicalObjectId, graphicalObjectDto }) => ({
        url: get_single_graphical_object_url
          .replace(":organizationId", organizationId)
          .replace(":graphicalObjectId", graphicalObjectId),
        method: "PATCH",
        body: graphicalObjectDto,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["GraphicalObjects"],
    }),
    deleteGraphicalObject: builder.mutation({
      query: ({ organizationId, graphicalObjectId }) => ({
        url: get_single_graphical_object_url
          .replace(":organizationId", organizationId)
          .replace(":graphicalObjectId", graphicalObjectId),
        method: "DELETE",
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["GraphicalObjects"],
    }),
  }),
});

export const {
  useGetGraphicalObjectsListQuery,
  useGetSingleGraphicalObjectQuery,
  useCreateGraphicalObjectMutation,
  useUpdateGraphicalObjectMutation,
  usePatchGraphicalObjectMutation,
  useDeleteGraphicalObjectMutation,
} = graphicalObjectsApiSlice;
