import { styled } from "@mui/system";
import { Box, FormControl } from "@mui/material";

export const Option = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  mt: 2,
}));

export const SelectInputFormControl = styled(FormControl)({
  marginTop: "15px",
});
