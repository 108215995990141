import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  selectCarouselIndex,
  selectItems,
  selectTabletTabValue,
  setCarouselIndex,
  setTabletTabValue,
} from "../../../store/slices/assetListSlice";
import {
  selectGlobalFontSize,
  selectGraphicalViewAssetDisplay,
  selectIsDrawerOpen,
  selectPageView,
  setGraphicalViewAssetDisplay,
} from "../../../store/slices/appSlice";
import {
  useGetAllCharacteristicsQuery,
  useGetAllFunctionsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { useUserRolePermissionsQuery } from "../../../store/slices/api/userManagementSlice";
import { useGetGraphicalObjectsListQuery } from "../../../store/slices/api/graphicalObjectsSlice";
import { selectFitScreen } from "../../../store/slices/floorplanSlice";
import { selectUser } from "../../../store/slices/authSlice";
import useOrientation from "../../../hooks/useOrientation";
import {
  COLLAPSED_DRAWER_WIDTH,
  EXPANDED_DRAWER_WIDTH,
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  RESOURCE_CATEGORIES,
  transitionDirections,
} from "../../../util/utils";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { alpha, Box, Menu, styled, useMediaQuery } from "@mui/material";
import CustomTabPanel from "../../CustomTabPanel";
import AssetDetailList from "../asset-detail/AssetDetailList";
import CarouselSlider from "../asset-detail/CarouselSlider";
import CreateAsset from "../asset-form/CreateAsset";
import AssetListRenderer from "./AssetListRenderer";
import { a11yAssetListTabProps } from "../../../pages/assets-pages/AssetList";
import {
  CreateAssetButton,
  NoAssetsContainer,
} from "../../styles/assets/AssetList.styles";
import {
  LightGrayText,
  SecondaryText,
} from "../../styles/assets/ListInlineView.styles";
import CreateNewButton from "../../buttons/CreateNewButton";
import FullScreenFloorplan from "../floorplan/FullScreenFloorplan";
import Floorplan from "../floorplan/Floorplan";
import CreateGraphicalRoomObject from "../floorplan/CreateGraphicalRoomObject";
import EditAsset from "../asset-form/EditAsset";
import {
  AssetListTabsFlexContainer,
  GraphicalRackViewTab,
  GraphicalViewMenuItem,
  HalfWidthContainer,
  StyledTab,
  StyledTabs,
  TabContentContainer,
  TabListContentContainer,
} from "../../styles/assets/asset-list/AssetListTabs.styles";
import { NavigationActionIcon } from "../../styles/menu/Menu.styles";
import {
  EditButton,
  EditButtonWrapper,
  SecondaryContrastButton,
} from "../../styles/general/General.styles";
import GraphicalRackView from "../graphical-rack-view/GraphicalRackView";
import { ASSET_DISPLAY_MODES } from "../../../util/graphical-rack-view-utils";
import ErrorHandling from "../../common/ErrorHandling";
import CategoryMenu from "../asset-form/CategoryMenu";

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const AssetListTabs = ({ resourceId, currentResourceData }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default).id;
  const currentpageview = useSelector(selectPageView);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const isDrawerOpen = useSelector(selectIsDrawerOpen);
  const items = useSelector(selectItems);
  const tabValue = useSelector(selectTabletTabValue);
  const fitScreen = useSelector(selectFitScreen);
  const carouselIndex = useSelector(selectCarouselIndex);
  const graphicalViewAssetDisplay = useSelector(
    selectGraphicalViewAssetDisplay
  );

  // States
  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElCategoryMenu, setAnchorElCategoryMenu] = useState(null);
  const [initializedCategory, setInitializedCategory] = useState("");

  // Queries
  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery({
    userId: user.id,
    organizationId,
  });

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  const {
    data: graphicalObjectsData,
    isLoading: isLoadingGraphicalObjects,
    isError: isErrorGraphicalObjects,
  } = useGetGraphicalObjectsListQuery(
    {
      resourceId: resourceId,
      organizationId: user?.organizations?.find((o) => o.default).id,
    },
    {
      skip: !Boolean(resourceId),
    }
  );

  const {
    data: characteristicDefinitionsData,
    isLoading: isLoadingCharacteristicDefinitions,
    isError: isErrorCharacteristicDefinitions,
  } = useGetAllCharacteristicsQuery({ organizationId });

  // Custom hooks
  const orientation = useOrientation();

  // Other variables
  const open = Boolean(anchorEl);

  const openCategoryMenu = Boolean(anchorElCategoryMenu);

  const resourceImages = currentResourceData?.images ?? [];

  const typeImages =
    currentResourceData?.type?.images.map((img) => {
      return {
        ...img,
        uri: `/organizations/${user?.organizations?.find((o) => o.default).id}${
          img.uri
        }`,
      };
    }) ?? [];

  const combinedTypeAndResourceImages = [...resourceImages, ...typeImages];

  const drawerWidth =
    isDrawerOpen && orientation === "landscape"
      ? EXPANDED_DRAWER_WIDTH
      : COLLAPSED_DRAWER_WIDTH;

  // Handlers
  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleOpenCreate = (category) => {
    setInitializedCategory(category);
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const handleClick = (event) => {
    if (tabValue === 2) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAssetDisplayMode = (mode) => {
    dispatch(setGraphicalViewAssetDisplay(mode));
    handleClose();
  };

  const handleImageChange = (oldIndex, newIndex) => {
    dispatch(setCarouselIndex(newIndex));
  };

  const handleAssetListTabChange = (event, newValue) => {
    dispatch(setTabletTabValue(newValue));
  };

  const handleNavigateToCreateAsset = (category) => {
    if (currentResourceData) {
      const { id } = currentResourceData;

      navigate(
        `/resources/add-new?resourceid=${id}&category=${category}&direction=${transitionDirections.BOTTOM_TO_TOP}`
      );
    } else {
      navigate(
        `/resources/add-new?category=${category}&direction=${transitionDirections.BOTTOM_TO_TOP}`
      );
    }
  };

  const handlerNavigateToEditAsset = () => {
    navigate(
      `/resources/${currentResourceData.id}/edit?direction=${transitionDirections.RIGHT_TO_LEFT}`
    );
  };

  const currentResourceFunction = allFunctionsData?.find(
    (f) => f.id === currentResourceData.functionId
  );

  const checkHasRackUnits = () => {
    const rackUnitsCharacteristicId = characteristicDefinitionsData?.find(
      (c) => c.name === "RACK_UNIT_CAPACITY"
    )?.id;

    const resourceRackUnits = currentResourceData?.characteristics.find(
      (c) => c.id === rackUnitsCharacteristicId
    );

    const typeRackUnits = currentResourceData?.type?.characteristics.find(
      (c) => c.id === rackUnitsCharacteristicId
    );

    const rackUnits = parseInt(
      resourceRackUnits?.value || typeRackUnits?.value
    );

    return rackUnits > 0;
  };

  const handleOpenCategoryMenu = (e) => {
    setAnchorElCategoryMenu(e.currentTarget);
  };

  const handleCloseCategoryMenu = () => {
    setAnchorElCategoryMenu(null);
  };

  // Effects
  useEffect(() => {
    if (currentResourceData?.childrenCount <= 0) {
      dispatch(setTabletTabValue(1));
    } else {
      dispatch(setTabletTabValue(0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentResourceData?.childrenCount]);

  return (
    <ErrorHandling
      isLoading={
        isLoadingCharacteristicDefinitions ||
        isLoadingFunctions ||
        isLoadingUserRoles ||
        isLoadingGraphicalObjects
      }
      isError={
        isErrorCharacteristicDefinitions ||
        isErrorFunctions ||
        isErrorGraphicalObjects ||
        isErrorUserRoles
      }
    >
      <AssetListTabsFlexContainer>
        <StyledMenu
          id="graphical-display-options-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <GraphicalViewMenuItem
            graphicalViewAssetDisplay={graphicalViewAssetDisplay}
            targetGraphicalViewAssetDisplay={ASSET_DISPLAY_MODES.IMAGES}
            onClick={() =>
              handleSelectAssetDisplayMode(ASSET_DISPLAY_MODES.IMAGES)
            }
            disableRipple
          >
            {getTranslation("IMAGES_VIEW", t, i18n)}
          </GraphicalViewMenuItem>
          <GraphicalViewMenuItem
            graphicalViewAssetDisplay={graphicalViewAssetDisplay}
            targetGraphicalViewAssetDisplay={ASSET_DISPLAY_MODES.BITMAPS}
            onClick={() =>
              handleSelectAssetDisplayMode(ASSET_DISPLAY_MODES.BITMAPS)
            }
            disableRipple
          >
            {getTranslation("BITMAP_VIEW", t, i18n)}
          </GraphicalViewMenuItem>
          <GraphicalViewMenuItem
            graphicalViewAssetDisplay={graphicalViewAssetDisplay}
            targetGraphicalViewAssetDisplay={ASSET_DISPLAY_MODES.BOX}
            onClick={() =>
              handleSelectAssetDisplayMode(ASSET_DISPLAY_MODES.BOX)
            }
            disableRipple
          >
            {getTranslation("BOX_VIEW", t, i18n)}
          </GraphicalViewMenuItem>
        </StyledMenu>

        <Box>
          <StyledTabs
            value={tabValue}
            onChange={handleAssetListTabChange}
            variant="fullWidth"
            scrollButtons="auto"
            fitScreen={fitScreen}
          >
            <StyledTab
              active={tabValue === 0}
              label={getTranslation("CONTENT", t, i18n)}
              {...a11yAssetListTabProps(0)}
            />
            <StyledTab
              active={tabValue === 1}
              label={getTranslation("DETAILS", t, i18n)}
              {...a11yAssetListTabProps(1)}
            />
            {currentResourceFunction?.category === RESOURCE_CATEGORIES.RACK &&
              checkHasRackUnits() && (
                <GraphicalRackViewTab
                  onClick={handleClick}
                  active={tabValue === 2}
                  // icon={<ArrowDropDownIcon />}
                  // iconPosition="end"
                  label={
                    getTranslation("GRAPHICAL_VIEW", t, i18n) +
                    `${tabValue === 2 ? " ▼" : ""}`
                  }
                  {...a11yAssetListTabProps(2)}
                />
              )}
            {/* Hide Floorplan */}
            {/* {currentResourceFunction?.name === "Room" && (
              <StyledTab
                active={tabValue === 2}
                label={getTranslation("FLOORPLAN", t, i18n)}
                {...a11yAssetListTabProps(2)}
              />
            )} */}
          </StyledTabs>
        </Box>

        <CustomTabPanel value={tabValue} index={0}>
          <TabListContentContainer itemsLength={items?.length}>
            <AssetListRenderer resourceid={resourceId} />

            {currentpageview !== "column" && (
              <>
                {items?.length <= 0 ? (
                  <NoAssetsContainer resourceid={resourceId}>
                    <SecondaryText align="center">
                      {getTranslation("NO_ASSETS", t, i18n)}
                    </SecondaryText>
                    <CreateAssetButton
                      disabled={
                        !hasAccess(
                          "all",
                          [
                            permissions.ASSET_MANAGEMENT_HARDWARE_ADD,
                            permissions.ASSET_MANAGEMENT_LOCATION_ADD,
                            permissions.ASSET_MANAGEMENT_RACK_ADD,
                          ],
                          getPermissionsFromUserRoles(userRoles)
                        )
                      }
                      onClick={handleOpenCategoryMenu}
                    >
                      <NavigationActionIcon customfontsize={globalFontSize}>
                        add
                      </NavigationActionIcon>
                      {getTranslation("CREATE_FIRST_ASSET", t, i18n)}
                    </CreateAssetButton>
                  </NoAssetsContainer>
                ) : (
                  <CreateNewButton
                    handler={handleOpenCategoryMenu}
                    userRoles={userRoles}
                    name="CREATE_NEW_ASSET"
                  />
                )}
              </>
            )}

            {!mobileMatches && (
              <>
                {openCreate && (
                  <CreateAsset
                    userRoles={userRoles}
                    initializedParentId={resourceId}
                    initializedCategory={initializedCategory}
                    open={openCreate}
                    handleClose={handleCloseCreate}
                  />
                )}
              </>
            )}

            <CategoryMenu
              open={openCategoryMenu}
              parentCategory={currentResourceFunction?.category}
              categoryActionHandler={
                !mobileMatches ? handleOpenCreate : handleNavigateToCreateAsset
              }
              handleClose={handleCloseCategoryMenu}
              anchorEl={anchorElCategoryMenu}
            />
          </TabListContentContainer>
        </CustomTabPanel>

        <CustomTabPanel value={tabValue} index={1}>
          <TabContentContainer drawerwidth={drawerWidth}>
            <CarouselSlider resourceImages={combinedTypeAndResourceImages} />

            <AssetDetailList resourceData={currentResourceData} />

            <div id="end"></div>
          </TabContentContainer>
        </CustomTabPanel>

        {currentResourceFunction?.category === "RACK" &&
          checkHasRackUnits() && (
            <CustomTabPanel value={tabValue} index={2}>
              <TabContentContainer drawerwidth={drawerWidth}>
                <HalfWidthContainer>
                  <GraphicalRackView
                    currentResourceData={currentResourceData}
                    resourceImages={resourceImages}
                    handleImageChange={handleImageChange}
                    currentIndex={carouselIndex}
                  />
                </HalfWidthContainer>
              </TabContentContainer>
            </CustomTabPanel>
          )}

        {/* {currentResourceFunction?.name === "Room" && (
          <CustomTabPanel type="floorplan" value={tabValue} index={2}>
            {graphicalObjectsData?.some(
              (graphicalObject) =>
                graphicalObject.parentResourceId === resourceId &&
                graphicalObject.type === "GENERAL"
            ) ? (
              <>
                <FullScreenFloorplan resourceId={resourceId} />
                <Floorplan resourceId={resourceId} shouldReset={true} />
              </>
            ) : (
              <CreateGraphicalRoomObject
                resourceId={resourceId}
                userRoles={userRoles}
              />
            )}
          </CustomTabPanel>
        )} */}
      </AssetListTabsFlexContainer>
    </ErrorHandling>
  );
};

export default AssetListTabs;
