import * as ReactDnD from "react-dnd";
import * as ReactDndTouchBackend from "react-dnd-touch-backend";
import { useDispatch, useSelector } from "react-redux";
import {
  selectQuery,
  selectReportingFields,
  selectShouldAddDefaultConditions,
  selectShouldCleanQuery,
  setQuery,
  setShouldAddDefaultConditions,
  setShouldCleanQuery,
} from "../../../store/slices/reportsSlice";
import {
  addDefaultConditions,
  COMBINATORS,
  filterEmptyRules,
  OPERATORS,
  REPORT_FIELDS,
} from "../../../util/reports-utils";
import { Box } from "@mui/material";
import { QueryBuilderMaterial } from "@react-querybuilder/material";
import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import QueryBuilder, { defaultValidator } from "react-querybuilder";
import "./QueryBuilderMobileView.css";
import CustomCombinatorSelector from "./query-builder/CustomCombinatorSelector";
import CustomAddGroupButton from "./query-builder/CustomAddGroupButton";
import CustomAddRuleButton from "./query-builder/CustomAddRuleButton";
import CustomRemoveGroupButton from "./query-builder/CustomRemoveGroupButton";
import CustomRemoveRuleButton from "./query-builder/CustomRemoveRuleButton";
import CustomFieldSelector from "./query-builder/CustomFieldSelector";
import CustomOperatorSelector from "./query-builder/CustomOperatorSelector";
import CustomValueEditor from "./query-builder/CustomValueEditor";
import { useEffect } from "react";

const QueryBuilderMobileView = () => {
  // General hooks
  const dispatch = useDispatch();

  // Selectors
  const query = useSelector(selectQuery);
  const reportingFields = useSelector(selectReportingFields);
  const shouldCleanQuery = useSelector(selectShouldCleanQuery);
  const shouldAddDefaultConditions = useSelector(
    selectShouldAddDefaultConditions
  );

  const controlElements = {
    addGroupAction: CustomAddGroupButton,
    addRuleAction: CustomAddRuleButton,
    removeGroupAction: CustomRemoveGroupButton,
    removeRuleAction: CustomRemoveRuleButton,
    fieldSelector: CustomFieldSelector,
    operatorSelector: CustomOperatorSelector,
    valueEditor: CustomValueEditor,
    combinatorSelector: CustomCombinatorSelector,
  };

  const fields = reportingFields.filter(
    (field) =>
      !(
        field.name === REPORT_FIELDS["ID"] ||
        field.name === REPORT_FIELDS["TAG_ID"] ||
        field.name === REPORT_FIELDS["P_ID"] ||
        field.name === REPORT_FIELDS["P_TAG_ID"] ||
        field.name === REPORT_FIELDS["CREATED_BY"] ||
        field.name === REPORT_FIELDS["RESOURCE_PARENT_CREATED_BY"]
      )
  );

  // Handlers
  const handleChangeQuery = (newQuery) => {
    dispatch(setQuery(newQuery));
  };

  // Effects
  useEffect(() => {
    if (shouldCleanQuery) {
      dispatch(setQuery(filterEmptyRules(query)));
      dispatch(setShouldCleanQuery(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCleanQuery]);

  useEffect(() => {
    if (shouldAddDefaultConditions) {
      dispatch(setQuery(addDefaultConditions(query)));
      dispatch(setShouldAddDefaultConditions(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldAddDefaultConditions]);

  return (
    <Box>
      <QueryBuilderMaterial>
        <ReactDnD.DndProvider backend={ReactDndTouchBackend.TouchBackend}>
          <QueryBuilderDnD>
            <QueryBuilder
              fields={fields}
              combinators={COMBINATORS}
              operators={OPERATORS}
              query={query}
              controlElements={controlElements}
              showCombinatorsBetweenRules={false}
              resetOnFieldChange={true}
              resetOnOperatorChange={true}
              onQueryChange={(newQuery) => handleChangeQuery(newQuery)}
              validator={defaultValidator}
            />
          </QueryBuilderDnD>
        </ReactDnD.DndProvider>
      </QueryBuilderMaterial>
    </Box>
  );
};

export default QueryBuilderMobileView;
