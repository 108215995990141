import { Typography, Box, IconButton } from "@mui/material";
import { styled } from "@mui/system";

export const ResourceAttachmentsListContainer = styled(Box)({
  paddingBottom: "30px",
});

export const ResourceAttachmentsText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: "20px",
}));

export const AddAttachmentButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "2px",
  backgroundColor: theme.palette.secondary.dark,
}));

export const AddAttachmentButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: "15px",
  fontWeight: "bold",
}));
