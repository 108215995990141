import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  NavigationActionIcon,
  NavigationActionRightIcon,
} from "../styles/menu/Menu.styles";
import {
  DEFAULT_REPORTS_PAGE_SIZE,
  getComparator,
  stableSort,
  WIDGETS,
  REPORTS_PAGE_OPTIONS,
} from "../../util/reports-utils";
import { getTranslation } from "../../util/utils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { visuallyHidden } from "@mui/utils";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import { selectUser } from "../../store/slices/authSlice";
import { useGetReportDefinitionsQuery } from "../../store/slices/api/reportsApiSlice";
import ErrorHandling from "../common/ErrorHandling";
import {
  selectReportId,
  selectSearchQuery,
  setReportId,
} from "../../store/slices/reportSlice";
import { useDispatch } from "react-redux";
import {
  EmptyRowTable,
  ReportName,
  ReportTableListBodyRow,
  ReportTableListDescriptionCell,
  ReportTableListSortLabel,
} from "../styles/reports/Reports.styles";
import { SecondaryText } from "../styles/assets/ListInlineView.styles";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    isVisible: true,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    isVisible: true,
  },
  {
    id: "widgets",
    numeric: false,
    disablePadding: false,
    label: "Widgets",
    isVisible: false,
  },
];

const TABLE_CELL_HEIGHT = 62;

const ReportTableList = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const reportId = useSelector(selectReportId);
  const searchQuery = useSelector(selectSearchQuery);

  // States
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_REPORTS_PAGE_SIZE);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  // Queries
  const { data, isLoading } = useGetReportDefinitionsQuery({ organizationId });

  // Other variables
  const rows = useMemo(
    () =>
      data?.filter((report) => {
        if (report.organizationId) {
          return true;
        }

        return WIDGETS.some((widget) => report.id === widget);
      }) ?? [],
    [data]
  );
  let filteredRows = rows;

  if (searchQuery) {
    filteredRows = rows?.filter((row) => {
      const lowerCaseDescription = row.description?.toLowerCase();
      const lowerCaseName = row.name.toLowerCase();
      const lowerCaseSearchQuery = searchQuery.toLowerCase();

      return (
        lowerCaseDescription?.includes(lowerCaseSearchQuery) ||
        lowerCaseName?.includes(lowerCaseSearchQuery)
      );
    });
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows?.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredRows]
  );

  // Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleSelect = (id) => {
    dispatch(setReportId(id));
  };

  const onlyVisibleHeadCells = headCells.filter((cell) => cell.isVisible);

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <ErrorHandling isLoading={isLoading} isError={false}>
      {filteredRows?.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {onlyVisibleHeadCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <ReportTableListSortLabel
                      active={orderBy === headCell.id}
                      IconComponent={KeyboardArrowDownIcon}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </ReportTableListSortLabel>
                  </TableCell>
                ))}
                <TableCell>
                  <NavigationActionIcon
                    sx={visuallyHidden}
                    customfontsize={globalFontSize}
                  >
                    arrow_forward_ios
                  </NavigationActionIcon>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {visibleRows?.map((report) => {
                const { id, name, description } = report;

                return (
                  <ReportTableListBodyRow
                    id="report-row-item"
                    hover
                    onClick={() => handleSelect(id)}
                    selected={reportId === id}
                    key={id}
                  >
                    <TableCell component="th" scope="row">
                      <ReportName active={reportId === id}>
                        {getTranslation(name, t, i18n)}
                      </ReportName>
                    </TableCell>
                    <ReportTableListDescriptionCell>
                      <Tooltip title={description}>
                        <SecondaryText
                          variant="body2"
                          sx={{
                            textWrap: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {description}
                        </SecondaryText>
                      </Tooltip>
                    </ReportTableListDescriptionCell>
                    <TableCell align="right">
                      <NavigationActionRightIcon
                        customfontsize={globalFontSize}
                      />
                    </TableCell>
                  </ReportTableListBodyRow>
                );
              })}

              {emptyRows > 0 && (
                <EmptyRowTable rowHeight={TABLE_CELL_HEIGHT * emptyRows}>
                  <TableCell colSpan={6} />
                </EmptyRowTable>
              )}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={REPORTS_PAGE_OPTIONS}
            component="div"
            count={filteredRows.length}
            labelRowsPerPage={getTranslation("ROWS_PER_PAGE", t, i18n)}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      ) : (
        <Typography>No results found</Typography>
      )}
    </ErrorHandling>
  );
};

export default ReportTableList;
