import { PIXELS_PER_RACK_UNIT } from "./utils";

export const ASSET_DISPLAY_MODES = {
  BOX: "Schematic",
  IMAGES: "Images",
  BITMAPS: "Bitmap stencils",
};

export const DESKTOP_NAV_BAR_HEIGHT = 64;
export const DESKTOP_HEADER_HEIGHT = 50;
export const COLUMN_VIEW_TABS_HEIGHT = 72;

export const TABLET_NAV_BAR_HEIGHT = 68;
export const TABLET_DETAILS_PAGE_NAV_BAR_HEIGHT = 60;
export const TABLET_DETAILS_PAGE_TABS_HEIGHT = 70;

export const TOP_BORDER_HEIGHT = 40;
export const TOP_BORDER_THICKNESS = 5;

export const findArrIntersection = (arr1, arr2) => {
  // Use filter to create a new array with elements present in both arrays
  const intersection = arr1.filter((element) => arr2.includes(element));

  // Remove duplicate elements from the intersection array
  const uniqueIntersection = Array.from(new Set(intersection));

  // Return the result
  return uniqueIntersection;
};

export const getObjectsPlacements = (rows, placedObjects, notPlacedObjects) => {
  let notPlaced = [...notPlacedObjects];
  let placed = [...placedObjects];

  notPlaced.sort((a, b) => b.height - a.height);

  // Step 1: Initialize free spaces
  let freeSpaces = Array(rows).fill(true);
  placed.forEach((item) => {
    for (let i = item.start - 1; i < item.start - 1 + item.height; i++) {
      freeSpaces[i] = false;
    }
  });

  const objectsWithStart = [];
  let index = 0;

  while (notPlaced.length > 0) {
    const notPlacedNumber = notPlaced.shift();

    for (let i = index; i < freeSpaces.length; i++) {
      if (freeSpaces[i]) {
        const notAvailableToPut = freeSpaces
          .slice(i, notPlacedNumber.height)
          .some((f) => !f);

        if (!notAvailableToPut) {
          const overlap = placed.some((placedObj) => {
            const placedObjArr = [];
            const notPlacedObjArr = [];

            const { start: placedObjStart, height: placedObjHeight } =
              placedObj;

            const notPlacedObjStart = i + 1;
            const { height: notPlacedObjHeight } = notPlacedNumber;

            for (
              let i = placedObjStart;
              i <= placedObjStart + placedObjHeight - 1;
              i++
            ) {
              placedObjArr.push(i);
            }

            for (
              let i = notPlacedObjStart;
              i <= notPlacedObjStart + notPlacedObjHeight - 1;
              i++
            ) {
              notPlacedObjArr.push(i);
            }

            const intersection = findArrIntersection(
              placedObjArr,
              notPlacedObjArr
            );

            return Boolean(intersection) && intersection.length > 0;
          });

          if (!overlap) {
            objectsWithStart.push({
              resourceId: notPlacedNumber.resourceId,
              height: notPlacedNumber.height,
              start: i + 1,
            });

            index = i + notPlacedNumber.height;
            break;
          }
        }
      }
    }
  }

  return objectsWithStart;
};

export const getCalculatedHeight = (height) => {
  const unitsCalculation = parseInt(height) / 10 / PIXELS_PER_RACK_UNIT;
  return height ? Math.round(unitsCalculation) : 48;
};

export const haveIntersection = (r1, r2) => {
  return !(
    r2.x > r1.x + r1.width ||
    r2.x + r2.width < r1.x ||
    r2.y > r1.y + r1.height ||
    r2.y + r2.height < r1.y
  );
};

export const getView = (rotation, view) => {
  if (rotation === 180) {
    if (view === "front") {
      return "rear";
    } else if (view === "rear") {
      return "front";
    }
  } else {
    return view;
  }
};

export const getAssetDisplay = () => {
  const assetDisplayKey = "assetDisplay";
  const mode = JSON.parse(localStorage.getItem(assetDisplayKey));

  return mode ?? ASSET_DISPLAY_MODES.BOX;
};
