import OnboardingUserCredential from "../pages/onboarding-pages/OnboardingUserCredential";
import OnboardingLocationCredential from "../pages/onboarding-pages/OnboardingLocationCredential";
import OnboardingPersonalData from "../pages/onboarding-pages/OnboardingPersonalData";
import OnboardingOrganizationPage from "../pages/onboarding-pages/organization/OnboardingOrganizationPage";
import OnboardingOrganizationDataLocationPage from "../pages/onboarding-pages/organization/OnboardingOrganizationDataLocationPage";
import { OnboardingMainContentContainer } from "./styles/onboarding/OnboardingStepped.styles";
import OnboardingOrganizationDetails from "../pages/onboarding-pages/organization/OnboardingOrganizationDetails";
import OnboardingOrganizationSubscriptionPage from "../pages/onboarding-pages/organization/OnboardingOrganizationSubscriptionPage";
import OnboardingOrganizationSummaryPage from "../pages/onboarding-pages/organization/OnboardingOrganizationSummaryPage";

const MainContent = ({ activeStep, transitionDirection, type }) => {
  // Handlers
  const handleRenderMainContent = () => {
    if (type === "user-onboarding") {
      if (activeStep === 0) {
        return (
          <OnboardingUserCredential transitionDirection={transitionDirection} />
        );
      } else if (activeStep === 1) {
        return (
          <OnboardingLocationCredential
            transitionDirection={transitionDirection}
          />
        );
      } else {
        return (
          <OnboardingPersonalData transitionDirection={transitionDirection} />
        );
      }
    } else {
      if (activeStep === 0) {
        return (
          <OnboardingOrganizationPage
            transitionDirection={transitionDirection}
          />
        );
      } else if (activeStep === 1) {
        return (
          <OnboardingOrganizationDetails
            transitionDirection={transitionDirection}
          />
        );
      } else if (activeStep === 2) {
        return (
          <OnboardingOrganizationDataLocationPage
            transitionDirection={transitionDirection}
          />
        );
      } else if (activeStep === 3) {
        return (
          <OnboardingOrganizationSummaryPage
            transitionDirection={transitionDirection}
          />
        );
      }
    }
  };

  return (
    <OnboardingMainContentContainer>
      {handleRenderMainContent()}
    </OnboardingMainContentContainer>
  );
};

export default MainContent;
