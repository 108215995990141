import { List, ListItem, ListItemButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectInputValue } from "../../../store/slices/tagsSlice";
import { getTranslation } from "../../../util/utils";
import ColorPicker from "../../color-picker/ColorPicker";
import { NewItemText } from "../../styles/assets/tags/Tags.styles";

const SingleTagItem = ({ handler, color, isNew }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const inputValue = useSelector(selectInputValue);

  return (
    <List>
      <ListItem secondaryAction={<ColorPicker color={color} />} disablePadding>
        <ListItemButton onMouseDown={handler}>
          <NewItemText id="item-text">{inputValue}&nbsp;</NewItemText>
          {isNew && (
            <Typography id="new-label-text">
              ({getTranslation("NEW_LABEL", t, i18n)})
            </Typography>
          )}
        </ListItemButton>
      </ListItem>
    </List>
  );
};

export default SingleTagItem;
