import AddIcon from "@mui/icons-material/Add";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import EditIcon from "@mui/icons-material/Edit";
import {
  Menu,
  IconButton,
  Box,
  Paper,
  ButtonGroup,
  Checkbox,
  Button,
  FormControl,
  DialogActions,
  MenuItem,
  ListItemIcon,
  DialogContent,
  Divider,
  Typography,
  Grid,
  Dialog,
} from "@mui/material";
import { styled } from "@mui/system";
import { TreeItem } from "@mui/x-tree-view";
import { Item } from "../ListInlineView.styles";

export const GraphicalObjectsMenu = styled(Menu)(({ theme }) => ({
  overflow: "visible",
  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
  marginTop: theme.spacing(1.5),
  "& .MuiAvatar-root": {
    width: 32,
    height: 32,
    marginLeft: -0.5,
    marginRight: 1,
  },
  "&:before": {
    content: '""',
    display: "block",
    position: "absolute",
    top: 0,
    right: theme.spacing(14),
    width: 10,
    height: 10,
    backgroundColor: theme.palette.background.paper,
    transform: "translateY(-50%) rotate(45deg)",
    zIndex: 0,
  },
}));

export const OpenGraphicalObjectsMenuButton = styled(IconButton)(
  ({ theme }) => ({
    color: theme.palette.secondary.contrastText,
  })
);

export const ContrastTextButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

export const ContrastMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

export const BottomControlsBoxContainer = styled(Box)(({ fitScreen }) => ({
  display: "flex",
  justifyContent: "space-between",
  zIndex: fitScreen ? 100 : 0,
}));

export const ButtonGroupPaperContainer = styled(Paper)(({ fitScreen }) => ({
  zIndex: fitScreen ? 100 : 0,
}));

export const RightBottomButtonGroup = styled(ButtonGroup)({
  boxShadow: "none",
});

export const LeftBottomButtonGroup = styled(RightBottomButtonGroup)({
  paddingInline: "10px",
});

export const StyledCheckbox = styled(Checkbox)(({ customColor }) => ({
  color: customColor,
  "&.Mui-checked": {
    color: customColor,
  },
}));

export const CreateDoorFormControl = styled(FormControl)({
  marginTop: "15px",
});

export const CreateDoorDialogActions = styled(DialogActions)({
  display: "flex",
});

export const AutoGenerateButtonBoxContainer = styled(Box)({
  flexGrow: 1,
});

export const StyledAddIcon = styled(AddIcon)(({ globalFontSize }) => ({
  fontSize: `${globalFontSize * 1.5}px`,
}));

export const ContrastStyledAddIcon = styled(StyledAddIcon)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

export const ContrastStyledAutoGenerateIcon = styled(AutoModeIcon)(
  ({ theme }) => ({
    color: theme.palette.secondary.contrastText,
  })
);

export const ContrastEditIcon = styled(EditIcon)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

export const StyledTreeItem = styled(TreeItem)({
  "&.MuiTreeItem-root .Mui-selected": {
    backgroundColor: "transparent",
  },
});

export const StyledItem = styled(Item)({
  "&:hover": {
    backgroundColor: "transparent",
  },
});

export const DeleteMenuItem = styled(MenuItem)({
  color: "#EB452C",
});

export const DeleteListItemIcon = styled(ListItemIcon)({
  color: "#EB452C",
});

export const CanvasBoxContainer = styled(Box)({
  minHeight: "400px",
  width: "100%",
});

export const ThumbnailPaperContainer = styled(Paper)({
  position: "absolute",
  bottom: 50,
  right: 10,
  width: "25%",
  height: "25%",
});

export const FloorplanBoxContainer = styled(Box)(
  ({ isMaximized, isDrawerOpen }) => ({
    padding: isMaximized ? 0 : "10px", // Adjust the spacing value as needed
    width: "100%",
    height: isMaximized ? "100%" : "70vh",
    maxWidth: isDrawerOpen
      ? `${window.innerWidth - 300}px` // Adjust the spacing value as needed
      : `${window.innerWidth - 125}px`, // Adjust the spacing value as needed
  })
);

export const FloorplanControlsBoxContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "inherit",
  rowGap: "10px",
});

export const GraphicalObjectsListBoxContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  columnGap: "10px",
});

export const EditAreaBoxContainer = styled(Box)({
  maxHeight: "450px",
  overflow: "hidden",
});

export const FullScreenFloorplanDialogContent = styled(DialogContent)({
  paddingBottom: "20px",
});

export const GraphicalObjectsListPaperContainer = styled(Paper)(
  ({ isObjectsListOpen, fitScreen }) => ({
    height: "450px", // Adjust the spacing value as needed
    minWidth: isObjectsListOpen ? "320px" : "160px", // Adjust the spacing value as needed
    maxWidth: isObjectsListOpen ? "320px" : "160px", // Adjust the spacing value as needed
    zIndex: fitScreen ? 100 : 0,
    overflowY: "auto",
    transition: "min-width 0.3s ease-in-out",
  })
);

export const GraphicalObjectsListDivider = styled(Divider)({
  margin: "15px 0",
});

export const SidePanelPaperContainer = styled(Paper)({
  height: "450px",
  minWidth: "320px",
  maxWidth: "320px",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const EllipsisTypography = styled(Typography)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

export const ObjectNameTypography = styled(EllipsisTypography)({
  padding: "10px",
});

export const StyledGrid = styled(Grid)({
  paddingInline: "10px",
});

export const SidePanelActionsBoxContainer = styled(Box)({
  display: "flex",
  paddingInline: "10px",
});

export const Styled2DIconButton = styled(IconButton)(({ theme, mode }) => ({
  border:
    mode === "2d"
      ? `2px solid ${theme.palette.secondary.contrastText}`
      : "none",
  borderRadius: 0,
}));

export const Styled3DIconButton = styled(IconButton)(({ theme, mode }) => ({
  border:
    mode === "3d"
      ? `2px solid ${theme.palette.secondary.contrastText}`
      : "none",
  borderRadius: 0,
}));

export const EditFloorplanContainer = styled(Box)({
  position: "absolute",
  top: "125px",
  left: 0,
});

export const FloorplanDialog = styled(Dialog)({
  ".MuiPaper-root": { overflow: "hidden" },
});
