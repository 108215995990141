import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageInfo } from "../store/slices/appSlice";
import { HomePagePadding } from "../components/styles/general/General.styles";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../util/utils";
import {
  ErrorText,
  ForbiddenContainer,
} from "../components/styles/home/Forbidden.styles";

const Forbidden = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Effects
  useEffect(() => {
    dispatch(
      setPageInfo({
        showBack: true,
        backName: getTranslation("home", t, i18n),
        back: "/",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HomePagePadding>
      <ForbiddenContainer>
        <ErrorText id="forbidden-text" variant="h3">
          Page Forbidden
        </ErrorText>
      </ForbiddenContainer>
    </HomePagePadding>
  );
};

export default Forbidden;
