import { useTranslation } from "react-i18next";
import { getTranslation, THEME } from "../../../util/utils";
import { useSelector } from "react-redux";
import { selectActiveStep } from "../../../store/slices/reportsSlice";
import { Stepper, Step, StepLabel, Box, useTheme } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { selectTheme } from "../../../store/slices/appSlice";

const ReportsStepper = () => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const activeStep = useSelector(selectActiveStep);
  const currentTheme = useSelector(selectTheme);

  // Other variables
  const steps = [
    getTranslation("BASIC_DATA", t, i18n),
    getTranslation("DEFINE_QUERY", t, i18n),
    getTranslation("FORMAT_RESULT", t, i18n),
  ];

  return (
    <Box
      sx={{
        overflowX: "auto",
        backgroundColor:
          currentTheme === THEME.LIGHT ? "rgb(244, 244, 244)" : "#383838",
      }}
    >
      <Stepper
        sx={{
          paddingBlock: "10px",
          width: "100%",
          "& .MuiStepIcon-root.Mui-active": {
            color: theme.palette.secondary.contrastText,
          },
          "& .MuiStepIcon-root.Mui-completed": {
            color: theme.palette.secondary.contrastText,
          },
        }}
        activeStep={activeStep - 1}
        connector={<ArrowForwardIosIcon sx={{ color: "rgb(216, 216, 216)" }} />}
      >
        {steps.map((label, index) => {
          return (
            <Step key={index}>
              <StepLabel
                sx={{
                  "& .MuiStepLabel-label": {
                    color: theme.palette.primary.main,
                  },
                  "& .MuiStepLabel-label.Mui-active": {
                    color: theme.palette.secondary.contrastText,
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default ReportsStepper;
