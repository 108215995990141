export const EVENT_DISCARD_CHANGES = "discard-changes";

const events = {};

const EventBus = {
  on(eventType, callback) {
    if (!events[eventType]) {
      events[eventType] = [];
    }

    events[eventType].push(callback);
  },
  checkEventTypeExisting(eventType) {
    return events[eventType]?.length > 0;
  },
  dispatch(eventType, data) {
    if (events[eventType]) {
      events[eventType].forEach((cb) => {
        cb(data);
      });
    }
  },
  remove(eventType, callback) {
    if (events[eventType]) {
      let callbackIndex = events[eventType].indexOf(callback);
      events[eventType].splice(callbackIndex, 1);
    }
  },
};

export default EventBus;
