import { useTranslation } from "react-i18next";
import PageTransition from "../../../components/PageTransition";
import { getTranslation } from "../../../util/utils";
import { OnboardingOrganizationPageContainer } from "../../../components/styles/onboarding/OnboardingOrganizationPage.styles";
import OrganizationDetailsGroup from "../../../components/inputs/OrganizationDetailsGroup";
import { PrimaryText } from "../../../components/styles/onboarding/Onboarding.styles";

const OnboardingOrganizationDetails = ({ transitionDirection }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  return (
    <PageTransition direction={transitionDirection}>
      <PrimaryText sx={{ marginBottom: "10px" }} variant="h2" align="start">
        {getTranslation("ORGANIZATION_DATA", t, i18n)}
      </PrimaryText>

      <PrimaryText sx={{ marginBottom: "20px" }} variant="body2">
        {getTranslation("ORGANIZATION_DETAILS_CAPTION", t, i18n)}
      </PrimaryText>

      <OnboardingOrganizationPageContainer>
        {/* Organization name and phone number inputs */}
        <OrganizationDetailsGroup />
      </OnboardingOrganizationPageContainer>
    </PageTransition>
  );
};

export default OnboardingOrganizationDetails;
