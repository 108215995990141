import {
  ActionTitle,
  HeaderGridContainer,
  HeaderWrapper,
  PageTitle,
} from "../../../components/styles/header/Header.styles";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import { useSelector } from "react-redux";
import { selectPageInfo } from "../../../store/slices/appSlice";
import LoadingSpinner from "../../../components/common/LoadingSpinner";
import {
  selectOriginalTypeInput,
  selectTypeInput,
} from "../../../store/slices/typeInputSlice";
import { checkTypeChanged } from "../../../util/type-utils";
import { selectUser } from "../../../store/slices/authSlice";

const AddTypeHeader = ({ handleAction, handleCancelAction }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const currentPageInfo = useSelector(selectPageInfo);
  const typeInput = useSelector(selectTypeInput);
  const originalInput = useSelector(selectOriginalTypeInput);

  // Other variables
  const isDoneDisabled =
    currentPageInfo.isActionButtonDisabled ||
    !checkTypeChanged(originalInput, typeInput, user.region) ||
    !typeInput?.name ||
    !typeInput?.displayId ||
    !typeInput?.description ||
    !typeInput?.functionId;

  return (
    <HeaderWrapper id="sticky-header">
      <HeaderGridContainer container>
        <Grid item xs={3}>
          <ActionTitle
            tabIndex="0"
            data-testid="action-title-cancel"
            id="action-title-cancel"
            onClick={handleCancelAction}
          >
            {getTranslation("CANCEL", t, i18n)}
          </ActionTitle>
        </Grid>
        <Grid item xs={6}>
          <PageTitle variant="h6" id="title">
            {getTranslation("CREATE_TYPE", t, i18n)}
          </PageTitle>
        </Grid>
        <Grid display="flex" justifyContent="end" item xs={3}>
          {currentPageInfo.isLoadingAction ? (
            <LoadingSpinner />
          ) : (
            <ActionTitle
              tabIndex="0"
              align="right"
              onClick={() => {
                if (!isDoneDisabled) {
                  handleAction();
                }
              }}
              aria-disabled={isDoneDisabled}
              disabled={isDoneDisabled ? 1 : 0}
              id="action-title-done"
              data-testid="action-title-done"
            >
              {getTranslation("DONE", t, i18n)}
            </ActionTitle>
          )}
        </Grid>
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default AddTypeHeader;
