import { Stack } from "@mui/material";
import ReportCard from "./ReportCard";
import { useSelector } from "react-redux/es";
import { selectUser } from "../../store/slices/authSlice";
import { useGetReportDefinitionsQuery } from "../../store/slices/api/reportsApiSlice";
import { selectSearchQuery } from "../../store/slices/reportSlice";
import { WIDGETS } from "../../util/reports-utils";
import LoadingSpinnerOverlay from "../common/LoadingSpinnerOverlay";

const ReportList = () => {
  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const searchQuery = useSelector(selectSearchQuery);

  // Queries
  const { data, isLoading } = useGetReportDefinitionsQuery({ organizationId });

  if (isLoading) {
    return <LoadingSpinnerOverlay />;
  }

  const filtered =
    data
      ?.filter((report) => {
        const lowerCaseName = report.name.toLowerCase();
        return (
          lowerCaseName.includes(searchQuery.toLowerCase()) &&
          (report.organizationId
            ? true
            : WIDGETS.some((widget) => report.id === widget))
        );
      })
      ?.slice()
      ?.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA > nameB) {
          return 1;
        } else if (nameA < nameB) {
          return -1;
        }

        return 0;
      }) ?? [];

  return (
    <Stack spacing={0.2}>
      {filtered?.map((report, index) => (
        <ReportCard key={report.id} report={report} index={index} />
      ))}
    </Stack>
  );
};

export default ReportList;
