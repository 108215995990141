import { styled } from "@mui/system";
import { Typography, Grid } from "@mui/material";

export const SectionHeaderContainer = styled(Grid)(({ theme }) => ({
  marginBottom: "10px",
  alignItems: "center",
  [theme.breakpoints.up("xs")]: {
    marginTop: "16px",
  },
  "& > *:nth-last-of-type(1)": {
    display: "flex",
    justifyContent: "end",
  },
}));

export const SectionName = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const SectionActionText = styled(Typography)(
  ({ theme }) =>
    theme.unstable_sx({
      marginRight: "10%",
      textTransform: "initial",
    }),
  ({ theme }) => ({
    color: theme.palette.secondary.contrastText,
    cursor: "pointer",
  })
);
