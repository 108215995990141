import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reportId: null,
  searchQuery: "",
};

const reportSlice = createSlice({
  name: "type",
  initialState: initialState,
  reducers: {
    setReportId(state, action) {
      const newReportId = action.payload;
      state.reportId = newReportId;
    },
    setSearchQuery(state, action) {
      const newSearchQuery = action.payload;
      state.searchQuery = newSearchQuery;
    },
  },
});

export default reportSlice.reducer;
export const { setReportId, setSearchQuery } = reportSlice.actions;
export const selectReportId = (state) => state.report.reportId;
export const selectSearchQuery = (state) => state.report.searchQuery;
