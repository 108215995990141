import { useSelector } from "react-redux";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import TypeDetailCard from "../../components/types/type-detail/TypeDetailCard";
import TypeDetailList from "../../components/types/type-detail/TypeDetailList";
import PageTransition from "../../components/PageTransition";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { selectUser } from "../../store/slices/authSlice";
import { selectCategory } from "../../store/slices/typeSearchSlice";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import TypeDetailHeader from "../../navigation/header/library/TypeDetailHeader";
import { transitionDirections } from "../../util/utils";
import {
  useAddTypeRecentMutation,
  useGetTypeDetailsQuery,
} from "../../store/slices/api/typesApiSlice";
import { useEffect } from "react";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const TypeDetailPage = () => {
  // General hooks
  const [searchParams] = useSearchParams();
  const { typeId } = useParams();
  const navigate = useNavigate();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const category = useSelector(selectCategory);

  // Mutations
  const [addRecent] = useAddTypeRecentMutation();

  // Queries
  const {
    data: typeData,
    isLoading: isLoadingType,
    isError: isErrorType,
    error: typeDataError,
  } = useGetTypeDetailsQuery({
    typeId,
    organizationId,
  });

  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery({
    userId: user.id,
    organizationId,
  });

  // Other variables
  const direction = searchParams.get("direction");

  // Effects
  useEffect(() => {
    const handleAddRecents = async () => {
      try {
        await addRecent({ organizationId, typeId }).unwrap();
      } catch (error) {
        console.error("Failed to add to recents", error);
      }
    };

    if (typeId) {
      handleAddRecents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (typeDataError?.status === 404) {
    return <Navigate to="*" />;
  }

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling
          isLoading={isLoadingType || isLoadingUserRoles}
          isError={isErrorType || isErrorUserRoles}
        >
          <PageTransition direction={direction}>
            <TypeDetailHeader
              typeData={typeData}
              userRoles={userRoles}
              actionEditFunction={() => {
                navigate(
                  `/library/${typeId}/edit?direction=${transitionDirections.RIGHT_TO_LEFT}&category=${category}&previousPage=type-details`
                );
              }}
            />
            <HomePagePadding id="type-details-container">
              <>
                <TypeDetailCard typeData={typeData} />

                <TypeDetailList typeData={typeData} />

                <div id="end"></div>
              </>
            </HomePagePadding>
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default TypeDetailPage;
