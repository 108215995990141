import React from "react";
import { useTranslation } from "react-i18next";
import { useGetOrganizationDataStorageLocationQuery } from "../../store/slices/api/organizationsApiSlice";
import { Box, Typography, useTheme } from "@mui/material";
import ErrorHandling from "../common/ErrorHandling";
import { SecondaryText } from "../styles/assets/ListInlineView.styles";
import { getTranslation } from "../../util/utils";
import { SecondaryLightText } from "../styles/general/General.styles";

const OrganizationDataStorageLocation = ({ organizationId }) => {
  // General hooks
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  // Queries
  const {
    data: orgDataStorageLocation,
    isLoading: isLoadingOrgDataStorageLocation,
    isError: isErrorSuccessDataStorageLocation,
  } = useGetOrganizationDataStorageLocationQuery({ organizationId });

  return (
    <ErrorHandling
      isLoading={isLoadingOrgDataStorageLocation}
      isError={isErrorSuccessDataStorageLocation}
    >
      <Box>
        <SecondaryLightText variant="caption">
          {getTranslation("ORG_GEO_LOCATION_STORING", t, i18n)}
        </SecondaryLightText>
        <SecondaryText>
          {orgDataStorageLocation
            ? i18n.exists(orgDataStorageLocation.displayId)
              ? t(orgDataStorageLocation.displayId)
              : orgDataStorageLocation.name
            : ""}
        </SecondaryText>
      </Box>
    </ErrorHandling>
  );
};

export default OrganizationDataStorageLocation;
