import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useGetAllCharacteristicsQuery,
  useGetCharacteristicSetTemplatesQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  groupCharacteristics,
  mergeCharacteristics,
} from "../../../util/asset-utils";
import { getTranslation } from "../../../util/utils";
import CharacteristicItem from "../../assets/asset-detail/CharacteristicItem";
import ErrorHandling from "../../common/ErrorHandling";
import { SectionTitle } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { ListContainer } from "../../styles/assets/asset-detail/AssetDetailCharacteristicGroup.styles";

const TypeCharacteristicGroup = ({ typeData }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default).id;

  // Queries
  const {
    data: characteristicSetTemplatesData,
    isLoading: isLoadingCharacteristicSetTemplates,
    isError: isErrorSetTemplates,
  } = useGetCharacteristicSetTemplatesQuery({
    resourceFunctionId: typeData.functionId,
    organizationId: user?.organizations?.find((o) => o.default).id,
  });
  const {
    data: characteristicDefinitionsData,
    isLoading: isLoadingCharacteristicDefinitions,
    isError: isErrorCharacteristicDefinitions,
  } = useGetAllCharacteristicsQuery({ organizationId });

  // Other variables
  const { characteristics: typeCharacteristics } = typeData;
  const characteristics = useMemo(
    () =>
      mergeCharacteristics(
        [],
        typeCharacteristics,
        characteristicDefinitionsData
      ),
    [typeCharacteristics, characteristicDefinitionsData]
  );

  const { setTemplates } = groupCharacteristics(
    characteristics,
    characteristicSetTemplatesData
  );

  if (
    isLoadingCharacteristicSetTemplates ||
    isLoadingCharacteristicDefinitions
  ) {
    return <></>;
  }

  return (
    <ErrorHandling
      isLoading={
        isLoadingCharacteristicSetTemplates ||
        isLoadingCharacteristicDefinitions
      }
      isError={isErrorSetTemplates || isErrorCharacteristicDefinitions}
    >
      <div>
        {setTemplates
          .filter(
            (setTemplate) =>
              setTemplate?.characteristics?.filter((char) =>
                Boolean(char.value)
              )?.length > 0
          )
          .map((setTemplate) => (
            <Fragment key={setTemplate.id}>
              <ListContainer>
                <SectionTitle id="charateristic-group">
                  {getTranslation(setTemplate.name, t, i18n)}
                </SectionTitle>
              </ListContainer>
              {setTemplate?.characteristics
                ?.filter((char) => Boolean(char.value))
                ?.slice()
                ?.sort((a, b) => {
                  if (a.id > b.id) {
                    return 1;
                  } else {
                    return -1;
                  }
                })
                ?.map((row) => (
                  <CharacteristicItem
                    key={row.id}
                    characteristic={row}
                    region={user?.region}
                  />
                ))}
            </Fragment>
          ))}
      </div>
    </ErrorHandling>
  );
};

export default TypeCharacteristicGroup;
