import React from "react";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../util/utils";
import { Box, TableHead, TableRow, TableSortLabel } from "@mui/material";
import {
  EmptyCell,
  SecondaryContrastColorCell,
} from "../styles/assets/asset-list/AssetListTable.styles";
import { visuallyHidden } from "@mui/utils";
import { NavigationActionIcon } from "../styles/menu/Menu.styles";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../store/slices/appSlice";

const EnhancedTableHead = (props) => {
  const { headCells, order, orderBy, onRequestSort, hideEmptyCell } = props;

  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Handlers
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {!hideEmptyCell && <EmptyCell></EmptyCell>}

        {headCells.map((headCell) => (
          <SecondaryContrastColorCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              IconComponent={(props) => {
                return orderBy === headCell.id ? (
                  <NavigationActionIcon
                    active={true}
                    customfontsize={globalFontSize}
                  >
                    {order === "asc"
                      ? "keyboard_arrow_down"
                      : "keyboard_arrow_up"}
                  </NavigationActionIcon>
                ) : (
                  <></>
                );
              }}
            >
              {getTranslation(headCell.label, t, i18n)}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </SecondaryContrastColorCell>
        ))}
        {!hideEmptyCell && <EmptyCell></EmptyCell>}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
