import {
  NextButtonContainer,
  StyledChevronLeftIcon,
  StyledMobileStepper,
} from "./styles/onboarding/MobileSteps.styles";

const MobileSteps = ({ stepQuantity, activeStep, handleBack }) => {
  return (
    <StyledMobileStepper
      id="mobile-stepper"
      color="primary"
      variant="dots"
      steps={stepQuantity + 1}
      position="static"
      activeStep={activeStep}
      backButton={
        <StyledChevronLeftIcon
          fontSize="large"
          data-testid="back-button"
          onClick={handleBack}
          aria-label="back"
          activeStep={activeStep}
        ></StyledChevronLeftIcon>
      }
      nextButton={
        <NextButtonContainer>
          <StyledChevronLeftIcon
            fontSize="large"
            onClick={handleBack}
            aria-label="back"
          ></StyledChevronLeftIcon>
        </NextButtonContainer>
      }
    />
  );
};

export default MobileSteps;
