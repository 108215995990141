import { TextField } from "@mui/material";
import { styled } from "@mui/system";
import { SecondaryText } from "./AssetDetailBasicInfo.styles";

export const AssetDetailSubHeader = styled(SecondaryText)({
  marginTop: "10px",
  textAlign: "center",
});

export const CharacteristicItemInput = styled(TextField)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: "10px",
}));
