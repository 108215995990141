import { styled } from "@mui/system";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
} from "@mui/material";
import { SecondaryContrastButton } from "../../general/General.styles";
import { THEME } from "../../../../util/utils";

export const CancelButton = styled(SecondaryContrastButton)(({ theme }) => ({
  width: "25%",
  borderColor: theme.palette.secondary.contrastText,
  "&:hover": {
    borderColor: theme.palette.secondary.contrastText,
  },
}));

export const CancelInviteButton = styled(CancelButton)(({ theme }) => ({
  width: "80px",
}));

export const CancelAlertButton = styled(CancelButton)(({ theme }) => ({
  width: "100%",
}));

export const CreateButton = styled(SecondaryContrastButton)(
  ({ theme, disabled }) => ({
    width: "25%",
    color: "white",
    backgroundColor: disabled
      ? "rgba(0, 0, 0, 0.26)"
      : theme.palette.secondary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.secondary.contrastText,
    },
  })
);

export const EndAlignedItemsContainer = styled(Grid)({
  alignItems: "end",
});

export const ChooseOptionsContainer = styled(Grid)({
  ".MuiGrid-item": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    mt: "10px",
  },
});

export const AlertDivider = styled(Divider)({
  marginBlock: "5px",
  borderBottomWidth: "2px",
});

export const StyledAccordion = styled(Accordion)(({ currentTheme }) => ({
  boxShadow: "none",
  "&::before": {
    display: "none",
  },
  margin: "0 !important",
  backgroundImage:
    currentTheme === THEME.DARK
      ? "linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));"
      : "none",
}));

export const BgAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  "&::before": {
    display: "none",
  },
  margin: "0 !important",
  backgroundColor: theme.palette.primary.contrastText,
}));

export const StyledAccordionSummary = styled(AccordionSummary)(
  ({ theme, value }) => ({
    flexDirection: "row-reverse",
    borderBottom: "2px solid",
    borderImage: `linear-gradient(to right, ${theme.palette.secondary.contrastText} ${value}, #DBDBDB ${value}) 1`,
    minHeight: "unset !important",
    height: "40px",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    padding: 0,
  })
);
export const StyledAccordionDetails = styled(AccordionDetails)({
  paddingInline: 0,
});

export const ChooseButton = styled(SecondaryContrastButton)({
  flexDirection: "column",
  height: "100%",
  width: "100%",
  gap: "8px",
  justifyContent: "end",
});
