import { Stack, styled } from "@mui/system";
import {
    Bar,
    Doughnut,
    Line,
    Pie
} from "react-chartjs-2";

export const BarStack = styled(Stack) ({
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
})

export const StyledBar = styled(Bar) ({
    maxHeight: "100px",
})

export const AreaStack = styled(Stack) ({
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
})

export const LineStack = styled(Stack) ({
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
})

export const StyledLine = styled(Line) ({
    maxHeight: "100px",
})

export const DoughnutStack = styled(Stack) ({
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
})

export const StyledDoughnut = styled(Doughnut) ({
    maxHeight: "100px",
})

export const PieStack = styled(Stack) ({
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
})

export const StyledPie = styled(Pie) ({
    maxHeight: "100px",
})