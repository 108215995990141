import { useSelector } from "react-redux";
import { selectUser, setUser } from "../store/slices/authSlice";
import { useUpdateUserOrganizationMutation } from "../store/slices/api/userManagementSlice";
import useCheckOrganizationRestricted from "../hooks/useCheckOrganizationRestricted";
import { ListItem } from "@mui/material";
import { StyledCheckIcon } from "./styles/general/General.styles";
import {
  NonVisibleCheckIcon,
  OrganizationItemButton,
  OrganizationItemText,
} from "./styles/profile/OrganizationsMenu.styles";
import { EllipseText } from "./styles/home/Home.styles";
import { useDispatch } from "react-redux";
import { messageError, messageSuccess } from "../util/notification";
import { getTranslation } from "../util/utils";
import { useTranslation } from "react-i18next";

const OrganizationsMenuListItem = ({ organization, setOpen }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const { id, name, nickname } = organization;

  // Mutations
  const [
    updateUserOrganization,
    { isLoading: isLoadingUpdateUserOrganization },
  ] = useUpdateUserOrganizationMutation();

  const { isRestricted } = useCheckOrganizationRestricted(
    organization,
    isLoadingUpdateUserOrganization
  );

  const handleChangeOrganization = async (organizationId) => {
    const newOrganizationId = organizationId;
    const newOrganizations = user?.organizations?.map((org) => {
      return org.id === newOrganizationId
        ? { ...org, default: true }
        : { ...org, default: false };
    });

    try {
      await updateUserOrganization({
        userId: user.id,
        newOrganizationId: newOrganizationId,
      }).unwrap();

      dispatch(
        setUser({
          ...user,
          defaultOrganizationId: newOrganizationId,
          organizations: newOrganizations,
        })
      );

      messageSuccess(
        getTranslation("successfulUpdateUserOrganization", t, i18n)
      );
    } catch (error) {
      messageError(getTranslation("failedUpdateUserOrganization", t, i18n));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ListItem
        onClick={() => {
          if (!isRestricted) {
            handleChangeOrganization(id);
            handleClose();
          }
        }}
        id="change-sorting-option"
        secondaryAction={
          user?.organizations?.find((o) => o.default)?.id === id ? (
            <StyledCheckIcon fontSize="small" />
          ) : (
            <NonVisibleCheckIcon fontSize="small" />
          )
        }
        disablePadding
      >
        <OrganizationItemButton>
          <OrganizationItemText
            primary={
              <>
                <EllipseText>{nickname || name}</EllipseText>

                {isRestricted && (
                  <EllipseText fontWeight="bold" align="center">
                    (Restricted)
                  </EllipseText>
                )}
              </>
            }
          />
        </OrganizationItemButton>
      </ListItem>
    </div>
  );
};

export default OrganizationsMenuListItem;
