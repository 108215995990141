import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";
import { delimiterLocalize } from "../../util/utils";
import {
  DarkBackgroundWrapper,
  ReportContentContainer,
  ReportSecondaryText,
} from "../styles/home/ChartPage.styles";

const ReportAssetCard = ({
  onClick,
  title,
  assetCount = 0,
  recentCreatedCount = 0,
}) => {
  const user = useSelector(selectUser);

  return (
    <DarkBackgroundWrapper onClick={onClick}>
      <ReportSecondaryText textAlign="center">{title}</ReportSecondaryText>
      <ReportContentContainer>
        <ReportSecondaryText textAlign="end" fontWeight="bold" variant="h1">
          {assetCount.toLocaleString(delimiterLocalize(user.region))}
        </ReportSecondaryText>
        {recentCreatedCount > 0 && (
          <ReportSecondaryText textAlign="end" variant="h2">
            +{recentCreatedCount.toLocaleString(delimiterLocalize(user.region))}
          </ReportSecondaryText>
        )}
      </ReportContentContainer>
    </DarkBackgroundWrapper>
  );
};

export default ReportAssetCard;
