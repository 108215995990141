import { createSelector, createSlice } from "@reduxjs/toolkit";

export const DEFAULT_COLOR = "#767676";

// Tag structure in the Redux store
// {
//   id: 1,
//   name: "Server",
//   color: "#D32F2F",
//   new: false,
//   edited: false,
//   deleted: false,
//   isFromDb: false,
// },

// Initial state
const initialState = {
  initialList: [],
  list: [],
  globalList: [],
  inputValue: "",
  error: "",
  autocompleteOpen: true,
  currentColor: DEFAULT_COLOR,
  recentColors: [],
  markedTag: null,
  mode: "",
  selectedEditTag: null,
  isInDialog: false,
};

// Slice
const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    // Used for adding a new tag to the store
    addTag: (state, action) => {
      const newTag = action.payload;

      if (
        Boolean(newTag.name) &&
        !state.list
          .filter((t) => !t.deleted)
          .some((t) => t.name.toLowerCase() === newTag.name.toLowerCase())
      ) {
        state.list.push({
          ...newTag,
          id: state.list.length + 1,
          new: true,
          edited: false,
          deleted: false,
          isFromDb: false,
        });
      }
    },
    // Used for editing an existing tag into the store
    editTag: (state, action) => {
      const existingTag = action.payload;

      state.list = state.list.filter((t) => t.id !== existingTag.id);

      state.list.push({
        ...existingTag,
        new: !existingTag.isFromDb,
        edited: true,
        deleted: false,
      });
    },
    // Used for deleting an existing tag from the store
    deleteTag: (state, action) => {
      const existingTag = action.payload;

      state.list = state.list.filter((t) => t.id !== existingTag.id);

      state.list.push({
        ...existingTag,
        new: !existingTag.isFromDb,
        deleted: true,
      });
    },
    // Used for setting the tags directly (usually from API request in EditAsset and AssetDetailPage when the BE is ready)
    setTags: (state, action) => {
      const newTags = action.payload;
      state.list = newTags;
    },
    // Used for setting the initial tags directly (usually from API request in EditAsset and AssetDetailPage when the BE is ready)
    setInitialTags: (state, action) => {
      const newInitialList = action.payload;
      state.initialList = newInitialList;
    },
    // Used for setting the initial tags directly (usually from API request in EditAsset and AssetDetailPage when the BE is ready)
    setGlobalTags: (state, action) => {
      const newGlobalTags = action.payload;
      state.globalList = newGlobalTags;
    },
    // Used for removing the new changes from the store (usually performed after clicking the "Done" button)
    // First condition is if new assets are added
    // Second condition is if existing assets are edited (for now, we can edit the color only)
    // Third condition is if existing assets are deleted
    removeTags: (state) => {
      state.list = state.list.filter(
        (t) =>
          !(
            (t.new && !t.deleted) ||
            (t.edited && !t.deleted) ||
            (!t.new && t.deleted)
          )
      );
    },
    // Used for resetting the list to its initial state
    resetTags: (state) => {
      state.list = state.initialList;
    },
    // Used for setting the input value of the autocomplete component
    setInputValue: (state, action) => {
      const newInputValue = action.payload;
      state.inputValue = newInputValue;
    },
    // Used for setting the validation error if users type non-allowed characters
    setError: (state, action) => {
      const newError = action.payload;
      state.error = newError;
    },
    // Used for setting the state of the autocomplete dropdown component
    setAutocompleteOpen: (state, action) => {
      const newValue = action.payload;
      state.autocompleteOpen = newValue;
    },
    // Used for setting the color selected either from the palette or the picker component itself
    setCurrentColor: (state, action) => {
      const newColor = action.payload;
      state.currentColor = newColor;
    },
    // Used for setting the recent colors directly (we wil probably use it to populate the colors stored in the local storage)
    setRecentColors: (state, action) => {
      const newRecentColors = action.payload;
      state.recentColors = newRecentColors;
    },
    // Used for pushing a new recent color to the list of colors
    addRecentColor: (state, action) => {
      const newRecentColor = action.payload;
      const filteredRecentColors = state.recentColors.filter(
        (rc) => rc.backgroundColor !== newRecentColor.backgroundColor
      );

      state.recentColors = [...filteredRecentColors, newRecentColor];
    },
    // Used for setting the marked tag before deleting it with the `Backspace` key
    setMarkedTag: (state, action) => {
      const newMarkedTag = action.payload;
      state.markedTag = newMarkedTag;
    },
    // Used for setting tags mode
    setMode: (state, action) => {
      const newMode = action.payload;
      state.mode = newMode;
    },
    // Used for setting the tag which is currently being edited
    // We need this logic in order not to overlap multiple modals
    setSelectedEditTag: (state, action) => {
      const newTag = action.payload;
      state.selectedEditTag = newTag;
    },
    // Used for controlling whether tags input is inside desktop modal or not
    setIsInDialog: (state, action) => {
      const newValue = action.payload;
      state.isInDialog = newValue;
    },
  },
});

// Export slice and its corresponding actions
export default tagsSlice.reducer;

export const {
  addTag,
  editTag,
  deleteTag,
  setTags,
  setInitialTags,
  setGlobalTags,
  removeTags,
  resetTags,
  setInputValue,
  setError,
  setAutocompleteOpen,
  setCurrentColor,
  setRecentColors,
  addRecentColor,
  setMarkedTag,
  setMode,
  setSelectedEditTag,
  setIsInDialog,
} = tagsSlice.actions;

// Selectors
export const selectAllGlobalTags = (state) => state.tags.globalList;
export const selectAllTags = (state) => state.tags.list;
export const selectInputValue = (state) => state.tags.inputValue.trim();
export const selectError = (state) => state.tags.error;
export const selectAutocompleteOpen = (state) => state.tags.autocompleteOpen;
export const selectCurrentColor = (state) => state.tags.currentColor;
export const selectRecentColors = (state) => state.tags.recentColors;
export const selectMarkedTag = (state) => state.tags.markedTag;
export const selectMode = (state) => state.tags.mode;
export const selectSelectedEditTag = (state) => state.tags.selectedEditTag;
export const selectIsInDialog = (state) => state.tags.isInDialog;

// Memoized selectors
// Select all tags that are not deleted from the store
export const selectAvailableTags = createSelector(
  [selectAllTags, (tags) => tags],
  (tags) => {
    return [...tags]
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      .slice()
      .filter((tag) => !tag.deleted);
  }
);

// Track if there are any changes to the tags list
export const selectHaveChanges = createSelector(
  [selectAllTags, (tags) => tags],
  (tags) => {
    return tags.some(
      (t) =>
        (t.new && !t.deleted) ||
        (t.edited && !t.deleted) ||
        (!t.new && t.deleted)
    );
  }
);
