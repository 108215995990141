import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HomePagePadding } from "../../../components/styles/general/General.styles";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "../../../store/slices/authSlice";
import { Box, TextField } from "@mui/material";
import { ValidationText } from "../../../components/styles/inputs/NamesGroup.styles";
import { messageError, messageSuccess } from "../../../util/notification";
import PageTransition from "../../../components/PageTransition";
import {
  transitionDirections,
  getTranslation,
  testInput,
  permissions,
} from "../../../util/utils";
import UpdateOrganizationContactDetailsHeader from "../../../navigation/header/organization/UpdateOrganizationContactDetailsHeader";
import {
  useGetOrganizationByIdQuery,
  useUpdateOrganizationMutation,
} from "../../../store/slices/api/organizationsApiSlice";
import Access from "../../../components/common/Access";
import ErrorHandling from "../../../components/common/ErrorHandling";
import AppAccess from "../../../components/common/AppAccess";

const UpdateOrganizationContactDetails = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const [searchParams] = useSearchParams();

  // Selectors
  const user = useSelector(selectUser);

  // State
  const [organization, setOrganization] = useState({
    contactPerson: "",
    contactEmail: "",
  });

  // Mutations
  const [updateOrganization, { isLoading: isLoadingUpdateOrganization }] =
    useUpdateOrganizationMutation();

  // Queries
  const { data, isLoading, isError } =
    useGetOrganizationByIdQuery(organizationId);

  // Other variables
  const isValidOrganizationContactPerson = testInput.CONTACT_PERSON(
    organization.contactPerson
  );
  const isValidOrganizationContactEmail = testInput.CONTACT_EMAIL(
    organization.contactEmail
  );
  const isValid =
    isValidOrganizationContactPerson && isValidOrganizationContactEmail;

  // Handlers
  const handleSubmit = async () => {
    if (!Boolean(organization.contactPerson)) {
      messageError(
        `${getTranslation("contactPerson", t, i18n)}: ${getTranslation(
          "userInfoValidation",
          t,
          i18n
        )}`
      );
      return;
    }

    if (!Boolean(organization.contactEmail)) {
      messageError(
        `${getTranslation("contactEmail", t, i18n)}: ${getTranslation(
          "contactEmailValidation",
          t,
          i18n
        )}`
      );
      return;
    }

    try {
      const organizationData = {
        contactPerson: organization.contactPerson,
        contactEmail: organization.contactEmail,
      };

      const data = await updateOrganization({
        organizationData: organizationData,
        organizationId: organizationId,
      }).unwrap();

      if (data) {
        let filteredOrganizations = user?.organizations?.filter(
          (o) => o.id !== data.id
        );

        filteredOrganizations.push(data);
        dispatch(
          setUser({
            ...user,
            organizations: filteredOrganizations,
          })
        );
      }

      messageSuccess(
        getTranslation("successfulUpdateOrganizationContactDetails", t, i18n)
      );
      navigate(
        "/profile/organization/" +
          organizationId +
          `?direction=${transitionDirections.TOP_TO_BOTTOM}`
      );
    } catch (error) {
      messageError(
        getTranslation("failedUpdateOrganizationContactDetails", t, i18n)
      );
    }
  };

  const handleOrganizationContactPersonChange = (event) => {
    setOrganization({
      ...organization,
      contactPerson: event.target.value,
    });
  };

  const handleOrganizationContactEmailChange = (event) => {
    setOrganization({
      ...organization,
      contactEmail: event.target.value,
    });
  };

  const cancelHandler = () => {
    navigate(
      "/profile/organization/" +
        organizationId +
        `?direction=${transitionDirections.TOP_TO_BOTTOM}`
    );
  };

  // Effects
  useEffect(() => {
    if (data) {
      setOrganization({
        contactPerson: data.contactPerson,
        contactEmail: data.contactEmail,
      });
    }
  }, [data]);

  // Other variables
  const transitionDirection = searchParams.get("direction");

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={isLoadingUpdateOrganization || isLoading}
        isError={isError}
      >
        <Access oneOf={[permissions.ORG_MANAGEMENT_EDIT]}>
          <PageTransition direction={transitionDirection}>
            <UpdateOrganizationContactDetailsHeader
              handleAction={handleSubmit}
              handleCancelAction={cancelHandler}
              disableDone={!isValid}
            />
            <HomePagePadding>
              <div>
                <TextField
                  id="organization-contact-person-input"
                  type="text"
                  label={getTranslation("contactPerson", t, i18n)}
                  placeholder={getTranslation("contactPerson", t, i18n)}
                  name="contactPerson"
                  value={organization.contactPerson}
                  onChange={handleOrganizationContactPersonChange}
                  error={!isValidOrganizationContactPerson}
                  required
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    "data-testid": "organization-contact-person-onboarding",
                  }}
                />
                <Box>
                  {!isValidOrganizationContactPerson && (
                    <ValidationText>
                      {getTranslation("userInfoValidation", t, i18n)}
                    </ValidationText>
                  )}
                </Box>
                <TextField
                  id="organization-contact-email-input"
                  type="text"
                  label={getTranslation("contactEmail", t, i18n)}
                  placeholder={getTranslation("contactEmail", t, i18n)}
                  name="contactEmail"
                  value={organization.contactEmail}
                  onChange={handleOrganizationContactEmailChange}
                  error={!isValidOrganizationContactEmail}
                  required
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    "data-testid": "organization-contact-email-onboarding",
                  }}
                />
                <Box>
                  {!isValidOrganizationContactEmail && (
                    <ValidationText>
                      {getTranslation("contactEmailValidation", t, i18n)}
                    </ValidationText>
                  )}
                </Box>
              </div>
            </HomePagePadding>
          </PageTransition>
        </Access>
      </ErrorHandling>
    </AppAccess>
  );
};

export default UpdateOrganizationContactDetails;
