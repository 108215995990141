import { styled } from "@mui/system";
import { Button, Dialog, DialogContentText, Divider } from "@mui/material";
import { CreateButton } from "../assets/asset-form/CreateAsset.styles";

export const ConfirmDialog = styled(Dialog)(({ theme, spaceTop }) => ({
  "& .MuiDialog-container": {
    alignItems: "flex-end",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    background: "bottom",
    boxShadow: "unset",
    borderRadius: "15px",
  },
  marginTop: spaceTop,
  color: theme.palette.text.primary,
}));

export const StyledDialogText = styled(DialogContentText)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.secondary.main,
  padding: theme.spacing(2),
  wordBreak: "break-word",
}));

export const ConfirmDialogButton = styled(Button)({
  width: "100%",
  margin: "auto",
});

export const CloseDialogButton = styled(Button)({
  width: "100%",
  margin: "auto",
  color: "#89C0ED",
});

export const StyledDivider = styled(Divider)({
  height: "5px",
});

export const TopLevelDialog = styled(Dialog)({
  zIndex: 1301,
});

export const MaxContentButton = styled(CreateButton)({
  width: "max-content",
});

export const InviteUserButton = styled(CreateButton)({
  width: "80px",
});
