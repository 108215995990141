import { FormatResultPreviewTableWrapper } from "../styles/reports/Reports.styles";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  constructMRTAggregatedCellContent,
  DEFAULT_REPORT_RESULTS_PAGE_SIZE,
  REPORT_RESULTS_PAGE_OPTIONS,
} from "../../util/reports-utils";

const ReportExecutionResultTable = ({
  executionFurtherResultFields,
  executionGroupFields,
  rows,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Other variables
  const columns = useMemo(
    () =>
      executionGroupFields.length > 0
        ? [
            {
              accessorFn: (row) => {
                const groupKey = executionGroupFields
                  .map((field) => field.field)
                  .join(":");

                return row[groupKey];
              },
              id: executionGroupFields.map((field) => field.field).join(":"),
              header: executionGroupFields
                .map((field) => field.displayName)
                .join(", "),
              usedForGrouping: true,
            },
            ...executionFurtherResultFields.map((field) => {
              const {
                field: accessorKey,
                displayName: header,
                aggregationTypes,
              } = field;

              const usedForAggregation = aggregationTypes.length > 0;

              return usedForAggregation
                ? {
                    accessorFn: (row) => row[accessorKey],
                    id: accessorKey,
                    header,
                    AggregatedCell: ({ row }) => (
                      <>
                        {constructMRTAggregatedCellContent(
                          accessorKey,
                          aggregationTypes,
                          t,
                          i18n,
                          row
                        )}
                      </>
                    ),
                  }
                : {
                    accessorFn: (row) => row[accessorKey],
                    id: accessorKey,
                    header,
                  };
            }),
          ]
        : [
            ...executionFurtherResultFields.map((field) => {
              const { field: accessorKey, displayName: header } = field;

              return {
                accessorFn: (row) => row[accessorKey],
                id: accessorKey,
                header,
              };
            }),
          ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [executionGroupFields, executionFurtherResultFields]
  );

  const rowsData = useMemo(() => rows, [rows]);

  const data = useMemo(
    () => [
      ...rowsData.map((row) => {
        const keys = Object.keys(row);

        const groupKeys = executionGroupFields.map((field) => field.field);

        const joinedGroupKeys = groupKeys.join(":");
        let joinedGroupValue = "";

        for (let i = 0; i < groupKeys.length; i++) {
          const groupKey = groupKeys[i];

          joinedGroupValue += `${row[groupKey]} `;
        }

        let resultObject = { ...row };

        if (Boolean(joinedGroupKeys)) {
          resultObject[joinedGroupKeys] = `${joinedGroupValue} ${
            keys?.some((key) => key === "subRows")
              ? `(${row.subRows?.length})`
              : ""
          }`;
        }

        return resultObject;
      }),
    ],
    [executionGroupFields, rowsData]
  );

  // MRT initialization
  const table = useMaterialReactTable({
    columns,
    data,
    enableTopToolbar: false,
    enableExpandAll: false, //hide expand all double arrow in column header
    enableExpanding: executionGroupFields?.length > 0,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableSorting: false,
    groupedColumnMode: false,
    initialState: {
      density: "compact",
      expanded: false,
      pagination: {
        pageSize: DEFAULT_REPORT_RESULTS_PAGE_SIZE,
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: "none",
      },
    },
    muiPaginationProps: {
      rowsPerPageOptions: REPORT_RESULTS_PAGE_OPTIONS,
    },
    state: {
      columnOrder: columns.map((column) => column.accessorKey),
    },
  });

  return (
    <FormatResultPreviewTableWrapper>
      <MaterialReactTable table={table} />
    </FormatResultPreviewTableWrapper>
  );
};

export default ReportExecutionResultTable;
