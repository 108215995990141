import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  coordinates: {
    x: 0,
    y: 0,
  },
  visible: false,
  resource: {
    name: "",
    type: "",
    functionId: null,
    characteristics: [],
  },
};

const tooltipSlice = createSlice({
  name: "tooltip",
  initialState,
  reducers: {
    setCoordinates: (state, action) => {
      const newCoordinates = action.payload;
      state.coordinates = newCoordinates;
    },
    setResource: (state, action) => {
      const newResource = action.payload;
      state.resource = newResource;
    },
    setVisible: (state, action) => {
      const newVisibility = action.payload;
      state.visible = newVisibility;
    },
  },
});

export default tooltipSlice.reducer;
export const { setCoordinates, setVisible, setResource } = tooltipSlice.actions;
export const selectCoordinates = (state) => state.tooltip.coordinates;
export const selectVisible = (state) => state.tooltip.visible;
export const selectResource = (state) => state.tooltip.resource;
