import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import {
  SectionActionText,
  SectionHeaderContainer,
  SectionName,
} from "./styles/home/SectionHeader.styles";
import { getTranslation } from "../util/utils";

const SectionHeader = ({
  title,
  titleId,
  actionName,
  actionNameId,
  action,
  actionNameVisible,
}) => {
  // General Hooks
  const { t, i18n } = useTranslation();

  return (
    <SectionHeaderContainer onClick={action ? action : null} container>
      <Grid item xs={6}>
        {/* Section name */}
        <SectionName id={titleId} variant="h6">
          {getTranslation(title, t, i18n)}
        </SectionName>
      </Grid>
      <Grid item xs={6}>
        {/* Action name */}
        {actionNameVisible && (
          <SectionActionText id={actionNameId} variant="button">
            {getTranslation(actionName, t, i18n)}
          </SectionActionText>
        )}
      </Grid>
    </SectionHeaderContainer>
  );
};

export default SectionHeader;
