import React, { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NamesGroup from "../../components/inputs/NamesGroup";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { useDispatch, useSelector } from "react-redux";
import { selectPageInfo, setPageInfo } from "../../store/slices/appSlice";
import {
  selectUserData,
  setUserData,
} from "../../store/slices/onboardingSlice";
import { selectUser, setUser } from "../../store/slices/authSlice";
import { useUpdateUserNameMutation } from "../../store/slices/api/userManagementSlice";
import { messageError, messageSuccess } from "../../util/notification";
import PageTransition from "../../components/PageTransition";
import {
  transitionDirections,
  getTranslation,
  testInput,
} from "../../util/utils";
import UpdateUserNameHeader from "../../navigation/header/profile/UpdateUserNameHeader";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";

const UpdateUserName = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Selectors
  const authUser = useSelector(selectUser);
  const userData = useSelector(selectUserData);
  const pageInfo = useSelector(selectPageInfo);

  // Mutations
  const [updateUserName, { isLoading: isLoadingUpdateUserName }] =
    useUpdateUserNameMutation();

  // Other variables
  const transitionDirection = searchParams.get("direction");

  // Handlers
  const handleSubmit = async () => {
    const isValidFirstName = testInput.USER_NAME(userData.firstName);
    const isValidLastName = testInput.USER_NAME(userData.lastName);

    if (!isValidFirstName) {
      messageError(
        `${getTranslation("firstName", t, i18n)}: ${getTranslation(
          "userInfoValidation",
          t,
          i18n
        )}`
      );
      return;
    }

    if (!isValidLastName) {
      messageError(
        `${getTranslation("lastName", t, i18n)}: ${getTranslation(
          "userInfoValidation",
          t,
          i18n
        )}`
      );
      return;
    }

    try {
      const data = await updateUserName({
        userId: userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
      }).unwrap();

      if (data) {
        dispatch(
          setUser({
            ...authUser,
            firstName: data.firstName,
            lastName: data.lastName,
          })
        );
      }

      messageSuccess(getTranslation("successfulUpdateUserName", t, i18n));
      navigate(
        `/profile/userdetail?direction=${transitionDirections.TOP_TO_BOTTOM}`
      );
    } catch (error) {
      messageError(getTranslation("failedUpdateUserName", t, i18n));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleActionButtonDisabled = (isValid) => {
    dispatch(
      setPageInfo({
        ...pageInfo,
        isActionButtonDisabled: !isValid,
      })
    );
  };

  const cancelHandler = () => {
    navigate(
      `/profile/userdetail?direction=${transitionDirections.TOP_TO_BOTTOM}`
    );
  };

  // Effects
  useEffect(() => {
    const currentUser = {
      ...authUser,
      firstName: authUser.firstName,
      lastName: authUser.lastName,
    };

    dispatch(setUserData(currentUser));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppAccess>
      <ErrorHandling isLoading={isLoadingUpdateUserName} isError={false}>
        <PageTransition direction={transitionDirection}>
          <UpdateUserNameHeader
            handleAction={handleSubmit}
            handleCancelAction={cancelHandler}
          />
          <HomePagePadding>
            <NamesGroup
              handleActionButtonDisabled={handleActionButtonDisabled}
            />
          </HomePagePadding>
        </PageTransition>
      </ErrorHandling>
    </AppAccess>
  );
};

export default UpdateUserName;
