import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import { NavigationActionIcon } from "../../styles/menu/Menu.styles";
import { Grid, IconButton } from "@mui/material";
import EditType from "../type-form/EditType";
import {
  CreationTypeGridContainer,
  HeaderContainer,
} from "../../styles/types/type-details/DesktopTypeDetails.styles";
import AddFavoriteType from "./AddFavoriteType";

const DesktopTypeDetailsHeader = ({ typeId, name, organizationId }) => {
  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [open, setOpen] = useState(false);

  // Handlers
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <HeaderContainer container gap={2}>
      <CreationTypeGridContainer item>
        {organizationId ? (
          <NavigationActionIcon
            className="material-icons material-icons-outlined"
            customfontsize={globalFontSize}
          >
            person_outlined
          </NavigationActionIcon>
        ) : (
          <NavigationActionIcon
            className="material-icons material-icons-outlined"
            customfontsize={globalFontSize}
          >
            local_library
          </NavigationActionIcon>
        )}
      </CreationTypeGridContainer>
      <Grid item md>
        <SecondaryText fontWeight="bold" variant="h5">
          {name}
        </SecondaryText>
      </Grid>
      <Grid item>
        {organizationId && (
          <>
            <IconButton onClick={handleOpen}>
              <NavigationActionIcon
                active={true}
                customfontsize={globalFontSize}
              >
                edit
              </NavigationActionIcon>
            </IconButton>
            {open && <EditType typeId={typeId} open={open} setOpen={setOpen} />}
          </>
        )}
      </Grid>
      <Grid item>
        <AddFavoriteType typeId={typeId} />
      </Grid>
    </HeaderContainer>
  );
};

export default DesktopTypeDetailsHeader;
