import { Button, Divider, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const SearchField = styled(TextField)({
  marginLeft: 60,
  marginBottom: 40,
  width: 200,
});

export const BaseDivider = styled(Divider)({
  borderBottomWidth: "2px",
});

export const StyledDivider = styled(BaseDivider)({
  marginTop: "8px",
  marginBottom: "8px",
});

export const InviteNewMembersButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.contrastText,
  display: "flex",
  justifyContent: "center",
  margin: "auto",
  marginTop: "20px",
  padding: theme.spacing(1),
  minWidth: "250px",
  borderRadius: "5px",
  "&:hover": {
    backgroundColor: theme.palette.secondary.contrastText,
  },
}));

export const InviteNewMembersButtonDesktop = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  color: theme.palette.secondary.contrastText,
  display: "flex",
  justifyContent: "end",
  margin: "auto",
  marginTop: "20px",
  padding: theme.spacing(1),
  minWidth: "250px",
  borderRadius: "5px",
  "&:hover": {
    backgroundColor: theme.palette.primary.contrastText,
    boxShadow: "none",
  },
  boxShadow: "none",
}));

export const LeaveOrganizationButton = styled(Button)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  margin: "auto",
  marginTop: "20px",
  minWidth: "250px",
  borderRadius: "15px",
  padding: theme.spacing(1),
}));
