import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { useState } from "react";
import { useCallback } from "react";
import { selectUser } from "../../store/slices/authSlice";
import { DEFAULT_RESOURCE_ATTACHMENT } from "../../store/slices/resourceAttachmentSlice";
import { useTranslation } from "react-i18next";
import { messageError, messageSuccess } from "../../util/notification";
import PageTransition from "../../components/PageTransition";
import {
  getTranslation,
  permissions,
  showValidationError,
  transitionDirections,
} from "../../util/utils";
import AddAttachmentHeader from "../../navigation/header/assets/AddAttachmentHeader";
import { useUploadResourceAttachmentMutation } from "../../store/slices/api/resourceAttachmentsApiSlice";
import Access from "../../components/common/Access";
import {
  useGetAllFunctionsQuery,
  useGetResourceDetailsQuery,
} from "../../store/slices/api/assetManagementSlice";
import { setMobileTabValue } from "../../store/slices/assetListSlice";
import ResourceAttachmentForm from "../../components/assets/asset-form/ResourceAttachmentForm";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const AddResourceAttachmentPage = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { resourceId } = useParams();

  // Selectors
  const user = useSelector(selectUser);

  // State
  const [resourceAttachmentInput, setResourceAttachmentInput] = useState(
    DEFAULT_RESOURCE_ATTACHMENT
  );

  const [name, setName] = useState("");
  const [extension, setExtension] = useState("");

  // Queries
  const {
    data: currentResourceData,
    isLoading: isLoadingCurrent,
    isError: isErrorCurrent,
  } = useGetResourceDetailsQuery(
    {
      resourceid: resourceId,
      organizationId: user?.organizations?.find((o) => o.default).id,
    },
    {
      skip: !Boolean(resourceId),
    }
  );

  const { data: allFunctionsData } = useGetAllFunctionsQuery({
    organizationId: user?.organizations?.find((o) => o.default)?.id,
  });

  // Other variables
  const direction = searchParams.get("direction");

  // Handlers
  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === id);
  };

  const cancelHandler = () => {
    dispatch(setMobileTabValue(1));

    navigate(
      `/resources/${resourceId}?direction=${transitionDirections.TOP_TO_BOTTOM}&comingFrom=add-attachment`
    );
  };

  // Other variables
  const isValid =
    (resourceAttachmentInput.file || resourceAttachmentInput.selectedFile) &&
    name;

  const isDisabled = !isValid;
  const resourceFunction = getFunction(currentResourceData?.functionId);

  const permissionsKey = `ASSET_MANAGEMENT_${
    resourceFunction?.category === "HARDWARE_ASSET"
      ? "HARDWARE"
      : resourceFunction?.category
  }_ADD`;

  // Mutations
  const [uploadResourceAttachment, { isLoading: isLoadingUpload }] =
    useUploadResourceAttachmentMutation();

  const handleSubmit = useCallback(async () => {
    try {
      if (!resourceAttachmentInput.selectedFile) {
        messageError(getTranslation("ATTACHMENT_IS_REQUIRED", t, i18n));
        return;
      }

      const formData = new FormData();

      const { file, description } = resourceAttachmentInput;

      formData.append("file", file);
      formData.append("fileName", name + extension);
      formData.append("description", description);

      await uploadResourceAttachment({
        formData,
        resourceid: resourceId,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      }).unwrap();

      cancelHandler();

      messageSuccess(
        getTranslation("RESOURCE_ATTACHMENT_CREATED_SUCCESSFULLY", t, i18n)
      );
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to upload resource attachment", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceAttachmentInput, name]);

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={isLoadingCurrent || isLoadingUpload}
        isError={isErrorCurrent}
      >
        <Access all={[permissions[permissionsKey]]}>
          <Layer2Access>
            <PageTransition direction={direction}>
              <AddAttachmentHeader
                handleAction={handleSubmit}
                handleCancelAction={cancelHandler}
                isDisabled={isDisabled}
              />

              <HomePagePadding>
                <ResourceAttachmentForm
                  resourceAttachmentInput={resourceAttachmentInput}
                  setResourceAttachmentInput={setResourceAttachmentInput}
                  name={name}
                  setName={setName}
                  setExtension={setExtension}
                />
              </HomePagePadding>
            </PageTransition>
          </Layer2Access>
        </Access>
      </ErrorHandling>
    </AppAccess>
  );
};

export default AddResourceAttachmentPage;
