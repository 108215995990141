import { Link, MenuList, useMediaQuery } from "@mui/material";
import { transitionDirections } from "../util/utils";
import { APPLICATION_VERSION } from "../Constants";
import { useGetAppInfoQuery } from "../store/slices/api/assetManagementSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import PageTransition from "../components/PageTransition";
import ProfilePreferenceMenuItem from "../components/ProfilePreferenceItem";
import { StyledDivider } from "../components/styles/profile/Profile.styles";
import AboutHeader from "../navigation/header/about/AboutHeader";
import {
  AboutPrimaryText,
  AboutStyledDivider,
} from "../components/styles/about/About.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../util/viewport-utils";
import DesktopAbout from "../components/about/DesktopAbout";
import ErrorHandling from "../components/common/ErrorHandling";
import AppAccess from "../components/common/AppAccess";

const AboutPage = () => {
  // General hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Queries
  const { data, isLoading, isError } = useGetAppInfoQuery();

  // Other variables
  const transitionDirection = searchParams.get("direction");

  // Handlers
  const handleCreditsClick = () => {
    navigate(`/credits?direction=${transitionDirections.RIGHT_TO_LEFT}`);
  };

  const handleThirdPartyNoticesClick = () => {
    navigate(
      `/third-party-notices?direction=${transitionDirections.RIGHT_TO_LEFT}`
    );
  };

  const goBackHandler = () =>
    navigate(`/more?direction=${transitionDirections.LEFT_TO_RIGHT}`);

  return (
    <AppAccess>
      <ErrorHandling isLoading={isLoading} isError={isError}>
        <>
          {mobileMatches ? (
            <PageTransition direction={transitionDirection}>
              <AboutHeader goBackHandler={goBackHandler} />
              <MenuList dense>
                <ProfilePreferenceMenuItem
                  handleClick={handleCreditsClick}
                  label="CREDITS"
                  labelId="btn-about-credits"
                  menuId="btn-menu-item-about-credits"
                />
                <StyledDivider />
                <ProfilePreferenceMenuItem
                  handleClick={handleThirdPartyNoticesClick}
                  label="THIRD_PARTY_NOTICES"
                  labelId="btn-about-legal-notices"
                  menuId="btn-menu-item-about-legal-notices"
                />
                <StyledDivider />
                <Link
                  href="https://twinalize.com/legal/privacy-policy/app"
                  target="_blank"
                  underline="none"
                >
                  <ProfilePreferenceMenuItem
                    label="PRIVACY_POLICY"
                    labelId="btn-about-privacy-policy"
                    menuId="btn-menu-item-about-privacy-policy"
                  />
                </Link>
                <AboutStyledDivider />
                <Link
                  href="https://twinalize.com/legal/terms-of-service/app"
                  target="_blank"
                  underline="none"
                >
                  <ProfilePreferenceMenuItem
                    label="TERMS_OF_SERVICE"
                    labelId="btn-about-terms-of-service"
                    menuId="btn-menu-item-about-terms-of-service"
                  />
                </Link>
                <AboutStyledDivider />
                <ProfilePreferenceMenuItem
                  label="APP_VERSION"
                  labelId="btn-about-app-version"
                  menuId="btn-menu-item-about-app-version"
                  value={APPLICATION_VERSION}
                  showrighticon={false}
                />
                <StyledDivider />
                <ProfilePreferenceMenuItem
                  label="API_VERSION"
                  labelId="btn-about-api-version"
                  menuId="btn-menu-item-about-api-version"
                  value={data?.applicationVersion}
                  showrighticon={false}
                />
                <StyledDivider />
              </MenuList>

              <AboutPrimaryText align="center">
                {"Copyright © "}
                2022-{new Date().getFullYear()}
                {process.env.REACT_APP_WEBSITE_NAME}
              </AboutPrimaryText>
            </PageTransition>
          ) : (
            <DesktopAbout applicationVersion={data?.applicationVersion} />
          )}
        </>
      </ErrorHandling>
    </AppAccess>
  );
};

export default AboutPage;
