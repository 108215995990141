import { Box, Container, Grid, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { APPLICATION_VERSION } from "../../Constants";
import { CREDITS, GROUP } from "../../util/about-utils";
import { getTranslation } from "../../util/utils";
import {
  FooterDivider,
  FooterWrapper,
  SpacedContainer,
  SpacedTitleSection,
  VersionCard,
} from "../styles/about/About.styles";
import { SecondaryText } from "../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { ContrastText } from "../styles/menu/Menu.styles";
import TeamMemberCard from "./TeamMemberCard";

const DesktopAbout = ({ applicationVersion }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Other variables
  const groupList = Object.keys(GROUP)
    .map((key) => {
      const credits = CREDITS.filter((c) => c.groupId === GROUP[key]).sort(
        (a, b) => {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          }

          return 0;
        }
      );

      return {
        id: GROUP[key],
        name: key,
        credits,
      };
    })
    .sort((a, b) => {
      const translatedNameA = getTranslation(a.name, t, i18n);
      const translatedNameB = getTranslation(b.name, t, i18n);

      if (translatedNameA > translatedNameB) {
        return 1;
      } else if (translatedNameA < translatedNameB) {
        return -1;
      }

      return 0;
    });

  return (
    <Box>
      <Container maxWidth="lg">
        <SpacedTitleSection variant="h1">
          {getTranslation("VERSIONS", t, i18n)}
        </SpacedTitleSection>
        <Grid container gap={4}>
          <Grid sm={4}>
            <VersionCard>
              <Stack>
                <ContrastText variant="h4" fontWeight="bolder" active={true}>
                  {applicationVersion}
                </ContrastText>
                <SecondaryText variant="body1">
                  {getTranslation("API_VERSION", t, i18n)}
                </SecondaryText>
              </Stack>
            </VersionCard>
          </Grid>
          <Grid sm={4}>
            <VersionCard>
              <Stack>
                <ContrastText variant="h4" fontWeight="bolder" active={true}>
                  {APPLICATION_VERSION}
                </ContrastText>
                <SecondaryText variant="body1">
                  {getTranslation("APP_VERSION", t, i18n)}
                </SecondaryText>
              </Stack>
            </VersionCard>
          </Grid>
        </Grid>

        <Box>
          <SpacedTitleSection variant="h1">
            {getTranslation("CREDITS", t, i18n)}
          </SpacedTitleSection>
          <Stack spacing={3}>
            {groupList.map((group) => {
              const { id, name, credits } = group;

              return (
                <Box key={id}>
                  <SecondaryText variant="h3" gutterBottom>
                    {getTranslation(name, t, i18n)}
                  </SecondaryText>
                  <Grid container key={id} spacing={1}>
                    {credits.map((credit) => (
                      <TeamMemberCard credit={credit} />
                    ))}
                  </Grid>
                </Box>
              );
            })}
          </Stack>
        </Box>
      </Container>
      <FooterWrapper elevation={3}>
        <SpacedContainer maxWidth="lg">
          <Grid container>
            <Grid item md={3}>
              <Typography gutterBottom variant="h5" component="div">
                {getTranslation("LEGAL_NOTICES", t, i18n)}
              </Typography>
              <Link
                href="https://twinalize.com/legal/privacy-policy/app"
                target="_blank"
                underline="none"
              >
                <Typography variant="body2" color="text.secondary">
                  {getTranslation("PRIVACY_POLICY", t, i18n)}
                </Typography>
              </Link>
              <Link
                href="https://twinalize.com/legal/terms-of-service/app"
                target="_blank"
                underline="none"
              >
                <Typography variant="body2" color="text.secondary">
                  {getTranslation("TERMS_OF_SERVICE", t, i18n)}
                </Typography>
              </Link>
              <Link href="/third-party-notices" underline="none">
                <Typography variant="body2" color="text.secondary">
                  {getTranslation("THIRD_PARTY_NOTICES", t, i18n)}
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <FooterDivider />
          <SecondaryText align="end">
            {"Copyright © "}
            2022-{new Date().getFullYear()} {process.env.REACT_APP_WEBSITE_NAME}
          </SecondaryText>
        </SpacedContainer>
      </FooterWrapper>
    </Box>
  );
};

export default DesktopAbout;
