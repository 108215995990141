import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { setShouldExecute } from "../../../store/slices/reportsSlice";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import { AddGroupButton } from "../../styles/reports/Reports.styles";

const CustomAddRuleButton = ({ handleOnClick, path }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Handlers
  const handleClick = () => {
    handleOnClick();
    dispatch(setShouldExecute(false));
  };

  return (
    <AddGroupButton id="add-condition" variant="text" onClick={handleClick}>
      <AddIcon />
      {getTranslation("ADD_CONDITION", t, i18n)}
    </AddGroupButton>
  );
};

export default CustomAddRuleButton;
