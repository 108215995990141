import React from "react";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../../store/slices/appSlice";
import { getTranslation } from "../../../../util/utils";
import { useTranslation } from "react-i18next";
import {
  HeaderContainer,
  NavigationOptionsContainer,
  PageTitle,
} from "../../../../components/styles/header/assets/desktop/AssetListDesktopHeader.styles";
import { IconButton } from "@mui/material";
import { NavigationActionIcon } from "../../../../components/styles/menu/Menu.styles";

const RecentsDesktopHeader = () => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  return (
    <HeaderContainer variant="elevation">
      <NavigationOptionsContainer>
        <IconButton>
          <NavigationActionIcon
            id="back-column-view"
            customfontsize={globalFontSize}
          >
            navigate_before
          </NavigationActionIcon>
        </IconButton>

        <IconButton>
          <NavigationActionIcon
            id="forward-column-view"
            customfontsize={globalFontSize}
          >
            navigate_next
          </NavigationActionIcon>
        </IconButton>
        <PageTitle variant="h5">
          {getTranslation("LAST_USED", t, i18n)}
        </PageTitle>
      </NavigationOptionsContainer>
    </HeaderContainer>
  );
};

export default RecentsDesktopHeader;
