import React from "react";
import { useSelector } from "react-redux";
import { useGetResourceImageQuery } from "../../../store/slices/api/resourceImagesApiSlice";
import ErrorHandling from "../../common/ErrorHandling";
import CanvasImage from "../../common/CanvasImage";
import { selectUser } from "../../../store/slices/authSlice";
import { getFallbackIcon } from "../../../util/utils";
import { TableIconImg } from "../../styles/assets/asset-list/AssetListTable.styles";

const TableMedia = ({ resourceImages, category }) => {
  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  // Queries
  const {
    data: resourceImageUri,
    isLoading,
    isError,
  } = useGetResourceImageQuery(
    {
      imageUri: resourceImages ? resourceImages[0]?.uri : "",
      organizationId,
    },
    {
      skip: !Boolean(resourceImages) || resourceImages.length <= 0,
    }
  );

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      {resourceImageUri && resourceImages?.length > 0 ? (
        <CanvasImage
          id={resourceImages[0]?.name}
          width={50}
          height={50}
          image={
            resourceImages[0]?.name?.endsWith(".svg")
              ? `${process.env.PUBLIC_URL}/icons/${resourceImages[0]?.name}`
              : resourceImageUri
          }
        />
      ) : (
        <TableIconImg
          width={50}
          height={50}
          src={`${process.env.PUBLIC_URL}/icons/${getFallbackIcon(category)}`}
          id="asset-image"
        />
      )}
    </ErrorHandling>
  );
};

export default TableMedia;
