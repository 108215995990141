import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BenefitCard from "../../components/BenefitCard";
import {
  WelcomeFirstText,
  PageContainer,
  NextGenIcon,
  BenefitsCardContainer,
  AvailabilityIcon,
  FlexibilityIcon,
} from "../../components/styles/onboarding/OnboardingWelcomePage.styles";
import { ContinueButton } from "../../components/styles/onboarding/Footer.styles";
import PageTransition from "../../components/PageTransition";
import { transitionDirections, getTranslation, THEME } from "../../util/utils";
import { selectTheme } from "../../store/slices/appSlice";
import { useSelector } from "react-redux";
import { Logo } from "../styles/OnboardingWelcomePage.styles";

const OnboardingWelcomePage = () => {
  // General hooks
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const currentTheme = useSelector(selectTheme);

  // Handlers
  const handleSubmit = (event) => {
    navigate("/user-onboarding");
  };

  return (
    <PageTransition direction={transitionDirections.RIGHT_TO_LEFT}>
      <PageContainer>
        <Stack alignItems="center">
          <WelcomeFirstText
            id="language-selection-header"
            data-testid="welcome-title"
            fontWeight="bolder"
            variant="h1"
          >
            {getTranslation("welcomingPageHeader", t, i18n)}
          </WelcomeFirstText>

          {currentTheme === THEME.DARK ? (
            <Logo
              src={`${process.env.PUBLIC_URL}/twinalize-logo-dark-theme.svg`}
              alt="app icon"
            />
          ) : (
            <Logo
              src={`${process.env.PUBLIC_URL}/twinalize-logo-light-theme.svg`}
              alt="app icon"
            />
          )}

          <Typography
            sx={{ marginTop: "15px", fontSize: "20px" }}
            align="start"
          >
            The{" "}
            <Typography
              sx={{ fontSize: "20px" }}
              fontWeight="bold"
              component="span"
            >
              simplest{" "}
            </Typography>
            <Typography sx={{ fontSize: "20px" }} component="span">
              way to keep
            </Typography>
          </Typography>

          <Typography sx={{ fontSize: "20px" }} align="start">
            <Typography sx={{ fontSize: "20px" }} component="span">
              your{" "}
            </Typography>
            <Typography
              sx={{ fontSize: "20px" }}
              fontWeight="bold"
              component="span"
              ali
            >
              IT assets{" "}
            </Typography>
            <Typography sx={{ fontSize: "20px" }} component="span">
              under{" "}
            </Typography>
            <Typography
              sx={{ fontSize: "20px" }}
              fontWeight="bold"
              component="span"
            >
              control
            </Typography>
          </Typography>
        </Stack>

        <BenefitsCardContainer>
          {/* Benefit for data center management*/}
          <BenefitCard
            icon={<NextGenIcon fontSize="large" />}
            title={getTranslation("welcomeNextGenAssetTitle", t, i18n)}
            description={getTranslation(
              "welcomeNextGenAssetDescription",
              t,
              i18n
            )}
          />
          {/* Benefit for IT management */}
          <BenefitCard
            icon={<AvailabilityIcon fontSize="large" />}
            title={getTranslation("welcomeDataCenterTitle", t, i18n)}
            description={getTranslation(
              "welcomeDataCenterDescription",
              t,
              i18n
            )}
          />
          {/* Benefit for accessibility for different dimensions */}
          <BenefitCard
            icon={<FlexibilityIcon fontSize="large" />}
            title={getTranslation("welcomeDevicesTitle", t, i18n)}
            description={getTranslation("welcomeDevicesDescription", t, i18n)}
          />
        </BenefitsCardContainer>
        <ContinueButton
          variant="contained"
          id="onboarding-welcome-button"
          data-testid="onboarding-welcome-button"
          onClick={handleSubmit}
        >
          {getTranslation("continueButton", t, i18n)}
        </ContinueButton>
      </PageContainer>
    </PageTransition>
  );
};

export default OnboardingWelcomePage;
