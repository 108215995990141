import { List, ListItem, ListItemButton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectInputValue } from "../../../store/slices/tagsSlice";
import { HighlightedText } from "../../styles/assets/tags/Tags.styles";

const TopTagResults = ({ startingTags, handleClickTag }) => {
  // Selectors
  const inputValue = useSelector(selectInputValue);

  // Handlers
  const handleGetTopResultsStartText = (tag) =>
    tag.label.substring(
      0,
      tag.label.toLowerCase().indexOf(inputValue.toLowerCase()) +
        inputValue.length
    );

  const handleGetTopResultsEndText = (tag) =>
    tag.label.substring(
      tag.label.toLowerCase().indexOf(inputValue.toLowerCase()) +
        inputValue.length
    );

  return (
    <List>
      {startingTags.map((tag) => (
        <ListItem key={"top-" + tag.value} disablePadding>
          <ListItemButton onMouseDown={() => handleClickTag(tag)}>
            <HighlightedText>
              {handleGetTopResultsStartText(tag)}
            </HighlightedText>
            <Typography>{handleGetTopResultsEndText(tag)}</Typography>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default TopTagResults;
