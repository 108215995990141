import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../util/utils";

const CustomFieldSelector = ({ rule }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  return (
    <Box sx={{ display: "flex", columnGap: "5px" }}>
      <Box sx={{ maxWidth: "75px" }}>
        <Typography
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {getTranslation(rule?.field, t, i18n)}
        </Typography>
      </Box>

      <Box sx={{ maxWidth: "75px" }}>
        <Typography
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {getTranslation(rule?.operator, t, i18n)}
        </Typography>
      </Box>

      <Box sx={{ maxWidth: "75px" }}>
        <Typography
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {rule?.value ? getTranslation(rule?.value, t, i18n) : `(Empty)`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomFieldSelector;
