import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Menu.css";
import { useTranslation } from "react-i18next";
import { MenuItem, Popover, Typography } from "@mui/material";
import { ROUTES, getTranslation } from "../../util/utils";
import {
  AssetsBottomNavigationAction,
  AssetsBottomNavigationActionIcon,
  ContrastText,
  FavoritesBottomNavigationAction,
  FavoritesBottomNavigationActionIcon,
  HomeBottomNavigationAction,
  MenuPaper,
  MoreBottomNavigationAction,
  MoreBottomNavigationActionIcon,
  ReportsBottomNavigationAction,
  ReportsBottomNavigationActionIcon,
  StyledBottomNavigation,
} from "../../components/styles/menu/Menu.styles";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import { useNavigate } from "react-router-dom/dist";
import EventBus, { EVENT_DISCARD_CHANGES } from "../../util/EventBus";
import { Logo } from "../styles/Menu.styles";

const Menu = () => {
  // General hooks
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // State
  const [value, setValue] = useState(0);

  // Handlers
  const handleNavigation = (route) => {
    if (EventBus.checkEventTypeExisting(EVENT_DISCARD_CHANGES)) {
      EventBus.dispatch(EVENT_DISCARD_CHANGES, { route });
    } else {
      navigate(route);
    }
  };

  const handleNavigateFavorites = () => {
    handleNavigation("/favorites");
  };

  // Effects
  useEffect(() => {
    if (pathname === "/") {
      setValue(0);
    } else if (
      pathname.includes(ROUTES.RESOURCES) ||
      pathname.includes(ROUTES.SEARCH)
    ) {
      setValue(1);
    } else if (pathname.includes(ROUTES.REPORTS)) {
      setValue(2);
    } else if (pathname.includes(ROUTES.FAVORITES)) {
      setValue(3);
    } else if (
      pathname.includes(ROUTES.PROFILE) ||
      pathname.includes(ROUTES.ORGANIZATION) ||
      pathname.includes(ROUTES.MORE) ||
      pathname.includes(ROUTES.REPORTS) ||
      pathname.includes(ROUTES.RECENTS)
    ) {
      setValue(4);
    }
  }, [pathname]);

  return (
    <>
      <MenuPaper elevation={3} id="footer">
        <StyledBottomNavigation
          className="stickToBottom"
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          id="footer-menu"
        >
          <HomeBottomNavigationAction
            id="home"
            onClick={() => handleNavigation("/")}
            label={
              <Typography variant="body2">
                {getTranslation("home", t, i18n)}
              </Typography>
            }
            active={value === 0}
            icon={
              <Logo
                src={`${process.env.PUBLIC_URL}/twinalize-icon.svg`}
                size={globalFontSize * 1.5}
                alt="home"
              />
            }
          />
          <AssetsBottomNavigationAction
            id="assets"
            onClick={() => handleNavigation("/resources")}
            label={
              <Typography variant="body2">
                {getTranslation("assets", t, i18n)}
              </Typography>
            }
            active={value === 1}
            icon={
              <AssetsBottomNavigationActionIcon
                customfontsize={globalFontSize}
                active={value === 1}
              />
            }
          />
          <ReportsBottomNavigationAction
            id="reports"
            onClick={() => handleNavigation("/reports")}
            label={
              <Typography variant="body2">
                {getTranslation("REPORTS", t, i18n)}
              </Typography>
            }
            active={value === 2}
            icon={
              <ReportsBottomNavigationActionIcon
                customfontsize={globalFontSize}
                active={value === 2}
              />
            }
          />
          <FavoritesBottomNavigationAction
            id="favorites"
            onClick={handleNavigateFavorites}
            label={
              <ContrastText active={value === 3} variant="body2">
                {getTranslation("FAVORITES", t, i18n)}
              </ContrastText>
            }
            active={value === 3}
            icon={
              <FavoritesBottomNavigationActionIcon
                customfontsize={globalFontSize}
                active={value === 3}
              />
            }
          />
          <MoreBottomNavigationAction
            id="more"
            onClick={() => handleNavigation("/more")}
            label={
              <ContrastText active={value === 4} variant="body2">
                {getTranslation("MORE", t, i18n)}
              </ContrastText>
            }
            active={value === 4}
            icon={
              <MoreBottomNavigationActionIcon
                customfontsize={globalFontSize}
                active={value === 4}
              />
            }
          />
        </StyledBottomNavigation>
      </MenuPaper>
    </>
  );
};

export default Menu;
