import HttpService from "./HttpService";

import {
  get_current_user_url,
  update_user_data,
  user_invitations_check,
} from "../Constants";

// The only function which will be kept as raw fetch without caching
export const fetchCurrentUser = async () => {
  let url = get_current_user_url;
  var config = { method: "GET", url };
  let result = await HttpService.sendRequest(config);
  return result;
};

export const fetchUserById = async (userId) => {
  let url = update_user_data.replace(":userId", userId);
  var config = { method: "GET", url };
  let result = await HttpService.sendRequest(config);
  return result;
};

export const fetchUserInvitationsCheck = async (email, status) => {
  let url = user_invitations_check + `?email=${email}&status=${status}`;
  var config = { method: "GET", url };
  let result = await HttpService.sendRequest(config);
  return result;
};
