import { useTheme } from "@mui/material";
import { RouletteSpinner } from "react-spinner-overlay";
import { AbsoluteLoadingSpinnerContainer } from "../styles/common/AbsoluteLoadingSpinner.styles";

const AbsoluteLoadingSpinner = () => {
  // General hooks
  const theme = useTheme();

  return (
    <AbsoluteLoadingSpinnerContainer
        id="loading-spinner"
    >
        <RouletteSpinner
            loading={true}
            color={theme.palette.secondary.contrastText}
        />
    </AbsoluteLoadingSpinnerContainer>
  );
};

export default AbsoluteLoadingSpinner;
