import { useDispatch, useSelector } from "react-redux";
import {
  useGetAllFunctionsQuery,
  useGetAllResourcesQuery,
  useGetResourceTreeQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { Box, Typography, useTheme } from "@mui/material";
import {
  selectIsFirstSubmitted,
  selectResourceInput,
  setResourceInput,
} from "../../../store/slices/resourceInputSlice";
import { useTranslation } from "react-i18next";
import { TreeSelect, ConfigProvider } from "antd";
import { getTranslation, RESOURCE_CATEGORIES } from "../../../util/utils";
import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import useTreeTraversal from "../../../hooks/useTreeTraversal";
import "./ParentInput.css";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { NavigationActionIcon } from "../../styles/menu/Menu.styles";
import ErrorHandling from "../../common/ErrorHandling";
import { messageWarning } from "../../../util/notification";
import WarningIcon from "@mui/icons-material/Warning";
import { ParentLabel } from "../../styles/assets/asset-form/ParentInput.styles";
import { LOCATION_DETAILS } from "../../../util/asset-utils";

const ParentInput = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { resourceid } = useParams();
  const { pathname } = useLocation();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const resourceInput = useSelector(selectResourceInput);
  const isFirstSubmitted = useSelector(selectIsFirstSubmitted);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // State
  const [showHint, setShowHint] = useState(false);

  // Queries
  const {
    data: allResourcesData,
    isLoading: isLoadingAll,
    isError: isErrorAll,
  } = useGetAllResourcesQuery({
    organizationId,
  });

  const {
    data: disabledOptionsData,
    isLoading: isLoadingDisabledOptions,
    isError: isErrorDisabledOptions,
  } = useGetResourceTreeQuery(
    {
      organizationId,
      isSingleList: true,
      parentId: resourceid,
    },
    {
      skip: !Boolean(resourceid) || !pathname.includes("edit"),
    }
  );

  const {
    data: resourceTreeData,
    isLoading: isLoadingResourceTree,
    isError: isErrorResourceTree,
  } = useGetResourceTreeQuery({
    organizationId: user?.organizations?.find((o) => o.default).id,
    isSingleList: false,
  });

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  const getFunctionDetails = (functionId) => {
    return allFunctionsData?.find((f) => f.id === Number(functionId));
  };

  // Other variables
  const resources =
    allResourcesData?.map((resource) => {
      const resourceFunction = getFunctionDetails(resource.functionId);

      return {
        label: resource.displayId || resource.name,
        value: resource.id,
        category: resourceFunction?.category,
      };
    }) ?? [];

  // State
  const [parentName, setParentName] = useState(
    resources?.find((r) => r.value === resourceInput.parentId)?.label
  );

  // Handlers
  const handleChangeParentId = (value) => {
    const newParentName = value;
    setParentName(newParentName);

    const newParentId = resources?.find(
      (r) => r.label === newParentName
    )?.value;

    dispatch(
      setResourceInput({
        ...resourceInput,
        parentId: newParentId,
        category: null,
        functionId: null,
        typeId: null,
        hasTypeChanged: true,
      })
    );

    setShowHint(false);
  };

  const handleFocus = (e) => setShowHint(true);

  const handleBlur = (e) => setShowHint(false);

  const resourceInputFunction = getFunctionDetails(resourceInput.functionId);

  const getDisabledOptions = () => {
    return resourceInputFunction?.category === RESOURCE_CATEGORIES.LOCATION
      ? resources
          ?.filter((resource) => {
            const resourceFunction = getFunctionDetails(resource.functionId);
            return (
              resourceFunction?.category === RESOURCE_CATEGORIES.RACK ||
              resourceFunction?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET
            );
          })
          .map((resource) => ({
            label: resource.label,
            value: resource.value,
          }))
      : resourceInputFunction?.category === RESOURCE_CATEGORIES.RACK
      ? resources
          ?.filter((resource) => {
            const resourceFunction = getFunctionDetails(resource.functionId);

            return (
              resourceFunction?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET
            );
          })
          .map((resource) => ({
            label: resource.label,
            value: resource.value,
          }))
      : [];
  };

  const mergedDisabledOptions = pathname.includes("edit")
    ? [
        ...getDisabledOptions(),
        {
          label: resources?.find((r) => r.value === resourceInput.id)?.label,
          value: resources?.find((r) => r.value === resourceInput.id)?.value,
        },
      ]
    : [...getDisabledOptions()];

  // Custom hooks
  const { treeData, isLoading: isLoadingTree } = useTreeTraversal(
    resourceTreeData,
    mergedDisabledOptions,
    disabledOptionsData
  );

  // Effects
  useEffect(() => {
    if (allResourcesData) {
      const resource = allResourcesData.find(
        (r) => r.id === resourceInput.parentId
      );

      if (resource) {
        setParentName(resource.displayId || resource.name);
      }
    }
  }, [allResourcesData, resourceInput.parentId]);

  return (
    <ErrorHandling
      isLoading={
        isLoadingAll ||
        isLoadingDisabledOptions ||
        isLoadingFunctions ||
        isLoadingResourceTree ||
        isLoadingTree
      }
      isError={
        isErrorAll ||
        isErrorDisabledOptions ||
        isErrorFunctions ||
        isErrorResourceTree
      }
    >
      {treeData && treeData.length > 0 && (
        <Box>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "inherit",
                colorBgElevated: theme.palette.primary.contrastText,
                colorText: theme.palette.primary.main,
                controlItemBgActive: theme.palette.secondary.dark,
                colorTextDisabled: "#6A6A6A",
                borderRadius: "8px",
                colorBorder:
                  isFirstSubmitted &&
                  resourceInputFunction?.category !==
                    RESOURCE_CATEGORIES.LOCATION &&
                  !resourceInput.parentId
                    ? "#f44336"
                    : theme.palette.mode === "light"
                    ? "#DFDFDF"
                    : theme.palette.secondary.dark,
                controlHeight: 56,
                colorPrimary: theme.palette.primary.main,
                fontSize: globalFontSize,
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                marginBottom: showHint ? "5px" : 0,
                visibility: showHint ? "visible" : "hidden",
              }}
            >
              <WarningIcon sx={{ color: "#F4510A" }} fontSize="small" />

              <Typography
                sx={{ textWrap: "wrap" }}
                variant="caption"
                align="center"
              >
                {getTranslation("PARENT_CHANGE_WARNING", t, i18n)}
              </Typography>
            </Box>

            <div className="parent-tree-select-container">
              <TreeSelect
                virtual={false}
                id="parent-tree-select"
                className={`parent-tree-select custom-tree-select ${
                  theme.palette.mode === "light"
                    ? "light-tree-select"
                    : "dark-tree-select"
                } ${
                  isFirstSubmitted &&
                  resourceInputFunction?.category !==
                    RESOURCE_CATEGORIES.LOCATION &&
                  !resourceInput.parentId
                    ? "custom-tree-select-error"
                    : ""
                } ${parentName ? "non-empty" : "empty"}`}
                showSearch
                style={{
                  width: "100%",
                }}
                value={parentName}
                bordered
                dropdownStyle={{
                  maxHeight: 400,
                  overflow: "auto",
                  zIndex: "5000",
                }}
                onChange={handleChangeParentId}
                onFocus={handleFocus}
                onBlur={handleBlur}
                treeData={treeData}
                treeDefaultExpandedKeys={[parentName]}
                suffixIcon={
                  <NavigationActionIcon customfontsize={globalFontSize}>
                    arrow_drop_down
                  </NavigationActionIcon>
                }
              />
              <ParentLabel
                fontSize={globalFontSize}
                className={`parent-tree-select-label ${
                  theme.palette.mode === "light" ? "light-label" : "dark-label"
                }`}
                htmlFor="parent-tree-select"
                id="parent-tree-select-label"
              >
                <div
                  className={`parent-tree-select-label-text ${
                    theme.palette.mode === "light" ? "light-text" : "dark-text"
                  }`}
                >
                  {getTranslation(
                    LOCATION_DETAILS[
                      resourceInput?.category ?? LOCATION_DETAILS.DEFAULT
                    ],
                    t,
                    i18n
                  )}
                </div>
              </ParentLabel>
            </div>
          </ConfigProvider>
        </Box>
      )}
    </ErrorHandling>
  );
};

export default ParentInput;
