import { ListItem, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const DarkListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
}));

export const BorderedDarkListItem = styled(DarkListItem)(({ theme }) => ({
  borderBottom: "solid 0.5px " + theme.palette.primary.dark,
}));

export const PrimaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

export const SecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const SectionTitle = styled(SecondaryText)(({ istablet }) => ({
  margin: istablet === 1 ? 0 : "auto",
  textAlign: istablet === 1 ? "left" : "center",
}));
