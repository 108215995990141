import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { resetWizard } from "../../store/slices/reportsSlice";
import { selectUser } from "../../store/slices/authSlice";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import { useDeleteReportDefinitionMutation } from "../../store/slices/api/reportsApiSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
} from "../../util/utils";
import useLongPress from "../../hooks/useLongPress";
import MobileUpdateReportDialog from "./mobile-view/MobileUpdateReportDialog";
import {
  Backdrop,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Stack,
} from "@mui/material";
import {
  ReportCardItem,
  StyledKeyboardArrowRightIcon,
} from "../styles/home/Home.styles";
import { ReportName } from "../styles/assets/reports/ReportsPage.styles";
import { SecondaryText } from "../styles/assets/ListInlineView.styles";
import { NavigationActionIcon } from "../styles/menu/Menu.styles";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import ErrorHandling from "../common/ErrorHandling";

const ReportCard = ({ report, index }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // Custom hooks
  const { action, setAction, handlers } = useLongPress();

  // Other variables
  const isLongPress = action === "longpress";

  const alert = {
    content: getTranslation("DELETE_REPORT_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DELETE", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Refs
  const elementRef = useRef();

  // State
  const [mobileUpdateDialogOpen, setMobileUpdateDialogOpen] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  // Queries
  const { data: userRoles, isLoading: isLoadingUserRoles } =
    useUserRolePermissionsQuery({
      userId: user.id,
      organizationId: user?.organizations?.find((o) => o.default)?.id,
    });

  // Mutations
  const [deleteReportDefinition] = useDeleteReportDefinitionMutation();

  // Handlers
  const handleClose = () => {
    setAction("");
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    handleOpenUpdateDialog();
  };

  const handleDelete = async () => {
    try {
      await deleteReportDefinition({
        organizationId,
        reportDefinitionId: report.id,
      }).unwrap();
      handleClose();
    } catch (error) {}
  };

  const handleOpenDeleteConfirm = () => {
    setOpenDeleteConfirm(true);
    handleClose();
  };

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
  };

  const handleDeleteConfirm = () => {
    handleDelete();
    handleCloseDeleteConfirm();
  };

  const handlePrint = (e) => {
    e.stopPropagation();
    handleClose();
  };

  const handleShare = (e) => {
    e.stopPropagation();
    handleClose();
  };

  const handleExport = (e) => {
    e.stopPropagation();
    handleClose();
  };

  const handleOpenUpdateDialog = () => {
    setMobileUpdateDialogOpen(true);
  };

  const handleCloseUpdateDialog = () => {
    setMobileUpdateDialogOpen(false);
    dispatch(resetWizard());
    handleClose();
  };

  // Other variables
  const rect = elementRef.current?.getBoundingClientRect();

  // Effects
  useEffect(() => {
    if (action === "click") {
      navigate(`/reports/${report.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, report.id]);

  return (
    <ErrorHandling isLoading={isLoadingUserRoles} isError={false}>
      <ConfirmAlert
        isOpen={openDeleteConfirm}
        setIsOpen={setOpenDeleteConfirm}
        alert={alert}
        handleConfirm={handleDeleteConfirm}
        label="delete"
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={isLongPress}
        onClick={handleClose}
      />
      {isLongPress && (
        <Stack
          sx={{
            top: rect?.bottom,
            position: "absolute",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          spacing={1}
        >
          <Paper
            sx={{
              borderRadius: "4px",
              padding: 1,
            }}
          >
            <SecondaryText variant="h5" fontWeight="bolder" gutterBottom>
              {getTranslation(report.name, t, i18n)}
            </SecondaryText>
            <SecondaryText>{report?.description}</SecondaryText>
          </Paper>

          <Paper sx={{ width: "320px", maxWidth: "100%" }}>
            <MenuList>
              {report.organizationId &&
                hasAccess(
                  "all",
                  [permissions["REPORTING_USER_DEFINED_EDIT"]],
                  getPermissionsFromUserRoles(userRoles)
                ) && (
                  <MenuItem onClick={handleEdit}>
                    <ListItemIcon>
                      <NavigationActionIcon
                        active={true}
                        customfontsize={globalFontSize}
                      >
                        edit
                      </NavigationActionIcon>
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </MenuItem>
                )}

              {report.organizationId &&
                hasAccess(
                  "all",
                  [permissions["REPORTING_USER_DEFINED_DELETE"]],
                  getPermissionsFromUserRoles(userRoles)
                ) && (
                  <MenuItem onClick={handleOpenDeleteConfirm}>
                    <ListItemIcon>
                      <NavigationActionIcon
                        sx={{ color: "red" }}
                        active={true}
                        customfontsize={globalFontSize}
                      >
                        delete
                      </NavigationActionIcon>
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                )}

              <MenuItem onClick={handlePrint}>
                <ListItemIcon>
                  <NavigationActionIcon
                    active={true}
                    customfontsize={globalFontSize}
                  >
                    print
                  </NavigationActionIcon>
                </ListItemIcon>
                <ListItemText>Print</ListItemText>
              </MenuItem>

              <MenuItem onClick={handleShare}>
                <ListItemIcon>
                  <NavigationActionIcon
                    active={true}
                    customfontsize={globalFontSize}
                  >
                    share
                  </NavigationActionIcon>
                </ListItemIcon>
                <ListItemText>Share</ListItemText>
              </MenuItem>

              <MenuItem onClick={handleExport}>
                <ListItemIcon>
                  <NavigationActionIcon
                    active={true}
                    customfontsize={globalFontSize}
                  >
                    file_download
                  </NavigationActionIcon>
                </ListItemIcon>
                <ListItemText>Export</ListItemText>
              </MenuItem>
            </MenuList>
          </Paper>

          {mobileUpdateDialogOpen && (
            <MobileUpdateReportDialog
              open={mobileUpdateDialogOpen}
              handleClose={handleCloseUpdateDialog}
              reportId={report.id}
            />
          )}
        </Stack>
      )}

      <ReportCardItem
        sx={{ position: "relative" }}
        ref={elementRef}
        key={report.name}
        component={Link}
        to={report.to}
        {...handlers}
        index={index}
      >
        <ReportName>{getTranslation(report.name, t, i18n)}</ReportName>
        <StyledKeyboardArrowRightIcon customfontsize={globalFontSize * 1.5} />
      </ReportCardItem>
    </ErrorHandling>
  );
};

export default ReportCard;
