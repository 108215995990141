import { Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledGrowingStack = styled(Stack)({
  flexGrow: 1,
});

export const CenteredStack = styled(Stack)({
  alignItems: "center",
});
