import {
  ActionTitle,
  HeaderGridContainer,
  HeaderWrapper,
} from "../../../components/styles/header/Header.styles";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation, RESOURCE_CATEGORIES } from "../../../util/utils";
import { useSelector } from "react-redux";
import { selectPageInfo } from "../../../store/slices/appSlice";
import { checkResourceChanged } from "../../../util/asset-utils";
import {
  selectOriginalInput,
  selectResourceInput,
} from "../../../store/slices/resourceInputSlice";
import { selectUser } from "../../../store/slices/authSlice";
import LoadingSpinner from "../../../components/common/LoadingSpinner";

const EditAssetHeader = ({ handleAction, handleCancelAction }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const originalInput = useSelector(selectOriginalInput);
  const resourceInput = useSelector(selectResourceInput);

  // Selectors
  const user = useSelector(selectUser);
  const currentPageInfo = useSelector(selectPageInfo);

  //Other variables
  const isDoneDisabled =
    currentPageInfo.isActionButtonDisabled ||
    !checkResourceChanged(originalInput, resourceInput, user?.region) ||
    !resourceInput?.name ||
    !resourceInput?.functionId ||
    ((resourceInput.category === RESOURCE_CATEGORIES.HARDWARE_ASSET ||
      resourceInput.category === RESOURCE_CATEGORIES.RACK) &&
      !resourceInput.typeId);

  return (
    <HeaderWrapper id="sticky-header">
      <HeaderGridContainer container>
        <Grid item xs={3}>
          <ActionTitle
            tabIndex="0"
            data-testid="action-title-cancel"
            id="action-title-cancel"
            onClick={handleCancelAction}
          >
            {getTranslation("CANCEL", t, i18n)}
          </ActionTitle>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid display="flex" justifyContent="end" item xs={3}>
          {currentPageInfo.isLoadingAction ? (
            <LoadingSpinner />
          ) : (
            <ActionTitle
              tabIndex="0"
              align="right"
              onClick={() => {
                if (!isDoneDisabled) {
                  handleAction();
                }
              }}
              aria-disabled={isDoneDisabled}
              disabled={isDoneDisabled ? 1 : 0}
              id="action-title-done"
              data-testid="action-title-done"
            >
              {getTranslation("DONE", t, i18n)}
            </ActionTitle>
          )}
        </Grid>
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default EditAssetHeader;
