import { useMediaQuery } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";
import { messageError } from "../../util/notification";
import {
  ALLOWED_EXTENSIONS,
  DEFAULT_IMAGE_SIZE,
  getNormalizedFile,
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
} from "../../util/utils";
import fileTypeChecker from "file-type-checker";
import CropEasy from "../CropEasy";
import {
  DesktopProfileAvatar,
  DesktopProfileAvatarContainer,
  RemoveImageButton,
} from "../styles/profile/ProfileDesktop.styles";
import { useLocation } from "react-router-dom";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";

const UploadImageDesktop = ({
  initialFile,
  submitUploadedImage,
  isUserOnboarding,
  isOrganizationOnboarding,
  uploadTitle,
  checker,
  permissions,
  userRoles,
  deleteImage,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const user = useSelector(selectUser);

  // State
  const [selectedFile, setSelectedFile] = useState(
    initialFile ? initialFile : null
  );
  const [photoURL, setPhotoURL] = useState(initialFile ? initialFile : null);
  const [openCrop, setOpenCrop] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  // Handlers
  const readFile = useCallback((file) => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        getNormalizedFile(file)
          .then((normalizedFile) => reader.readAsDataURL(normalizedFile))
          .catch((error) => reject(error));
      } catch (error) {
        reject(error);
      }
    });
  }, []);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    e.target.value = null;

    if (file) {
      if (file.size > DEFAULT_IMAGE_SIZE / 2) {
        messageError(getTranslation("TOO_LARGE_FILE_VALIDATION", t, i18n));
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const buffer = reader.result;
        const uintArray = new Uint8Array(buffer);

        const isImage = fileTypeChecker.validateFileType(
          uintArray,
          ALLOWED_EXTENSIONS
        );

        if (!isImage) {
          messageError(getTranslation("NOT_ALLOWED_FILE_TYPE", t, i18n));
          setPhotoURL(null);
          setOpenCrop(false);
        }
      };

      reader.readAsArrayBuffer(file);

      const url = await readFile(file);
      setPhotoURL(url);
      setOpenCrop(true);
    }
  };

  const handleAvatarClick = () => {
    if (
      pathname.includes("profile") &&
      tabletMatches &&
      Boolean(selectedFile)
    ) {
      setOpenConfirm(true);
    }
  };

  const handleConfirm = () => {
    deleteImage();
  };

  useEffect(() => {
    setSelectedFile(initialFile);
  }, [initialFile]);

  useEffect(() => {
    setPhotoURL(initialFile);
  }, [initialFile]);

  return (
    <>
      <ConfirmAlert
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        alert={{
          content: getTranslation("ASSET_IMAGE_DELETE_MESSAGE", t, i18n),
          confirmTitle: getTranslation("DELETE_ASSET_IMAGE", t, i18n),
          closeTitle: getTranslation("CANCEL", t, i18n),
          showConfirm: true,
        }}
        label="delete-image"
        handleConfirm={handleConfirm}
      />

      <CropEasy
        openCrop={openCrop}
        setOpenCrop={setOpenCrop}
        photoURL={photoURL}
        setPhotoURL={setPhotoURL}
        setSelectedFile={setSelectedFile}
        submitUploadedImage={submitUploadedImage}
        isUserOnboarding={isUserOnboarding}
        isOrganizationOnboarding={isOrganizationOnboarding}
      />

      {desktopMatches ? (
        <DesktopProfileAvatarContainer>
          <DesktopProfileAvatar
            id="profile-avatar"
            alt={user?.shortUsername}
            src={selectedFile}
          />
        </DesktopProfileAvatarContainer>
      ) : (
        <DesktopProfileAvatar
          onClick={handleAvatarClick}
          id="profile-avatar"
          alt={user?.shortUsername}
          src={selectedFile}
        />
      )}

      {(permissions
        ? hasAccess(
            checker,
            permissions,
            getPermissionsFromUserRoles(userRoles)
          )
        : true) && (
        <RemoveImageButton
          id="upload-image-button"
          data-testid="upload-image-button"
          aria-label="upload picture"
          component="label"
          src={selectedFile}
          variant="text"
        >
          <input
            hidden
            accept="image/*"
            type="file"
            onChange={handleFileChange}
            data-testid="upload-image-input"
          />
          {getTranslation(uploadTitle, t, i18n)}
        </RemoveImageButton>
      )}
    </>
  );
};

export default UploadImageDesktop;
