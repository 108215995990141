import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Badge,
  Box,
  Grid,
  IconButton,
  Stack,
  useMediaQuery,
} from "@mui/material";
import FavoritesDesktopHeader from "../../navigation/header/assets/desktop/FavoritesDesktopHeader";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { getTranslation, transitionDirections } from "../../util/utils";
import { CategoryChip } from "../../components/styles/common/Favorites.styles";
import FavoriteAssetsAccordion from "../../components/assets/asset-list/FavoriteAssetsAccordion";
import FavoriteTypesAccordion from "../../components/types/type-list/FavoriteTypesAccordion";
import { NavigationActionIcon } from "../../components/styles/menu/Menu.styles";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import FavoritesHeader from "../../navigation/common/FavoritesHeader";
import {
  FirstScreenContainer,
  SplitScreen,
} from "../../components/styles/types/type-list/TypeListPage.styles";
import DesktopTypeDetails from "../../components/types/type-detail/DesktopTypeDetails";
import AssetDetails from "../../components/assets/asset-detail/AssetDetails";
import { useNavigate } from "react-router-dom";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const CATEGORY = {
  ASSETS: "ASSETS",
  LIBRARY: "LIBRARY",
  // REPORTS: "REPORTS",
};

const Favorites = () => {
  // General hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [category, setCategory] = useState(null);
  const [resourceId, setResourceId] = useState(null);
  const [typeId, setTypeId] = useState(null);

  // Handlers
  const handleSelectCategory = (category) => {
    setCategory(category);
  };

  const handleClickItemType = (id) => {
    if (desktopMatches) {
      setResourceId(null);
      setTypeId(id);
    } else {
      navigate(
        `/library/${id}/details?direction=${transitionDirections.RIGHT_TO_LEFT}`
      );
    }
  };

  const handleClickItemAsset = (id) => {
    if (desktopMatches) {
      setTypeId(null);
      setResourceId(id);
    } else {
      navigate(
        `/resources/${id}?direction=${transitionDirections.RIGHT_TO_LEFT}`
      );
    }
  };

  return (
    <AppAccess>
      <Layer2Access>
        <Box sx={{ marginBottom: "16px" }}>
          {mobileMatches ? <FavoritesHeader /> : <FavoritesDesktopHeader />}
          {desktopMatches ? (
            <Grid container>
              <FirstScreenContainer item lg={6} xl={5}>
                <Stack
                  sx={{
                    marginTop: "16px",
                  }}
                  direction="row"
                  spacing={2}
                >
                  {Object.values(CATEGORY).map((categoryValue) => (
                    <Badge
                      badgeContent={
                        categoryValue === category && (
                          <IconButton
                            onClick={() => handleSelectCategory(null)}
                          >
                            <NavigationActionIcon
                              active={true}
                              customfontsize={globalFontSize}
                            >
                              cancel
                            </NavigationActionIcon>
                          </IconButton>
                        )
                      }
                    >
                      <CategoryChip
                        active={category === categoryValue}
                        onClick={() => handleSelectCategory(categoryValue)}
                        label={getTranslation(categoryValue, t, i18n)}
                      />
                    </Badge>
                  ))}
                </Stack>

                {(!Boolean(category) || category === CATEGORY.ASSETS) && (
                  <FavoriteAssetsAccordion
                    handleClickItem={handleClickItemAsset}
                    currentResourceId={resourceId}
                  />
                )}

                {(!Boolean(category) || category === CATEGORY.LIBRARY) && (
                  <FavoriteTypesAccordion
                    handleClickItem={handleClickItemType}
                    currentTypeId={typeId}
                  />
                )}
              </FirstScreenContainer>
              <SplitScreen item lg={6} xl={7}>
                <HomePagePadding>
                  {typeId && <DesktopTypeDetails typeId={typeId} />}
                  {resourceId && <AssetDetails resourceId={resourceId} />}
                </HomePagePadding>
              </SplitScreen>
            </Grid>
          ) : (
            <HomePagePadding>
              <Stack
                sx={{
                  marginTop: "16px",
                }}
                direction="row"
                spacing={2}
              >
                {Object.values(CATEGORY).map((categoryValue) => (
                  <Badge
                    badgeContent={
                      categoryValue === category && (
                        <IconButton onClick={() => handleSelectCategory(null)}>
                          <NavigationActionIcon
                            active={true}
                            customfontsize={globalFontSize}
                          >
                            cancel
                          </NavigationActionIcon>
                        </IconButton>
                      )
                    }
                  >
                    <CategoryChip
                      active={category === categoryValue}
                      onClick={() => handleSelectCategory(categoryValue)}
                      label={getTranslation(categoryValue, t, i18n)}
                    />
                  </Badge>
                ))}
              </Stack>
              {(!Boolean(category) || category === CATEGORY.ASSETS) && (
                <FavoriteAssetsAccordion
                  handleClickItem={handleClickItemAsset}
                />
              )}
              {(!Boolean(category) || category === CATEGORY.LIBRARY) && (
                <FavoriteTypesAccordion handleClickItem={handleClickItemType} />
              )}
            </HomePagePadding>
          )}
        </Box>
      </Layer2Access>
    </AppAccess>
  );
};

export default Favorites;
