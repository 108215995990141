import { Stack, TextField, Box } from "@mui/material";
import {
  InputContainer,
  ValidationText,
} from "../styles/inputs/NamesGroup.styles";
import { getTranslation, testInput } from "../../util/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectOrganizationData,
  setOrganizationData,
} from "../../store/slices/onboardingSlice";
import { inputLabelProps } from "./BillingGroup";
import { useState } from "react";

const OrganizationDataGroup = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const organizationData = useSelector(selectOrganizationData);

  // State
  const [organizationNameValid, setOrganizationNameValid] = useState(true);

  // Handlers
  const handleOrganizationNameChange = (event) => {
    const isValid = testInput.ORGANIZATION_NAME(event.target.value);

    dispatch(
      setOrganizationData({
        ...organizationData,
        organizationName: event.target.value,
      })
    );

    setOrganizationNameValid(isValid);
  };

  const handleOrganizationNicknameChange = (event) => {
    dispatch(
      setOrganizationData({
        ...organizationData,
        nickname: event.target.value,
      })
    );
  };

  return (
    <Stack spacing={0}>
      {/* Organization name input */}
      <InputContainer>
        <TextField
          inputProps={{ "data-testid": "organization-name-input" }}
          id="organization-name-input"
          type="text"
          label={getTranslation("ORGANIZATION_NAME", t, i18n)}
          required
          placeholder={getTranslation("ORGANIZATION_NAME", t, i18n)}
          name="organizationName"
          value={organizationData.organizationName}
          onChange={handleOrganizationNameChange}
          error={!organizationNameValid}
          InputLabelProps={inputLabelProps}
        />

        <Box>
          {!organizationNameValid && (
            <ValidationText
              data-testid="validation-text-organization-name"
              variant="body5"
              align="center"
            >
              {getTranslation("organizationNameValidation", t, i18n)}
            </ValidationText>
          )}
        </Box>
      </InputContainer>

      {/* Organization name input */}
      <InputContainer>
        <TextField
          inputProps={{ "data-testid": "organization-nickname-input" }}
          id="organization-nickname-input"
          type="text"
          label={getTranslation("ORGANIZATION_NICKNAME", t, i18n)}
          placeholder={getTranslation("ORGANIZATION_NICKNAME", t, i18n)}
          name="organizationNickname"
          value={organizationData.nickname}
          onChange={handleOrganizationNicknameChange}
          InputLabelProps={inputLabelProps}
        />
      </InputContainer>
    </Stack>
  );
};

export default OrganizationDataGroup;
