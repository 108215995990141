import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  RESOURCE_CATEGORIES,
  transitionDirections,
} from "../../../util/utils";
import {
  ActionTitle,
  BackInfoContainer,
  BackPageTitle,
  BackTitle,
  HeaderGridContainer,
  HeaderWrapper,
  LeftActionButton,
  PageTitle,
  StyledChevronLeftIcon,
} from "../../../components/styles/header/Header.styles";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  DEFAULT_INDEX,
  selectGlobalFontSize,
  setIndex,
} from "../../../store/slices/appSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  useAddFavoriteMutation,
  useDeleteFavoriteMutation,
  useGetAllFunctionsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { DynamicVisibilityIconButton } from "../../../components/styles/assets/asset-list/ResourceColumnHeading.styles";
import { NavigationActionIcon } from "../../../components/styles/menu/Menu.styles";
import ErrorHandling from "../../../components/common/ErrorHandling";

const AssetDetailsHeader = ({
  userRoles,
  actionEditFunction,
  currentResourceData,
  resourceid,
  view,
  favorites,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Mutations
  const [addFavorite] = useAddFavoriteMutation();
  const [deleteFavorite] = useDeleteFavoriteMutation();

  // Queries
  const {
    data: allFunctionsData,
    isLoading,
    isError,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Handlers
  const handleGetBackInfo = () => {
    if (Boolean(currentResourceData) && Boolean(resourceid)) {
      const { id, name, displayId } = currentResourceData;
      const backName = displayId || name;
      const back = `/resources/${id}?direction=${transitionDirections.LEFT_TO_RIGHT}`;

      return {
        back,
        backName,
      };
    } else {
      return null;
    }
  };

  const hasEditPermissions = () => {
    if (Boolean(currentResourceData) && Boolean(resourceid)) {
      const { functionId } = currentResourceData;
      const currentFunction = allFunctionsData?.find(
        (f) => f.id === functionId
      );

      const permissionsKey = `ASSET_MANAGEMENT_${
        currentFunction?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET
          ? "HARDWARE"
          : currentFunction?.category
      }_EDIT`;

      return hasAccess(
        "all",
        [permissions[permissionsKey]],
        getPermissionsFromUserRoles(userRoles)
      );
    } else {
      return false;
    }
  };

  const onBackToClickHandler = (back) => {
    if (view === "column") {
      navigate(`/resources?direction=${transitionDirections.LEFT_TO_RIGHT}`);
      return;
    }

    navigate(back);
  };

  const handleAddFavorite = async (e, id) => {
    e.stopPropagation();

    try {
      await addFavorite({
        resourceid: id,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      }).unwrap();

      dispatch(setIndex(DEFAULT_INDEX));
    } catch (error) {
      console.error("Failed to add favorite", error);
    }
  };

  const handleDeleteFavorite = async (e, id) => {
    e.stopPropagation();

    try {
      await deleteFavorite({
        resourceid: id,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      }).unwrap();

      dispatch(setIndex(DEFAULT_INDEX));
    } catch (error) {
      console.error("Failed to delete favorite", error);
    }
  };

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <HeaderWrapper id="sticky-header">
        <HeaderGridContainer>
          {tabletMatches ? (
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <Box>
                  {Boolean(handleGetBackInfo()) ? (
                    <LeftActionButton
                      id="back-button"
                      onClick={() =>
                        onBackToClickHandler(handleGetBackInfo().back)
                      }
                    >
                      <StyledChevronLeftIcon
                        id="header-back-button-icon"
                        customfontsize={globalFontSize * 2}
                      />
                      <BackTitle component="span" id="previous_page-title">
                        {handleGetBackInfo().backName}
                      </BackTitle>
                    </LeftActionButton>
                  ) : (
                    <BackPageTitle variant="h4" id="title">
                      {getTranslation("assets", t, i18n)}
                    </BackPageTitle>
                  )}
                </Box>
              </Grid>
              <Grid item xs={8}>
                <PageTitle variant="h4" id="title">
                  {currentResourceData
                    ? currentResourceData.displayId || currentResourceData.name
                    : ""}
                </PageTitle>
              </Grid>
              <Grid display="flex" justifyContent="end" item xs={2}>
                {favorites?.data?.some((f) => f.resource.id === resourceid) ? (
                  <DynamicVisibilityIconButton
                    data-testid="StarIconDetailViewFavored"
                    onClick={(e) => handleDeleteFavorite(e, resourceid)}
                    isvisible={Boolean(resourceid)}
                  >
                    <NavigationActionIcon
                      active={true}
                      customfontsize={globalFontSize}
                    >
                      star
                    </NavigationActionIcon>
                  </DynamicVisibilityIconButton>
                ) : (
                  <DynamicVisibilityIconButton
                    data-testid="StarIconDetailViewNonFavored"
                    onClick={(e) => handleAddFavorite(e, resourceid)}
                    isvisible={Boolean(resourceid)}
                  >
                    <NavigationActionIcon
                      active={true}
                      className="material-icons material-icons-outlined"
                      customfontsize={globalFontSize}
                    >
                      star_outlined_outlined
                    </NavigationActionIcon>
                  </DynamicVisibilityIconButton>
                )}
              </Grid>
            </Grid>
          ) : (
            <>
              <BackInfoContainer>
                {Boolean(handleGetBackInfo()) ? (
                  <LeftActionButton
                    id="back-button"
                    onClick={() =>
                      onBackToClickHandler(handleGetBackInfo().back)
                    }
                  >
                    <StyledChevronLeftIcon
                      id="header-back-button-icon"
                      customfontsize={globalFontSize * 2}
                    />
                    <BackTitle component="span" id="previous_page-title">
                      {handleGetBackInfo().backName}
                    </BackTitle>
                  </LeftActionButton>
                ) : (
                  <BackPageTitle variant="h4" id="title">
                    {getTranslation("assets", t, i18n)}
                  </BackPageTitle>
                )}
              </BackInfoContainer>
              {hasEditPermissions() && (
                <Box>
                  <ActionTitle
                    tabIndex="0"
                    align="right"
                    onClick={actionEditFunction}
                    id="action-title-done"
                    data-testid="action-title-edit"
                  >
                    <NavigationActionIcon
                      active={true}
                      customfontsize={globalFontSize}
                    >
                      edit
                    </NavigationActionIcon>
                    {getTranslation("EDIT", t, i18n)}
                  </ActionTitle>
                </Box>
              )}
            </>
          )}
        </HeaderGridContainer>
      </HeaderWrapper>
    </ErrorHandling>
  );
};

export default AssetDetailsHeader;
