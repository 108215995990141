import { Box, Switch } from "@mui/material";
import { styled } from "@mui/system";

export const OnboardingSteppedContentContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "space-around",
  paddingInline: "32px",
  width: "100%",
});

export const OnboardingFullContainer = styled(Box)({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflow: "hidden",
});

export const OnboardingMainContentContainer = styled(Box)({
  minWidth: "300px",
  maxWidth: "300px",
  flex: 0.6,
});

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  ".MuiSwitch-thumb": {
    backgroundColor: "#0076BC",
  },
  ".MuiSwitch-track": {
    backgroundColor: "#00B5E3",
  },
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      "+ .MuiSwitch-track": {
        backgroundColor: "#00B5E3",
      },
      ".MuiSwitch-thumb": {
        backgroundColor: "#0076BC",
      },
    },
  },
}));
