import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  typeId: null,
};

const typeSlice = createSlice({
  name: "type",
  initialState: initialState,
  reducers: {
    setTypeId(state, action) {
      const newTypeId = action.payload;
      state.typeId = newTypeId;
    },
  },
});

export default typeSlice.reducer;
export const { setTypeId } = typeSlice.actions;
export const selectTypeId = (state) => state.type.typeId;
