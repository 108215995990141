import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getFallbackIcon,
  getTranslation,
  transitionDirections,
} from "../../../util/utils";
import {
  selectGlobalFontSize,
  selectSortingOption,
} from "../../../store/slices/appSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  useGetAllFunctionsQuery,
  useGetFavoritesQuery,
} from "../../../store/slices/api/assetManagementSlice";
import {
  TableContainer,
  TableCell,
  TableBody,
  useMediaQuery,
  Grid,
  ListItemIcon,
} from "@mui/material";
import ErrorHandling from "../../common/ErrorHandling";
import {
  LoadMoreWrapper,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/types/type-list/TypeListPage.styles";
import {
  Item,
  ItemContainer,
  ItemName,
  SecondaryText,
} from "../../styles/assets/ListInlineView.styles";
import { NavigationActionIcon } from "../../styles/menu/Menu.styles";
import {
  CategoryTableCell,
  CountTableCell,
  FlexBox,
  FlexContainer,
  ListTable,
  NameTableCell,
  SelectedTableRow,
  TableCellText,
} from "../../styles/assets/asset-list/AssetListTable.styles";
import { ArrowRightIcon } from "../../styles/assets/ListInlineView.styles";
import {
  NoDataTextContainer,
  SecondaryContrastButton,
} from "../../styles/general/General.styles";
import InfiniteScrollLoader from "../../InfiniteScrollLoader";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import { getComparator, stableSort } from "../../../util/reports-utils";
import { useNavigate } from "react-router-dom";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { CreateAssetButton } from "../../styles/assets/AssetList.styles";
import TableMedia from "./TableMedia";

const PAGE_SIZE = 20;

const headCells = [
  {
    id: "displayIdAndName",
    numeric: false,
    disablePadding: true,
    label: "NAME",
  },
  {
    id: "childrenCount",
    numeric: true,
    disablePadding: false,
    label: "COUNT",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "CATEGORY",
  },
];

const FavoriteAssetsAccordion = ({ handleClickItem, currentResourceId }) => {
  // General hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const user = useSelector(selectUser);
  const organizationId = user?.organizations.find((o) => o.default)?.id;
  const sortingOption = useSelector(selectSortingOption);

  // States
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(headCells[0].id);
  const [expand, setExpand] = useState(true);
  const [index, setIndex] = useState(1);
  const [items, setItems] = useState([]);
  const [fetchingItems, setFetchingItems] = useState(false);

  // Queries
  const {
    data: favoritesData,
    isLoading,
    isFetching,
    isError,
  } = useGetFavoritesQuery({
    organizationId,
    index,
    size: PAGE_SIZE,
    sortBy: sortingOption.type,
    order: sortingOption.order,
  });

  const { data: allFunctionsData } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Other variables
  const favorites = favoritesData?.data;
  const fetching = fetchingItems || isFetching;
  const totalRecord = favoritesData?.totalRecord;
  const pageCount = favoritesData?.pageCount;
  const sortedItems = useMemo(() => {
    return stableSort(items, getComparator(order, orderBy));
  }, [order, orderBy, items]);

  // Handlers
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const toggleExpand = () => {
    setExpand(!expand);
  };

  const fetchMoreData = () => {
    setFetchingItems(true);
    setTimeout(() => {
      setIndex(index + 1);
      setFetchingItems(false);
    }, 1000);
  };

  const handleNavigateToAssets = () => {
    if (desktopMatches) {
      navigate("/resources");
    } else {
      navigate(`/resources?direction=${transitionDirections.RIGHT_TO_LEFT}`);
    }
  };

  // Effects
  useEffect(() => {
    if (favoritesData && allFunctionsData) {
      const newItems = [...items];
      const mappedFavorites = favorites.map((r) => {
        const { displayId, name, functionId } = r.resource;
        const combinationDisplayIdAndName = displayId || name;
        const category = allFunctionsData?.find(
          (f) => f.id === functionId
        ).category;

        return {
          ...r.resource,
          displayIdAndName: combinationDisplayIdAndName,
          category,
        };
      });

      newItems.push(
        ...mappedFavorites.filter((r) => !items.some((i) => i.id === r.id))
      );

      setItems(newItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoritesData, allFunctionsData]);

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <StyledAccordion
        id="assets-accordion"
        expanded={expand}
        onChange={toggleExpand}
      >
        <StyledAccordionSummary
          expandIcon={
            <NavigationActionIcon customfontsize={globalFontSize}>
              keyboard_arrow_right
            </NavigationActionIcon>
          }
        >
          <SecondaryText>{`${getTranslation("ASSETS", t, i18n)} (${
            sortedItems.length
          }/${totalRecord})`}</SecondaryText>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {sortedItems.length > 0 ? (
            desktopMatches ? (
              <TableContainer>
                <ListTable sx={{ minWidth: "0px" }} aria-label="simple table">
                  <EnhancedTableHead
                    headCells={headCells}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {sortedItems.map((resource) => {
                      const {
                        id,
                        displayIdAndName,
                        category,
                        childrenCount,
                        images,
                      } = resource;

                      return (
                        <SelectedTableRow
                          isselected={currentResourceId === id}
                          key={id}
                          onClick={() => handleClickItem(id)}
                        >
                          <TableCell>
                            <FlexBox>
                              <TableMedia
                                category={category}
                                resourceImages={images}
                              />
                            </FlexBox>
                          </TableCell>
                          <NameTableCell>
                            <TableCellText
                              isSelected={currentResourceId === id}
                            >
                              {displayIdAndName}
                            </TableCellText>
                          </NameTableCell>
                          <CountTableCell>
                            <TableCellText>{childrenCount}</TableCellText>
                          </CountTableCell>
                          <CategoryTableCell>
                            <TableCellText>
                              {getTranslation(category, t, i18n)}
                            </TableCellText>
                          </CategoryTableCell>
                          <TableCell>
                            <FlexContainer>
                              <ArrowRightIcon
                                customfontsize={globalFontSize * 1.5}
                              />
                            </FlexContainer>
                          </TableCell>
                        </SelectedTableRow>
                      );
                    })}
                  </TableBody>
                </ListTable>
              </TableContainer>
            ) : (
              <Grid
                container
                display="flex"
                justifyContent="start"
                columns={36}
                rowGap={tabletMatches ? 2 : 0}
              >
                {sortedItems?.map((row) => {
                  const { id, displayIdAndName, category } = row;

                  return (
                    <React.Fragment key={`resource-${id}`}>
                      <Grid item xs={0} sm={1}></Grid>

                      <ItemContainer item xs={36} sm={10}>
                        <Item
                          data-testid={`item-${displayIdAndName}`}
                          onClick={() => handleClickItem(id)}
                        >
                          <ListItemIcon>
                            <img
                              width={globalFontSize * 1.5}
                              height={globalFontSize * 1.5}
                              src={`${
                                process.env.PUBLIC_URL
                              }/icons/${getFallbackIcon(category)}`}
                              alt="icon"
                            />
                          </ListItemIcon>
                          <ItemName>{displayIdAndName}</ItemName>
                          <ArrowRightIcon
                            customfontsize={globalFontSize * 1.5}
                          />
                        </Item>
                      </ItemContainer>

                      <Grid item xs={0} sm={1}></Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            )
          ) : (
            <NoDataTextContainer>
              <SecondaryText align="center">
                {getTranslation("NO_FAVORITES", t, i18n)}
              </SecondaryText>
              <CreateAssetButton onClick={handleNavigateToAssets}>
                {getTranslation("OPEN_ASSETS", t, i18n)}
              </CreateAssetButton>
            </NoDataTextContainer>
          )}
          {fetching ? (
            <InfiniteScrollLoader />
          ) : (
            <>
              {index < pageCount && (
                <LoadMoreWrapper>
                  <SecondaryContrastButton
                    onClick={fetchMoreData}
                    sx={{
                      width: "auto",
                      margin: "auto",
                    }}
                  >
                    {getTranslation("LOAD_MORE", t, i18n)}
                  </SecondaryContrastButton>
                </LoadMoreWrapper>
              )}
            </>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
    </ErrorHandling>
  );
};

export default FavoriteAssetsAccordion;
