import { Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ListItemContainer } from "../styles/assets/asset-detail/AssetDetail.styles";
import { PrimaryText } from "../styles/assets/ListGridView.styles";
import { SecondaryText } from "../styles/assets/ListInlineView.styles";
import { getTranslation } from "../../util/utils";

const ThirdPartyNoticesCard = ({ category, name, link }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  return (
    <ListItemContainer>
      <SecondaryText id={`${name}-label`}>
        {getTranslation(category, t, i18n)}
      </SecondaryText>
      <Link align="left" href={link} underline="always" target="_blank">
        <PrimaryText id={name}>{name}</PrimaryText>
      </Link>
    </ListItemContainer>
  );
};

export default ThirdPartyNoticesCard;
