import { useSelector } from "react-redux";
import { useGetCurrentSubscriptionActualUsageQuery } from "../store/slices/api/subscriptionsApiSlice";
import { selectUser } from "../store/slices/authSlice";
import ErrorHandling from "./common/ErrorHandling";
import useCheckOrganizationRestricted from "../hooks/useCheckOrganizationRestricted";
import { useGetOrganizationUsersQuery } from "../store/slices/api/organizationsApiSlice";
import {
  getPermissionsFromUserRoles,
  hasAccess,
  permissions,
} from "../util/utils";
import { useUserRolePermissionsQuery } from "../store/slices/api/userManagementSlice";
import { Stack, SnackbarContent } from "@mui/material";

const Layer2Alerts = ({ organization }) => {
  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const activeOrganization = user?.organizations?.find((o) => o.default);

  // Custom hooks
  const { isLoading, isRestricted } =
    useCheckOrganizationRestricted(activeOrganization);

  // Queries
  const { data: userRoles, isLoading: isLoadingUserRoles } =
    useUserRolePermissionsQuery(
      {
        organizationId: activeOrganization?.id,
        userId: user?.id,
      },
      { skip: !activeOrganization }
    );

  const { data: organizationUsers, isLoading: isLoadingOrganizationUsers } =
    useGetOrganizationUsersQuery(activeOrganization?.id, {
      skip:
        isRestricted ||
        !hasAccess(
          "all",
          [permissions.USER_MANAGEMENT_VIEW],
          getPermissionsFromUserRoles(userRoles)
        ),
    });

  const { data: actualUsage, isLoading: isLoadingActualUsage } =
    useGetCurrentSubscriptionActualUsageQuery(
      {
        organizationId: activeOrganization?.id,
      },
      {
        skip: isRestricted,
      }
    );

  // Other variables
  const facts = actualUsage?.facts;
  const assetsUsage = facts?.find((f) => f.name === "ASSET_COUNT");
  const usersUsage = facts?.find((f) => f.name === "USER_COUNT");
  const assetsSubscriptionValue = Number(assetsUsage?.subscriptionValue);
  const assetsCurrentValue = Number(assetsUsage?.currentValue);
  const usersSubscriptionValue = Number(usersUsage?.subscriptionValue);
  const usersCurrentValue = organizationUsers?.length ?? 0;

  return (
    <ErrorHandling
      isLoading={
        isLoading ||
        isLoadingActualUsage ||
        isLoadingUserRoles ||
        isLoadingOrganizationUsers
      }
      isError={false}
    >
      {userRoles?.some((ur) => ur.name === "OWNER" || ur.name === "ADMIN") &&
        organization?.id === activeOrganization?.id && (
          <Stack sx={{ marginTop: "10px" }} spacing={2}>
            {activeOrganization?.owner && !activeOrganization?.subscription && (
              <SnackbarContent
                sx={{
                  backgroundColor: "#f44336",
                  boxShadow: "none",
                  paddingBlock: 0,
                }}
                message="No active subscription found"
              />
            )}

            {activeOrganization?.subscription &&
              usersCurrentValue >= usersSubscriptionValue && (
                <SnackbarContent
                  sx={{
                    backgroundColor: "#f44336",
                    boxShadow: "none",
                    paddingBlock: 0,
                  }}
                  message="Users limit exceeded"
                />
              )}

            {activeOrganization?.subscription &&
              assetsCurrentValue >= assetsSubscriptionValue && (
                <SnackbarContent
                  sx={{
                    backgroundColor: "#f44336",
                    boxShadow: "none",
                    paddingBlock: 0,
                  }}
                  message="Assets limit exceeded"
                />
              )}
          </Stack>
        )}
    </ErrorHandling>
  );
};

export default Layer2Alerts;
