import { useDispatch } from "react-redux";
import { setShouldExecute } from "../../../../store/slices/reportsSlice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getTranslation } from "../../../../util/utils";
import { useTranslation } from "react-i18next";

const CustomCombinatorSelector = ({ options, value, handleOnChange }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Handlers
  const handleChange = (e) => {
    handleOnChange(e.target.value);
    dispatch(setShouldExecute(false));
  };

  return (
    <FormControl sx={{ marginLeft: "5px" }} variant="standard">
      <Select
        labelId="field-select-label"
        id="field-select"
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={option.name} value={option.name}>
            {getTranslation(option.label, t, i18n)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomCombinatorSelector;
