import React from "react";
import { useTranslation } from "react-i18next";
import { RESOURCE_CATEGORIES, getTranslation } from "../../../util/utils";
import { Menu } from "@mui/material";
import { SecondaryContrastMenuItem } from "../../styles/assets/asset-form/CategoryMenu.styles";
import { useGetAllResourcesQuery } from "../../../store/slices/api/assetManagementSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import ErrorHandling from "../../common/ErrorHandling";

const CategoryMenu = ({
  open,
  anchorEl,
  handleClose,
  parentCategory,
  categoryActionHandler,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);

  // Queries
  const { data: allResourcesData, isLoading: isLoadingResources } =
    useGetAllResourcesQuery({
      organizationId: user?.organizations?.find((o) => o.default)?.id,
    });

  // Other variables
  const isParentHardwareAsset =
    parentCategory === RESOURCE_CATEGORIES.HARDWARE_ASSET;
  const isParentRack = parentCategory === RESOURCE_CATEGORIES.RACK;
  const shouldCreateLocation =
    !allResourcesData || allResourcesData?.length <= 0 || !parentCategory;

  // Handlers
  const handleOption = (category) => {
    handleClose();
    categoryActionHandler(category);
  };

  return (
    <ErrorHandling isLoading={isLoadingResources}>
      <Menu
        slotProps={{
          paper: {
            elevation: 1,
            sx: {
              borderRadius: "8px",
            },
          },
        }}
        id="menu-category-options"
        aria-labelledby="more-icon"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <SecondaryContrastMenuItem
          id="hardware-asset-item"
          disabled={shouldCreateLocation}
          onClick={() => handleOption(RESOURCE_CATEGORIES.HARDWARE_ASSET)}
        >
          {getTranslation("CREATE_HARDWARE_ASSET", t, i18n)}
        </SecondaryContrastMenuItem>
        <SecondaryContrastMenuItem
          id="location-item"
          disabled={isParentRack || isParentHardwareAsset}
          onClick={() => handleOption(RESOURCE_CATEGORIES.LOCATION)}
        >
          {getTranslation("CREATE_LOCATION", t, i18n)}
        </SecondaryContrastMenuItem>
        <SecondaryContrastMenuItem
          id="rack-item"
          disabled={shouldCreateLocation || isParentHardwareAsset}
          onClick={() => handleOption(RESOURCE_CATEGORIES.RACK)}
        >
          {getTranslation("CREATE_RACK", t, i18n)}
        </SecondaryContrastMenuItem>
      </Menu>
    </ErrorHandling>
  );
};

export default CategoryMenu;
