import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

export const DEFAULT_TYPE_INPUT = {
  displayId: null,
  category: null,
  functionId: null,
  hasFunctionIdChanged: false,
  name: "",
  description: "",
  version: "",
  lifecycleStatus: "ACTIVE",
  lifecycleDate: dayjs(),
  characteristics: [],
};

const initialState = {
  typeInput: DEFAULT_TYPE_INPUT,
  originalTypeInput: DEFAULT_TYPE_INPUT,
  error: {},
  isFirstSubmitted: false,
};

const typeInputSlice = createSlice({
  name: "typeInput",
  initialState,
  reducers: {
    setTypeInput: (state, action) => {
      const newTypeInput = action.payload;
      state.typeInput = newTypeInput;
    },
    setOriginalTypeInput: (state, action) => {
      const newOriginalTypeInput = action.payload;
      state.originalTypeInput = newOriginalTypeInput;
    },
    setError: (state, action) => {
      const newError = action.payload;
      state.error = newError;
    },
    setIsFirstSubmitted: (state, action) => {
      const newIsFirstSubmitted = action.payload;
      state.isFirstSubmitted = newIsFirstSubmitted;
    },
    resetForm: (state, action) => {
      state.typeInput = DEFAULT_TYPE_INPUT;
      state.originalTypeInput = DEFAULT_TYPE_INPUT;
      state.error = {};
      state.isFirstSubmitted = false;
    },
  },
});

// Export slice and its corresponding actions
export default typeInputSlice.reducer;
export const {
  setTypeInput,
  setOriginalTypeInput,
  setError,
  setIsFirstSubmitted,
  resetForm,
} = typeInputSlice.actions;

// Selectors
export const selectTypeInput = (state) => state.typeInput.typeInput;
export const selectOriginalTypeInput = (state) =>
  state.typeInput.originalTypeInput;
export const selectError = (state) => state.typeInput.error;
export const selectIsFirstSubmitted = (state) =>
  state.typeInput.isFirstSubmitted;
