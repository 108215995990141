import { createSlice } from "@reduxjs/toolkit";

export const DEFAULT_RESOURCE_ATTACHMENT = {
  selectedFile: null,
  file: null,
  resourceid: "",
  id: "",
  name: "",
  mimeType: "",
  description: "",
  isFromDb: false,
};

// Initial state
const initialState = {
  list: [],
};

// Slice
const resourceAttachmentSlice = createSlice({
  name: "resourceAttachment",
  initialState,
  reducers: {
    addResourceAttachment: (state, action) => {
      const newAttachment = action.payload;
      state.list.push(newAttachment);
    },
    editResourceAttachment: (state, action) => {
      const { index, resourceAttachment } = action.payload;
      state.list[index] = { ...resourceAttachment };
    },
    deleteResourceAttachment: (state, action) => {
      const index = Number(action.payload);
      state.list.splice(index, 1);
    },
    setAttachmentList: (state, action) => {
      const newList = action.payload;
      state.list = newList;
    },
    clearAttachmentList: (state) => {
      state.list = [];
    },
  },
});

// Export slice and its corresponding actions
export default resourceAttachmentSlice.reducer;
export const {
  addResourceAttachment,
  editResourceAttachment,
  deleteResourceAttachment,
  setAttachmentList,
  clearAttachmentList,
} = resourceAttachmentSlice.actions;

// selectors
export const selectResourceAttachments = (state) =>
  state.resourceAttachment.list;
