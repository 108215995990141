import { styled } from "@mui/system";
import { Typography, Grid, ListItem, Button, Box } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const DropDownIcon = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

export const EllipseText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

export const OrganizationDropDownName = styled(EllipseText)({
  maxWidth: `300px`,
});

export const StyledStarIcon = styled(StarIcon)({
  color: "#07ABF7",
  marginRight: "30px",
});

export const StyledKeyboardArrowRightIcon = styled(KeyboardArrowRightIcon)(
  ({ theme, customfontsize }) => ({
    color: theme.palette.primary.main,
    float: "right",
    fontSize: `${customfontsize}px`,
  })
);

export const ListItemName = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    color: theme.palette.text.primary,
  })
);

export const AssetIdNumberText = styled(Typography)(
  ({ theme }) =>
    theme.unstable_sx({
      marginRight: "auto",
    }),
  ({ theme }) => ({
    color: theme.palette.secondary.main,
  })
);

export const AssetClassText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const AssetTypeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const AssetProgressStatus = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.text.primary,
  }),
  ({ theme, progressStatusColor }) =>
    theme.unstable_sx({
      backgroundColor: progressStatusColor,
    })
);

export const CardItem = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  borderBottom: "solid 0.5px " + theme.palette.primary.dark,
  marginTop: "10px",
}));

export const ReportCardItem = styled(ListItem)(({ theme, index }) => ({
  borderTop: index === 0 ? `3px solid ${theme.palette.secondary.dark}` : null,
  borderBottom: `3px solid ${theme.palette.secondary.dark}`,
  position: "relative",
}));

export const AddButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  display: "flex",
  justifyContent: "center",
  margin: "auto",
  marginTop: "20px",
}));

export const ChartContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
  height: "100%",
});
