import { useState, forwardRef } from "react";
import {
  Grid,
  Menu,
  MenuItem,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  PreferenceArrowIcon,
  PreferenceItemNameSecondary,
  PreferenceItemEmail,
  TopSpacedMenuItem,
} from "./styles/profile/ProfilePreferenceItem.styles";
import {
  hasAccess,
  permissions,
  getPermissionsFromUserRoles,
  getTranslation,
  showValidationError,
} from "../util/utils";
import {
  useInviteUserMutation,
  useRemoveInvitationMutation,
  useUpdateInvitationRoleMutation,
} from "../store/slices/api/userManagementSlice";
import { useTranslation } from "react-i18next";
import {
  CancelButton,
  ChangeRoleButton,
  RemoveFromOrganizationButton,
  ResendInvitationButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "./styles/general/General.styles";
import { messageError, messageSuccess } from "../util/notification";
import { StyledDivider } from "./styles/profile/Profile.styles";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../store/slices/appSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../util/viewport-utils";
import {
  ContrastMoreIcon,
  MoreIconGridContainer,
} from "./styles/profile/ProfileDesktop.styles";
import { ResendInvitationMenuItem } from "./styles/invitation/OrganizationInvitationCard.styles";
import ErrorHandling from "./common/ErrorHandling";
import { selectUser } from "../store/slices/authSlice";
import useCheckOrganizationRestricted from "../hooks/useCheckOrganizationRestricted";
import { useGetOrganizationUsersQuery } from "../store/slices/api/organizationsApiSlice";
import { useGetCurrentSubscriptionActualUsageQuery } from "../store/slices/api/subscriptionsApiSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InvitationCard = ({ invitation, userRoles, roles, organizationId }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Other variables
  const organization = user?.organizations?.find(
    (o) => o.id === organizationId
  );
  const canEditInvitations = Boolean(organization?.subscription);
  const openMenu = Boolean(anchorEl);

  // Custom hooks
  const { isLoading: isLoadingCheckOrgRestricted, isRestricted } =
    useCheckOrganizationRestricted(organization);

  // Queries
  const { data: organizationUsers, isLoading: isLoadingOrganizationUsers } =
    useGetOrganizationUsersQuery(organizationId, {
      skip:
        isRestricted ||
        !hasAccess(
          "all",
          [permissions.USER_MANAGEMENT_VIEW],
          getPermissionsFromUserRoles(userRoles)
        ),
    });

  const { data: actualUsage, isLoading: isLoadingActualUsage } =
    useGetCurrentSubscriptionActualUsageQuery(
      {
        organizationId: organization?.id,
      },
      {
        skip: isRestricted,
      }
    );

  // Other variables
  const facts = actualUsage?.facts;
  const usersUsage = facts?.find((f) => f.name === "USER_COUNT");
  const usersSubscriptionValue = Number(usersUsage?.subscriptionValue);
  const usersCurrentValue = organizationUsers?.length ?? 0;

  // Mutations
  const [removeInvitation, { isLoading: isLoadingRemoveInvitation }] =
    useRemoveInvitationMutation();
  const [inviteUser, { isLoading: isLoadingInviteUser }] =
    useInviteUserMutation();
  const [updateInvitationRole, { isLoading: isLoadingUpdateInvitationRole }] =
    useUpdateInvitationRoleMutation();

  // Other variables
  const invitationEmail =
    invitation.email.length > 25
      ? invitation.email.substring(0, 25) + "..."
      : invitation.email;

  // Handlers
  const handleOpenOptionsMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptionsMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenOptionsDialog = () => {
    setOpen(true);
  };

  const handleCloseOptionsDialog = () => {
    setOpen(false);
  };

  const handleResendInvitation = async () => {
    const { email, roles } = invitation;

    const userData = {
      email,
      roles: roles?.map((r) => r.id),
    };

    const invitationData = [userData];

    try {
      await inviteUser({ invitationData, organizationId }).unwrap();
      messageSuccess(getTranslation("successfulResendInvitation", t, i18n));
      handleCloseOptionsDialog();
    } catch (error) {
      showValidationError(error, t, i18n);
    }
  };

  const handleUpdateInvitationRoles = async (roleId) => {
    const { id: invitationId } = invitation;

    try {
      await updateInvitationRole({
        invitationId,
        organizationId,
        roleId,
      }).unwrap();

      messageSuccess(getTranslation("successfulUpdateInvitationRole", t, i18n));
      handleCloseOptionsDialog();
    } catch (error) {
      messageError(getTranslation("failedUpdateInvitationRole", t, i18n));
    }
  };

  const handleRemoveInvitation = async () => {
    try {
      await removeInvitation({
        organizationId,
        invitationId: invitation.id,
      });

      messageSuccess(getTranslation("successfulRemoveInvitation", t, i18n));
      handleCloseOptionsDialog();
    } catch (error) {
      messageError(getTranslation("failedRemoveInvitation", t, i18n));
    }
  };

  // Other variables
  const leftRolesExceptOwner = roles?.filter(
    (r) =>
      !invitation.roles?.some((ur) => ur.name === r.name) && r.name !== "OWNER"
  );

  return (
    <ErrorHandling
      isLoading={
        isLoadingInviteUser ||
        isLoadingRemoveInvitation ||
        isLoadingUpdateInvitationRole ||
        isLoadingCheckOrgRestricted ||
        isLoadingOrganizationUsers ||
        isLoadingActualUsage
      }
      isError={false}
    >
      <div>
        <StyledDialog
          fullWidth
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseOptionsDialog}
          aria-describedby="alert-dialog-slide-description"
        >
          <StyledDialogContent>
            <StyledDialogTitle>{invitationEmail}</StyledDialogTitle>

            {usersCurrentValue < usersSubscriptionValue && <StyledDivider />}

            {usersCurrentValue < usersSubscriptionValue && (
              <ResendInvitationButton
                id={`resend-invitation`}
                onClick={handleResendInvitation}
              >
                {getTranslation("RESENDINVITATION", t, i18n)}
              </ResendInvitationButton>
            )}

            {usersCurrentValue <= usersSubscriptionValue && (
              <>
                {" "}
                {leftRolesExceptOwner?.map((r) => (
                  <div key={r.id}>
                    <StyledDivider />
                    <ChangeRoleButton
                      id={`change-role`}
                      onClick={() => handleUpdateInvitationRoles(r.id)}
                    >
                      {getTranslation("CHANGETO" + r.name, t, i18n)}
                    </ChangeRoleButton>
                  </div>
                ))}
              </>
            )}

            {hasAccess(
              "all",
              [permissions.USER_MANAGEMENT_DELETE],
              getPermissionsFromUserRoles(userRoles)
            ) && (
              <>
                <StyledDivider />
                <RemoveFromOrganizationButton
                  id={`remove-invitation-from-organization`}
                  color="error"
                  onClick={handleRemoveInvitation}
                >
                  {getTranslation("REMOVEFROMORGANIZATION", t, i18n)}
                </RemoveFromOrganizationButton>
              </>
            )}
          </StyledDialogContent>
          <CancelButton id={`cancel-button`} onClick={handleCloseOptionsDialog}>
            {getTranslation("CANCEL", t, i18n)}
          </CancelButton>
        </StyledDialog>

        <Menu
          slotProps={{
            paper: {
              elevation: 1,
              sx: {
                borderRadius: "8px",
              },
            },
          }}
          id="menu-options"
          aria-labelledby="more-icon"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseOptionsMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {usersCurrentValue < usersSubscriptionValue && (
            <ResendInvitationMenuItem
              id={`resend-invitation`}
              onClick={() => {
                handleResendInvitation();
                handleCloseOptionsMenu();
              }}
            >
              {getTranslation("RESENDINVITATION", t, i18n)}
            </ResendInvitationMenuItem>
          )}

          {usersCurrentValue <= usersSubscriptionValue && (
            <>
              {leftRolesExceptOwner?.map((r) => (
                <MenuItem
                  key={r.id}
                  id={`change-role`}
                  onClick={() => {
                    handleUpdateInvitationRoles(r.id);
                    handleCloseOptionsMenu();
                  }}
                >
                  {getTranslation("CHANGETO" + r.name, t, i18n)}
                </MenuItem>
              ))}
            </>
          )}

          {hasAccess(
            "all",
            [permissions.USER_MANAGEMENT_DELETE],
            getPermissionsFromUserRoles(userRoles)
          ) && (
            <MenuItem
              id={`remove-invitation-from-organization`}
              onClick={() => {
                handleRemoveInvitation();
                handleCloseOptionsMenu();
              }}
            >
              <Typography color="red">
                {getTranslation("REMOVEFROMORGANIZATION", t, i18n)}
              </Typography>
            </MenuItem>
          )}
        </Menu>

        <TopSpacedMenuItem
          slotProps={{
            paper: {
              elevation: 1,
              sx: {
                borderRadius: "8px",
              },
            },
          }}
          onClick={
            hasAccess(
              "all",
              [permissions.USER_MANAGEMENT_ADD],
              getPermissionsFromUserRoles(userRoles)
            ) && mobileMatches
              ? handleOpenOptionsDialog
              : null
          }
          dense
          id={`menu-item`}
          desktopMatches={desktopMatches}
        >
          <Grid container>
            <Grid item xs={9}>
              {/* Preference name */}
              <PreferenceItemEmail
                variant="body1"
                id={`label-${invitation.id}`}
              >
                {invitationEmail}
              </PreferenceItemEmail>
            </Grid>

            <Grid item xs={2}>
              <PreferenceItemNameSecondary
                data-testid="preference-value"
                variant="body1"
                id={`label-secondary-${invitation.id}`}
              >
                {invitation.roles?.length
                  ? getTranslation(invitation.roles[0].name, t, i18n)
                  : "REFERRAL"}
              </PreferenceItemNameSecondary>
            </Grid>

            <MoreIconGridContainer item xs={1}>
              {canEditInvitations &&
                hasAccess(
                  "all",
                  [permissions.USER_MANAGEMENT_ADD],
                  getPermissionsFromUserRoles(userRoles)
                ) &&
                (mobileMatches ? (
                  <PreferenceArrowIcon
                    onClick={handleOpenOptionsDialog}
                    id={`arrow-icon-${invitation.id}`}
                    data-testid={`arrow-icon-${invitation.id}`}
                    customfontsize={globalFontSize * 1.5}
                  />
                ) : (
                  <ContrastMoreIcon
                    aria-controls={openMenu ? "more-icon" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    onClick={handleOpenOptionsMenu}
                    id="more-icon"
                    data-testid={`more-icon-${invitation.id}`}
                    globalFontSize={globalFontSize}
                  />
                ))}
            </MoreIconGridContainer>
          </Grid>
        </TopSpacedMenuItem>
        <StyledDivider />
      </div>
    </ErrorHandling>
  );
};

export default InvitationCard;
