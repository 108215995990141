import { List } from "@mui/material";
import AssetDetailBasicInfo from "./AssetDetailBasicInfo";
import AssetDetailTypeInfo from "./AssetDetailTypeInfo";
import AssetDetailCharacteristicGroup from "./AssetDetailCharacteristicGroup";
import AssetDetailTagList from "../tags/AssetDetailTagList";
import ResourceAttachmentList from "../asset-attachments/ResourceAttachmentList";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import { useGetResourceAttachmentsQuery } from "../../../store/slices/api/resourceAttachmentsApiSlice";
import { setAttachmentList } from "../../../store/slices/resourceAttachmentSlice";
import ErrorHandling from "../../common/ErrorHandling";

const AssetDetailList = ({ resourceData }) => {
  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  // Queries
  const {
    data: resourceAttachmentsData,
    isLoading: isLoadingAttachments,
    isError: isErrorAttachments,
  } = useGetResourceAttachmentsQuery(
    {
      resourceid: resourceData.id,
      organizationId,
    },
    { skip: !Boolean(resourceData) }
  );

  return (
    <ErrorHandling
      isLoading={isLoadingAttachments}
      isError={isErrorAttachments}
    >
      <List key={"asset-detail-main-list"} className="no-padding">
        <AssetDetailTagList resourceData={resourceData} />
        <AssetDetailBasicInfo resourceData={resourceData} />

        {resourceData?.type && (
          <AssetDetailTypeInfo resourceData={resourceData} />
        )}

        <AssetDetailCharacteristicGroup
          resourceCharacteristics={resourceData?.characteristics}
          typeCharacteristics={resourceData?.type?.characteristics ?? []}
          functionId={resourceData?.functionId}
          resourceParentId={resourceData?.parent?.id}
        />

        {/* Resource attachments */}
        <ResourceAttachmentList attachments={resourceAttachmentsData} />
      </List>
    </ErrorHandling>
  );
};

export default AssetDetailList;
