import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCreateCommunitySSOTokenMutation } from "../store/slices/api/userManagementSlice";
import { selectUser } from "../store/slices/authSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../util/viewport-utils";
import { useMediaQuery } from "@mui/material";
import { messageError } from "../util/notification";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../util/utils";
import AppAccess from "../components/common/AppAccess";

const Community = () => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selector
  const user = useSelector(selectUser);
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const [createToken] = useCreateCommunitySSOTokenMutation();
  const navigate = useNavigate();

  useEffect(() => {
    const navigateToCommunityPortal = async () => {
      try {
        const data = await createToken(user?.email).unwrap();

        if (data) {
          const { communityUrl, token } = data;
          window.location.href = `${communityUrl}/?sso=${token}`;
        }
      } catch (error) {
        console.log(error);
        if (error.data?.error === "MISSING_NICKNAME") {
          if (mobileMatches) {
            navigate("/profile/update-user-nickname?done-action=community");
          } else {
            navigate("/profile");
            messageError(
              getTranslation("nicknameRequiredForForumPortal", t, i18n)
            );
          }
        }
      }
    };

    navigateToCommunityPortal();
  }, []);
  return (
    <AppAccess>
      <div></div>
    </AppAccess>
  );
};

export default Community;
