import { RadioGroup } from "@mui/material";
import { REPORT_LOGICAL_OPERATORS } from "../../../util/reports-utils";
import LogicalOperatorRadioButton from "./LogicalOperatorRadioButton";
import { useDispatch } from "react-redux";
import { setShouldExecute } from "../../../store/slices/reportsSlice";
import { CustomCombinatorSelectorFormControl } from "../../styles/reports/Reports.styles";

const CustomCombinatorSelector = ({ options, value, handleOnChange }) => {
  // General hooks
  const dispatch = useDispatch();

  // Handlers
  const handleChange = (e) => {
    handleOnChange(e.target.value);
    dispatch(setShouldExecute(false));
  };

  return (
    <CustomCombinatorSelectorFormControl>
      <RadioGroup
        id="logical-operator-radio-group"
        row
        aria-labelledby="logical-operator-radio-buttons-group-label"
        name="logical-operator-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => (
          <LogicalOperatorRadioButton
            key={option.name}
            logicalOperator={REPORT_LOGICAL_OPERATORS[option.name]}
          />
        ))}
      </RadioGroup>
    </CustomCombinatorSelectorFormControl>
  );
};

export default CustomCombinatorSelector;
