import {
  Box,
  FormControl,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import AppsTwoToneIcon from "@mui/icons-material/AppsTwoTone";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClearIcon from "@mui/icons-material/Clear";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";

export const StickySearchContainer = styled(Box)(({ theme }) => ({
  position: "sticky",
  zIndex: 50,
  top: 20,
  marginTop: "8px",
  backgroundColor: theme.palette.primary.contrastText,
}));

export const StickySearchIcon = styled(SearchIcon)(
  ({ theme, customfontsize }) => ({
    color: theme.palette.text.primary,
    fontSize: `${customfontsize}px`,
  })
);

export const StickySearchFormControl = styled(FormControl)(({ theme }) =>
  theme.unstable_sx({
    minWidth: "100%",
  })
);

export const ListColumnViewIcon = styled(ViewColumnIcon)(
  ({ theme, customfontsize }) => ({
    color: theme.palette.secondary.contrastText,
    fontSize: `${customfontsize}px`,
  })
);

export const ListInlineViewIcon = styled(FormatAlignLeftIcon)(
  ({ theme, currentpageview, customfontsize }) => ({
    ...(currentpageview === "inline"
      ? {
          color: theme.palette.secondary.contrastText,
          fontSize: `${customfontsize}px`,
        }
      : { color: theme.palette.text.primary, fontSize: `${customfontsize}px` }),
  })
);

export const ListGridViewIcon = styled(AppsTwoToneIcon)(
  ({ theme, currentpageview, customfontsize }) => ({
    ...(currentpageview === "app"
      ? { color: "#07ABF7", fontSize: `${customfontsize}px` }
      : { color: theme.palette.text.primary, fontSize: `${customfontsize}px` }),
  })
);

export const StickySearchListOptions = styled(List)({
  width: "100%",
});

export const StickySearchListOptionsCheckIcon = styled(CheckIcon)(
  ({ customfontsize }) => ({
    color: "#B0B0B0",
    fontSize: `${customfontsize}px`,
  })
);

export const StickySearchListOptionsHiddenCheckIcon = styled(CheckIcon)(
  ({ customfontsize }) => ({
    color: "#B0B0B0",
    visibility: "hidden",
    fontSize: `${customfontsize}px`,
  })
);

export const StickySearchStyledDialogListItemButton = styled(ListItemButton)(
  ({ theme }) => ({
    color: theme.palette.secondary.contrastText,
  })
);

export const StickySearchListItemText = styled(ListItemText)({
  display: "flex",
  justifyContent: "center",
});

export const SortingOptionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const SortingOptionArrowDown = styled(KeyboardArrowDownIcon)(
  ({ theme, customfontsize }) => ({
    color: theme.palette.secondary.contrastText,
    fontSize: `${customfontsize}px`,
  })
);

export const StickySearchTextField = styled(TextField)({
  marginTop: "-8px", // evaluated of margin-top of the TextField
});

export const StyledClearIcon = styled(ClearIcon)(
  ({ theme, customfontsize }) => ({
    color: theme.palette.secondary.contrastText,
    fontSize: `${customfontsize}px`,
  })
);

export const SortingOptionContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const SearchFieldContainer = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
