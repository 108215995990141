import { CardContent, Grid } from "@mui/material";
import React from "react";
import {
  CardContainer,
  FixedHeightCardMedia,
  TextBrokenWord,
} from "../styles/about/TeamMemberCard.styles";

const TeamMemberCard = ({ credit }) => {
  const { name, avatarUri } = credit;
  return (
    <Grid item>
      <CardContainer>
        <FixedHeightCardMedia
          image={`${process.env.PUBLIC_URL}/images/users/${avatarUri}`}
          title={name}
        />
        <CardContent>
          <TextBrokenWord gutterBottom variant="body1" component="div">
            {name}
          </TextBrokenWord>
        </CardContent>
      </CardContainer>
    </Grid>
  );
};

export default TeamMemberCard;
