import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextField, Box } from "@mui/material";
import {
  InputContainer,
  NamesGroupContainer,
  ValidationText,
} from "../styles/inputs/NamesGroup.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserData,
  setUserData,
} from "../../store/slices/onboardingSlice";
import { getTranslation, testInput } from "../../util/utils";
import { inputLabelProps } from "./BillingGroup";

const NamesGroup = ({ isDesktop, handleActionButtonDisabled }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // Selectors
  const userData = useSelector(selectUserData);

  // State
  const [firstNameValid, setFirstNameValid] = useState(true);
  const [lastNameValid, setLastNameValid] = useState(true);

  // Handlers
  const handleFirstNameChange = (event) => {
    const isValid = testInput.USER_NAME(event.target.value);

    dispatch(setUserData({ ...userData, firstName: event.target.value }));
    setFirstNameValid(isValid);

    if (handleActionButtonDisabled) {
      handleActionButtonDisabled(isValid);
    }
  };

  const handleLastNameChange = (event) => {
    const isValid = testInput.USER_NAME(event.target.value);

    dispatch(setUserData({ ...userData, lastName: event.target.value }));
    setLastNameValid(isValid);

    if (handleActionButtonDisabled) {
      handleActionButtonDisabled(isValid);
    }
  };

  return (
    <NamesGroupContainer>
      {/* First name input */}
      <InputContainer isDesktop={isDesktop}>
        <TextField
          inputProps={{ "data-testid": "first-name-input-onboarding" }}
          id="first-name-input-onboarding"
          label={getTranslation("firstName", t, i18n)}
          placeholder={getTranslation("firstName", t, i18n)}
          variant="outlined"
          value={userData.firstName}
          onChange={handleFirstNameChange}
          name="firstName"
          error={!firstNameValid}
          InputLabelProps={inputLabelProps}
          required
        />
        <Box>
          {!firstNameValid && (
            <ValidationText
              id="firstName-validation-text"
              variant="body5"
              align="center"
            >
              {getTranslation("userInfoValidation", t, i18n)}
            </ValidationText>
          )}
        </Box>
      </InputContainer>

      {/* Last name input */}
      <InputContainer>
        <TextField
          inputProps={{ "data-testid": "last-name-input-onboarding" }}
          id="last-name-input-onboarding"
          label={getTranslation("lastName", t, i18n)}
          placeholder={getTranslation("lastName", t, i18n)}
          variant="outlined"
          value={userData.lastName}
          onChange={handleLastNameChange}
          name="lastName"
          error={!lastNameValid}
          InputLabelProps={inputLabelProps}
          required
        />
        <Box>
          {!lastNameValid && (
            <ValidationText
              id="lastName-validation-text"
              variant="body5"
              align="center"
            >
              {getTranslation("userInfoValidation", t, i18n)}
            </ValidationText>
          )}
        </Box>
      </InputContainer>
    </NamesGroupContainer>
  );
};

export default NamesGroup;
