import { Fragment } from "react";
import ProfilePreferenceMenuItem from "./ProfilePreferenceItem";
import {
  OwnerIcon,
  SelectCheckBox,
} from "./styles/profile/Organization.styles";
import { StyledDivider } from "./styles/profile/Profile.styles";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectUser, setUser } from "../store/slices/authSlice";
import { useUpdateUserOrganizationMutation } from "../store/slices/api/userManagementSlice";
import useCheckOrganizationRestricted from "../hooks/useCheckOrganizationRestricted";
import { messageError, messageSuccess } from "../util/notification";
import { getTranslation, transitionDirections } from "../util/utils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  selectGlobalFontSize,
  setActiveLink,
  setOrganizationId,
} from "../store/slices/appSlice";
import { useMediaQuery } from "@mui/material";
import { VIEWPORT_MEDIA_QUERIES } from "../util/viewport-utils";

const OrganizationPageMenuItem = ({ org }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Mutations
  const [
    updateUserOrganization,
    { isLoading: isLoadingUpdateUserOrganization },
  ] = useUpdateUserOrganizationMutation();

  // Custom hooks
  const { isRestricted } = useCheckOrganizationRestricted(
    org,
    isLoadingUpdateUserOrganization
  );

  // Other variables
  const showHeader = mobileMatches;
  const orgName = org.nickname || org.name;
  const orgDisabled = !org.owner && isRestricted;

  // Handlers
  const handleChangeOrganization = async (organizationId) => {
    const newOrganizationId = organizationId;
    const newOrganizations = user?.organizations?.map((org) => {
      return org.id === newOrganizationId
        ? { ...org, default: true }
        : { ...org, default: false };
    });

    try {
      await updateUserOrganization({
        userId: user.id,
        newOrganizationId: newOrganizationId,
      }).unwrap();

      dispatch(
        setUser({
          ...user,
          defaultOrganizationId: newOrganizationId,
          organizations: newOrganizations,
        })
      );

      messageSuccess(
        getTranslation("successfulUpdateUserOrganization", t, i18n)
      );
    } catch (error) {
      messageError(getTranslation("failedUpdateUserOrganization", t, i18n));
    }
  };

  const handleOrganizationClick = (organizationId) => {
    if (showHeader) {
      navigate(
        "/profile/organization/" +
          organizationId +
          `?direction=${transitionDirections.RIGHT_TO_LEFT}`
      );
    } else {
      dispatch(setActiveLink("organization"));
      dispatch(setOrganizationId(organizationId));
    }
  };

  return (
    <Fragment>
      <ProfilePreferenceMenuItem
        disabled={orgDisabled}
        handleClick={() => handleOrganizationClick(org.id)}
        label={orgName}
        labelId="btn-organization-name"
        menuId="btn-menu-item-organization-name"
        icon={
          <SelectCheckBox
            data-testid={`switch-organization-${org.id}`}
            onClick={(e) => {
              e.stopPropagation();

              if (!isRestricted) {
                if (!org.default) {
                  handleChangeOrganization(org.id);
                }
              }
            }}
            checked={org.default}
            disabled={isRestricted}
          />
        }
        rightIcon={
          org.owner ? (
            <OwnerIcon
              globalFontSize={globalFontSize}
              onClick={() => handleOrganizationClick(org.id)}
            />
          ) : null
        }
        organizationId={org.id}
      />

      <StyledDivider />
    </Fragment>
  );
};

export default OrganizationPageMenuItem;
