import React from "react";
import { Grid, ListItemIcon, useMediaQuery } from "@mui/material";
import {
  ArrowRightIcon,
  Item,
  ItemContainer,
  ItemName,
  SecondaryText,
} from "../../styles/assets/ListInlineView.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { getTranslation, transitionDirections } from "../../../util/utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import {
  TypeItemContainer,
  TypeNameGridContainer,
} from "../../styles/types/type-list/TypeItem.styles";
import { NavigationActionIcon } from "../../styles/menu/Menu.styles";
import {
  LocalLibraryOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";

const getIconCreationType = (organizationId, globalFontSize) => {
  return organizationId ? (
    <NavigationActionIcon
      id="user-created-icon"
      className="material-icons material-icons-outlined"
      customfontsize={globalFontSize * 1.25}
    >
      person_outlined
    </NavigationActionIcon>
  ) : (
    <NavigationActionIcon
      id="system-created-icon"
      className="material-icons material-icons-outlined"
      customfontsize={globalFontSize * 1.25}
    >
      local_library
    </NavigationActionIcon>
  );
};

const getIconCreationTypeDesktop = (organizationId) => {
  return organizationId ? (
    <PersonOutlineOutlined
      id="user-created-icon"
      sx={{ width: 50, height: 50 }}
    />
  ) : (
    <LocalLibraryOutlined
      id="system-created-icon"
      sx={{ width: 50, height: 50 }}
    />
  );
};

const TypeItem = ({ type, lastIndex, currentTypeId, currentTypeHandler }) => {
  const { id, name, organizationId } = type;

  // General hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const creationType = organizationId
    ? getTranslation("USER_CREATED_TYPE", t, i18n)
    : getTranslation("SYSTEM_CREATED_TYPE", t, i18n);

  // Handlers
  const handleClick = () => {
    if (desktopMatches) {
      currentTypeHandler(id);
    } else {
      navigate(
        `/library/${id}/details?direction=${transitionDirections.RIGHT_TO_LEFT}`
      );
    }
  };

  return desktopMatches ? (
    <TypeItemContainer
      lastindex={lastIndex}
      selected={currentTypeId === id}
      onClick={handleClick}
    >
      <Grid py={1} container gap={1} alignItems="center">
        <Grid item alignItems="center" display="flex" position="relative">
          {getIconCreationTypeDesktop(organizationId)}
        </Grid>
        <TypeNameGridContainer item xs={7}>
          <ItemName isSelected={currentTypeId === id}>{name}</ItemName>
        </TypeNameGridContainer>
        <Grid item xs>
          <SecondaryText>{creationType}</SecondaryText>
        </Grid>
        <Grid item display="flex" alignItems="center">
          <ArrowRightIcon />
        </Grid>
      </Grid>
    </TypeItemContainer>
  ) : (
    <ItemContainer>
      <Item key={`resource-${id}`} onClick={handleClick}>
        <ListItemIcon>
          {getIconCreationType(organizationId, globalFontSize)}
        </ListItemIcon>
        <ItemName>{name}</ItemName>
        <ArrowRightIcon />
      </Item>
    </ItemContainer>
  );
};

export default TypeItem;
