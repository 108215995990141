import { Autocomplete, Box, TextField } from "@mui/material";
import { countries, getTranslation } from "../../util/utils";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const CountriesAutocomplete = ({ handleChange, value }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // State
  const [selected, setSelected] = useState(
    countries?.find((c) => c.code === value)
  );

  // Other variables
  const autocompleteValue = selected
    ? {
        value: selected?.code,
        label: getTranslation(selected?.label, t, i18n),
      }
    : null;

  // Handlers
  const handleIsOptionEqualToValue = (option, value) => {
    return option.code === value.code;
  };

  const handleAutocompleteChange = (event, newValue) => {
    handleChange(newValue?.code);
    setSelected(newValue);
  };

  useEffect(() => {
    if (value) {
      const country = countries.find((c) => c.code === value);

      setSelected(country);
    }
  }, [value]);

  return (
    <Autocomplete
      id="country-select"
      isOptionEqualToValue={handleIsOptionEqualToValue}
      value={autocompleteValue}
      onChange={handleAutocompleteChange}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...optionProps}
          >
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              alt=""
            />
            {option.label} ({option.code}) +{option.phone}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={getTranslation("COUNTRY", t, i18n)}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          required
        />
      )}
    />
  );
};

export default CountriesAutocomplete;
