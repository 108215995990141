import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectUser } from "../../../store/slices/authSlice";
import { useGetTypeImagesQuery } from "../../../store/slices/api/typesApiSlice";

import { getTranslation, ICON_NAMES } from "../../../util/utils";
import {
  AssetDetailCardMedia,
  AssetDetailCardMediaHeaderContainer,
  AssetDetailCardMediaHeaderTextContainer,
  AssetHeaderText,
} from "../../styles/assets/asset-detail/AssetDetailCard.styles";
import TypeCarousel from "./TypeCarousel";
import {
  FullWidthChip,
  TypeContainerText,
  TypeText,
} from "../../styles/types/type-details/TypeDetailCard.styles";
import { Grid, Stack } from "@mui/material";
import AddFavoriteType from "./AddFavoriteType";
import { PrimaryText } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import ErrorHandling from "../../common/ErrorHandling";

const TypeDetailCard = ({ typeData }) => {
  // General hooks
  const { typeId } = useParams();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const userDefaultOrganizationId = user?.organizations?.find(
    (o) => o.default
  )?.id;

  // Queries
  const {
    data: typeImagesData,
    isLoading,
    isError,
  } = useGetTypeImagesQuery({
    typeId: typeId,
    organizationId: userDefaultOrganizationId,
  });

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <>
        {typeImagesData?.length > 0 ? (
          <TypeCarousel typeImages={typeImagesData} />
        ) : (
          <AssetDetailCardMedia
            id="library-image-big"
            component="img"
            image={`${process.env.PUBLIC_URL}/icons/${ICON_NAMES["ICN-EXPERT-RESOURCES"]}`}
          />
        )}

        <AssetDetailCardMediaHeaderContainer>
          <AssetDetailCardMediaHeaderTextContainer>
            <TypeContainerText direction="row">
              <TypeText id="type-name" align="center">
                {typeData?.name}
              </TypeText>
              <AddFavoriteType typeId={typeId} />
            </TypeContainerText>

            <FullWidthChip
              label={
                typeData.organizationId
                  ? getTranslation("USER_CREATED_TYPE", t, i18n)
                  : getTranslation("SYSTEM_CREATED_TYPE", t, i18n)
              }
            />
          </AssetDetailCardMediaHeaderTextContainer>
        </AssetDetailCardMediaHeaderContainer>
      </>
    </ErrorHandling>
  );
};

export default TypeDetailCard;
