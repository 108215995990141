import { Box, Container } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../util/utils";
import {
  LegalNoticesGridContainer,
  SpacedTitleSection,
} from "../styles/about/About.styles";
import TechnologyCard from "./TechnologyCard";

const DesktopThirdPartyNotices = () => {
  // General hooks
  const { t, i18n } = useTranslation();

  return (
    <Container maxWidth="lg">
      <SpacedTitleSection variant="h1">
        {getTranslation("THIRD_PARTY_NOTICES", t, i18n)}
      </SpacedTitleSection>
      <LegalNoticesGridContainer container spacing={2}>
        <TechnologyCard
          category="STATE_MANAGEMENT"
          name="Redux Toolkit"
          link="https://redux.js.org/"
        />

        <TechnologyCard
          category="NAVIGATION"
          name="React Router"
          link="https://reactrouter.com/en/main"
        />

        <TechnologyCard
          category="TRANSLATION"
          name="react-i18next"
          link="https://react.i18next.com/"
        />

        <TechnologyCard
          category="INFINITE_SCROLL"
          name="react-infinite-scroll-component"
          link="https://github.com/ankeetmaini/react-infinite-scroll-component#readme"
        />

        <TechnologyCard
          category="STYLING"
          name="Material UI"
          link="https://mui.com/"
        />

        <TechnologyCard
          category="CHARTS"
          name="react-chartjs-2"
          link="https://react-chartjs-2.js.org/"
        />

        <TechnologyCard
          category="TOASTS"
          name="React-Toastify"
          link="https://github.com/fkhadra/react-toastify#readme"
        />

        <TechnologyCard
          category="OVERLAY"
          name="React Spinner Overlay"
          link="https://arisaokasaka.github.io/react-spinner-overlay/"
        />

        <TechnologyCard
          category="CAROUSEL"
          name="React Responsive Carousel"
          link="https://www.npmjs.com/package/react-responsive-carousel"
        />

        <TechnologyCard
          category="TESTING"
          name="React Testing Library"
          link="https://testing-library.com/"
        />
      </LegalNoticesGridContainer>
    </Container>
  );
};

export default DesktopThirdPartyNotices;
