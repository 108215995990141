import { useEffect, useState } from "react";
import { selectUser } from "../../store/slices/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import LoadingSpinnerOverlay from "./LoadingSpinnerOverlay";
import { messageWarning } from "../../util/notification";
import { handleCheckCurrentSubscription } from "../../util/utils";

const Layer2Access = ({ children }) => {
  // General hooks
  const navigate = useNavigate();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const user = useSelector(selectUser);

  // State
  const [loading, setLoading] = useState(true);

  const handleResponsiveRedirect = () => {
    if (mobileMatches) {
      navigate("/more");
    } else {
      navigate("/profile");
    }
  };

  const handleRedirection = async () => {
    if (user?.id) {
      const organizations = user?.organizations;

      if (!organizations || organizations.length <= 0) {
        handleResponsiveRedirect();
      } else {
        const activeOrganization = organizations?.find((o) => o.default);

        if (activeOrganization?.restricted) {
          messageWarning("Your access to the organization is restricted", {
            toastId: "organization-access-restricted",
          });

          handleResponsiveRedirect();
        } else {
          let hasSubscription = true;

          if (activeOrganization?.owner) {
            hasSubscription = await handleCheckCurrentSubscription(user);
          }

          if (!hasSubscription) {
            messageWarning("No active subscription found", {
              toastId: "no-active-subscription",
            });

            handleResponsiveRedirect();
          }
        }
      }

      setLoading(false);
    }
  };

  // Effects
  useEffect(() => {
    handleRedirection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, user?.organizations?.length]);

  if (loading) {
    return <LoadingSpinnerOverlay />;
  }

  return <>{children}</>;
};

export default Layer2Access;
