import { createSelector, createSlice } from "@reduxjs/toolkit";
import { getOppositeAction } from "../../util/utils";

export const DEFAULT_MULTIPLIER = 0.25;
export const STEPS = 1;

// For now, `resourceId` is not used
// `undo` is used to store all of the past changes (changes to be undone). It stores an array of objects with the following signature: `{ action: 'CREATE' | 'UPDATE' | 'DELETE', payload: any }`. Normally, the payload stores the information about a graphical object
// `redo` is used to store all of the future changes (changes to be redone). It stores an array of objects with the same signature as the objects in `undo` array
// `constant` is just used as a dependency in a `useEffect`
// `event` stores the type of the action we've performed - the value of this property can be either `undo` or `redo` for now
export const DEFAULT_UNDO_REDO_STATE = {
  resourceId: "",
  undo: [],
  redo: [],
  constant: 1,
  event: "",
};

// Initial state
const initialState = DEFAULT_UNDO_REDO_STATE;

// Slice
const undoRedoGraphicalViewSlice = createSlice({
  name: "undoRedoGraphicalView",
  initialState,
  reducers: {
    // Not used for now
    setResourceId: (state, action) => {
      const newResourceId = action.payload;
      state.resourceId = newResourceId;
    },
    // It performs an 'undo' action
    undo: (state, action) => {
      // We get the last element from `undo` array and remove it
      const lastElement = state.undo.pop();

      // If payload is not null (this case is useful when we want to undo 'UPDATE' actions)
      if (action.payload) {
        // We are transferring the undone change as the first element of `redo` array
        // Action is turned into its opposite. If it is 'CREATE', it becomes 'DELETE' and vice versa. 'UPDATE' remains the same
        // New payload is assigned
        // The rest of `redo` array is appended
        state.redo = [
          {
            ...lastElement,
            action: getOppositeAction(lastElement.action),
            payload: action.payload,
          },
          ...state.redo,
        ];
      } else {
        // Same logic as above
        // Only difference is that we don't set any payload here, we use the existing one coming from `lastElement`
        state.redo = [
          { ...lastElement, action: getOppositeAction(lastElement.action) },
          ...state.redo,
        ];
      }
    },
    // It performs 'redo' action
    // Process is same as `undo`
    // Differences are that from `redo` we take the first element and we append it to the end of `undo`
    redo: (state, action) => {
      const firstElement = state.redo.shift();

      if (action.payload) {
        state.undo = [
          ...state.undo,
          {
            ...firstElement,
            action: getOppositeAction(firstElement.action),
            payload: action.payload,
          },
        ];
      } else {
        state.undo = [
          ...state.undo,
          { ...firstElement, action: getOppositeAction(firstElement.action) },
        ];
      }
    },
    // New change is added to `undo` and `redo` is reset
    updatePresent: (state, action) => {
      state.undo = [...state.undo, action.payload];
      state.redo = [];
    },
    // Whole state is reset
    reset: (state) => {
      state.resourceId = DEFAULT_UNDO_REDO_STATE.resourceId;
      state.undo = DEFAULT_UNDO_REDO_STATE.undo;
      state.redo = DEFAULT_UNDO_REDO_STATE.redo;
      state.constant = DEFAULT_UNDO_REDO_STATE.constant;
      state.event = DEFAULT_UNDO_REDO_STATE.event;
    },
    // Used to notify the component we handle the mutation logic that `redo` has happened
    increaseConstant: (state) => {
      state.constant += DEFAULT_MULTIPLIER;
      state.event = "redo";
    },
    // Used to notify the component we handle the mutation logic that `undo` has happened
    decreaseConstant: (state) => {
      state.constant -= DEFAULT_MULTIPLIER;
      state.event = "undo";
    },
    // Updates all objects' ids in both `undo` and `redo` by a given ids (the old ids)
    // Used in scenarios when we create a new object and we need to update all the references because ids have changed
    updateHistory: (state, action) => {
      // ids is the old ones, payload new ones
      const { ids } = action.payload;

      // If there are references in `undo`
      state.undo = state.undo.map((u) => {
        const element = u;
        const newPayload = element.payload.map((object) => {
          const id = ids.find((i) => i.oldId === object.id);

          return id
            ? {
                ...object,
                id: id.newId,
              }
            : object;
        });

        return {
          ...element,
          payload: newPayload,
        };
      });

      // Same logic for `redo`

      state.redo = state.redo.map((r) => {
        const element = r;
        const newPayload = element.payload.map((object) => {
          const id = ids.find((i) => i.oldId === object.id);
          return id
            ? {
                ...object,
                id: id.newId,
              }
            : object;
        });

        return {
          ...element,
          payload: newPayload,
        };
      });
    },
    // For defining current event
    setEvent: (state, action) => {
      const newEvent = action.payload;
      state.event = newEvent;
    },
  },
});

// Export slice and its corresponding actions
export default undoRedoGraphicalViewSlice.reducer;

export const {
  setResourceId,
  undo,
  redo,
  updatePresent,
  reset,
  increaseConstant,
  decreaseConstant,
  updateHistory,
  setEvent,
} = undoRedoGraphicalViewSlice.actions;

// Selectors

// Selects the last element from `undo`
export const selectLastUndo = (state) =>
  state.undoRedoGraphicalView.undo.length > 0
    ? state.undoRedoGraphicalView.undo[
        state.undoRedoGraphicalView.undo.length - 1
      ]
    : null;

// Selects the first element from `redo`
export const selectFirstRedo = (state) =>
  state.undoRedoGraphicalView.redo.length > 0
    ? state.undoRedoGraphicalView.redo[0]
    : null;

// Selects `constant`
export const selectConstant = (state) => state.undoRedoGraphicalView.constant;

// Selects `event`
export const selectEvent = (state) => state.undoRedoGraphicalView.event;
