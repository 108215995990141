import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../../store/slices/authSlice";
import {
  useGetUserProfileImageQuery,
  useUserRolePermissionsQuery,
} from "../../../store/slices/api/userManagementSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  transitionDirections,
  userLocalStorageKey,
} from "../../../util/utils";
import {
  BackPageTitle,
  HeaderGridContainer,
  HeaderWrapper,
  StyledAvatar,
} from "../../../components/styles/header/Header.styles";
import { Grid, Link, Stack, useMediaQuery } from "@mui/material";
import AssetsImportExport from "../../../components/assets/asset-list/AssetsImportExport";
import { NavigationActionIcon } from "../../../components/styles/menu/Menu.styles";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import KeycloakService from "../../../services/KeycloakService";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";

const HomeHeader = () => {
  // General hooks
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Queries
  const { data: selectedFile } = useGetUserProfileImageQuery(
    {
      avatarUri: user?.avatarUri,
    },
    {
      skip: !Boolean(user?.avatarUri),
    }
  );

  const { data: userRoles, isLoading: isLoadingUserRoles } =
    useUserRolePermissionsQuery({
      userId: user.id,
      organizationId:
        organizationId ?? user?.organizations?.find((o) => o.default).id,
    });

  // Handlers
  const onAvatarClickHandler = () => {
    navigate(`/profile?direction=${transitionDirections.RIGHT_TO_LEFT}`);
  };

  const onLogoutClickHandler = () => {
    KeycloakService.doLogout();
    localStorage.removeItem("keycloak-initialized");
    localStorage.removeItem(userLocalStorageKey("app-initialized"));
  };

  return (
    <HeaderWrapper id="sticky-header">
      <HeaderGridContainer container>
        <Grid item xs>
          <BackPageTitle variant="h4" id="title">
            {getTranslation("home", t, i18n)}
          </BackPageTitle>
        </Grid>
        <Grid item>
          <Stack spacing={2} direction="row" alignItems="center">
            {hasAccess(
              "oneOf",
              [
                permissions.ASSET_MANAGEMENT_HARDWARE_ADD,
                permissions.ASSET_MANAGEMENT_RACK_ADD,
                permissions.ASSET_MANAGEMENT_LOCATION_ADD,
              ],
              getPermissionsFromUserRoles(userRoles)
            ) && <AssetsImportExport />}
            {tabletMatches && (
              <Link id="logout-btn" onClick={onLogoutClickHandler}>
                <NavigationActionIcon
                  active={true}
                  customfontsize={globalFontSize}
                >
                  logout
                </NavigationActionIcon>
              </Link>
            )}
            <StyledAvatar
              id="avatar"
              src={selectedFile}
              onClick={onAvatarClickHandler}
            >
              {user?.shortUsername}
            </StyledAvatar>
          </Stack>
        </Grid>
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default HomeHeader;
