import { styled } from "@mui/system";
import { Chip, Stack } from "@mui/material";
import { PrimaryText } from "../../assets/asset-detail/AssetDetailBasicInfo.styles";

export const FullWidthChip = styled(Chip)({
  width: "100%",
});

export const TypeText = styled(PrimaryText)({
  flexShrink: "1",
  marginInline: "8px",
});

export const TypeContainerText = styled(Stack)({
  overflow: "hidden",
  justifyContent: "space-between",
  alignItems: "center",
});
