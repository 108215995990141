import { Box, Grid, Paper, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const ThemePaper = styled(Paper)(
  ({ active, theme, backgroundcolor }) => ({
    paddingBottom: "8px",
    boxShadow: active
      ? `0px 0px 0px 4px ${theme.palette.secondary.contrastText}`
      : "",
    backgroundColor: backgroundcolor,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.6,
    },
    border: active ? "" : "1px solid #f5f5f5",
  })
);

export const AutomaticThemePaper = styled(Paper)(({ theme, active }) => ({
  height: "122px",
  boxShadow: active
    ? `0px 0px 0px 4px ${theme.palette.secondary.contrastText}`
    : "",
  background: "linear-gradient(to bottom left, #fff 50%, #000  50%)",
  cursor: "pointer",
  "&:hover": {
    opacity: 0.6,
  },
  border: active ? "" : "1px solid #f5f5f5",
}));

export const HeaderBlockCard = styled(Box)(({ backgroundcolor }) => ({
  width: "100%",
  height: "32px",
  backgroundColor: backgroundcolor,
}));

export const AutomaticHeaderBlockCard = styled(Box)({
  width: "100%",
  height: "32px",
  background:
    "linear-gradient(135deg, rgba(0,0,0,1) 70%, rgba(255,255,255,1) 50%)",
});

export const ContentContainer = styled(Stack)(({ backgroundcolor }) => ({
  marginInline: "8px",
  marginTop: "8px",
  "& .element": {
    backgroundColor: backgroundcolor,
  },
}));

export const GridBlockCard = styled(Grid)({
  borderRadius: "4px",
  height: "12px",
});

export const BiggerGridBlockCard = styled(Grid)({
  borderRadius: "4px",
  height: "32px",
});

export const TextBlockCard = styled(Box)({
  borderRadius: "2px",
  height: "6px",
});

export const LogoIcon = styled("img")(({ customfontsize }) => ({
  width: customfontsize * 1.5,
  height: customfontsize * 1.5,
  padding: "4px",
}));
