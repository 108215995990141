import { Avatar, Box, Container, Divider, Grid, Paper } from "@mui/material";
import { styled } from "@mui/system";
import { ListItemContainer } from "../assets/asset-detail/AssetDetail.styles";
import { SecondaryText } from "../assets/asset-detail/AssetDetailBasicInfo.styles";
import { PrimaryText } from "../assets/ListGridView.styles";
import { StyledDivider } from "../profile/Profile.styles";

export const StyledListItemContainer = styled(ListItemContainer)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

export const StyledAvatar = styled(Avatar)({
  width: "50px",
  height: "50px",
});

export const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

export const StyledPrimaryText = styled(PrimaryText)({
  maxWidth: `${window.outerWidth - 100}px`,
});

export const AboutStyledDivider = styled(StyledDivider)({
  marginBlock: "5px",
});

export const AboutPrimaryText = styled(PrimaryText)({
  paddingBlock: "15px",
});

export const FooterWrapper = styled(Paper)({
  marginTop: "24px",
  position: "sticky",
  left: 0,
  right: 0,
});

export const SpacedContainer = styled(Container)({
  padding: "16px",
});

export const SpacedTitleSection = styled(SecondaryText)({
  fontWeight: "bold",
  marginBlock: "32px",
});

export const LegalNoticesGridContainer = styled(Grid)({
  marginBottom: "64px",
});

export const FooterDivider = styled(Divider)({
  marginBlock: "32px",
});

export const VersionCard = styled(Box)(({ theme }) => ({
  borderLeft: "1px solid",
  borderColor: theme.palette.divider,
  padding: "16px",
  minHeight: "125px",
  display: "flex",
  alignItems: "center",
}));
