import { styled } from "@mui/system";
import { Box, Tab, Tabs } from "@mui/material";

export const MobileHeaderContainer = styled(Box)(({ mobiletabvalue }) => ({
  overflowY: mobiletabvalue === 1 ? "hidden" : "scroll",
  overflowX: "hidden",
}));

export const TabsWrapper = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.primary.dark}`,
}));

export const TabsContainer = styled(Tabs)({
  ".MuiTabs-flexContainer": {
    gap: "8px",
  },
});

const StyledTab = styled(Tab)(({ theme, active }) => ({
  border: !active ? `1px solid ${theme.palette.primary.dark}` : "",
  backgroundColor: active ? "#7E7E7E" : "",
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
  "&.MuiTab-textColorPrimary": {
    color: active
      ? theme.palette.primary.contrastText
      : theme.palette.primary.dark,
  },
  textTransform: "none",
}));

export const HalfSizeTab = styled(StyledTab)({
  flexBasis: "50%",
});

export const QuarterSizeTab = styled(StyledTab)({
  flexBasis: "25%",
});
