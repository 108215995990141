import { useSelector } from "react-redux";
import ErrorHandling from "../../components/common/ErrorHandling";
import Footer from "../../components/Footer";
import MainContent from "../../components/MainContent";
import MobileSteps from "../../components/MobileSteps";
import {
  OnboardingFullContainer,
  OnboardingSteppedContentContainer,
} from "../../components/styles/onboarding/OnboardingStepped.styles";
import {
  selectContinueBtnName,
  selectUserData,
  selectUserOnboardingSteps,
  setContinueBtnName,
  setUserData,
} from "../../store/slices/onboardingSlice";
import {
  useOnboardUserMutation,
  useUploadProfileImageMutation,
} from "../../store/slices/api/userManagementSlice";
import { useEffect, useState } from "react";
import {
  testInput,
  transitionDirections,
  userLocalStorageKey,
} from "../../util/utils";
import { useDispatch } from "react-redux";
import { refreshUser, selectUser, setUser } from "../../store/slices/authSlice";
import { setLanguage } from "../../store/slices/appSlice";
import { useNavigate } from "react-router-dom";

const UserOnboardingPage = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selectors
  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);
  const userOnboardingSteps = useSelector(selectUserOnboardingSteps);
  const continueBtnName = useSelector(selectContinueBtnName);

  // Mutations
  const [onboardUser, { isLoading: isLoadingOnboardUser }] =
    useOnboardUserMutation();

  const [uploadImage, { isLoading: isLoadingUploadImage }] =
    useUploadProfileImageMutation();

  // State
  const [activeStep, setActiveStep] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [transitionDirection, setTransitionDirection] = useState(
    transitionDirections.RIGHT_TO_LEFT
  );

  // Handlers
  const submitUploadedImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const resp = await uploadImage({ formData, userId: user.id }).unwrap();
      dispatch(setUser({ ...user, avatarUri: resp.avatarUri }));

      return resp.avatarUri;
    } catch (error) {
      console.error("Failed to upload user profile image", error);
    }
  };

  const handleOnboardUser = async () => {
    try {
      const data = await onboardUser({
        firstName: userData.firstName,
        lastName: userData.lastName,
        region: userData.region,
        timeZone: userData.timeZone,
        language: userData.language,
        dataStorageLocationId: userData.dataStorageLocationId,
      }).unwrap();

      if (data) {
        if (userData.file) {
          const avatarUri = await submitUploadedImage(userData.file);
          dispatch(refreshUser({ ...data, avatarUri }));
        } else {
          dispatch(refreshUser({ ...data, avatarUri: user?.avatarUri }));
        }

        const organizations = data.organizations;

        if (!organizations || organizations.length <= 0) {
          navigate("/organization-onboarding");
          return;
        } else {
          dispatch(setLanguage(userData.language));
          navigate("/");

          localStorage.setItem(
            userLocalStorageKey("app-initialized"),
            JSON.stringify(true)
          );
        }
      }
    } catch (error) {
      console.error("Failed to onboard user", error);
    }
  };

  const handleNext = async () => {
    if (activeStep === 2) {
      await handleOnboardUser();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    setTransitionDirection(transitionDirections.RIGHT_TO_LEFT);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
    dispatch(setContinueBtnName("continueButton"));
    setTransitionDirection(transitionDirections.LEFT_TO_RIGHT);
  };

  const handleCurrentUserData = () => {
    const currentUser = user;

    const email = currentUser?.email ?? "";
    const firstName = currentUser?.firstName ?? "";
    const lastName = currentUser?.lastName ?? "";
    const avatarUri = currentUser?.avatarUri ?? "";

    dispatch(
      setUserData({
        ...userData,
        email,
        firstName,
        lastName,
        avatarUri,
        language:
          currentUser.language ||
          localStorage
            .getItem(userLocalStorageKey("language"))
            ?.split("-")[0] ||
          userData.language,
      })
    );
  };

  const handleUserNameSurname = () => {
    const isFirstNameValid = testInput.USER_NAME(userData.firstName ?? "");
    const isLastNameValid = testInput.USER_NAME(userData.lastName ?? "");

    if (isFirstNameValid && isLastNameValid) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  const handleUserLocationCredential = () => {
    const { language, region, timeZone } = userData;
    const isValid = language && region && timeZone;

    setIsButtonDisabled(!isValid);
  };

  const handleUserDataStorageLocation = () => {
    const isValid = userData.dataStorageLocationId;

    setIsButtonDisabled(!isValid);
  };

  // Effects
  useEffect(() => {
    handleCurrentUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeStep === 0 || activeStep === 1) {
      dispatch(setContinueBtnName("continueButton"));
    } else {
      dispatch(setContinueBtnName("CREATE_ACCOUNT_AND_CONTINUE"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    if (activeStep === 0) {
      handleUserNameSurname();
    } else if (activeStep === 1) {
      handleUserLocationCredential();
    } else if (activeStep === 2) {
      handleUserDataStorageLocation();
    } else {
      setIsButtonDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userData.firstName,
    userData.lastName,
    userData.language,
    userData.region,
    userData.timeZone,
    userData.dataStorageLocationId,
    activeStep,
  ]);

  return (
    <ErrorHandling
      isLoading={isLoadingOnboardUser || isLoadingUploadImage}
      isError={false}
    >
      <OnboardingFullContainer>
        <MobileSteps
          stepQuantity={userOnboardingSteps}
          activeStep={activeStep}
          handleBack={handleBack}
        />

        <OnboardingSteppedContentContainer>
          <MainContent
            activeStep={activeStep}
            transitionDirection={transitionDirection}
            type="user-onboarding"
          />

          <Footer
            activeStep={activeStep}
            isButtonDisabled={isButtonDisabled}
            handleNext={handleNext}
            continueBtnName={continueBtnName}
          />
        </OnboardingSteppedContentContainer>
      </OnboardingFullContainer>
    </ErrorHandling>
  );
};

export default UserOnboardingPage;
