import { createSelector, createSlice } from "@reduxjs/toolkit";
import { DEFAULT_INDEX } from "./appSlice";

// Initial state
const initialState = {
  typeSearchTerm: "",
  typeQuery: "",
  category: "",
  function: "",
  types: [],
  index: DEFAULT_INDEX,
  favoritesIndex: DEFAULT_INDEX,
  recentsIndex: DEFAULT_INDEX,
  isSearchOpen: false,
};

export const TYPES_SORTING_OPTIONS = [
  {
    value: "A-Z",
    order: "asc",
    type: "name",
  },
  {
    value: "Z-A",
    order: "desc",
    type: "name",
  },
  {
    value: "FAVOURED",
    order: "desc",
    type: "favoured",
  },
];

export const DESKTOP_TYPES_SORTING_OPTIONS = [
  {
    value: "NAME",
    order: "asc",
    type: "name",
  },
  {
    value: "FAVOURED",
    order: "asc",
    type: "favoured",
  },
];

export const FAVORITE_TYPES_SORTING_OPTIONS = [
  {
    label: "From A to Z",
    value: "A-Z",
    order: "asc",
    type: "displayIdAndNameCombination",
  },
  {
    label: "From Z to A",
    value: "Z-A",
    order: "desc",
    type: "displayIdAndNameCombination",
  },
  {
    label: "Favoured",
    value: "FAVOURED",
    order: "desc",
    type: "favoured",
  },
];

export const RECENTS_TYPES_SORTING_OPTIONS = [
  {
    label: "From A to Z",
    value: "A-Z",
    order: "asc",
    type: "displayIdAndNameCombination",
  },
  {
    label: "From Z to A",
    value: "Z-A",
    order: "desc",
    type: "displayIdAndNameCombination",
  },
];

export const TYPE_DEFAULT_SORTING_OPTION = TYPES_SORTING_OPTIONS[0];

// Slice
const typeSearchSlice = createSlice({
  name: "typeSearch",
  initialState,
  reducers: {
    setIsSearchOpen: (state, action) => {
      const newIsSearchOpen = action.payload;
      state.isSearchOpen = newIsSearchOpen;
    },
    setTypeSearchTerm: (state, action) => {
      const newTypeSearchTerm = action.payload;
      state.typeSearchTerm = newTypeSearchTerm;
    },
    setTypeQuery: (state, action) => {
      const newQuery = action.payload;
      state.typeQuery = newQuery;
    },
    setCategory: (state, action) => {
      const newCategory = action.payload;
      state.category = newCategory;
    },
    setFunction: (state, action) => {
      const newFunction = action.payload;
      state.function = newFunction;
    },
    setTypes: (state, action) => {
      const newTypes = action.payload;
      state.types = newTypes;
    },
    setIndex: (state, action) => {
      const newIndex = action.payload;
      state.index = newIndex;
    },
    setFavoritesIndex: (state, action) => {
      const newIndex = action.payload;
      state.favoritesIndex = newIndex;
    },
    setRecentsIndex: (state, action) => {
      const newIndex = action.payload;
      state.recentsIndex = newIndex;
    },
  },
});

// Export slice and its corresponding actions
export default typeSearchSlice.reducer;
export const {
  setIsSearchOpen,
  setTypeSearchTerm,
  setTypeQuery,
  setCategory,
  setFunction,
  setTypes,
  setIndex,
  setFavoritesIndex,
  setRecentsIndex,
} = typeSearchSlice.actions;

// Selectors
export const selectIsSearchOpen = (state) => state.typeSearch.isSearchOpen;
export const selectTypeSearchTerm = (state) => state.typeSearch.typeSearchTerm;
export const selectTypeQuery = (state) => state.typeSearch.typeQuery;
export const selectCategory = (state) => state.typeSearch.category;
export const selectFunction = (state) => state.typeSearch.function;
export const selectTypes = (state) => state.typeSearch.types;
export const selectIndex = (state) => state.typeSearch.index;
export const selectFavoritesIndex = (state) => state.typeSearch.favoritesIndex;
export const selectRecentsIndex = (state) => state.typeSearch.recentsIndex;

export const selectTypesByNameAsc = createSelector(
  [selectTypes, (types, name) => name],
  (types, name) => {
    return types
      .filter((type) => type.name.toLowerCase().includes(name.toLowerCase()))
      .slice()
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else {
          return -1;
        }
      });
  }
);

export const selectTypesByNameDesc = createSelector(
  [selectTypes, (types, name) => name],
  (types, name) => {
    return types
      .filter((type) => type.name.toLowerCase().includes(name.toLowerCase()))
      .slice()
      .sort((a, b) => {
        if (a.name < b.name) {
          return 1;
        } else {
          return -1;
        }
      });
  }
);
