import React from "react";
import {
  WIDGET_CATEGORY,
  selectAggregations,
  selectQuery,
  selectResultFields,
  selectTypeId,
} from "../../../store/slices/reportsSlice";
import LinkWidgetForm from "./categorized-widget-forms/LinkWidgetForm";
import CountWidgetForm from "./categorized-widget-forms/CountWidgetForm";
import MinMaxAverageWidgetForm from "./categorized-widget-forms/MinMaxAverageWidgetForm";
import PercentageWidgetForm from "./categorized-widget-forms/PercentageWidgetForm";
import StatusWidgetForm from "./categorized-widget-forms/StatusWidgetForm";
import PieChartWidgetForm from "./categorized-widget-forms/PieChartWidgetForm";
import DonutChartWidgetForm from "./categorized-widget-forms/DonutChartWidgetForm";
import LineChartWidgetForm from "./categorized-widget-forms/LineChartWidgetForm";
import BarChartWidgetForm from "./categorized-widget-forms/BarChartWidgetForm";
import FunnelChartWidgetForm from "./categorized-widget-forms/FunnelChartWidgetForm";
import SumChartWidgetForm from "./categorized-widget-forms/SumWidgetForm";
import AreaChartWidgetForm from "./categorized-widget-forms/AreaChartWidgetForm";
import { useReportExecutionBaseQuery } from "../../../store/slices/api/reportsApiSlice";
import { parseQuery } from "../../../util/reports-utils";
import { selectUser } from "../../../store/slices/authSlice";
import { useSelector } from "react-redux";

const WidgetForm = ({ category }) => {
  // Selectors
  const user = useSelector(selectUser);
  const query = useSelector(selectQuery);
  const resultFields = useSelector(selectResultFields);
  const typeId = useSelector(selectTypeId);
  const aggregations = useSelector(selectAggregations);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  const fields = [
    ...resultFields.map((field) => {
      return {
        field: {
          name: field.field,
        },
        usedForGrouping: field.usedForGrouping,
      };
    }),
    ...aggregations.map((aggregation) => {
      return {
        field: {
          name: aggregation.field,
        },
        usedForGrouping: false,
      };
    }),
  ];

  const filters = [parseQuery(query)];

  const finalAggregations = aggregations.map((aggregation) => {
    return {
      field: {
        name: aggregation.field,
      },
      aggregationType: aggregation.aggregationType,
      filters: aggregation.filters,
    };
  });

  // Queries
  const { data: reportExecutionData } = useReportExecutionBaseQuery({
    organizationId,
    typeId,
    fields,
    filters,
    aggregations: finalAggregations,
  });

  return (
    <>
      {/* Line report (without grouping)*/}
      {category === WIDGET_CATEGORY.LINK && <LinkWidgetForm />}
      {category === WIDGET_CATEGORY.COUNT && <CountWidgetForm />}
      {category === WIDGET_CATEGORY.MIN_MAX_AVERAGE && (
        <MinMaxAverageWidgetForm />
      )}
      {category === WIDGET_CATEGORY.PERCENTAGE && <PercentageWidgetForm />}
      {category === WIDGET_CATEGORY.STATUS && <StatusWidgetForm />}
      {category === WIDGET_CATEGORY.SUM && <SumChartWidgetForm />}

      {/* Summary report (with grouping)*/}
      {category === WIDGET_CATEGORY.PIE_CHART && (
        <PieChartWidgetForm rows={reportExecutionData?.data} />
      )}
      {category === WIDGET_CATEGORY.DONUT_CHART && (
        <DonutChartWidgetForm rows={reportExecutionData?.data} />
      )}
      {category === WIDGET_CATEGORY.LINE_CHART && (
        <LineChartWidgetForm rows={reportExecutionData?.data} />
      )}
      {category === WIDGET_CATEGORY.BAR_CHART && (
        <BarChartWidgetForm rows={reportExecutionData?.data} />
      )}
      {category === WIDGET_CATEGORY.FUNNEL_CHART && (
        <FunnelChartWidgetForm rows={reportExecutionData?.data} />
      )}
      {category === WIDGET_CATEGORY.AREA_CHART && (
        <AreaChartWidgetForm rows={reportExecutionData?.data} />
      )}
    </>
  );
};

export default WidgetForm;
