import { styled } from "@mui/system";
import { alpha } from "@mui/material";
import { Item } from "../../assets/ListInlineView.styles";
import { NavigationActionIcon } from "../../menu/Menu.styles";
import { Grid } from "@mui/material";

export const TypeItemContainer = styled(Item)(
  ({ theme, selected, lastindex }) => ({
    backgroundColor: selected ? alpha("#0076bc", 0.1) : "",
    ":hover": {
      backgroundColor: selected ? alpha("#0076bc", 0.2) : alpha("#000", 0.04),
    },
    borderBottom: !lastindex ? `1px solid ${theme.palette.divider}` : "",
  })
);

export const FavoriteIcon = styled(NavigationActionIcon)({
  position: "absolute",
  bottom: "-25%",
  right: `-10%`,
});

export const TypeNameGridContainer = styled(Grid)({
  overflow: "hidden",
});
