import { Grid, Link, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../util/utils";

const TechnologyCard = ({ name, category, link }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  return (
    <Grid item md={3}>
      <Typography align="start">{getTranslation(category, t, i18n)}</Typography>
      <Link href={link} underline="always" target="_blank">
        <Typography align="start">{name}</Typography>
      </Link>
    </Grid>
  );
};

export default TechnologyCard;
