import { get_current_subscription_url } from "../Constants";
import HttpService from "./HttpService";

export const fetchCurrentSubscription = async (organizationId) => {
  let url = get_current_subscription_url.replace(
    ":organizationId",
    organizationId
  );
  var config = { method: "GET", url };
  let result = await HttpService.sendRequest(config);
  return result;
};
