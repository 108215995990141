import { Box, CircularProgress, Stack } from "@mui/material";
import React from "react";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";

const PercentageWidgetContent = ({ percentage, color }) => {
  return (
    <Stack sx={{ flexGrow: 1, alignItems: "center", justifyContent: "center" }}>
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress
          variant="determinate"
          value={percentage}
          sx={{
            color,
          }}
        />
        <Box
          sx={{
            inset: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SecondaryText variant="caption" component="div">
            {`${percentage}%`}
          </SecondaryText>
        </Box>
      </Box>
    </Stack>
  );
};

export default PercentageWidgetContent;
