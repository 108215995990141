import {
  ActionTitle,
  HeaderGridContainer,
  HeaderWrapper,
} from "../../../components/styles/header/Header.styles";
import { Grid, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import { useSelector } from "react-redux";
import { selectPageInfo } from "../../../store/slices/appSlice";
import { RouletteSpinner } from "react-spinner-overlay";
import LoadingSpinner from "../../../components/common/LoadingSpinner";

const ChooseAssetImageHeader = ({
  handleAction,
  handleCancelAction,
  isDisabled,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const currentPageInfo = useSelector(selectPageInfo);

  return (
    <HeaderWrapper id="sticky-header">
      <HeaderGridContainer container>
        <Grid item xs={3}>
          <ActionTitle
            tabIndex="0"
            data-testid="action-title-cancel"
            id="action-title-cancel"
            onClick={handleCancelAction}
          >
            {getTranslation("CANCEL", t, i18n)}
          </ActionTitle>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid display="flex" justifyContent="end" item xs={3}>
          {currentPageInfo.isLoadingAction ? (
            <LoadingSpinner />
          ) : (
            <ActionTitle
              tabIndex="0"
              align="right"
              onClick={() => {
                if (!isDisabled) {
                  handleAction();
                }
              }}
              aria-disabled={isDisabled}
              disabled={isDisabled ? 1 : 0}
              id="action-title-done"
              data-testid="action-title-done"
            >
              {getTranslation("DONE", t, i18n)}
            </ActionTitle>
          )}
        </Grid>
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default ChooseAssetImageHeader;
