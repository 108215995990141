import { Box, Grid } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useGetAllCharacteristicsQuery,
  useGetCharacteristicSetTemplatesQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  groupCharacteristics,
  mergeCharacteristics,
} from "../../../util/asset-utils";
import {
  delimiterLocalize,
  getTranslation,
  localizeDate,
  measurementDisplay,
} from "../../../util/utils";
import ErrorHandling from "../../common/ErrorHandling";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import {
  DarkGreyText,
  FlexSpacedContainer,
  FlexUnShrinkableText,
} from "../../styles/types/type-details/DesktopTypeDetails.styles";

const DesktopTypeCharacteristicGroup = ({
  typeCharacteristics,
  functionId,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const region = user?.region;
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  // Queries
  const {
    data: characteristicSetTemplatesData,
    isLoading: isLoadingCharacteristicDefinitions,
    isError: isErrorCharacteristicDefinitions,
  } = useGetCharacteristicSetTemplatesQuery({
    resourceFunctionId: functionId,
    organizationId,
  });
  const {
    data: characteristicDefinitionsData,
    isLoading: isLoadingSetTemplates,
    isError: isErrorSetTemplates,
  } = useGetAllCharacteristicsQuery({ organizationId });

  // Other variables
  const characteristics = useMemo(
    () =>
      mergeCharacteristics(
        [],
        typeCharacteristics,
        characteristicDefinitionsData
      ),
    [typeCharacteristics, characteristicDefinitionsData]
  );

  const { setTemplates } = groupCharacteristics(
    characteristics,
    characteristicSetTemplatesData
  );

  return (
    <ErrorHandling
      isLoading={isLoadingCharacteristicDefinitions || isLoadingSetTemplates}
      isError={isErrorCharacteristicDefinitions || isErrorSetTemplates}
    >
      <Box>
        {setTemplates
          .filter(
            (setTemplate) =>
              setTemplate?.characteristics?.filter((char) =>
                Boolean(char.value)
              )?.length > 0
          )
          .map((setTemplate) => (
            <Box mb={4}>
              <DarkGreyText mb={2}>
                {getTranslation(setTemplate.name, t, i18n)}
              </DarkGreyText>
              <Grid px={2} container columnSpacing={4}>
                {setTemplate?.characteristics
                  ?.filter((char) => Boolean(char.value))
                  ?.slice()
                  ?.sort((a, b) => {
                    if (a.id > b.id) {
                      return 1;
                    } else {
                      return -1;
                    }
                  })
                  .map((c) => {
                    const { name, value, measurementUnit } = c;
                    const {
                      value: characteristicValue,
                      unit,
                      dataType,
                    } = measurementDisplay({
                      value,
                      unit: measurementUnit,
                      region: region,
                      reverse: false,
                    });
                    return (
                      <Grid item md={4}>
                        <FlexSpacedContainer>
                          <FlexUnShrinkableText>
                            {getTranslation(name, t, i18n)}
                          </FlexUnShrinkableText>
                          <SecondaryText>
                            {`${
                              !isNaN(characteristicValue) &&
                              dataType !== "STRING"
                                ? Number(characteristicValue).toLocaleString(
                                    delimiterLocalize(region)
                                  )
                                : name === "LIFECYCLE_DATE" ||
                                  dataType === "DATETIME"
                                ? localizeDate(value, region)
                                : value
                            } ${unit}`}
                          </SecondaryText>
                        </FlexSpacedContainer>
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          ))}
      </Box>
    </ErrorHandling>
  );
};

export default DesktopTypeCharacteristicGroup;
