import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import HomePage from "../pages/home-pages/HomePage";
import Profile from "../pages/profile-pages/Profile";
import Theme from "../pages/profile-pages/Theme";
import GenericNotFound from "./GenericNotFound";
import AssetDetailPage from "../pages/assets-pages/AssetDetailPage";
import Language from "../pages/profile-pages/Language";
import SuccessfulLogin from "../pages/successful-login/SuccessfulLogin";
import Settings from "../pages/profile-pages/Settings";
import UserDetailPage from "../pages/profile-pages/UserDetailPage";
import UpdateUserName from "../pages/profile-pages/UpdateUserName";
import UpdateUserNickname from "../pages/profile-pages/UpdateUserNickname";
import Organization from "../pages/profile-pages/Organization";
import OrganizationDetails from "../pages/profile-pages/OrganizationDetails";
import UpdateOrganizationName from "../pages/profile-pages/organization-pages/UpdateOrganizationName";
import UpdateOrganizationPhone from "../pages/profile-pages/organization-pages/UpdateOrganizationPhone";
import OrganizationEdit from "../pages/profile-pages/organization-pages/OrganizationEdit";
import AssetList from "../pages/assets-pages/AssetList";
import UserManagementPage from "../pages/profile-pages/organization-pages/UserManagementPage";
import UpdateOrganizationContactDetails from "../pages/profile-pages/organization-pages/UpdateOrganizationContactDetails";
import EditAsset from "../pages/assets-pages/EditAsset";
import AddAsset from "../pages/assets-pages/AddAsset";
import InvitationForm from "../components/InvitationForm";
import MorePage from "../pages/MorePage";
import AddResourceAttachmentPage from "../pages/assets-pages/AddResourceAttachmentPage";
import SearchAssetList from "../pages/assets-pages/SearchAssetList";
import ReportsPage from "../pages/reports-pages/ReportsPage";
import ReportsByAssetsListPerLocation from "../pages/reports-pages/ReportsByAssetsListPerLocation";
import ReportsByAssetsListPerRack from "../pages/reports-pages/ReportsByAssetsListPerRack";
import ReportsByZoneList from "../pages/reports-pages/ReportsByZoneList";
import TimeZone from "../pages/profile-pages/TimeZone";
import Forbidden from "../pages/Forbidden";
import RegionalPreference from "../pages/profile-pages/RegionalPreference";
import TypeListPage from "../pages/type-pages/TypeListPage";
import TypeDetailPage from "../pages/type-pages/TypeDetailPage";
import AddTypePage from "../pages/type-pages/AddTypePage";
import EditTypePage from "../pages/type-pages/EditTypePage";
import AboutPage from "../pages/About";
import CreditsPage from "../pages/CreditsPage";
import ReportsByHardwareAssets from "../pages/reports-pages/ReportsByHardwareAssets";
import ReportsByRacks from "../pages/reports-pages/ReportsByRacks";
import ReportsByHardwareAssetsByFunction from "../pages/reports-pages/ReportsByHardwareAssetsByFunction";
import ReportsByHardwareAssetsByVendor from "../pages/reports-pages/ReportsByHardwareAssetsByVendor";
import EditTags from "../pages/assets-pages/EditTags";
import TagsFilterPage from "../pages/assets-pages/TagsFilterPage";
import Community from "../pages/Community";
import ThirdPartyNoticesPage from "../pages/ThirdPartyNoticesPage";
import TypeFavoritesPage from "../pages/type-pages/TypeFavoritesPage";
import TypeRecentsPage from "../pages/type-pages/TypeRecentsPage";
import ErrorPage from "../pages/ErrorPage";
import ReportDetailsPage from "../pages/reports-pages/ReportDetailsPage";
import ReportPopUpPage from "../pages/reports-pages/ReportPopUpPage";
import OnboardingWelcomePage from "../pages/onboarding-pages/OnboardingWelcomePage";
import UserOnboardingPage from "../pages/onboarding-pages/UserOnboardingPage";
import OrganizationOnboardingPage from "../pages/onboarding-pages/OrganizationOnboardingPage";
import Favorites from "../pages/common/Favorites";
import Recents from "../pages/common/Recents";
import UpdateOrganizationAddress from "../pages/profile-pages/organization-pages/UpdateOrganizationAddress";
import CreateReferralPage from "../pages/CreateReferralPage";
import EditResourceImagePage from "../pages/assets-pages/EditResourceImagePage";
import AddResourceImagePage from "../pages/assets-pages/AddResourceImagePage";
import EditResourceAttachmentPage from "../pages/assets-pages/EditResourceAttachmentPage";
import AddLocalResourceImagePage from "../pages/assets-pages/AddLocalResourceImagePage";
import EditLocalResourceImagePage from "../pages/assets-pages/EditLocalResourceImagePage";
import AddLocalResourceAttachmentPage from "../pages/assets-pages/AddLocalResourceAttachmentPage";
import EditLocalResourceAttachmentPage from "../pages/assets-pages/EditLocalResourceAttachmentPage";

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<HomePage />} exact />

        <Route path="/successful-login" element={<SuccessfulLogin />} exact />

        {/* Onboarding endpoints */}
        <Route path="/onboarding" element={<OnboardingWelcomePage />} exact />

        <Route path="/user-onboarding" element={<UserOnboardingPage />} exact />

        <Route
          path="/organization-onboarding"
          element={<OrganizationOnboardingPage />}
          exact
        />

        {/* Profile endpoints */}
        <Route path="/profile/organization" element={<Organization />} exact />
        <Route
          path="/profile/organization/:organizationId"
          element={<OrganizationDetails />}
          exact
        />
        <Route
          path="/profile/organization/:organizationId/edit"
          element={<OrganizationEdit />}
          exact
        />
        <Route
          path="/profile/organization/:organizationId/update-organization-name"
          element={<UpdateOrganizationName />}
          exact
        />
        <Route
          path="/profile/organization/:organizationId/update-organization-address/:addressId"
          element={<UpdateOrganizationAddress />}
          exact
        />
        <Route
          path="/profile/organization/:organizationId/update-organization-phone"
          element={<UpdateOrganizationPhone />}
          exact
        />
        <Route
          path="/profile/organization/:organizationId/update-organization-contact-details"
          element={<UpdateOrganizationContactDetails />}
          exact
        />
        <Route
          path="/profile/organization/:organizationId/user-management"
          element={<UserManagementPage />}
          exact
        />
        <Route
          path="/profile/organization/:organizationId/user-management/invite"
          element={<InvitationForm />}
          exact
        />
        <Route path="/profile" element={<Profile />} exact />
        <Route path="/profile/userdetail" element={<UserDetailPage />} exact />
        <Route path="/profile/update-user-name" element={<UpdateUserName />} />
        <Route
          path="/profile/update-user-nickname"
          element={<UpdateUserNickname />}
        />
        <Route
          path="/profile/preferences/regional-preference"
          element={<RegionalPreference />}
        />
        <Route path="/profile/theme" element={<Theme />} exact />
        <Route path="/profile/language" element={<Language />} exact />
        <Route path="/profile/preferences" element={<Settings />} exact />
        <Route
          path="/profile/preferences/time-zone"
          element={<TimeZone />}
          exact
        />
        <Route path="/referral/create" element={<CreateReferralPage />} exact />

        {/* Resources endpoints */}
        <Route path="/resources/add-new" element={<AddAsset />} exact />
        <Route
          path="/resources/:resourceId/add-image"
          element={<AddResourceImagePage />}
          exact
        />
        <Route
          path="/resources/:resourceId?/add-local-image"
          element={<AddLocalResourceImagePage />}
          exact
        />
        <Route
          path="/resources/:resourceId/edit-image/:resourceImageId"
          element={<EditResourceImagePage />}
          exact
        />
        <Route
          path="/resources/:resourceId?/edit-local-image"
          element={<EditLocalResourceImagePage />}
          exact
        />
        <Route
          path="/resources/:resourceId/add-attachment"
          element={<AddResourceAttachmentPage />}
          exact
        />
        <Route
          path="/resources/:resourceId?/add-local-attachment"
          element={<AddLocalResourceAttachmentPage />}
          exact
        />
        <Route
          path="/resources/:resourceId/edit-attachment/:resourceAttachmentId"
          element={<EditResourceAttachmentPage />}
          exact
        />
        <Route
          path="/resources/:resourceId?/edit-local-attachment"
          element={<EditLocalResourceAttachmentPage />}
          exact
        />
        <Route path="/favorites" element={<Favorites />} exact />
        <Route path="/recents" element={<Recents />} exact />
        {/* Assets starting point (all assets without parent)*/}
        <Route path="/resources/:resourceid?" element={<AssetList />} exact />
        <Route
          path="/resources/:resourceid/details"
          element={<AssetDetailPage />}
          exact
        />
        <Route
          path="/resources/:resourceid/edit"
          element={<EditAsset />}
          exact
        />
        <Route path="/search" element={<SearchAssetList />} exact />
        <Route
          path="/tags-filter/:resourceid/:tagid"
          element={<TagsFilterPage />}
          exact
        />
        <Route path="/tags/:resourceid/edit" element={<EditTags />} exact />

        {/* Reports endpoints */}
        <Route path="/reports" element={<ReportsPage />} exact />
        <Route
          path="/reports/per-location"
          element={<ReportsByAssetsListPerLocation />}
          exact
        />
        <Route
          path="/reports/per-rack"
          element={<ReportsByAssetsListPerRack />}
          exact
        />
        <Route
          path="/reports/zone-list"
          element={<ReportsByZoneList />}
          exact
        />
        <Route
          path="/reports/hardware-assets"
          element={<ReportsByHardwareAssets />}
          exact
        />
        <Route path="/reports/racks" element={<ReportsByRacks />} exact />
        <Route
          path="/reports/hardware-assets-by-function"
          element={<ReportsByHardwareAssetsByFunction />}
          exact
        />
        <Route
          path="/reports/hardware-assets-by-vendor"
          element={<ReportsByHardwareAssetsByVendor />}
          exact
        />
        <Route
          path="/reports/:reportId"
          element={<ReportDetailsPage />}
          exact
        />
        <Route path="/report-popup" element={<ReportPopUpPage />} exact />

        {/* Library endpoints */}
        <Route path="/library" element={<TypeListPage />} exact />
        <Route path="/library/add-new" element={<AddTypePage />} exact />
        <Route
          path="/library/favorites"
          element={<TypeFavoritesPage />}
          exact
        />
        <Route path="/library/recents" element={<TypeRecentsPage />} exact />
        <Route path="/library/:typeId/edit" element={<EditTypePage />} exact />
        <Route
          path="/library/:typeId/details"
          element={<TypeDetailPage />}
          exact
        />

        {/* Other endpoints */}
        <Route path="/community" element={<Community />} exact />
        <Route path="/more" element={<MorePage />} exact />
        <Route path="/about" element={<AboutPage />} exact />
        <Route path="/credits" element={<CreditsPage />} exact />
        <Route
          path="/third-party-notices"
          element={<ThirdPartyNoticesPage />}
          exact
        />

        {/* Error endpoints */}
        <Route path="/forbidden" element={<Forbidden />} exact />
        <Route path="/error-page" element={<ErrorPage />} exact />

        <Route path="*" element={<GenericNotFound />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
