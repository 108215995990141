import {
  Paper,
  BottomNavigationAction,
  Icon,
  BottomNavigation,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import StarRateIcon from "@mui/icons-material/StarRate";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PersonIcon from "@mui/icons-material/Person";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import TimerIcon from "@mui/icons-material/Timer";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/system";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export const MenuPaper = styled(Paper)({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 10,
});

export const HomeBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme, active }) => ({
    "& .Mui-selected": {
      color: active ? theme.palette.secondary.contrastText : "",
    },
    minWidth: "60px",
  })
);

export const HomeBottomNavigationActionIcon = styled(HomeIcon)(
  ({ theme, active, customfontsize }) => ({
    color: active ? theme.palette.secondary.contrastText : "",
    fontSize: `${customfontsize * 1.5}px`,
  })
);

export const AssetsBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme, active }) => ({
    "& .Mui-selected": {
      color: active ? theme.palette.secondary.contrastText : "",
    },
    minWidth: "60px",
  })
);

export const AssetsBottomNavigationActionIcon = styled(FileCopyIcon)(
  ({ theme, active, customfontsize }) => ({
    color: active ? theme.palette.secondary.contrastText : "",
    fontSize: `${customfontsize * 1.5}px`,
  })
);

export const ReportsBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme, active }) => ({
    "& .Mui-selected": {
      color: active ? theme.palette.secondary.contrastText : "",
    },
    minWidth: "60px",
  })
);

export const ReportsBottomNavigationActionIcon = styled(FindInPageIcon)(
  ({ theme, active, customfontsize }) => ({
    color: active ? theme.palette.secondary.contrastText : "",
    fontSize: `${customfontsize * 1.5}px`,
  })
);

export const LibraryBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme, active }) => ({
    "& .Mui-selected": {
      color: active ? theme.palette.secondary.contrastText : "",
    },
    minWidth: "60px",
  })
);

export const LibraryBottomNavigationActionIcon = styled(LocalLibraryIcon)(
  ({ theme, active, customfontsize }) => ({
    color: active ? theme.palette.secondary.contrastText : "",
    fontSize: `${customfontsize * 1.5}px`,
  })
);

export const FavoritesBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme, active }) => ({
    "& .Mui-selected": {
      color: active ? theme.palette.secondary.contrastText : "",
    },
    minWidth: "60px",
  })
);

export const FavoritesBottomNavigationActionIcon = styled(StarRateIcon)(
  ({ theme, active, customfontsize }) => ({
    color: active ? theme.palette.secondary.contrastText : "",
    fontSize: `${customfontsize * 1.5}px`,
  })
);

export const MoreBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme, active }) => ({
    "& .Mui-selected": {
      color: active ? theme.palette.secondary.contrastText : "",
    },
    minWidth: "60px",
  })
);

export const MoreBottomNavigationActionIcon = styled(MoreHorizIcon)(
  ({ theme, active, customfontsize }) => ({
    color: active ? theme.palette.secondary.contrastText : "",
    fontSize: `${customfontsize * 1.5}px`,
  })
);

export const ProfileDrawerNavigationActionIcon = styled(PersonIcon)(
  ({ theme, active, customfontsize }) => ({
    color: active ? theme.palette.secondary.contrastText : "",
    fontSize: `${customfontsize * 1.5}px`,
  })
);

export const ReportsDrawerNavigationActionIcon = styled(ShowChartIcon)(
  ({ theme, active, customfontsize }) => ({
    color: active ? theme.palette.secondary.contrastText : "",
    fontSize: `${customfontsize * 1.5}px`,
  })
);

export const LastUsedDrawerNavigationActionIcon = styled(TimerIcon)(
  ({ theme, active, customfontsize }) => ({
    color: active ? theme.palette.secondary.contrastText : "",
    fontSize: `${customfontsize * 1.5}px`,
  })
);

export const AboutDrawerNavigationActionIcon = styled(QuestionMarkIcon)(
  ({ theme, active, customfontsize }) => ({
    color: active ? theme.palette.secondary.contrastText : "",
    fontSize: `${customfontsize * 1.5}px`,
  })
);

export const RecentlyDeletedDrawerNavigationActionIcon = styled(DeleteIcon)(
  ({ theme, active, customfontsize }) => ({
    color: active ? theme.palette.secondary.contrastText : "",
    fontSize: `${customfontsize * 1.5}px`,
  })
);

export const NavigationActionIcon = styled(Icon)(
  ({ theme, active, customfontsize }) => ({
    color: active ? theme.palette.secondary.contrastText : "",
    fontSize: `${customfontsize * 1.5}px`,
  })
);

export const NavigationActionRightIcon = styled(KeyboardArrowRightIcon)(
  ({ theme, active, customfontsize }) => ({
    color: active ? theme.palette.secondary.contrastText : "",
    fontSize: `${customfontsize * 1.5}px`,
  })
);

export const StyledBottomNavigation = styled(BottomNavigation)({
  paddingBottom: "8px",
  "@supports (padding-bottom: max(0px))": {
    paddingBottom: "max(8px, calc(env(safe-area-inset-bottom) * 0.7))",
  },
  paddingTop: "5px",
  minHeight: "56px",
});

export const ContrastText = styled(Typography)(({ theme, active }) => ({
  color: active ? theme.palette.secondary.contrastText : "",
}));
