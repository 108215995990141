import { Stack } from "@mui/material";
import React from "react";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";

const SumChartWidgetContent = ({ title, sum }) => {
  return (
    <Stack sx={{ flexGrow: 1, alignItems: "center", justifyContent: "center" }}>
      <SecondaryText fontWeight="bold">{sum}</SecondaryText>
    </Stack>
  );
};

export default SumChartWidgetContent;
