import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useGetAllFunctionsQuery } from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import GridViewCardMedia from "./GridViewCardMedia";
import { transitionDirections } from "../../../util/utils";
import { setIsSearchOpen } from "../../../store/slices/appSlice";
import useOrientation from "../../../hooks/useOrientation";
import { clearHistory } from "../../../store/slices/assetListSlice";
import { Grid } from "@mui/material";
import {
  AssetGridViewCard,
  AssetText,
  CardMediaContainer,
  ItemsNumberText,
} from "../../styles/assets/asset-list/GridViewCard.styles";
import ErrorHandling from "../../common/ErrorHandling";

const GridViewCard = ({ row }) => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  // Custom hooks
  const orientation = useOrientation();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  // Queries
  const {
    data: allFunctionsData,
    isLoading,
    isError,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Other variables
  const favoritesRecentsRouteCheck =
    pathname.includes("favorites") || pathname.includes("recents");

  const { id, displayId, name, functionId, childrenCount, images } =
    favoritesRecentsRouteCheck ? row.resource : row;
  const combinationDisplayIdAndName = displayId || name;
  const resourceFunction = allFunctionsData?.find((f) => f.id === functionId);

  const tagName = searchParams.get("tagName");
  const from = searchParams.get("from");
  const tagId = searchParams.get("tagId");
  const resourceId = searchParams.get("resourceId");

  // Handlers
  const handleNavigate = () => {
    navigate(
      `/resources/${id}?direction=${
        transitionDirections.RIGHT_TO_LEFT
      }&pathname=${pathname}${Boolean(tagName) ? `&tagName=${tagName}` : ""}${
        Boolean(from) ? `&from=${from}` : ""
      }${Boolean(tagId) ? `&tagId=${tagId}` : ""}${
        Boolean(resourceId) ? `&resourceId=${resourceId}` : ""
      }`
    );

    dispatch(setIsSearchOpen(false));
    dispatch(clearHistory());
  };

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <Grid
        item
        xs={6}
        sm={orientation === "landscape" ? 1.5 : 2}
        lg={1.2}
        key={`asset-image-grid-item-${id}`}
      >
        <AssetGridViewCard elevation={0} onClick={handleNavigate}>
          <CardMediaContainer
            isicon={
              !images || !images.length || images[0]?.name?.endsWith(".svg")
            }
          >
            <GridViewCardMedia
              isIcon={
                !images || !images.length || images[0]?.name?.endsWith(".svg")
              }
              category={resourceFunction.category}
              resourceImages={images}
            />
          </CardMediaContainer>
          <AssetText id="asset-name" variant="subtitle1">
            {combinationDisplayIdAndName}
          </AssetText>
          <ItemsNumberText id="asset-children-count" variant="subtitle2">
            {`${childrenCount} items`}
          </ItemsNumberText>
        </AssetGridViewCard>
      </Grid>
    </ErrorHandling>
  );
};

export default GridViewCard;
