import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageTransition from "../../components/PageTransition";
import AssetForm from "../../components/assets/asset-form/AssetForm";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import {
  useCreateResourceMutation,
  useGetAllFunctionsQuery,
  useGetAllResourcesQuery,
} from "../../store/slices/api/assetManagementSlice";
import {
  setIsActionButtonDisabled,
  setIsLoadingAction,
} from "../../store/slices/appSlice";
import { selectUser } from "../../store/slices/authSlice";
import {
  transitionDirections,
  permissions,
  getTranslation,
  RESOURCE_CATEGORIES,
  showValidationError,
} from "../../util/utils";
import {
  checkResourceChanged,
  transformResourceInputRequest,
  validateResourceForm,
} from "../../util/asset-utils";
import {
  clearList,
  selectResourceImages,
} from "../../store/slices/resourceImageSlice";
import {
  DEFAULT_RESOURCE_INPUT,
  resetResourceInput,
  selectError,
  selectIsFirstSubmitted,
  selectOriginalInput,
  selectResourceInput,
  setIsFirstSubmitted,
  setOriginalInput,
  setResourceInput,
} from "../../store/slices/resourceInputSlice";
import {
  clearAttachmentList,
  selectResourceAttachments,
} from "../../store/slices/resourceAttachmentSlice";
import { messageError, messageSuccess } from "../../util/notification";
import AddAssetHeader from "../../navigation/header/assets/AddAssetHeader";
import { useUploadResourceImageMutation } from "../../store/slices/api/resourceImagesApiSlice";
import { useUploadResourceAttachmentMutation } from "../../store/slices/api/resourceAttachmentsApiSlice";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import EventBus, { EVENT_DISCARD_CHANGES } from "../../util/EventBus";
import Access from "../../components/common/Access";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const AddAsset = () => {
  // General Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const parentId = searchParams.get("resourceid");
  const previousPage = searchParams.get("previousPage");
  const direction = searchParams.get("direction");
  const category = searchParams.get("category");

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const resourceInput = useSelector(selectResourceInput);
  const originalInput = useSelector(selectOriginalInput);
  const isFirstSubmitted = useSelector(selectIsFirstSubmitted);
  const error = useSelector(selectError);
  const localResourceImages = useSelector(selectResourceImages);
  const localResourceAttachments = useSelector(selectResourceAttachments);

  // States
  const [open, setOpen] = useState(false);
  const [redirectRoute, setRedirectRoute] = useState("");

  // Queries
  const {
    data: allResourcesData,
    isLoading: isLoadingResources,
    isError: isErrorResources,
  } = useGetAllResourcesQuery({
    organizationId,
  });

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Mutations
  const [createResource, { isLoading: isLoadingCreate }] =
    useCreateResourceMutation();
  const [uploadResourceImage, { isLoading: isLoadingImage }] =
    useUploadResourceImageMutation();
  const [uploadResourceAttachment, { isLoading: isLoadingAttachment }] =
    useUploadResourceAttachmentMutation();

  // Handlers
  const cancelHandler = (redirectUrl) => {
    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      navigate(redirectRoute);
    }

    // Reset form
    dispatch(resetResourceInput);
  };

  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === id);
  };

  const handleSubmit = async () => {
    dispatch(setIsFirstSubmitted(true));

    const parent = allResourcesData?.find(
      (r) => r.id === resourceInput.parentId
    );

    const parentFunction = getFunction(parent?.functionId);

    const isParentHardwareAsset =
      parentFunction?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET;

    const isParentRack = parentFunction?.category === RESOURCE_CATEGORIES.RACK;

    const hasLocations =
      allResourcesData?.filter((r) => {
        return (
          getFunction(r.functionId)?.category === RESOURCE_CATEGORIES.LOCATION
        );
      })?.length > 0;

    if (
      resourceInput.category === RESOURCE_CATEGORIES.HARDWARE_ASSET ||
      resourceInput.category === RESOURCE_CATEGORIES.RACK
    ) {
      if (!hasLocations) {
        messageError(getTranslation("NO_LOCATIONS_VALIDATION", t, i18n));
        return;
      }
    }

    if (
      resourceInput.category === RESOURCE_CATEGORIES.LOCATION &&
      resourceInput.parentId &&
      (isParentHardwareAsset || isParentRack)
    ) {
      messageError(getTranslation("CHANGE_PARENT_VALIDATION", t, i18n));
      return;
    }

    if (
      resourceInput.category === RESOURCE_CATEGORIES.RACK &&
      resourceInput.parentId &&
      isParentHardwareAsset
    ) {
      messageError(
        getTranslation("CHANGE_HARDWARE_PARENT_VALIDATION", t, i18n)
      );
      return;
    }

    const { error: evaluatedError, firstError } = validateResourceForm(
      resourceInput,
      t
    );

    const isValid =
      Object.keys(evaluatedError).length === 0 && !Boolean(error.name);

    if (!isValid) {
      if (firstError) {
        messageError(firstError);
      }

      if (error.name) {
        messageError(error.name);
      }
      dispatch(setIsActionButtonDisabled(true));
      return;
    }

    try {
      // Creates resource
      const resource = await createResource({
        resourceInput: transformResourceInputRequest(
          resourceInput,
          user?.region
        ),
        organizationId: user?.organizations?.find((o) => o.default).id,
        category: resourceInput.category.replace("_", "-").toLowerCase(),
      }).unwrap();

      // Upload resource images
      for (let i = 0; i < localResourceImages.length; i++) {
        const resourceImage = localResourceImages[i];
        const { imageCategory, file, name, description } = resourceImage;

        const formData = new FormData();

        if (file) {
          formData.append("file", file);
          formData.append("fileName", name);
          formData.append("imageCategory", imageCategory);
          formData.append("description", description);
        }

        await uploadResourceImage({
          formData,
          resourceid: resource.id,
          organizationId: user?.organizations?.find((o) => o.default).id,
        }).unwrap();
      }

      // Upload resource attachments
      for (let i = 0; i < localResourceAttachments.length; i++) {
        const resourceAttachment = localResourceAttachments[i];
        const { file, name: fileName, description } = resourceAttachment;

        const formData = new FormData();

        if (file) {
          formData.append("file", file);
          formData.append("fileName", fileName);
          formData.append("description", description);
        }

        await uploadResourceAttachment({
          formData,
          resourceid: resource.id,
          organizationId: user?.organizations?.find((o) => o.default).id,
        }).unwrap();
      }

      messageSuccess(getTranslation("ASSET_CREATED_SUCCESSFULLY", t, i18n));

      navigate(
        `/resources${
          resourceInput.parentId ? `/${resourceInput.parentId}` : ""
        }?direction=${transitionDirections.TOP_TO_BOTTOM}`
      );

      // Reset form
      dispatch(resetResourceInput());
      dispatch(setIsLoadingAction(false));
      dispatch(clearList());
      dispatch(clearAttachmentList());
    } catch (error) {
      dispatch(setIsLoadingAction(false));
      showValidationError(error, t, i18n);
      console.error("Failed to create a resource", error);
    }
  };

  // Effects
  useEffect(() => {
    const toBePersistedResourceInput =
      previousPage?.includes("add-type") ||
      previousPage?.includes("edit-type") ||
      previousPage?.includes("add-local-image") ||
      previousPage?.includes("add-local-attachment") ||
      previousPage?.includes("edit-local-image") ||
      previousPage?.includes("edit-local-attachment");

    if (toBePersistedResourceInput) {
      dispatch(
        setResourceInput({
          ...resourceInput,
          parentId: resourceInput.parentId,
          category: resourceInput.category,
        })
      );
    } else {
      dispatch(resetResourceInput());
      dispatch(setIsActionButtonDisabled(false));

      const initialResourceInput = {
        ...DEFAULT_RESOURCE_INPUT,
        category,
        parentId,
      };

      dispatch(setResourceInput(initialResourceInput));
      dispatch(setOriginalInput(initialResourceInput));

      dispatch(clearList());
      dispatch(clearAttachmentList());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleNavigation = (data) => {
      if (checkResourceChanged(originalInput, resourceInput, user?.region)) {
        setRedirectRoute(data.route);
        setOpen(true);
      } else {
        cancelHandler(data.route);
      }
    };

    EventBus.on(EVENT_DISCARD_CHANGES, handleNavigation);

    return () => EventBus.remove(EVENT_DISCARD_CHANGES, handleNavigation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceInput, originalInput]);

  useEffect(() => {
    if (!isFirstSubmitted) return;

    const { error: evaluatedError } = validateResourceForm(resourceInput, t);
    const isValid = Object.keys(evaluatedError).length === 0 && !error.name;

    if (isValid) {
      dispatch(setIsActionButtonDisabled(false));
    } else {
      dispatch(setIsActionButtonDisabled(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceInput, error]);

  useEffect(() => {
    if (isLoadingCreate || isLoadingImage || isLoadingAttachment) {
      dispatch(setIsLoadingAction(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAttachment, isLoadingCreate, isLoadingImage]);

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={
          isLoadingAttachment ||
          isLoadingCreate ||
          isLoadingFunctions ||
          isLoadingImage ||
          isLoadingResources
        }
        isError={isErrorFunctions || isErrorResources}
      >
        <Access
          oneOf={[
            permissions.ASSET_MANAGEMENT_HARDWARE_ADD,
            permissions.ASSET_MANAGEMENT_LOCATION_ADD,
            permissions.ASSET_MANAGEMENT_RACK_ADD,
          ]}
        >
          <Layer2Access>
            <PageTransition direction={direction}>
              <ConfirmAlert
                isOpen={open}
                setIsOpen={setOpen}
                alert={{
                  content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
                  confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),
                  closeTitle: getTranslation("CANCEL", t, i18n),
                  showConfirm: true,
                }}
                label="discard-changes"
                handleConfirm={cancelHandler}
              />
              <AddAssetHeader
                handleAction={handleSubmit}
                handleCancelAction={() => {
                  const backRoute = `/resources${
                    parentId ? "/" + parentId : ""
                  }?direction=${transitionDirections.TOP_TO_BOTTOM}`;
                  if (
                    checkResourceChanged(
                      originalInput,
                      resourceInput,
                      user?.region
                    )
                  ) {
                    setRedirectRoute(backRoute);
                    setOpen(true);
                    return;
                  }

                  cancelHandler(backRoute);
                }}
              />
              <HomePagePadding>
                <AssetForm
                  allResourcesData={allResourcesData}
                  allFunctionsData={allFunctionsData}
                  mode="create-asset"
                />
              </HomePagePadding>
            </PageTransition>
          </Layer2Access>
        </Access>
      </ErrorHandling>
    </AppAccess>
  );
};

export default AddAsset;
