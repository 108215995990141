import { createTheme } from "@mui/material";
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#FFFFFF",
      contrastText: "#000000",
      dark: "#FFFFFF",
      light: "#FFFFFF",
    },
    secondary: {
      main: "#808080",
      dark: "rgb(30,30,30)",
      contrastText: "#00B5E3",
      light: "#F5F5F5",
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    // Name of the component
    MuiFormGroup: {
      styleOverrides: {
        root: {
          alignItems: "center",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: "15px",
          width: "100%",
          opacity: "1",
          /* fontFamily: "var(--font-family-roboto)", */
          fontWeight: "400",
          fontStyle: "normal",
          letterSpacing: "0, 15px",
          textAlign: "left",
          lineHeight: "24px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "5px !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "initial",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: "#0076bc",
          marginLeft: "7px",
          "&$error": {
            color: "red",
          },
        },
      },
    },
  },
});

export default darkTheme;
