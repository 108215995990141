import { Box } from "@mui/material";
import { CustomTabPanelContainer } from "./styles/general/General.styles";

const CustomTabPanel = (props) => {
  const { children, value, index, type, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`custom-tabpanel`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ flexGrow: 1 }}
      {...other}
    >
      {value === index &&
        (type === "floorplan" ? (
          <Box>{children}</Box>
        ) : (
          <CustomTabPanelContainer>{children}</CustomTabPanelContainer>
        ))}
    </Box>
  );
};

export default CustomTabPanel;
