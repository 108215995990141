import SelectInput from "../../components/SelectInput";
import {
  OnboardingContainer,
  PrimaryText,
  SelectInputsContainer,
} from "../../components/styles/onboarding/Onboarding.styles";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../store/slices/appSlice";
import {
  useGetRegionsQuery,
  useGetTimeZonesQuery,
  useGetUserIPInfoQuery,
} from "../../store/slices/api/userManagementSlice";
import {
  selectUserData,
  setUserData,
} from "../../store/slices/onboardingSlice";
import {
  supportedLanguages,
  extractUtcOffset,
  getFallBackLanguage,
  getTranslation,
} from "../../util/utils";
import PageTransition from "../../components/PageTransition";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ErrorHandling from "../../components/common/ErrorHandling";
import { Typography } from "@mui/material";

const transformTimeZone = (timeZoneObj) => {
  const { region, utc, description } = timeZoneObj;

  return `${utc} ${region}/${description}`;
};

const OnboardingLocationCredential = ({ transitionDirection }) => {
  // Global hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const userData = useSelector(selectUserData);

  // States
  const [dirty, setDirty] = useState({});

  // Queries
  const {
    data: regionsData,
    isLoading: isLoadingRegions,
    isError: isErrorRegions,
  } = useGetRegionsQuery();

  const {
    data: timeZonesData,
    isLoading: isLoadingTimeZones,
    isError: isErrorTimeZones,
  } = useGetTimeZonesQuery();

  const {
    data: userIPInfoData,
    isLading: isLoadingUserIPInfo,
    isError: isErrorUserIPInfo,
  } = useGetUserIPInfoQuery();

  // Other variables
  const { language, timeZone, region } = userData;

  // Handlers
  const transformedLanguagesData = supportedLanguages?.map((language) => {
    return {
      value: language.code,
      label: t(language.code, { lng: language.code }),
    };
  });

  const transformedRegionsData = regionsData?.map((region) => {
    return {
      value: region.code,
      label: getTranslation(region.code, t, i18n),
    };
  });

  const transformedTimeZonesData = timeZonesData
    ?.slice()
    .sort((a, b) => {
      const offsetA = extractUtcOffset(a.utc);
      const offsetB = extractUtcOffset(b.utc);

      if (offsetA !== offsetB) {
        return offsetA - offsetB;
      } else {
        return a.region.localeCompare(b.region);
      }
    })
    .map((timeZone) => {
      return {
        value: timeZone.name,
        label: transformTimeZone(timeZone),
      };
    });

  const handleOnBlur = (e) => {
    setDirty({
      ...dirty,
      [e.target.name]: true,
    });
  };

  const handleLanguageChange = (value) => {
    dispatch(setUserData({ ...userData, language: value }));
    dispatch(setLanguage(value));

    i18n.changeLanguage(value);
    i18n.options.fallbackLng = getFallBackLanguage(value);
  };

  const handleRegionChange = (value) =>
    dispatch(setUserData({ ...userData, region: value }));

  const handleTimeZoneChange = (value) => {
    dispatch(
      setUserData({
        ...userData,
        timeZone: value,
      })
    );
  };

  useEffect(() => {
    if (timeZonesData && userIPInfoData && !Boolean(userData?.timeZone)) {
      const timeZoneMatch = timeZonesData.find(
        (t) =>
          t.description.includes(userIPInfoData.country_capital) &&
          t.ISO3166 === userIPInfoData.country
      );

      if (timeZoneMatch) {
        dispatch(
          setUserData({
            ...userData,
            timeZone: timeZoneMatch.name,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIPInfoData, timeZonesData]);

  return (
    <ErrorHandling
      isLoading={isLoadingRegions || isLoadingTimeZones || isLoadingUserIPInfo}
      isError={isErrorRegions || isErrorTimeZones || isErrorUserIPInfo}
    >
      <PageTransition direction={transitionDirection}>
        <PrimaryText sx={{ marginBottom: "10px" }} variant="h2" align="start">
          {getTranslation("PERSONAL_PREFERENCES", t, i18n)}
        </PrimaryText>

        <PrimaryText sx={{ marginBottom: "20px" }} variant="body2">
          {getTranslation("PERSONAL_PREFERENCES_CAPTION", t, i18n)}
        </PrimaryText>

        <OnboardingContainer>
          <SelectInputsContainer>
            {/* Language location select input*/}
            <SelectInput
              label="LANGUAGE"
              selectLabelId="language-location-select"
              value={language}
              name="language"
              onBlur={handleOnBlur}
              error={dirty["language"] && !language}
              handleChange={handleLanguageChange}
              data={transformedLanguagesData}
            />
            {/* Region preference select input */}
            <SelectInput
              label="regionalPreference"
              selectLabelId="regional-preference-select"
              name="region"
              onBlur={handleOnBlur}
              error={dirty["region"] && !region}
              value={region}
              handleChange={handleRegionChange}
              data={transformedRegionsData}
            />
            {/* Time Zone select input */}
            <SelectInput
              label="timeZone"
              selectLabelId="timezone-select-onboarding"
              name="timeZone"
              onBlur={handleOnBlur}
              value={timeZone}
              error={dirty["timeZone"] && !timeZone}
              handleChange={handleTimeZoneChange}
              data={transformedTimeZonesData}
              isSelect={false}
            />
          </SelectInputsContainer>
        </OnboardingContainer>
      </PageTransition>
    </ErrorHandling>
  );
};

export default OnboardingLocationCredential;
