import { styled } from "@mui/system";
import { Box, Checkbox, Typography } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export const OrganizationContainer = styled(Box)({
  marginTop: "40px",
});

export const SelectCheckBox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  "&.Mui-checked": {
    color: theme.palette.secondary.contrastText,
  },
  "& .MuiSvgIcon-root": { fontSize: "24px !important" },
}));

export const OwnerIcon = styled(ArrowRightIcon)(
  ({ theme, globalFontSize }) => ({
    paddingLeft: "10px",
    color: theme.palette.primary.main,
    fontSize: `${globalFontSize * 1.5}px`,
  })
);

export const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginLeft: "16px",
  marginTop: "40px",
  marginBottom: "10px",
}));
