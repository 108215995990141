import { Grid } from "@mui/material";
import { styled } from "@mui/system";

export const ListColumnViewContainer = styled(Grid)(({ overflowx }) => ({
  display: "flex",
  flexWrap: "nowrap",
  whiteSpace: "nowrap",
  scrollBehavior: "smooth",
  transition: "scroll 0.3s ease-in-out",
  overflowX: overflowx,
  overflowY: "hidden",
}));
