import { Box, Button } from "@mui/material";
import { styled } from "@mui/system";

export const FooterContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const FooterWrapper = styled(Box)({
  flex: 0.05,
  display: "flex",
  alignItems: "end",
});

export const ContinueButton = styled(Button)(({ theme, isButtonDisabled }) =>
  theme.unstable_sx({
    width: "100%",
    backgroundColor: `${theme.palette.primary.contrastText} !important`,
    fontSize: theme.typography.h6.fontSize,
    color: isButtonDisabled ? "grey" : theme.palette.secondary.contrastText,
    boxShadow: "none",
    ":hover": {
      boxShadow: "none",
    },
  })
);
