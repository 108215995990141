export const GROUP = {
  UX_UI: 1,
  DEVELOPMENT: 2,
  DEV_OPS: 3,
  PRODUCT_MARKETING: 4,
  SOFTWARE_ARCHITECTURE: 5,
  TESTING_QA: 6,
  PRODUCT_MANAGEMENT: 7,
};

export const CREDITS = [
  {
    name: "Daniel Brenner",
    avatarUri: "Daniel_Brenner.jpg",
    groupId: GROUP.PRODUCT_MANAGEMENT,
  },
  {
    name: "Emiliyan Yankov",
    avatarUri: "Emiliyan_Yankov.jpg",
    groupId: GROUP.DEVELOPMENT,
  },
  {
    name: "Erkan Kamber",
    avatarUri: "Erkan_Kamber.jpg",
    groupId: GROUP.DEVELOPMENT,
  },
  {
    name: "Friederike Spielmannleitner",
    avatarUri: "Friederike_Spielmannleitner.jpg",
    groupId: GROUP.PRODUCT_MARKETING,
  },
  {
    name: "Jessica Rup",
    avatarUri: "Jessica_Rup.jpg",
    groupId: GROUP.UX_UI,
  },
  {
    name: "Kerim Sisman",
    avatarUri: "Kerim_Sisman.jpg",
    groupId: GROUP.DEVELOPMENT,
  },
  {
    name: "Marc Schönig",
    avatarUri: "Marc_Sch%C3%B6nig.jpg",
    groupId: GROUP.DEVELOPMENT,
  },
  {
    name: "Marjan Karafiloski",
    avatarUri: "Marjan_Karafiloski.jpg",
    groupId: GROUP.TESTING_QA,
  },
  {
    name: "Matthias Gruler",
    avatarUri: "Matthias_Gruler.jpg",
    groupId: GROUP.SOFTWARE_ARCHITECTURE,
  },
  {
    name: "Mehmet Namver",
    avatarUri: "Mehmet_Namver.jpg",
    groupId: GROUP.DEVELOPMENT,
  },
  {
    name: "Miroslav Uzunov",
    avatarUri: "Miroslav_Uzunov.jpg",
    groupId: GROUP.DEVELOPMENT,
  },
  {
    name: "Nikolche Veljanovski",
    avatarUri: "Nikolche_Veljanovski.jpg",
    groupId: GROUP.DEV_OPS,
  },
  {
    name: "Oleg Vasilev",
    avatarUri: "Oleg_Vasilev.jpg",
    groupId: GROUP.SOFTWARE_ARCHITECTURE,
  },
  {
    name: "Oliver Lindner",
    avatarUri: "Oliver_Lindner.jpg",
    groupId: GROUP.PRODUCT_MANAGEMENT,
  },
  {
    name: "Ozan Kocaoglu",
    avatarUri: "Ozan_Kocaoglu.jpg",
    groupId: GROUP.DEVELOPMENT,
  },
  {
    name: "Regine Vogel",
    avatarUri: "Regine_Vogel.jpg",
    groupId: GROUP.UX_UI,
  },
  {
    name: "Stefko Tsonyovski",
    avatarUri: "Stefko_Tsonyovski.jpg",
    groupId: GROUP.DEVELOPMENT,
  },
  {
    name: "Susanne Rieger",
    avatarUri: "Susanne_Rieger.jpg",
    groupId: GROUP.PRODUCT_MARKETING,
  },
  {
    name: "Antoniya Kvetsinska",
    avatarUri: "Antoniya_Kvetsinska.jpg",
    groupId: GROUP.TESTING_QA,
  },
  {
    name: "Benjamin Höfer",
    avatarUri: "Benjamin_Höfer.jpg",
    groupId: GROUP.DEVELOPMENT,
  },
  {
    name: "Christopher Hosper",
    avatarUri: "Christopher_Hosper.jpg",
    groupId: GROUP.PRODUCT_MANAGEMENT,
  },
  {
    name: "Anastasia Kozachenko",
    avatarUri: "Anastasia_Kozachenko.jpg",
    groupId: GROUP.UX_UI,
  },
  {
    name: "Samet Yildiz",
    avatarUri: "Samet_Yildiz.jpg",
    groupId: GROUP.DEVELOPMENT,
  },
];
