import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  HomePagePadding,
  StyledSwitch,
} from "../../components/styles/general/General.styles";
import {
  useGetTimeZonesQuery,
  useGetUserIPInfoQuery,
  useUpdateUserTimeZoneMutation,
} from "../../store/slices/api/userManagementSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  extractUtcOffset,
  getTranslation,
  transitionDirections,
} from "../../util/utils";
import PageTransition from "../../components/PageTransition";
import { refreshUser, selectUser } from "../../store/slices/authSlice";
import { messageError, messageSuccess } from "../../util/notification";
import { useTranslation } from "react-i18next";
import SelectInput from "../../components/SelectInput";
import ProfilePreferenceMenuItem from "../../components/ProfilePreferenceItem";
import TimeZoneHeader from "../../navigation/header/profile/TimeZoneHeader";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";

export const transformTimeZone = (timeZoneObj) => {
  const { region, utc, description } = timeZoneObj;

  return `${utc} ${region}/${description}`;
};

export const transformTimeZonesData = (timeZones) =>
  timeZones
    ?.slice()
    .sort((a, b) => {
      const offsetA = extractUtcOffset(a.utc);
      const offsetB = extractUtcOffset(b.utc);
      if (offsetA !== offsetB) {
        return offsetA - offsetB;
      } else {
        return a.region.localeCompare(b.region);
      }
    })
    .map((timezoneData) => {
      return {
        value: timezoneData.name,
        label: transformTimeZone(timezoneData),
      };
    });

const TimeZone = () => {
  // General hooks
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // Selectors
  const user = useSelector(selectUser);

  // Queries
  const {
    data: timeZonesData,
    isLoading: isLoadingTimeZones,
    isError: isErrorTimeZones,
  } = useGetTimeZonesQuery();
  const { data: userIPInfoData } = useGetUserIPInfoQuery();

  // Mutations
  const [updateTimeZone, { isLoading: isLoadingUpdateTimeZone }] =
    useUpdateUserTimeZoneMutation();

  // States
  const [localTimeZone, setLocalTimeZone] = useState(user.timeZone);
  const [isAutomaticTimeZone, setIsAutomaticTimeZone] = useState();

  //   Other variables
  const transitionDirection = searchParams.get("direction");

  // Handlers
  const handleSubmit = async () => {
    try {
      const existingTimeZone = timeZonesData.find((timeZoneOption) => {
        return timeZoneOption.name === localTimeZone;
      });

      const data = await updateTimeZone({
        userId: user?.id,
        timeZone: existingTimeZone.name,
      }).unwrap();

      if (data) {
        dispatch(refreshUser(data));
      }

      navigate(
        `/profile/preferences?direction=${transitionDirections.LEFT_TO_RIGHT}`
      );
      messageSuccess(getTranslation("successfulUpdateUserTimeZone", t, i18n));
    } catch (error) {
      messageError(getTranslation("failedUpdateUserTimeZone", t, i18n));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleTimeZoneChange = (value) => {
    setLocalTimeZone(value);
  };

  const handleToggleAutomaticTimeZone = () => {
    const newIsAutomaticTimeZone = !isAutomaticTimeZone;
    setIsAutomaticTimeZone(newIsAutomaticTimeZone);

    if (newIsAutomaticTimeZone) {
      const timeZoneMatch = timeZonesData.find(
        (t) =>
          t.description.includes(userIPInfoData?.country_capital) &&
          t.ISO3166 === userIPInfoData?.country
      );

      if (timeZoneMatch) {
        setLocalTimeZone(timeZoneMatch.name);
      }
    }
  };

  const goBackHandler = () =>
    navigate(
      `/profile/preferences?direction=${transitionDirections.LEFT_TO_RIGHT}`
    );

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={isLoadingTimeZones || isLoadingUpdateTimeZone}
        isError={isErrorTimeZones}
      >
        <PageTransition direction={transitionDirection}>
          <TimeZoneHeader
            goBackHandler={goBackHandler}
            handleAction={handleSubmit}
          />
          <HomePagePadding>
            <ProfilePreferenceMenuItem
              label="Automatically find Time Zone"
              labelId="automatic-time-zone"
              menuId="menu-item-automatic-time-zone"
              rightIcon={
                <StyledSwitch
                  onClick={handleToggleAutomaticTimeZone}
                  checked={isAutomaticTimeZone}
                />
              }
            />
            <SelectInput
              fullWidth
              label="timeZone"
              selectLabelId="timezone-select"
              value={localTimeZone}
              handleChange={handleTimeZoneChange}
              data={transformTimeZonesData(timeZonesData)}
              disabled={isAutomaticTimeZone}
              isSelect={false}
            />
          </HomePagePadding>
        </PageTransition>
      </ErrorHandling>
    </AppAccess>
  );
};

export default TimeZone;
