import { Box } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetUserInvitationsQuery } from "../../store/slices/api/userManagementSlice";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import { selectUser } from "../../store/slices/authSlice";
import { getTranslation } from "../../util/utils";
import OrganizationInvitationCard from "../OrganizationInvitationCard";
import { StyledDivider } from "../styles/profile/Profile.styles";
import {
  ActiveOrganizationHeaderContainer,
  ActiveOrganizationHeading,
  OrganizationDesktopBoxContainer,
  OrganizationDesktopCardStackContainer,
  OrganizationDesktopInvitationsContainer,
  OrganizationDesktopWrapper,
  OtherOrganizationsHeading,
} from "../styles/profile/ProfileDesktop.styles";
import {
  InviteButtonText,
  StyledAddIcon,
} from "../styles/profile/UserManagementPage.styles";
import OrganizationDesktopCard from "./OrganizationDesktopCard";
import { SecondaryContrastButton } from "../styles/general/General.styles";
import ErrorHandling from "../common/ErrorHandling";

const OrganizationDesktop = () => {
  // General hooks
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const organizations = user?.organizations;
  const activeOrganization = organizations?.find((o) => o.default);

  // Queries
  const {
    data: organizationInvitationsData,
    isLoading: isLoadingOrganizationInvitations,
    isError: isErrorOrganizationInvitations,
  } = useGetUserInvitationsQuery();

  // Handlers
  const handleClickNewOrganization = () => {
    navigate("/organization-onboarding");
  };

  return (
    <ErrorHandling
      isLoading={isLoadingOrganizationInvitations}
      isError={isErrorOrganizationInvitations}
    >
      <OrganizationDesktopWrapper>
        <OrganizationDesktopBoxContainer>
          <ActiveOrganizationHeaderContainer>
            <Box>
              <ActiveOrganizationHeading variant="h5">
                {organizations?.length > 1
                  ? getTranslation("ACTIVE_ORGANIZATION", t, i18n)
                  : getTranslation("organization", t, i18n)}
              </ActiveOrganizationHeading>
            </Box>

            {!organizations?.find((o) => o.owner === true) && (
              <Box>
                <SecondaryContrastButton
                  id="add-new-organization"
                  onClick={handleClickNewOrganization}
                  variant="text"
                >
                  <StyledAddIcon globalFontSize={globalFontSize} />
                  <InviteButtonText>
                    {getTranslation("ADD_NEW_ORGANIZATION", t, i18n)}
                  </InviteButtonText>
                </SecondaryContrastButton>
              </Box>
            )}
          </ActiveOrganizationHeaderContainer>

          {activeOrganization && (
            <OrganizationDesktopCardStackContainer
              direction="row"
              flexWrap="wrap"
              gap={2}
            >
              <OrganizationDesktopCard
                organization={activeOrganization}
                isActive={true}
              />
            </OrganizationDesktopCardStackContainer>
          )}

          {organizations?.length > 1 && (
            <>
              <OtherOrganizationsHeading variant="h5">
                {getTranslation("OTHER_ORGANIZATIONS", t, i18n)}
              </OtherOrganizationsHeading>

              <OrganizationDesktopCardStackContainer
                direction="row"
                flexWrap="wrap"
                gap={2}
              >
                {organizations
                  ?.filter(
                    (organization) => organization.id !== activeOrganization?.id
                  )
                  ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                  ?.slice()
                  ?.map((organization) => (
                    <OrganizationDesktopCard
                      key={organization.id}
                      organization={organization}
                    />
                  ))}
              </OrganizationDesktopCardStackContainer>
            </>
          )}

          {organizationInvitationsData?.length > 0 && (
            <OrganizationDesktopCardStackContainer container>
              <OtherOrganizationsHeading variant="h5">
                {getTranslation("organization-invitations-title", t, i18n) +
                  "..."}
              </OtherOrganizationsHeading>

              <OrganizationDesktopInvitationsContainer>
                {organizationInvitationsData?.map((invitation) => {
                  return (
                    <Fragment key={invitation.organization.id}>
                      <OrganizationInvitationCard invitation={invitation} />
                      <StyledDivider />
                    </Fragment>
                  );
                })}
              </OrganizationDesktopInvitationsContainer>
            </OrganizationDesktopCardStackContainer>
          )}
        </OrganizationDesktopBoxContainer>
      </OrganizationDesktopWrapper>
    </ErrorHandling>
  );
};

export default OrganizationDesktop;
