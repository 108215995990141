import {
  get_current_subscription_actual_usage_url,
  get_current_subscription_url,
} from "../../../Constants";
import KeycloakService from "../../../services/KeycloakService";
import { baseApiSlice } from "./baseApiSlice";

export const subscriptionsApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentSubscription: builder.query({
      query: ({ organizationId }) => ({
        url: get_current_subscription_url.replace(
          ":organizationId",
          organizationId
        ),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["Subscription"],
    }),
    getCurrentSubscriptionActualUsage: builder.query({
      query: ({ organizationId }) => ({
        url: get_current_subscription_actual_usage_url.replace(
          ":organizationId",
          organizationId
        ),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["Subscription"],
    }),
  }),
});

export const {
  useGetCurrentSubscriptionQuery,
  useGetCurrentSubscriptionActualUsageQuery,
} = subscriptionsApiSlice;
