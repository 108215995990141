import { useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  delimiterLocalize,
  getTranslation,
  localizeDate,
  measurementDisplay,
} from "../../../util/utils";
import { ListItemContainer } from "../../styles/assets/asset-detail/AssetDetail.styles";
import {
  PrimaryText,
  SecondaryText,
} from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";

const CharacteristicItem = ({ characteristic, region }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Other variables
  const { value, measurementUnit, name, dataType } = characteristic;

  const { value: characteristicValue, unit } = measurementDisplay({
    value,
    unit: measurementUnit,
    region: region,
    reverse: false,
  });

  const isTablet = tabletMatches || desktopMatches ? 1 : 0;

  return (
    <ListItemContainer
      istablet={isTablet}
      id={"characteristic-" + name.toLowerCase()}
    >
      <SecondaryText variant="body2" id="characteristics_label">
        {getTranslation(name, t, i18n)}
      </SecondaryText>

      <PrimaryText id="characteristics-value">
        {`${
          !isNaN(characteristicValue) && dataType !== "STRING"
            ? Number(characteristicValue).toLocaleString(
                delimiterLocalize(region)
              )
            : name === "LIFECYCLE_DATE" || dataType === "DATETIME"
            ? localizeDate(value, region)
            : value
        } ${unit}`}
      </PrimaryText>
    </ListItemContainer>
  );
};

export default CharacteristicItem;
