import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const ForbiddenContainer = styled(Box)({
  height: "100%",
});

export const ErrorText = styled(Typography)({
  text: "red",
});
