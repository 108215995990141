import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { messageError, messageSuccess } from "../../util/notification";
import PageTransition from "../../components/PageTransition";
import {
  transitionDirections,
  getTranslation,
  showValidationError,
} from "../../util/utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";
import ErrorHandling from "../../components/common/ErrorHandling";
import { useUploadResourceAttachmentMutation } from "../../store/slices/api/resourceAttachmentsApiSlice";
import {
  addResourceAttachment,
  DEFAULT_RESOURCE_ATTACHMENT,
} from "../../store/slices/resourceAttachmentSlice";
import ResourceAttachmentForm from "../../components/assets/asset-form/ResourceAttachmentForm";
import AddAttachmentHeader from "../../navigation/header/assets/AddAttachmentHeader";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const AddLocalResourceAttachmentPage = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { resourceId } = useParams();

  // Selectors
  const user = useSelector(selectUser);

  // Mutations
  const [uploadResourceAttachment, { isLoading: isLoadingUpload }] =
    useUploadResourceAttachmentMutation();

  // State
  const [resourceAttachmentInput, setResourceAttachmentInput] = useState(
    DEFAULT_RESOURCE_ATTACHMENT
  );

  const [name, setName] = useState("");
  const [extension, setExtension] = useState("");

  // Other variables
  const direction = searchParams.get("direction");

  // Handlers
  const cancelHandler = () => {
    if (resourceId) {
      navigate(
        `/resources/${resourceId}/edit?direction=${transitionDirections.TOP_TO_BOTTOM}&previousPage=add-local-attachment`
      );
    } else {
      navigate(
        `/resources/add-new?direction=${transitionDirections.TOP_TO_BOTTOM}&previousPage=add-local-attachment`
      );
    }
  };

  // Other variables
  const isValid =
    (resourceAttachmentInput.file || resourceAttachmentInput.selectedFile) &&
    name;

  const isDisabled = !isValid;

  const handleSubmit = useCallback(async () => {
    try {
      if (!resourceAttachmentInput.selectedFile) {
        messageError(getTranslation("UPLOAD_IMAGE", t, i18n));
        return;
      }

      if (resourceId) {
        const formData = new FormData();

        const { file, description } = resourceAttachmentInput;

        formData.append("file", file);
        formData.append("fileName", name + extension);
        formData.append("description", description);

        await uploadResourceAttachment({
          formData,
          resourceid: resourceId,
          organizationId: user?.organizations?.find((o) => o.default)?.id,
        }).unwrap();

        messageSuccess(
          getTranslation("RESOURCE_ATTACHMENT_CREATED_SUCCESSFULLY", t, i18n)
        );
      } else {
        const input = {
          ...resourceAttachmentInput,
          name: name + extension,
        };

        dispatch(addResourceAttachment(input));
      }

      cancelHandler();
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to upload resource attachment", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceAttachmentInput, name]);

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling isLoading={isLoadingUpload}>
          <PageTransition direction={direction}>
            <AddAttachmentHeader
              handleAction={handleSubmit}
              handleCancelAction={cancelHandler}
              isDisabled={isDisabled}
            />

            <HomePagePadding>
              <ResourceAttachmentForm
                resourceAttachmentInput={resourceAttachmentInput}
                setResourceAttachmentInput={setResourceAttachmentInput}
                name={name}
                setName={setName}
                setExtension={setExtension}
              />
            </HomePagePadding>
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default AddLocalResourceAttachmentPage;
