import { List, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RouletteSpinnerOverlay } from "react-spinner-overlay";
import { selectUser } from "../../../store/slices/authSlice";
import { useGetTypeDetailsQuery } from "../../../store/slices/api/typesApiSlice";
import TypeCharacteristicGroup from "./TypeCharacteristicGroup";
import TypeInfo from "./TypeInfo";
import ErrorHandling from "../../common/ErrorHandling";

const TypeDetailList = () => {
  // General hooks
  const theme = useTheme();
  const { typeId } = useParams();

  // Selectors
  const user = useSelector(selectUser);

  // Queries
  const {
    data: typeData,
    isLoading,
    isError,
  } = useGetTypeDetailsQuery({
    typeId,
    organizationId: user?.organizations?.find((o) => o.default).id,
  });

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <List key={"asset-detail-main-list"} className="no-padding">
        <TypeInfo typeData={typeData} />
        <TypeCharacteristicGroup typeData={typeData} />
      </List>
    </ErrorHandling>
  );
};

export default TypeDetailList;
