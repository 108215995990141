import { useSelector } from "react-redux";
import {
  useDeleteResourceImageMutation,
  useGetResourceImageQuery,
} from "../../../store/slices/api/resourceImagesApiSlice";
import { selectUser } from "../../../store/slices/authSlice";
import ErrorHandling from "../../common/ErrorHandling";
import CanvasImage from "../../common/CanvasImage";
import { useNavigate, useParams } from "react-router-dom";
import { forwardRef, useState } from "react";
import { Slide, useMediaQuery } from "@mui/material";
import {
  CancelButton,
  ChangeRoleButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "../../styles/general/General.styles";
import { StyledDivider } from "../../styles/profile/Profile.styles";
import {
  DeleteAttachmentButton,
  StyledDeleteIcon,
  StyledDividerWithSpace,
  StyledDownloadIcon,
  StyledEditIcon,
} from "../../styles/assets/asset-attachments/AssetResourceAttachment.styles";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  RESOURCE_CATEGORIES,
  transitionDirections,
} from "../../../util/utils";
import { useTranslation } from "react-i18next";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import { messageSuccess } from "../../../util/notification";
import { useUserRolePermissionsQuery } from "../../../store/slices/api/userManagementSlice";
import {
  useGetAllFunctionsQuery,
  useGetResourceDetailsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import EditImageDialog from "../asset-form/EditImageDialog";
import { selectParentId } from "../../../store/slices/columnViewSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CarouselImage = ({ resourceImage, index }) => {
  // General hooks
  const { resourceid } = useParams();
  const { t, i18n } = useTranslation();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const navigate = useNavigate();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const parentId = useSelector(selectParentId);

  // State
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openEditImage, setOpenEditImage] = useState(false);

  // Queries
  const {
    data: resourceImageUri,
    isLoading,
    isError,
    error,
  } = useGetResourceImageQuery(
    {
      imageUri: resourceImage ? resourceImage?.uri : "",
      organizationId: user?.organizations?.find((o) => o.default).id,
    },
    {
      skip: !resourceImage,
    }
  );

  const { data: userRoles, isLoading: isLoadingUserRoles } =
    useUserRolePermissionsQuery({
      organizationId: user?.organizations?.find((o) => o.default)?.id,
      userId: user?.id,
    });

  const { data: allFunctionsData, isLoading: isLoadingFunctions } =
    useGetAllFunctionsQuery({
      organizationId: user?.organizations?.find((o) => o.default)?.id,
    });

  const { data: resourceData, isLoading: isLoadingResourceData } =
    useGetResourceDetailsQuery(
      {
        resourceid: resourceid || parentId,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      },
      { skip: !Boolean(resourceid) && !Boolean(parentId) }
    );

  // Mutations
  const [deleteResourceImageMutation, { isLoading: isLoadingDelete }] =
    useDeleteResourceImageMutation();

  // Other variables
  const transformedImage = {
    imageCategory: resourceImage.imageCategory,
    selectedFile: resourceImageUri,
    resourceid: resourceid || parentId,
    id: resourceImage.id,
    name: resourceImage.name,
    mimeType: resourceImage.name?.substring(
      resourceImage.name?.lastIndexOf(".")
    ),
    description: resourceImage.description,
    file: null,
  };

  // Handlers
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenEditImage = () => {
    if (mobileMatches) {
      navigate(
        `/resources/${resourceid}/edit-image/${resourceImage?.id}?direction=${transitionDirections.RIGHT_TO_LEFT}`
      );
    } else {
      setOpenEditImage(true);
    }
  };

  const handleDownloadResourceImage = () => {
    const { name } = resourceImage;
    const link = document.createElement("a");
    link.href = resourceImageUri;

    link.download = name; // Set the original file name as the downloaded file name

    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteConfirm = () => {
    setOpenConfirm(true);
  };

  const handleDeleteResourceImage = async (index, resourceImageId) => {
    try {
      await deleteResourceImageMutation({
        resourceid: resourceid || parentId,
        organizationId: user?.organizations?.find((o) => o.default).id,
        resourceImageId,
      }).unwrap();
    } catch (error) {
      console.error("Failed to delete resource image", error);
    }

    messageSuccess(
      getTranslation("RESOURCE_IMAGE_DELETED_SUCCESSFULLY", t, i18n)
    );
  };

  const handlePermissions = (key) => {
    if (!Boolean(resourceid) && !parentId) {
      return true;
    }

    const functionDetails = allFunctionsData?.find(
      (f) => f.id === resourceData?.functionId
    );

    const permissionsKey = `ASSET_MANAGEMENT_${
      functionDetails?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET
        ? "HARDWARE"
        : functionDetails?.category
    }_${key.toUpperCase()}`;

    if (
      hasAccess(
        "all",
        [permissions[permissionsKey]],
        getPermissionsFromUserRoles(userRoles)
      )
    ) {
      return true;
    }

    return false;
  };

  return (
    <ErrorHandling
      isLoading={
        isLoading ||
        isLoadingDelete ||
        isLoadingUserRoles ||
        isLoadingFunctions ||
        isLoadingResourceData
      }
      isError={error?.status !== 404 && isError}
    >
      {resourceImageUri && (
        <div
          style={{ marginTop: "10px", pointer: "cursor" }}
          onClick={handleClickOpen}
        >
          <CanvasImage
            id={resourceImage.name}
            height={100}
            width={100}
            image={
              resourceImage?.name?.endsWith(".svg")
                ? `${process.env.PUBLIC_URL}/icons/${resourceImage?.name}`
                : resourceImageUri
            }
            offsetX={0}
          />
        </div>
      )}

      <StyledDialog
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <StyledDialogContent>
          <StyledDialogTitle>{resourceImage.name}</StyledDialogTitle>
          <StyledDivider />

          {handlePermissions("edit") && (
            <ChangeRoleButton
              id={`edit-resource-image`}
              onClick={() => {
                handleClickOpenEditImage();
                handleClose();
              }}
            >
              <StyledEditIcon customfontsize={globalFontSize * 1.5} />
              {getTranslation("EDIT", t, i18n)}
            </ChangeRoleButton>
          )}

          <StyledDivider />

          <ChangeRoleButton
            id={`download-resource-image`}
            onClick={() => {
              handleDownloadResourceImage();
              handleClose();
            }}
          >
            <StyledDownloadIcon customfontsize={globalFontSize * 1.5} />
            {getTranslation("DOWNLOAD", t, i18n)}
          </ChangeRoleButton>

          {handlePermissions("delete") && (
            <>
              <StyledDividerWithSpace />

              <DeleteAttachmentButton
                id={`delete-resource-image`}
                data-testid={`delete-resource-image-${index}`}
                color="error"
                onClick={() => {
                  handleClose();
                  handleDeleteConfirm();
                }}
              >
                <StyledDeleteIcon customfontsize={globalFontSize * 1.5} />
                {getTranslation("DELETE", t, i18n)}
              </DeleteAttachmentButton>
            </>
          )}
        </StyledDialogContent>

        <CancelButton id={`cancel-button`} onClick={handleClose}>
          {getTranslation("CANCEL", t, i18n)}
        </CancelButton>
      </StyledDialog>

      <ConfirmAlert
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        alert={{
          content: getTranslation("ASSET_IMAGE_DELETE_MESSAGE", t, i18n),
          confirmTitle: getTranslation("DELETE_ASSET_IMAGE", t, i18n),
          closeTitle: getTranslation("CANCEL", t, i18n),
          showConfirm: true,
        }}
        label="delete-image"
        handleConfirm={() =>
          handleDeleteResourceImage(index, resourceImage?.id)
        }
      />

      {openEditImage && (
        <EditImageDialog
          resourceId={resourceid || parentId}
          open={openEditImage}
          setOpen={setOpenEditImage}
          resourceImage={transformedImage}
        />
      )}
    </ErrorHandling>
  );
};

export default CarouselImage;
