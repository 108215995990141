import {
  ListItemIcon,
  MenuItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectPageView,
  selectSortingOption,
  setPageView,
  setSortingOption,
  SORTING_OPTIONS,
} from "../../store/slices/appSlice";
import { useTranslation } from "react-i18next";
import { getTranslation, transitionDirections } from "../../util/utils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppViewListItemIcon,
  ColumnViewListItemIcon,
  EllipsisListItemText,
  InlineViewListItemIcon,
  SortingOptionListItemIcon,
  StyledAppsIcon,
  StyledAppsSmallerIcon,
  StyledCheckIcon,
  StyledDivider,
  StyledFormatAlignLeftIcon,
  StyledFormatAlignLeftSmallerIcon,
  StyledKeyboardDownIcon,
  StyledKeyboardUpIcon,
  StyledListColumnViewIcon,
  StyledOptionsMenu,
  StyledViewColumnIcon,
} from "../../components/styles/menu/OptionsMenu.styles";
import { selectParentId } from "../../store/slices/columnViewSlice";

const OptionsMenu = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Selectors
  const currentpageview = useSelector(selectPageView);
  const sortingOption = useSelector(selectSortingOption);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const parentId = useSelector(selectParentId);

  // State
  const [anchorEl, setAnchorEl] = useState(null);

  // Other variables
  const open = Boolean(anchorEl);

  const menuListProps = {
    "aria-labelledby": "basic-button",
  };

  const anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  };

  const transformOrigin = {
    vertical: "top",
    horizontal: "left",
  };

  // Handlers
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFormatClick = (value) => {
    dispatch(setPageView(value));

    if (currentpageview === "column") {
      navigate(
        `/resources${parentId ? `/${parentId}` : ""}?direction=${
          transitionDirections.LEFT_TO_RIGHT
        }`
      );
    }

    handleClose();
  };

  const handleSortingOptionClick = (option) => {
    dispatch(setSortingOption(option));
  };

  const handleOrderClick = (e) => {
    e.stopPropagation();

    dispatch(
      setSortingOption({
        ...sortingOption,
        order: sortingOption.order === "asc" ? "desc" : "asc",
      })
    );
  };

  const handleRenderCorrectViewIcon = () => {
    if (currentpageview === "inline") {
      return (
        <StyledFormatAlignLeftIcon
          onClick={handleClick}
          currentpageview={currentpageview}
          globalFontSize={globalFontSize}
          id="inline-view"
        />
      );
    } else if (currentpageview === "app") {
      return (
        <StyledAppsIcon
          onClick={handleClick}
          currentpageview={currentpageview}
          globalFontSize={globalFontSize}
          id="grid-view"
        />
      );
    } else if (currentpageview === "column") {
      return (
        <StyledListColumnViewIcon
          onClick={handleClick}
          currentpageview={currentpageview}
          globalFontSize={globalFontSize}
          id="column-view"
        />
      );
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        {handleRenderCorrectViewIcon()}
      </IconButton>
      <StyledOptionsMenu
        id="options-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={menuListProps}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {pathname.includes("/resources") && (
          <MenuItem
            onClick={() => {
              handleFormatClick("column");
              navigate("/resources");
            }}
          >
            <ColumnViewListItemIcon currentpageview={currentpageview}>
              <StyledCheckIcon globalFontSize={globalFontSize} />
            </ColumnViewListItemIcon>
            <EllipsisListItemText>
              {getTranslation("COLUMNS", t, i18n)}
            </EllipsisListItemText>
            <ListItemIcon>
              <StyledViewColumnIcon globalFontSize={globalFontSize} />
            </ListItemIcon>
          </MenuItem>
        )}

        <MenuItem onClick={() => handleFormatClick("inline")}>
          <InlineViewListItemIcon currentpageview={currentpageview}>
            <StyledCheckIcon globalFontSize={globalFontSize} />
          </InlineViewListItemIcon>
          <ListItemText>{getTranslation("LIST", t, i18n)}</ListItemText>
          <ListItemIcon>
            <StyledFormatAlignLeftSmallerIcon globalFontSize={globalFontSize} />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={() => handleFormatClick("app")}>
          <AppViewListItemIcon currentpageview={currentpageview}>
            <StyledCheckIcon globalFontSize={globalFontSize} />
          </AppViewListItemIcon>
          <ListItemText>{getTranslation("TILES", t, i18n)}</ListItemText>
          <ListItemIcon>
            <StyledAppsSmallerIcon globalFontSize={globalFontSize} />
          </ListItemIcon>
        </MenuItem>
        {pathname !== "/recents" && (
          <>
            <StyledDivider />
            {SORTING_OPTIONS.filter((option) => option.value !== "Z-A").map(
              (option) => {
                const { value } = option;
                return (
                  <MenuItem
                    onClick={() => handleSortingOptionClick(option)}
                    key={value}
                  >
                    <SortingOptionListItemIcon
                      sortingOption={sortingOption}
                      value={value}
                    >
                      <StyledCheckIcon globalFontSize={globalFontSize} />
                    </SortingOptionListItemIcon>
                    <ListItemText>
                      {value === "A-Z"
                        ? getTranslation("NAME", t, i18n)
                        : getTranslation(value, t, i18n)}
                    </ListItemText>
                    <SortingOptionListItemIcon
                      sortingOption={sortingOption}
                      value={value}
                      onClick={handleOrderClick}
                    >
                      {sortingOption.order === "asc" ? (
                        <StyledKeyboardUpIcon globalFontSize={globalFontSize} />
                      ) : (
                        <StyledKeyboardDownIcon
                          globalFontSize={globalFontSize}
                        />
                      )}
                    </SortingOptionListItemIcon>
                  </MenuItem>
                );
              }
            )}
          </>
        )}
      </StyledOptionsMenu>
    </>
  );
};

export default OptionsMenu;
