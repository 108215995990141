import React, { useState } from "react";
import { Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsSubscribed,
  selectShowNotifications,
  selectUserTheme,
  setIsSubscribed,
  setTheme,
  setUserTheme,
} from "../../store/slices/appSlice";
import {
  THEME,
  THEME_EXPIRATION_DAYS,
  getFallBackLanguage,
  getTranslation,
  supportedLanguages,
  urlBase64ToUint8Array,
} from "../../util/utils";
import { SecondaryText } from "../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import {
  SettingsContainer,
  ThemeText,
} from "../styles/profile/DesktopSettings.styles";
import ThemeCard from "./ThemeCard";
import { StyledSwitch } from "../styles/general/General.styles";
import SelectInput from "../SelectInput";
import { refreshUser, selectUser } from "../../store/slices/authSlice";
import {
  useGetRegionsQuery,
  useGetTimeZonesQuery,
  useGetUserIPInfoQuery,
  useUpdateUserLanguageMutation,
  useUpdateUserRegionalPreferenceMutation,
  useUpdateUserTimeZoneMutation,
} from "../../store/slices/api/userManagementSlice";
import { messageError, messageSuccess } from "../../util/notification";
import { transformTimeZonesData } from "../../pages/profile-pages/TimeZone";
import { useSubscribe } from "react-pwa-push-notifications";
import { useSubscribeMutation } from "../../store/slices/api/notificationsApiSlice";
import { PUBLIC_PUSH_KEY } from "../../App";
import ErrorHandling from "../common/ErrorHandling";
import { setCookie } from "../../util/cookies";
import AutomaticThemeCard from "./AutomaticThemeCard";

const DesktopSettings = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { getSubscription } = useSubscribe({ publicKey: PUBLIC_PUSH_KEY });

  // Selectors
  const user = useSelector(selectUser);

  const {
    language: userLanguage,
    region: userRegion,
    timeZone: userTimeZone,
  } = user;

  const userTheme = useSelector(selectUserTheme);
  const showNotifications = useSelector(selectShowNotifications);
  const isSubscribed = useSelector(selectIsSubscribed);

  // States
  const [isAutomaticTimeZone, setIsAutomaticTimeZone] = useState(false);
  const [localTimeZone, setLocalTimeZone] = useState(userTimeZone);
  const [localLanguage, setLocalLanguage] = useState(userLanguage);
  const [localRegion, setLocalRegion] = useState(userRegion);

  // Queries
  const {
    data: timeZonesData,
    isLoading: isLoadingTimeZones,
    isError: isErrorTimeZones,
  } = useGetTimeZonesQuery();
  const {
    data: regionsData,
    isLoading: isLoadingRegions,
    isError: isErrorRegions,
  } = useGetRegionsQuery();
  const { data: userIPInfoData } = useGetUserIPInfoQuery();

  // Mutations
  const [updateUserTimeZone] = useUpdateUserTimeZoneMutation();
  const [updateUserLanguage] = useUpdateUserLanguageMutation();
  const [updateUserRegion] = useUpdateUserRegionalPreferenceMutation();
  const [subscribe] = useSubscribeMutation();

  const transformLanguagesData = (languages) =>
    languages?.map((language) => {
      return {
        value: language.code,
        label: t(language.code, { lng: language.code }),
      };
    });

  const transformRegionsData = (regions) =>
    regions?.map((region) => {
      return {
        value: region.code,
        label: getTranslation(region.code, t, i18n),
      };
    });

  const handleTimeZoneChange = async (value) => {
    setLocalTimeZone(value);

    try {
      const existingTimeZone = timeZonesData.find((tz) => {
        return tz.name === value;
      });

      const data = await updateUserTimeZone({
        userId: user?.id,
        timeZone: existingTimeZone.name,
      }).unwrap();

      if (data) {
        dispatch(refreshUser(data));
      }

      messageSuccess(getTranslation("successfulUpdateUserTimeZone", t, i18n));
    } catch (error) {
      messageError(getTranslation("failedUpdateUserTimeZone", t, i18n));
    }
  };

  const handleLanguageChange = async (value) => {
    setLocalLanguage(value);

    try {
      const data = await updateUserLanguage({
        userId: user.id,
        language: value,
      }).unwrap();

      if (data) {
        dispatch(refreshUser(data));
        i18n.changeLanguage(data.language);
        i18n.options.fallbackLng = getFallBackLanguage(data.language);
      }

      messageSuccess(
        `${getTranslation(
          `successfulUpdateLanguage_${data.language}`,
          t,
          i18n
        )}`
      );
    } catch (error) {
      messageError(getTranslation("failedUpdateLanguage", t, i18n));
    }
  };

  const handleRegionChange = async (value) => {
    setLocalRegion(value);
    try {
      const data = await updateUserRegion({
        userId: user?.id,
        regionalPreference: value,
      }).unwrap();

      dispatch(refreshUser(data));
      messageSuccess(
        getTranslation("successfulUpdateUserRegionalPreference", t, i18n)
      );
    } catch (error) {
      messageError(
        getTranslation("failedUpdateUserRegionalPreference", t, i18n)
      );
    }
  };

  const handleThemeChange = async (themeValue) => {
    try {
      // const data = await updateUserTheme({
      //   userId: id,
      //   organizationId,
      //   theme: themeValue,
      // }).unwrap();

      // if (data) {
      //   dispatch(refreshUser(data));
      // }
      if (themeValue !== THEME.AUTOMATIC) {
        dispatch(setTheme(themeValue));
      } else {
        if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
          // Dark mode
          dispatch(setTheme(THEME.DARK));
        } else {
          // Light mode
          dispatch(setTheme(THEME.LIGHT));
        }
      }

      setCookie("theme", themeValue, THEME_EXPIRATION_DAYS);
      dispatch(setUserTheme(themeValue));
    } catch (error) {}
  };

  const handleToggleAutomaticTimeZone = async () => {
    const newIsAutomaticTimeZone = !isAutomaticTimeZone;
    setIsAutomaticTimeZone(newIsAutomaticTimeZone);

    if (newIsAutomaticTimeZone) {
      const timeZoneMatch = timeZonesData.find(
        (t) =>
          t.description.includes(userIPInfoData?.country_capital) &&
          t.ISO3166 === userIPInfoData?.country
      );

      if (!timeZoneMatch) return;
      setLocalTimeZone(timeZoneMatch.name);

      try {
        const data = await updateUserTimeZone({
          userId: user?.id,
          timeZone: timeZoneMatch.name,
        }).unwrap();

        if (data) {
          dispatch(refreshUser(data));
        }
        messageSuccess(getTranslation("successfulUpdateUserTimeZone", t, i18n));
      } catch (error) {
        messageError(getTranslation("failedUpdateUserTimeZone", t, i18n));
      }
    }
  };

  const onSubscribe = async () => {
    try {
      const subscription = await getSubscription();

      const notificationsSubscribeDto = {
        endpoint: subscription.endpoint,
        p256dh: subscription.toJSON().keys.p256dh,
        auth: subscription.toJSON().keys.auth,
      };

      await subscribe({
        notificationsSubscribeDto,
      }).unwrap();

      dispatch(setIsSubscribed(true));

      messageSuccess(getTranslation("SUBSCRIBED_FOR_NOTIFICATIONS", t, i18n));
    } catch (e) {
      if (e.errorCode === "ExistingSubscription") {
        const registration = await navigator.serviceWorker.ready;
        const convertedVapidKey = urlBase64ToUint8Array(PUBLIC_PUSH_KEY);

        const existingSubscription = await registration.pushManager.subscribe({
          applicationServerKey: convertedVapidKey,
          userVisibleOnly: true,
        });

        const notificationsSubscribeDto = {
          endpoint: existingSubscription.endpoint,
          p256dh: existingSubscription.toJSON().keys.p256dh,
          auth: existingSubscription.toJSON().keys.auth,
        };

        await subscribe({
          notificationsSubscribeDto,
        }).unwrap();

        dispatch(setIsSubscribed(true));

        messageSuccess(getTranslation("SUBSCRIBED_FOR_NOTIFICATIONS", t, i18n));
      } else {
        console.error("Subscribe to push failed", e);
      }
    }
  };

  const onUnsubscribe = async () => {
    const registration = await navigator.serviceWorker.ready;

    const existingSubscription =
      await registration.pushManager.getSubscription();

    const result = await existingSubscription.unsubscribe();

    if (result) {
      dispatch(setIsSubscribed(false));

      messageSuccess(
        getTranslation("UNSUBSCRIBED_FROM_NOTIFICATIONS", t, i18n)
      );
    }
  };

  const handleChange = async () => {
    if (isSubscribed) {
      await onUnsubscribe();
    } else {
      await onSubscribe();
    }
  };

  return (
    <SettingsContainer spacing={4}>
      <Stack spacing={2}>
        <SecondaryText gutterBottom variant="h6" fontWeight="bolder">
          {getTranslation("theme", t, i18n)}
        </SecondaryText>
        <Grid spacing={3} container>
          <Grid item sm={2.5} md={2} lg={1.5}>
            <AutomaticThemeCard
              theme="automatic"
              handleClick={() => handleThemeChange(THEME.AUTOMATIC)}
              active={userTheme === THEME.AUTOMATIC}
            />
            <ThemeText align="center">
              {getTranslation("AUTOMATIC", t, i18n)}
            </ThemeText>
          </Grid>
          <Grid item sm={2.5} md={2} lg={1.5}>
            <ThemeCard
              theme="light"
              handleClick={() => handleThemeChange(THEME.LIGHT)}
              active={userTheme === THEME.LIGHT}
            />
            <ThemeText align="center">
              {getTranslation("LIGHT", t, i18n)}
            </ThemeText>
          </Grid>
          <Grid item sm={2.5} md={2} lg={1.5}>
            <ThemeCard
              theme="dark"
              handleClick={() => handleThemeChange(THEME.DARK)}
              active={userTheme === THEME.DARK}
            />
            <ThemeText align="center">
              {getTranslation("DARK", t, i18n)}
            </ThemeText>
          </Grid>
        </Grid>
      </Stack>

      {showNotifications && (
        <Stack spacing={1}>
          <SecondaryText gutterBottom variant="h6" fontWeight="bolder">
            {getTranslation("NOTIFICATIONS", t, i18n)}
          </SecondaryText>
          <Stack direction="row" spacing={1} alignItems="center">
            <StyledSwitch checked={isSubscribed} onClick={handleChange} />
          </Stack>
        </Stack>
      )}

      <ErrorHandling isLoading={isLoadingTimeZones} isError={isErrorTimeZones}>
        <Stack spacing={1}>
          <SecondaryText gutterBottom variant="h6" fontWeight="bolder">
            {getTranslation("TIME_ZONE", t, i18n)}
          </SecondaryText>
          <Stack direction="row" spacing={1} alignItems="center">
            <StyledSwitch onClick={handleToggleAutomaticTimeZone} />
            <SecondaryText>
              Set time zone automatically using your current location
            </SecondaryText>
          </Stack>
          <Grid container>
            <Grid item xs={3.6}>
              <SelectInput
                fullWidth
                label="timeZone"
                selectLabelId="timezone-select"
                value={localTimeZone}
                handleChange={handleTimeZoneChange}
                data={transformTimeZonesData(timeZonesData)}
                disabled={isAutomaticTimeZone}
                disableClearable
                isSelect={false}
              />
            </Grid>
          </Grid>
        </Stack>
      </ErrorHandling>

      <Stack spacing={1}>
        <SecondaryText gutterBottom variant="h6" fontWeight="bolder">
          {`${getTranslation("LANGUAGE", t, i18n)} & ${getTranslation(
            "REGION",
            t,
            i18n
          )}`}
        </SecondaryText>
        <Grid container gap={1} alignItems="center">
          <Grid item xs={3.6}>
            <SelectInput
              disableClearable
              label="LANGUAGE"
              selectLabelId="language-location-select"
              value={localLanguage}
              name="language"
              handleChange={handleLanguageChange}
              data={transformLanguagesData(supportedLanguages)}
            />
          </Grid>
          <ErrorHandling isLoading={isLoadingRegions} isError={isErrorRegions}>
            <Grid item xs={3.6}>
              <SelectInput
                disableClearable
                fullWidth
                label="regionalPreference"
                selectLabelId="regional-preference-select"
                value={localRegion}
                handleChange={handleRegionChange}
                data={transformRegionsData(regionsData, t, i18n)}
              />
            </Grid>
          </ErrorHandling>
        </Grid>
      </Stack>
    </SettingsContainer>
  );
};

export default DesktopSettings;
