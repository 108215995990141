import { useTheme } from "@mui/material";
import { RouletteSpinnerOverlay } from "react-spinner-overlay";

const LoadingSpinnerOverlay = () => {
  // General hooks
  const theme = useTheme();

  return (
    <div id="loading-spinner-overlay">
      <RouletteSpinnerOverlay
        loading={true}
        color={theme.palette.secondary.contrastText}
        overlayColor={theme.palette.primary.contrastText}
      />
    </div>
  );
};

export default LoadingSpinnerOverlay;
