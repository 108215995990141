import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TextField, Box } from "@mui/material";
import {
  InputContainer,
  ValidationText,
} from "../../components/styles/inputs/NamesGroup.styles";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { useDispatch, useSelector } from "react-redux";
import { selectPageInfo, setPageInfo } from "../../store/slices/appSlice";
import {
  selectUserData,
  setUserData,
} from "../../store/slices/onboardingSlice";
import { selectUser, setUser } from "../../store/slices/authSlice";
import { useUpdateUserNicknameMutation } from "../../store/slices/api/userManagementSlice";
import { messageError, messageSuccess } from "../../util/notification";
import { RouletteSpinnerOverlay } from "react-spinner-overlay";
import { useTheme } from "@mui/material";

import PageTransition from "../../components/PageTransition";
import {
  transitionDirections,
  getTranslation,
  testInput,
} from "../../util/utils";
import UpdateUserNameHeader from "../../navigation/header/profile/UpdateUserNameHeader";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";

const UpdateUserNickname = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  // Selectors
  const authUser = useSelector(selectUser);
  const userData = useSelector(selectUserData);
  const pageInfo = useSelector(selectPageInfo);

  const [nicknameValid, setNicknameValid] = useState(true);
  // Mutations
  const [updateUserNickname, { isLoading: isLoadingUpdateUserNickname }] =
    useUpdateUserNicknameMutation();

  // Other variables
  const transitionDirection = searchParams.get("direction");
  const doneAction = searchParams.get("done-action");

  // Handlers
  const handleActionButtonDisabled = (isValid) => {
    dispatch(
      setPageInfo({
        ...pageInfo,
        isActionButtonDisabled: !isValid,
      })
    );
  };

  const cancelHandler = () => {
    navigate(
      `/profile/userdetail?direction=${transitionDirections.TOP_TO_BOTTOM}`
    );
  };

  // Handlers
  const handleNicknameChange = (event) => {
    const isValid =
      doneAction !== "community" && !event.target.value
        ? true
        : testInput.NICKNAME(event.target.value);

    dispatch(setUserData({ ...userData, nickname: event.target.value }));
    setNicknameValid(isValid);
    handleActionButtonDisabled(isValid);
  };

  const handleSubmit = useCallback(async () => {
    const isValidNickname =
      doneAction !== "community" && !userData.nickname
        ? true
        : testInput.NICKNAME(userData.nickname);

    if (!isValidNickname) {
      messageError(
        `${getTranslation("nickname", t, i18n)}: ${getTranslation(
          "nicknameInfoValidation",
          t,
          i18n
        )}`
      );
      return;
    }

    try {
      const data = await updateUserNickname({
        userId: userData.id,
        nickname: Boolean(userData.nickname) ? userData.nickname : null,
      }).unwrap();

      if (data) {
        dispatch(
          setUser({
            ...authUser,
            nickname: data.nickname,
          })
        );
      }

      messageSuccess(getTranslation("successfulUpdateUserNickname", t, i18n));
      if (
        doneAction !== null &&
        doneAction !== undefined &&
        doneAction === "community"
      ) {
        navigate("/community");
      } else {
        navigate(
          `/profile/userdetail?direction=${transitionDirections.TOP_TO_BOTTOM}`
        );
      }
    } catch (error) {
      messageError(getTranslation("failedUpdateUserName", t, i18n));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, dispatch]);

  // Effects
  useEffect(() => {
    const currentUser = {
      ...authUser,
      nickname: authUser.nickname,
    };

    dispatch(setUserData(currentUser));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    //Show message for required nickName. If doneAction equals to community, it means nickname required and user redirected to provide it.
    if (
      doneAction !== null &&
      doneAction !== undefined &&
      doneAction === "community"
    ) {
      messageError(getTranslation("nicknameRequiredForForumPortal", t, i18n));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppAccess>
      <ErrorHandling isLoading={isLoadingUpdateUserNickname} isError={false}>
        <PageTransition direction={transitionDirection}>
          <UpdateUserNameHeader
            handleAction={handleSubmit}
            handleCancelAction={cancelHandler}
          />
          <HomePagePadding>
            <InputContainer>
              <TextField
                inputProps={{
                  "data-testid": "nickname-input-user-nickname-update",
                }}
                id="nickname-input-user-nickname-update"
                label={getTranslation("nickname", t, i18n)}
                placeholder={getTranslation("nickname", t, i18n)}
                variant="outlined"
                value={userData.nickname}
                onChange={handleNicknameChange}
                name="nickname"
                error={!nicknameValid}
                InputLabelProps={{ shrink: true }}
                required
              />
              <Box>
                {!nicknameValid && (
                  <ValidationText variant="body5" align="center">
                    {getTranslation("nicknameInfoValidation", t, i18n)}
                  </ValidationText>
                )}
              </Box>
            </InputContainer>
          </HomePagePadding>
        </PageTransition>
      </ErrorHandling>
    </AppAccess>
  );
};

export default UpdateUserNickname;
