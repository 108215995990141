import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouletteSpinnerOverlay } from "react-spinner-overlay";
import useOrientation from "../../../hooks/useOrientation";
import {
  useGetAllFunctionsQuery,
  useSearchAssetsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import {
  selectIndex,
  selectPageView,
  selectSearchTerm,
  selectShouldSearch,
  selectSortingOption,
  setIndex,
} from "../../../store/slices/appSlice";
import {
  selectItems,
  selectSearchResultItems,
  setItems,
  setSearchResultItems,
} from "../../../store/slices/assetListSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  CATEGORY_SELECT_LIST,
  getPageSize,
  RESOURCE_ITEM_HEIGHT,
  TABLET_LANDSCAPE_PAGE_SIZE,
  TABLET_PORTRAIT_PAGE_SIZE,
} from "../../../util/utils";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import ErrorHandling from "../../common/ErrorHandling";
import { AssetListContainer } from "../../styles/assets/asset-list/AssetList.styles";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import {
  GroupByResourceCategoryContainer,
  ListGridViewContainer,
} from "../../styles/general/General.styles";
import ListGridView from "./ListGridView";
import ListInlineView from "./ListInlineView";

const SearchAssetListRenderer = ({ resourceId, tagId }) => {
  // General hooks
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Custom hooks
  const orientation = useOrientation();

  // Selectors
  const currentpageview = useSelector(selectPageView);
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const sortingOption = useSelector(selectSortingOption);
  const searchTerm = useSelector(selectSearchTerm);
  const index = useSelector(selectIndex);
  const items = useSelector(selectItems);
  const searchResultItems = useSelector(selectSearchResultItems);
  const shouldSearch = useSelector(selectShouldSearch);

  // Other variables
  const pageSize = mobileMatches
    ? getPageSize(RESOURCE_ITEM_HEIGHT)
    : orientation === "landscape"
    ? TABLET_LANDSCAPE_PAGE_SIZE
    : TABLET_PORTRAIT_PAGE_SIZE;

  // Queries
  const {
    data: searchResults,
    isLoading: isLoadingSearch,
    isError: isErrorSearch,
  } = useSearchAssetsQuery(
    {
      organizationId,
      q: searchTerm,
      tagId,
      resourceId,
      index,
      size: pageSize,
      sortBy:
        sortingOption.type === "displayIdAndNameCombination"
          ? "resource.coalesce.displayIdAndName"
          : "resource.favorite.date.forUser",
      order: sortingOption.order,
    },
    {
      skip: !Boolean(searchTerm) || !shouldSearch || searchTerm.length < 3,
    }
  );

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Handlers
  const fetchMoreData = () => {
    setTimeout(() => {
      dispatch(setIndex(index + 1));
    }, 1000);
  };

  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === id);
  };

  // Effects
  useEffect(() => {
    if (searchResults?.data) {
      if (index <= 1) {
        dispatch(setItems([...searchResults.data]));
      } else {
        dispatch(
          setItems([
            ...items,
            ...searchResults.data.filter(
              (r) => !items.some((i) => i.id === r?.id)
            ),
          ])
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults?.data, index]);

  useEffect(() => {
    if (items && items.length > 0) {
      dispatch(setSearchResultItems(items));
    } else {
      dispatch(setSearchResultItems([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const resultItems = Boolean(searchTerm) ? searchResultItems : items;

  return (
    <ErrorHandling
      isLoading={isLoadingFunctions || isLoadingSearch}
      isError={isErrorFunctions || isErrorSearch}
    >
      <AssetListContainer
        isdesktop={desktopMatches}
        isappview={currentpageview === "app"}
      >
        {sortingOption.value === "CATEGORY" ? (
          CATEGORY_SELECT_LIST.sort((a, b) => a.label > b.label).map(
            (category) => {
              const list = resultItems?.filter(
                (r) => getFunction(r.functionId)?.category === category.value
              );
              const hasItems = list?.length > 0;

              return (
                hasItems && (
                  <GroupByResourceCategoryContainer key={category.value}>
                    <SecondaryText>{category.label + "s"}</SecondaryText>
                    {currentpageview === "inline" ? (
                      <ListInlineView
                        list={list}
                        index={index}
                        fetchMoreData={fetchMoreData}
                        resourcesData={searchResults}
                        pageSize={pageSize}
                      />
                    ) : (
                      <ListGridView
                        list={list}
                        index={index}
                        fetchMoreData={fetchMoreData}
                        resourcesData={searchResults}
                        pageSize={pageSize}
                      />
                    )}
                  </GroupByResourceCategoryContainer>
                )
              );
            }
          )
        ) : currentpageview === "inline" ? (
          <ListInlineView
            list={resultItems}
            index={index}
            fetchMoreData={fetchMoreData}
            resourcesData={searchResults}
            pageSize={pageSize}
          />
        ) : (
          <ListGridView
            list={resultItems}
            index={index}
            fetchMoreData={fetchMoreData}
            resourcesData={searchResults}
            pageSize={pageSize}
          />
        )}
      </AssetListContainer>
    </ErrorHandling>
  );
};

export default SearchAssetListRenderer;
