import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  DEFAULT_AGGREGATION,
  DEFAULT_RESULT_FIELD,
  INITIAL_AGGREGATIONS,
  INITIAL_GROUP_FIELDS,
  INITIAL_QUERY,
  INITIAL_RESULT_FIELDS,
  REPORT_FIELDS,
} from "../../util/reports-utils";
import { v4 as uuidv4 } from "uuid";

export const MIN_STEP = 1;
export const MAX_STEP = 3;

export const WIDGET_CATEGORY = {
  LINK: "link",
  COUNT: "count",
  MIN_MAX_AVERAGE: "min_max_average",
  PERCENTAGE: "percentage",
  STATUS: "status",
  PIE_CHART: "pie_chart",
  DONUT_CHART: "donut_chart",
  LINE_CHART: "line_chart",
  BAR_CHART: "bar_chart",
  FUNNEL_CHART: "funnel_chart",
  SUM: "sum",
  AREA_CHART: "area_chart",
};

const initialState = {
  isReportWizardDialogOpen: false,
  wizardStep: MIN_STEP,
  query: INITIAL_QUERY,
  shouldExecute: false,
  reportingFields: [],
  resultFields: INITIAL_RESULT_FIELDS,
  executionResultFields: INITIAL_RESULT_FIELDS,
  groupFields: INITIAL_GROUP_FIELDS,
  executionGroupFields: INITIAL_GROUP_FIELDS,
  aggregations: INITIAL_AGGREGATIONS,
  executionAggregations: INITIAL_AGGREGATIONS,
  widgets: [],
  widgetCategory: null,
  typeId: 100,
  name: "",
  description: "",
  isPrivate: false,
  reportType: "LIST",
  reportExecutionFurtherFields: [],
  reportExecutionGroupFields: [],
  reportDefinitionId: null,
  queryDefinitionTablePage: 0,
  mobileDialogOpen: false,
  activeStep: MIN_STEP,
  shouldCleanQuery: false,
  shouldAddDefaultConditions: false,
  shouldRefreshDialogTable: false,
  mobileUpdateDialogOpen: false,
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setIsReportWizardDialogOpen: (state, action) => {
      const newValue = action.payload;
      state.isReportWizardDialogOpen = newValue;
    },
    setWizardStep: (state, action) => {
      const newWizardStep = action.payload;
      state.wizardStep = newWizardStep;
      state.shouldExecute = false;
    },
    nextWizardStep: (state) => {
      if (state.wizardStep < MAX_STEP) {
        state.wizardStep++;
        state.shouldExecute = false;
      }
    },
    previousWizardStep: (state) => {
      if (state.wizardStep > MIN_STEP) {
        state.wizardStep--;
        state.shouldExecute = false;
      }
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setShouldExecute: (state, action) => {
      state.shouldExecute = action.payload;
    },
    addResultField: (state) => {
      const newResultField = { uniqueId: uuidv4(), ...DEFAULT_RESULT_FIELD };
      state.resultFields.push(newResultField);
    },
    addFieldWithPayload: (state, action) => {
      const newResultField = action.payload;
      state.resultFields.push(newResultField);
    },
    editResultField: (state, action) => {
      const payload = action.payload;

      const existingResultFieldIndex = state.resultFields.findIndex(
        (field) => field.uniqueId === payload.uniqueId
      );

      const existingResultField = state.resultFields.find(
        (field) => field.uniqueId === payload.uniqueId
      );

      const resultFieldWithSameOrder = state.resultFields
        .filter((field) => field.usedForGrouping === payload.usedForGrouping)
        .find((field) => field.orderIndex === payload.orderIndex);

      if (resultFieldWithSameOrder) {
        const resultFieldWithSameOrderIndex = state.resultFields.findIndex(
          (field) => field.uniqueId === resultFieldWithSameOrder.uniqueId
        );

        state.resultFields.splice(resultFieldWithSameOrderIndex, 1);

        state.resultFields.splice(resultFieldWithSameOrderIndex, 0, {
          ...resultFieldWithSameOrder,
          orderIndex: existingResultField.orderIndex,
          sortMethod: existingResultField.sortMethod,
        });
      }

      state.resultFields.splice(existingResultFieldIndex, 1);
      state.resultFields.splice(existingResultFieldIndex, 0, payload);
    },
    removeResultField: (state, action) => {
      const uniqueId = action.payload;

      state.resultFields = state.resultFields.filter(
        (field) => field.uniqueId !== uniqueId
      );
    },
    setWidgetCategory: (state, action) => {
      const newWidgetCategory = action.payload;
      state.widgetCategory = newWidgetCategory;
    },
    setReportingFields: (state, action) => {
      const newReportingFields = action.payload;
      state.reportingFields = newReportingFields;
    },
    setResultFields: (state, action) => {
      const newResultFields = action.payload;
      state.resultFields = newResultFields;
    },
    setExecutionResultFields: (state, action) => {
      const newExecutionResultFields = action.payload;
      state.executionResultFields = newExecutionResultFields;
    },
    addGroupField: (state) => {
      const newGroupField = {
        uniqueId: uuidv4(),
        ...DEFAULT_RESULT_FIELD,
        usedForGrouping: true,
      };
      state.resultFields.push(newGroupField);
    },
    editGroupField: (state, action) => {
      const payload = action.payload;

      const existingGroupFieldIndex = state.groupFields.findIndex(
        (field) => field.uniqueId === payload.uniqueId
      );

      const existingGroupField = state.groupFields.find(
        (field) => field.uniqueId === payload.uniqueId
      );

      const groupFieldWithSameName = state.groupFields.find(
        (field) => field.field === payload.field
      );

      const groupFieldWithSameNameIndex = state.groupFields.findIndex(
        (field) => field.field === payload.field
      );

      if (groupFieldWithSameName) {
        state.groupFields.splice(groupFieldWithSameNameIndex, 1);

        state.groupFields.splice(groupFieldWithSameNameIndex, 0, {
          ...groupFieldWithSameName,
          field: existingGroupField.field,
        });
      }

      state.groupFields.splice(existingGroupFieldIndex, 1);
      state.groupFields.splice(existingGroupFieldIndex, 0, payload);

      const existingResultField = state.resultFields.find(
        (field) => field.field === payload.field
      );
      existingResultField.usedForGrouping = true;
    },
    removeGroupField: (state, action) => {
      const uniqueId = action.payload;

      const existingGroupField = state.groupFields.find(
        (field) => field.uniqueId === uniqueId
      );

      const existingResultField = state.resultFields.find(
        (field) => field.field === existingGroupField.field
      );
      existingResultField.usedForGrouping = false;

      state.groupFields = state.groupFields.filter(
        (field) => field.uniqueId !== uniqueId
      );
    },
    setGroupFields: (state, action) => {
      const newGroupFields = action.payload;
      state.groupFields = newGroupFields;
    },
    setExecutionGroupFields: (state, action) => {
      const newExecutionGroupFields = action.payload;
      state.executionGroupFields = newExecutionGroupFields;
    },
    addAggregation: (state) => {
      const newAggregation = { uniqueId: uuidv4(), ...DEFAULT_AGGREGATION };
      state.aggregations.push(newAggregation);
    },
    editAggregation: (state, action) => {
      const payload = action.payload;

      const existingAggregation = state.aggregations.find(
        (aggregation) => aggregation.uniqueId === payload.uniqueId
      );

      existingAggregation.aggregationType = payload.aggregationType;

      existingAggregation.field =
        payload.aggregationType === "COUNT"
          ? REPORT_FIELDS["ID"]
          : payload.field;
    },
    removeAggregation: (state, action) => {
      const uniqueId = action.payload;

      state.aggregations = state.aggregations.filter(
        (aggregation) => aggregation.uniqueId !== uniqueId
      );
    },
    setAggregations: (state, action) => {
      const newAggregations = action.payload;
      state.aggregations = newAggregations;
    },
    setExecutionAggregations: (state, action) => {
      const newExecutionAggregations = action.payload;
      state.executionAggregations = newExecutionAggregations;
    },
    setName: (state, action) => {
      const newName = action.payload;
      state.name = newName;
    },
    setDescription: (state, action) => {
      const newDescription = action.payload;
      state.description = newDescription;
    },
    setIsPrivate: (state, action) => {
      const newPrivate = action.payload;
      state.isPrivate = newPrivate;
    },
    setReportType: (state, action) => {
      const newReportType = action.payload;
      state.reportType = newReportType;
    },
    setTypeId: (state, action) => {
      const newTypeId = action.payload;
      state.typeId = newTypeId;
    },
    setReportDefinitionId: (state, action) => {
      const newReportDefinitionId = action.payload;
      state.reportDefinitionId = newReportDefinitionId;
    },
    resetWizard: () => initialState,
    setReportExecutionFurtherFields: (state, action) => {
      const newFields = action.payload;
      state.reportExecutionFurtherFields = newFields;
    },
    setReportExecutionGroupFields: (state, action) => {
      const newFields = action.payload;
      state.reportExecutionGroupFields = newFields;
    },
    setQueryDefinitionTablePage: (state, action) => {
      const newPage = action.payload;
      state.queryDefinitionTablePage = newPage;
    },
    setMobileDialogOpen: (state, action) => {
      const newValue = action.payload;
      state.mobileDialogOpen = newValue;
    },
    setActiveStep: (state, action) => {
      const newActiveStep = action.payload;
      state.activeStep = newActiveStep;
      state.shouldExecute = false;
    },
    nextActiveStep: (state) => {
      if (state.activeStep < MAX_STEP) {
        state.activeStep++;
        state.shouldExecute = false;
      }
    },
    previousActiveStep: (state) => {
      if (state.activeStep > MIN_STEP) {
        state.activeStep--;
        state.shouldExecute = false;
      }
    },
    setShouldCleanQuery: (state, action) => {
      const newValue = action.payload;
      state.shouldCleanQuery = newValue;
    },
    setShouldAddDefaultConditions: (state, action) => {
      const newValue = action.payload;
      state.shouldAddDefaultConditions = newValue;
    },
    deleteAllResultFields: (state) => {
      state.resultFields = state.resultFields.filter(
        (field) => field.usedForGrouping
      );
    },
    deleteAllGroupFields: (state) => {
      state.resultFields = state.resultFields.filter(
        (field) => !field.usedForGrouping
      );
    },
    setShouldRefreshDialogTable: (state, action) => {
      const newValue = action.payload;
      state.shouldRefreshDialogTable = newValue;
    },
    setMobileUpdateDialogOpen: (state, action) => {
      const newValue = action.payload;
      state.mobileUpdateDialogOpen = newValue;
    },
  },
});

export default reportsSlice.reducer;

export const {
  setIsReportWizardDialogOpen,
  setWizardStep,
  nextWizardStep,
  previousWizardStep,
  setQuery,
  setShouldExecute,
  addResultField,
  addFieldWithPayload,
  editResultField,
  removeResultField,
  addGroupField,
  editGroupField,
  removeGroupField,
  setGroupFields,
  addAggregation,
  editAggregation,
  removeAggregation,
  setAggregations,
  addWidget,
  removeWidget,
  setWidgetCategory,
  setReportingFields,
  setResultFields,
  setExecutionResultFields,
  setExecutionGroupFields,
  setExecutionAggregations,
  setName,
  setDescription,
  setIsPrivate,
  setReportType,
  setTypeId,
  setReportDefinitionId,
  resetWizard,
  setReportExecutionFurtherFields,
  setReportExecutionGroupFields,
  setQueryDefinitionTablePage,
  setMobileDialogOpen,
  setActiveStep,
  nextActiveStep,
  previousActiveStep,
  setShouldCleanQuery,
  setShouldAddDefaultConditions,
  deleteAllResultFields,
  deleteAllGroupFields,
  setShouldRefreshDialogTable,
  setMobileUpdateDialogOpen,
} = reportsSlice.actions;

export const selectIsReportWizardDialogOpen = (state) =>
  state.reports.isReportWizardDialogOpen;
export const selectWizardStep = (state) => state.reports.wizardStep;
export const selectQuery = (state) => state.reports.query;
export const selectShouldExecute = (state) => state.reports.shouldExecute;
export const selectResultFields = (state) => state.reports.resultFields;
export const selectWidgetCategory = (state) => state.reports.widgetCategory;
export const selectTypeId = (state) => state.reports.typeId;
export const selectReportingFields = (state) => state.reports.reportingFields;
export const selectName = (state) => state.reports.name;
export const selectDescription = (state) => state.reports.description;
export const selectIsPrivate = (state) => state.reports.isPrivate;
export const selectGroupFields = (state) => state.reports.groupFields;
export const selectReportType = (state) => state.reports.reportType;
export const selectAggregations = (state) => state.reports.aggregations;
export const selectExecutionResultFields = (state) =>
  state.reports.executionResultFields;
export const selectExecutionGroupFields = (state) =>
  state.reports.executionGroupFields;
export const selectExecutionAggregations = (state) =>
  state.reports.executionAggregations;
export const selectReportExecutionFurtherFields = (state) =>
  state.reports.reportExecutionFurtherFields;
export const selectReportExecutionGroupFields = (state) =>
  state.reports.reportExecutionGroupFields;
export const selectReportDefinitionId = (state) =>
  state.reports.reportDefinitionId;
export const selectQueryDefinitionTablePage = (state) =>
  state.reports.queryDefinitionTablePage;
export const selectMobileDialogOpen = (state) => state.reports.mobileDialogOpen;
export const selectActiveStep = (state) => state.reports.activeStep;
export const selectShouldCleanQuery = (state) => state.reports.shouldCleanQuery;
export const selectShouldAddDefaultConditions = (state) =>
  state.reports.shouldAddDefaultConditions;
export const selectShouldRefreshDialogTable = (state) =>
  state.reports.shouldRefreshDialogTable;
export const selectMobileUpdateDialogOpen = (state) =>
  state.reports.mobileUpdateDialogOpen;

export const selectGroupResultFields = createSelector(
  [selectResultFields, (resultFields) => resultFields],
  (resultFields) => {
    return resultFields.filter((field) => field.usedForGrouping);
  }
);

export const selectFurtherResultFields = createSelector(
  [selectResultFields, (resultFields) => resultFields],
  (resultFields) => {
    return resultFields.filter((field) => !field.usedForGrouping);
  }
);

export const selectExecutionGroupResultFields = createSelector(
  [selectExecutionResultFields, (resultFields) => resultFields],
  (resultFields) => {
    return resultFields.filter((field) => field.usedForGrouping);
  }
);

export const selectExecutionFurtherResultFields = createSelector(
  [selectExecutionResultFields, (resultFields) => resultFields],
  (resultFields) => {
    return resultFields.filter((field) => !field.usedForGrouping);
  }
);

export const selectNonNullableResultFields = createSelector(
  [selectResultFields, (resultFields) => resultFields],
  (resultFields) => {
    return resultFields.filter((field) => Boolean(field.field));
  }
);

export const selectNonNullableGroupFields = createSelector(
  [selectGroupFields, (groupFields) => groupFields],
  (groupFields) => {
    return groupFields.filter((field) => Boolean(field.field));
  }
);
