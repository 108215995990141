import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { NavigationActionIcon } from "../menu/Menu.styles";

export const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  position: "sticky !important",
  zIndex: "100 !important",
  padding: "8px",
  bottom: 0,
  left: 0,
  right: 0,
}));

export const ButtonContainer = styled(Box)(({ view }) => ({
  display: "flex",
  justifyContent: view === "column" ? "flex-start" : "center",
}));

export const StyledNavigationIcon = styled(NavigationActionIcon)(
  ({ theme }) => ({
    marginRight: "5px",
  })
);
