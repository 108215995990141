import { Box, Grid, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Stage } from "react-konva";

export const GraphicalRackViewContainer = styled(Box)({
  overflowX: "hidden",
  marginTop: "8px",
});

export const GraphicalRackViewStageContainer = styled(Box)({
  overflow: "hidden",
});

export const TopBorderContainer = styled(Box)({
  background:
    "linear-gradient(to right, lightgray 0%, white 25%, white 50%, lightgray 100%)",
  position: "absolute",
  top: "0px",
  left: "0px",
  right: "0px",
  zIndex: "1 !important",
  border: "3px solid #000",
});

export const TopBorderWrapper = styled(Grid)({
  position: "relative",
  alignItems: "center",
});

export const TopBorderText = styled(Typography)({
  marginLeft: "8px",
});

export const MenuIconButton = styled(IconButton)({
  marginLeft: "8px",
});

export const LeftContentContainer = styled(Grid)({
  display: "flex",
  justifyContent: "end",
});

export const RackStage = styled(Stage)({
  margin: "20px 0",
  padding: 0,
});