import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  BackTitle,
  HeaderWrapper,
  LeftActionButton,
  PageTitle,
  StyledChevronLeftIcon,
} from "../../../components/styles/header/Header.styles";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { getTranslation } from "../../../util/utils";

const ReportHeader = ({ goBackHandler, title }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  return (
    <HeaderWrapper id="sticky-header">
      <Grid
        container
        sx={{
          display: "flex",
          padding: "10px",
          alignItems: "center",
        }}
      >
        <Grid item xs={3}>
          <LeftActionButton id="back-button" onClick={goBackHandler}>
            <StyledChevronLeftIcon
              id="header-back-button-icon"
              customfontsize={globalFontSize * 2}
            />
            <BackTitle component="span" id="previous_page-title">
              {getTranslation("REPORTS", t, i18n)}
            </BackTitle>
          </LeftActionButton>
        </Grid>
        <Grid item xs={6}>
          <PageTitle variant="h6" id="title">
            {getTranslation(title, t, i18n)}
          </PageTitle>
        </Grid>
      </Grid>
    </HeaderWrapper>
  );
};

export default ReportHeader;
