import ResourceImageForm from "../../components/assets/asset-form/ResourceImageForm";
import { useGetAllFunctionsQuery } from "../../store/slices/api/assetManagementSlice";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { DEFAULT_RESOURCE_IMAGE } from "../../store/slices/resourceImageSlice";
import { useCallback } from "react";
import { selectUser } from "../../store/slices/authSlice";
import { useTranslation } from "react-i18next";
import { messageError, messageSuccess } from "../../util/notification";
import PageTransition from "../../components/PageTransition";
import {
  transitionDirections,
  permissions,
  getTranslation,
  showValidationError,
} from "../../util/utils";
import ChooseAssetImageHeader from "../../navigation/header/assets/ChooseAssetImageHeader";
import { useUploadResourceImageMutation } from "../../store/slices/api/resourceImagesApiSlice";
import { useGetResourceDetailsQuery } from "../../store/slices/api/assetManagementSlice";
import Access from "../../components/common/Access";
import ErrorHandling from "../../components/common/ErrorHandling";
import { useDispatch } from "react-redux";
import { setMobileTabValue } from "../../store/slices/assetListSlice";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const AddResourceImagePage = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { resourceId } = useParams();

  // Selectors
  const user = useSelector(selectUser);

  // State
  const [resourceImageInput, setResourceImageInput] = useState(
    DEFAULT_RESOURCE_IMAGE
  );

  const [name, setName] = useState("");
  const [extension, setExtension] = useState("");

  // Queries
  const {
    data: currentResourceData,
    isLoading: isLoadingCurrent,
    isError: isErrorCurrent,
  } = useGetResourceDetailsQuery(
    {
      resourceid: resourceId,
      organizationId: user?.organizations?.find((o) => o.default).id,
    },
    {
      skip: !Boolean(resourceId),
    }
  );

  const { data: allFunctionsData } = useGetAllFunctionsQuery({
    organizationId: user?.organizations?.find((o) => o.default)?.id,
  });

  // Other variables
  const imageCategory = searchParams.get("imageCategory") ?? "FRONT";
  const direction = searchParams.get("direction");

  // Handlers
  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === id);
  };

  const cancelHandler = () => {
    dispatch(setMobileTabValue(1));

    navigate(
      `/resources/${resourceId}?direction=${transitionDirections.TOP_TO_BOTTOM}&comingFrom=add-image`
    );
  };

  // Other variables
  const isValid =
    (resourceImageInput.file || resourceImageInput.selectedFile) && name;

  const isDisabled = !isValid;
  const resourceFunction = getFunction(currentResourceData?.functionId);

  const permissionsKey = `ASSET_MANAGEMENT_${
    resourceFunction?.category === "HARDWARE_ASSET"
      ? "HARDWARE"
      : resourceFunction?.category
  }_ADD`;

  // Mutations
  const [uploadResourceImage, { isLoading: isLoadingUpload }] =
    useUploadResourceImageMutation();

  const handleSubmit = useCallback(async () => {
    try {
      if (!resourceImageInput.selectedFile) {
        messageError(getTranslation("UPLOAD_IMAGE", t, i18n));
        return;
      }

      const formData = new FormData();

      const { file, description } = resourceImageInput;
      const finalImageCategory =
        imageCategory === "OTHER" ? "MISC" : imageCategory;

      formData.append("file", file);
      formData.append("fileName", name + extension);
      formData.append("imageCategory", finalImageCategory);
      formData.append("description", description);

      await uploadResourceImage({
        formData,
        resourceid: resourceId,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      }).unwrap();

      cancelHandler();

      messageSuccess(
        getTranslation("RESOURCE_IMAGE_CREATED_SUCCESSFULLY", t, i18n)
      );
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to upload resource image", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceImageInput, name]);

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={isLoadingCurrent || isLoadingUpload}
        isError={isErrorCurrent}
      >
        <Access all={[permissions[permissionsKey]]}>
          <Layer2Access>
            <PageTransition direction={direction}>
              <ChooseAssetImageHeader
                handleAction={handleSubmit}
                handleCancelAction={cancelHandler}
                isDisabled={isDisabled}
              />

              <HomePagePadding>
                <ResourceImageForm
                  resourceImageInput={resourceImageInput}
                  setResourceImageInput={setResourceImageInput}
                  name={name}
                  setName={setName}
                  setExtension={setExtension}
                />
              </HomePagePadding>
            </PageTransition>
          </Layer2Access>
        </Access>
      </ErrorHandling>
    </AppAccess>
  );
};

export default AddResourceImagePage;
