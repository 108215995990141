import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGetAllFunctionsQuery } from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { localizeDate, getTranslation } from "../../../util/utils";
import ErrorHandling from "../../common/ErrorHandling";
import {
  PrimaryText,
  SecondaryText,
  SectionTitle,
} from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { ListItemContainerColumn } from "../../styles/assets/asset-detail/AssetDetailCard.styles";
import { LIFECYCLE_STATUSES } from "../asset-form/AssetForm";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { useRef, useState, useEffect } from "react";
import { selectTheme } from "../../../store/slices/appSlice";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const AssetDetailBasicInfoColumnVersion = ({ resourceData }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const currentTheme = useSelector(selectTheme);

  // Refs
  const textRef = useRef(null);
  const iconRef = useRef(null);

  // States
  const [borderWidth, setBorderWidth] = useState("25%");

  // Queries
  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Other variables
  const leftPadding = 16;

  const {
    displayId,
    description,
    version,
    lifecycleStatus,
    lifecycleDate,
    functionId,
  } = resourceData;

  const resourceFunction = allFunctionsData?.find((f) => f.id === functionId);

  const resourceLifecycleStatusLabel = LIFECYCLE_STATUSES?.find(
    (ls) => ls.value === lifecycleStatus
  )?.label;

  const index = tabletMatches || desktopMatches ? 1 : 0;

  // Effects
  useEffect(() => {
    if (textRef.current && iconRef.current) {
      const textWidth = textRef.current.offsetWidth;
      const totalWidth = textRef.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRef.current.width?.animVal?.value) /
          totalWidth) *
        100;

      setBorderWidth(`${percentage}%`);
    }
  }, []);

  return (
    <ErrorHandling isLoading={isLoadingFunctions} isError={isErrorFunctions}>
      <div>
        <StyledAccordion currentTheme={currentTheme} defaultExpanded>
          <StyledAccordionSummary
            value={borderWidth}
            expandIcon={
              <KeyboardArrowRightIcon
                sx={{
                  color: theme.palette.secondary.contrastText,
                  marginRight: "5px",
                }}
                ref={iconRef}
              />
            }
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle variant="body2" istablet={1} ref={textRef}>
              {getTranslation("BASIC_DATA", t, i18n)}
            </SectionTitle>
          </StyledAccordionSummary>

          <StyledAccordionDetails>
            <Grid container columnSpacing={2}>
              {displayId && (
                <Grid item xs={12}>
                  <ListItemContainerColumn index={index}>
                    <SecondaryText variant="body2" id="display-id-label">
                      {getTranslation("DISPLAY_ID", t, i18n)}
                    </SecondaryText>
                    <PrimaryText id="display-id">{displayId}</PrimaryText>
                  </ListItemContainerColumn>
                </Grid>
              )}

              {description && (
                <Grid item xs={12}>
                  <ListItemContainerColumn index={index}>
                    <SecondaryText variant="body2" id="description-label">
                      {getTranslation("NOTES", t, i18n)}
                    </SecondaryText>
                    <PrimaryText id="description">{description}</PrimaryText>
                  </ListItemContainerColumn>
                </Grid>
              )}

              {version && (
                <Grid item xs={12}>
                  <ListItemContainerColumn index={index}>
                    <SecondaryText variant="body2" id="description-label">
                      {getTranslation("VERSION", t, i18n)}
                    </SecondaryText>
                    <PrimaryText id="description">{version}</PrimaryText>
                  </ListItemContainerColumn>
                </Grid>
              )}

              {lifecycleStatus && (
                <Grid item xs={12}>
                  <ListItemContainerColumn index={index}>
                    <SecondaryText variant="body2" id="lifecycle-status-label">
                      {getTranslation("LIFECYCLE_STATUS", t, i18n)}
                    </SecondaryText>
                    <PrimaryText id="lifecycle-status">
                      {getTranslation(resourceLifecycleStatusLabel, t, i18n)}
                    </PrimaryText>
                  </ListItemContainerColumn>
                </Grid>
              )}

              {lifecycleDate && (
                <Grid item xs={12}>
                  <ListItemContainerColumn index={index}>
                    <SecondaryText variant="body2" id="lifecycle-date-label">
                      {getTranslation("LIFECYCLE_DATE", t, i18n)}
                    </SecondaryText>
                    <PrimaryText id="lifecycle-date">
                      {localizeDate(lifecycleDate, user.region)}
                    </PrimaryText>
                  </ListItemContainerColumn>
                </Grid>
              )}

              <Grid item xs={12}>
                <ListItemContainerColumn index={index}>
                  <SecondaryText variant="body2" id="type-function-label">
                    {getTranslation("FUNCTION", t, i18n)}
                  </SecondaryText>
                  <PrimaryText id="type-function">
                    {getTranslation(resourceFunction?.name, t, i18n)}
                  </PrimaryText>
                </ListItemContainerColumn>
              </Grid>
            </Grid>
          </StyledAccordionDetails>
        </StyledAccordion>
      </div>
    </ErrorHandling>
  );
};

export default AssetDetailBasicInfoColumnVersion;
