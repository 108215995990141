import { useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useOrientation from "../../../hooks/useOrientation";
import {
  useGetAllFunctionsQuery,
  useSearchAssetsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import {
  selectIndex,
  selectIsSearchOpen,
  selectPageView,
  selectSearchTerm,
  selectSortingOption,
  setIndex,
} from "../../../store/slices/appSlice";
import {
  selectItems,
  selectSearchResultItems,
  setItems,
  setSearchResultItems,
} from "../../../store/slices/assetListSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  CATEGORY_SELECT_LIST,
  getPageSize,
  getTranslation,
  RESOURCE_CATEGORIES,
  RESOURCE_ITEM_HEIGHT,
  TABLET_LANDSCAPE_PAGE_SIZE,
  TABLET_PORTRAIT_PAGE_SIZE,
} from "../../../util/utils";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import {
  GroupByResourceCategoryContainer,
  ListGridViewContainer,
  NoDataTextContainer,
} from "../../styles/general/General.styles";
import ListGridView from "./ListGridView";
import ListInlineView from "./ListInlineView";
import { RecentlyUsedText } from "../../styles/assets/tags/Tags.styles";
import { useTranslation } from "react-i18next";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { AssetListContainer } from "../../styles/assets/asset-list/AssetList.styles";
import ErrorHandling from "../../common/ErrorHandling";

const TagsFilterRenderer = ({ tagId, tagName }) => {
  // General hooks
  const dispatch = useDispatch();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);
  const { t, i18n } = useTranslation();

  // Custom hooks
  const orientation = useOrientation();

  // Selectors
  const currentpageview = useSelector(selectPageView);
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const sortingOption = useSelector(selectSortingOption);
  const searchTerm = useSelector(selectSearchTerm);
  const index = useSelector(selectIndex);
  const items = useSelector(selectItems);
  const searchResultItems = useSelector(selectSearchResultItems);
  const isSearchOpen = useSelector(selectIsSearchOpen);

  // Other variables
  const pageSize = mobileMatches
    ? getPageSize(RESOURCE_ITEM_HEIGHT)
    : orientation === "landscape"
    ? TABLET_LANDSCAPE_PAGE_SIZE
    : TABLET_PORTRAIT_PAGE_SIZE;

  // Queries
  const {
    data: resultsByTag,
    isLoading: isLoadingResultsByTag,
    isError: isErrorResultsByTag,
  } = useSearchAssetsQuery({
    organizationId,
    tagId,
    index,
    size: pageSize,
    sortBy:
      sortingOption.type === "displayIdAndNameCombination"
        ? "resource.coalesce.displayIdAndName"
        : "resource.favorite.date.forUser",
    order: sortingOption.order,
  });

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Handlers
  const fetchMoreData = () => {
    setTimeout(() => {
      dispatch(setIndex(index + 1));
    }, 1000);
  };

  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === id);
  };

  const handleGetCategoryContainerId = (category) => {
    return category === RESOURCE_CATEGORIES.HARDWARE_ASSET
      ? "hardware-assets-list"
      : category.toLowerCase() + "s-list";
  };

  // Effects
  useEffect(() => {
    if (items) {
      dispatch(
        setSearchResultItems(
          items.filter((item) => {
            const { name, displayId } = item;
            const combination = displayId || name;

            return combination
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
          })
        )
      );
    } else {
      dispatch(setSearchResultItems([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, searchTerm]);

  useEffect(() => {
    if (resultsByTag?.data) {
      if (index <= 1) {
        dispatch(setItems([...resultsByTag.data]));
      } else {
        dispatch(
          setItems([
            ...items,
            ...resultsByTag.data.filter(
              (f) => !items?.some((i) => i?.id === f?.id)
            ),
          ])
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsByTag?.data, index]);

  const resultItems = isSearchOpen ? searchResultItems : items;

  return (
    <ErrorHandling
      isLoading={isLoadingFunctions || isLoadingResultsByTag}
      isError={isErrorFunctions || isErrorResultsByTag}
    >
      <AssetListContainer
        isdesktop={desktopMatches}
        isappview={currentpageview === "app"}
      >
        <SecondaryText align="center" fontWeight="bold">
          {`${getTranslation("SELECTED_TAG", t, i18n)} = ${tagName}`}
        </SecondaryText>
        <RecentlyUsedText align="center">
          {`${resultItems?.length} ${
            resultItems?.length === 1
              ? getTranslation("TAG_SEARCH_RESULT", t, i18n)
              : getTranslation("TAG_SEARCH_RESULTS", t, i18n)
          }`}
        </RecentlyUsedText>

        {sortingOption.value === "CATEGORY" ? (
          CATEGORY_SELECT_LIST.sort((a, b) => a.label > b.label)
            .filter((category) => {
              const list = resultItems?.filter((r) => {
                return getFunction(r.functionId)?.category === category.value;
              });

              const hasItems = list?.length > 0;
              return hasItems;
            })
            .map((category) => {
              const list = resultItems?.filter((r) => {
                return getFunction(r.functionId)?.category === category.value;
              });

              return (
                <GroupByResourceCategoryContainer
                  id={handleGetCategoryContainerId(category.value)}
                  key={category.value}
                >
                  <SecondaryText>{category.label + "s"}</SecondaryText>
                  <ListGridViewContainer>
                    {currentpageview === "inline" ? (
                      <ListInlineView
                        list={list}
                        index={index}
                        fetchMoreData={fetchMoreData}
                        resourcesData={resultsByTag}
                        pageSize={pageSize}
                      />
                    ) : (
                      <ListGridView
                        list={list}
                        index={index}
                        fetchMoreData={fetchMoreData}
                        resourcesData={resultsByTag}
                        pageSize={pageSize}
                      />
                    )}
                  </ListGridViewContainer>
                </GroupByResourceCategoryContainer>
              );
            })
        ) : currentpageview === "inline" ? (
          <ListInlineView
            list={resultItems}
            index={index}
            fetchMoreData={fetchMoreData}
            resourcesData={resultsByTag}
            pageSize={pageSize}
          />
        ) : (
          <ListGridView
            list={resultItems}
            index={index}
            fetchMoreData={fetchMoreData}
            resourcesData={resultsByTag}
            pageSize={pageSize}
          />
        )}
      </AssetListContainer>
      {isSearchOpen &&
        resultItems?.length <= 0 &&
        resultsByTag?.totalRecord > 0 && (
          <NoDataTextContainer>
            <SecondaryText align="center">
              {getTranslation("NO_RESULTS_FOUND", t, i18n)}
            </SecondaryText>
          </NoDataTextContainer>
        )}
    </ErrorHandling>
  );
};

export default TagsFilterRenderer;
