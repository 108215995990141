import { Box, ListItemAvatar, MenuItem, Stack, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  IconsGridItem,
  ImageActionsContainer,
  ImageActionsItem,
  SecondaryTextSpace,
  StorageIconAvatar,
  StorageIconAvatarContainer,
  StyledCollectionsIcon,
  StyledStorageIcon,
  UploadButton,
} from "../../styles/assets/ChooseAssetImagePage.styles";
import { PrimaryText } from "../../styles/assets/ListGridView.styles";
import { useTranslation } from "react-i18next";
import {
  ALLOWED_EXTENSIONS,
  DEFAULT_IMAGE_SIZE,
  getTranslation,
  ICON_NAMES,
} from "../../../util/utils";
import { messageError } from "../../../util/notification";
import {
  selectGlobalFontSize,
  setIsActionButtonDisabled,
} from "../../../store/slices/appSlice";
import fileTypeChecker from "file-type-checker";
import CanvasImage from "../../common/CanvasImage";

const ResourceImageForm = ({
  resourceImageInput,
  setResourceImageInput,
  name,
  setName,
  setExtension,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Handlers
  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > DEFAULT_IMAGE_SIZE) {
        messageError(getTranslation("TOO_LARGE_FILE_VALIDATION", t, i18n));
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const buffer = reader.result;
        const uintArray = new Uint8Array(buffer);

        const isImage = fileTypeChecker.validateFileType(
          uintArray,
          ALLOWED_EXTENSIONS
        );

        if (!isImage) {
          messageError(getTranslation("NOT_ALLOWED_FILE_TYPE", t, i18n));
          dispatch(setIsActionButtonDisabled(true));
          setResourceImageInput({ ...resourceImageInput, name: "" });
          setName("");
        } else {
          dispatch(setIsActionButtonDisabled(false));
        }
      };

      reader.readAsArrayBuffer(file);
      const url = URL.createObjectURL(file);

      setResourceImageInput({
        ...resourceImageInput,
        selectedFile: url,
        file,
        mimeType: file.name.substring(file.name.lastIndexOf(".")),
      });

      setName(file?.name?.substring(0, file?.name?.lastIndexOf(".")));
      setExtension(file?.name?.substring(file?.name?.lastIndexOf(".")));

      dispatch(setIsActionButtonDisabled(false));
    }
  };

  const handleSelectIcon = async (iconPath) => {
    try {
      const response = await fetch(
        `${process.env.PUBLIC_URL}/icons/${iconPath}`
      );
      const fileAsBlob = await response.blob();
      const file = new File([fileAsBlob], iconPath, {
        type: fileAsBlob.type,
      });

      if (file) {
        if (file.size > DEFAULT_IMAGE_SIZE) {
          messageError(getTranslation("TOO_LARGE_FILE_VALIDATION", t, i18n));
          return;
        }

        dispatch(setIsActionButtonDisabled(false));

        const url = URL.createObjectURL(file);

        setResourceImageInput({
          ...resourceImageInput,
          selectedFile: url,
          file,
          mimeType: file.name.substring(file.name.lastIndexOf(".")),
        });

        setName(file?.name?.substring(0, file?.name?.lastIndexOf(".")));
        setExtension(file?.name?.substring(file?.name?.lastIndexOf(".")));
      }
    } catch (error) {
      console.error("Failed to select an icon", error);
    }
  };

  const handleChangeName = (e) => {
    const newName = e.target.value;

    if (!newName) {
      dispatch(setIsActionButtonDisabled(true));
    } else {
      dispatch(setIsActionButtonDisabled(false));
    }

    setName(newName);
  };

  const handleChangeDescription = (e) => {
    const newDescription = e.target.value;

    setResourceImageInput({
      ...resourceImageInput,
      description: newDescription,
    });
  };

  return (
    <>
      <StorageIconAvatarContainer>
        <ListItemAvatar>
          {/* Profile avatar picture */}
          {resourceImageInput.selectedFile ? (
            <CanvasImage
              style={{ display: "block", margin: "0 auto" }}
              id={name}
              width={150}
              height={100}
              image={resourceImageInput.selectedFile}
            />
          ) : (
            <StorageIconAvatar variant="square" id="image-avatar">
              <StyledStorageIcon customfontsize={globalFontSize * 1.5} />
            </StorageIconAvatar>
          )}
        </ListItemAvatar>
      </StorageIconAvatarContainer>

      <Stack alignItems="center">
        <div>
          <ImageActionsItem>
            <MenuItem>
              <ListItemAvatar>
                {/* Profile avatar picture */}
                <StorageIconAvatar
                  variant="square"
                  id="add-from-gallery-avatar"
                  date-testid="add-from-gallery-avatar"
                >
                  <UploadButton
                    color="primary"
                    component="label"
                    align="center"
                    className="upload-image"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <StyledCollectionsIcon
                      customfontsize={globalFontSize * 1.5}
                    />
                  </UploadButton>
                </StorageIconAvatar>
              </ListItemAvatar>
            </MenuItem>
          </ImageActionsItem>
          <PrimaryText align="center">
            {getTranslation("GALLERY", t, i18n)}
          </PrimaryText>
        </div>
      </Stack>

      <SecondaryTextSpace>
        {getTranslation("ICONS", t, i18n)}
      </SecondaryTextSpace>

      <ImageActionsContainer container>
        {Object.keys(ICON_NAMES).map((key) => (
          <IconsGridItem
            onClick={() => handleSelectIcon(ICON_NAMES[key])}
            key={key}
            data-testid={key}
            item
            xs={2}
          >
            <img
              height={30}
              width={30}
              src={`${process.env.PUBLIC_URL}/icons/${ICON_NAMES[key]}`}
              alt={`icon-${key}`}
            />
          </IconsGridItem>
        ))}
      </ImageActionsContainer>

      <Box>
        <TextField
          inputProps={{ "data-testid": "name-field" }}
          label={getTranslation("NAME", t, i18n)}
          value={name}
          onChange={handleChangeName}
        />
      </Box>

      <Box>
        <TextField
          inputProps={{ "data-testid": "description-field" }}
          label={getTranslation("DESCRIPTION", t, i18n)}
          value={resourceImageInput.description}
          onChange={handleChangeDescription}
        />
      </Box>
    </>
  );
};

export default ResourceImageForm;
