import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { MenuItem, ListItemAvatar, ListItemText } from "@mui/material";
import {
  EllipsePrimaryText,
  EllipseSecondaryText,
  ProfileAvatar,
} from "./styles/profile/ProfileCardItm.styles";
import { selectUser } from "../store/slices/authSlice";
import { useGetUserProfileImageQuery } from "../store/slices/api/userManagementSlice";
import { transitionDirections } from "../util/utils";
import { selectGlobalFontSize } from "../store/slices/appSlice";
import { StyledArrowRightIcon } from "./styles/general/General.styles";

const ProfileCardItem = () => {
  // General Hooks
  let navigate = useNavigate();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Queries
  const { data: selectedFile } = useGetUserProfileImageQuery(
    {
      avatarUri: user?.avatarUri,
    },
    {
      skip: !Boolean(user?.avatarUri),
    }
  );

  // Handlers
  const handleUserDetailClick = () => {
    navigate(
      `/profile/userdetail?direction=${transitionDirections.RIGHT_TO_LEFT}`
    );
  };

  return (
    <MenuItem data-testid="profile-card-item" onClick={handleUserDetailClick}>
      <ListItemAvatar>
        {/* Profile avatar picture */}
        <ProfileAvatar
          data-testid="profile-avatar"
          id="profile-avatar"
          alt={user?.shortUsername}
          src={selectedFile}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <EllipsePrimaryText
            data-testid="profile-user-name"
            id="profile-user-name"
          >
            {user?.firstName + " " + user?.lastName}
          </EllipsePrimaryText>
        }
        secondary={
          <EllipseSecondaryText data-testid="profile-email" id="profile-email">
            {user?.email}
          </EllipseSecondaryText>
        }
      />
      <StyledArrowRightIcon globalFontSize={globalFontSize} />
    </MenuItem>
  );
};

export default ProfileCardItem;
