import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTransition from "../../components/PageTransition";
import TableReport from "../../components/reports/TableReport";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { ASSET_COUNT_PER_LOCATION_WIDGET_REPORT_DEFINITION_ID } from "../../Constants";
import ReportHeader from "../../navigation/header/reports/ReportHeader";
import { useReportExecutionQuery } from "../../store/slices/api/reportsApiSlice";
import { selectUser } from "../../store/slices/authSlice";
import { handleMapDynamicColumns } from "../../util/reports-utils";
import { transitionDirections } from "../../util/utils";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const ReportsByHardwareAssets = () => {
  // General hooks
  const navigate = useNavigate();

  // Selectors
  const user = useSelector(selectUser);

  // Queries
  const {
    data: assetCountPerLocationWidgetData,
    isLoading: isLoadingAssetCountPerLocationWidget,
    isError: isErrorAssetCountPerLocationWidget,
  } = useReportExecutionQuery({
    organizationId: user?.organizations?.find((o) => o.default).id,
    reportDefinitionId: ASSET_COUNT_PER_LOCATION_WIDGET_REPORT_DEFINITION_ID,
  });

  // Handlers
  const handleGoBack = () => {
    navigate(
      `/reports?direction=${transitionDirections.LEFT_TO_RIGHT}&quick-access=true`
    );
  };

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling
          isLoading={isLoadingAssetCountPerLocationWidget}
          isError={isErrorAssetCountPerLocationWidget}
        >
          <PageTransition>
            <ReportHeader
              goBackHandler={handleGoBack}
              title="HARDWARE_ASSETS"
            />

            <HomePagePadding>
              <TableReport
                rows={assetCountPerLocationWidgetData?.data ?? []}
                columns={handleMapDynamicColumns(
                  assetCountPerLocationWidgetData?.data
                )}
                isRowClickable={true}
              />
            </HomePagePadding>
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default ReportsByHardwareAssets;
