import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { selectUser } from "../../../store/slices/authSlice";
import {
  useGetInvitedUsersQuery,
  useUserRolePermissionsQuery,
} from "../../../store/slices/api/userManagementSlice";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  Stack,
} from "@mui/material";
import InvitationTable from "../../../components/InvitationTable";
import OrganizationUsersList from "../../../components/OrganizationUsersList";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  SORT_ORDER_NAME,
  transitionDirections,
} from "../../../util/utils";
import { useTranslation } from "react-i18next";
import {
  InviteNewMembersButton,
  StyledDivider,
} from "../../../components/styles/profile/Profile.styles";
import PageTransition from "../../../components/PageTransition";
import {
  HeaderContainer,
  HeadingTitle,
  InviteButtonText,
  SectionTitle,
  StyledAddIcon,
} from "../../../components/styles/profile/UserManagementPage.styles";
import UserManagementHeader from "../../../navigation/header/organization/UserManagementHeader";
import { useGetOrganizationUsersQuery } from "../../../store/slices/api/organizationsApiSlice";
import Access from "../../../components/common/Access";
import { forwardRef, useState } from "react";
import {
  CancelButton,
  HomePagePadding,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "../../../components/styles/general/General.styles";
import { NavigationActionIcon } from "../../../components/styles/menu/Menu.styles";
import {
  StickySearchListOptionsCheckIcon,
  StickySearchListOptionsHiddenCheckIcon,
  StickySearchStyledDialogListItemButton,
} from "../../../components/styles/assets/StickySearch.styles";
import { SecondaryText } from "../../../components/styles/assets/ListInlineView.styles";
import ErrorHandling from "../../../components/common/ErrorHandling";
import { useGetCurrentSubscriptionActualUsageQuery } from "../../../store/slices/api/subscriptionsApiSlice";
import { INVITATION_STATUS } from "../../../components/InvitationForm";
import useCheckOrganizationRestricted from "../../../hooks/useCheckOrganizationRestricted";
import AppAccess from "../../../components/common/AppAccess";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserManagementPage = ({ propOrganizationId }) => {
  // Global hooks
  const { organizationId: routeOrganizationId } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [sortOrder, setSortOrder] = useState(SORT_ORDER_NAME.FIRST_NAME);
  const [openSort, setOpenSort] = useState(false);

  // Other variables
  const organizationId = propOrganizationId ?? routeOrganizationId;

  const organization = user?.organizations?.find(
    (o) => o.id === organizationId
  );

  // Custom hooks
  const { isRestricted } = useCheckOrganizationRestricted(organization);

  // Queries
  const {
    data: invitedUsers,
    isLoading: isLoadingInvitedUsers,
    isError: isErrorInvitedUsers,
  } = useGetInvitedUsersQuery(organizationId);

  const {
    data: organizationUsers,
    isLoading: isLoadingOrganizationUsers,
    isError: isErrorOrganizationUsers,
  } = useGetOrganizationUsersQuery(organizationId);

  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery({
    organizationId,
    userId: user.id,
  });

  const { data: actualUsage, isLoading: isLoadingActualUsage } =
    useGetCurrentSubscriptionActualUsageQuery(
      {
        organizationId,
      },
      { skip: isRestricted }
    );

  // Other variables
  const transitionDirection = searchParams.get("direction");
  const facts = actualUsage?.facts;
  const usersUsage = facts?.find((f) => f.name === "USER_COUNT");
  const usersSubscriptionValue = Number(usersUsage?.subscriptionValue);
  const sentInvitationsCount =
    invitedUsers?.filter((iu) => iu.status === INVITATION_STATUS.SENT)
      ?.length ?? 0;
  const organizationUsersCount = organizationUsers?.length ?? 0;
  const usersCurrentValue = sentInvitationsCount + organizationUsersCount;
  const isInviteDisabled =
    isRestricted || usersCurrentValue >= usersSubscriptionValue;

  // Handlers
  const handleCloseSortOrder = () => {
    setOpenSort(false);
  };

  const handleClickSortOrder = () => {
    setOpenSort(true);
  };

  const handleSortOrderMembers = (order) => {
    setSortOrder(order);
    handleCloseSortOrder();
  };

  const goBackHandler = () =>
    navigate(
      `/profile/organization/${organizationId}?direction=${transitionDirections.LEFT_TO_RIGHT}`
    );

  return (
    <AppAccess>
      <Access
        oneOf={[permissions.USER_MANAGEMENT_VIEW]}
        organizationId={organizationId}
      >
        <ErrorHandling
          isLoading={
            isLoadingInvitedUsers ||
            isLoadingOrganizationUsers ||
            isLoadingUserRoles ||
            isLoadingActualUsage
          }
          isError={
            isErrorInvitedUsers || isErrorOrganizationUsers || isErrorUserRoles
          }
        >
          <PageTransition direction={transitionDirection}>
            {routeOrganizationId && (
              <UserManagementHeader goBackHandler={goBackHandler} />
            )}
            <HomePagePadding>
              <Stack spacing={5}>
                {hasAccess(
                  "all",
                  [permissions.USER_MANAGEMENT_ADD],
                  getPermissionsFromUserRoles(userRoles)
                ) && (
                  <HeaderContainer>
                    {routeOrganizationId && (
                      <HeadingTitle id="members-count" variant="h5">
                        {`${organizationUsers?.length} ${
                          organizationUsers?.length > 1 ||
                          organizationUsers?.length === 0
                            ? getTranslation("members", t, i18n)
                            : getTranslation("member", t, i18n)
                        }`}
                      </HeadingTitle>
                    )}

                    {routeOrganizationId && (
                      <InviteNewMembersButton
                        id="invite-new-members"
                        disabled={isInviteDisabled}
                        onClick={() =>
                          navigate(
                            `/profile/organization/${organizationId}/user-management/invite?direction=${transitionDirections.BOTTOM_TO_TOP}`
                          )
                        }
                        variant="contained"
                      >
                        <StyledAddIcon globalFontSize={globalFontSize} />
                        <InviteButtonText>
                          {getTranslation("inviteNewMembers", t, i18n)}
                        </InviteButtonText>
                      </InviteNewMembersButton>
                    )}
                  </HeaderContainer>
                )}
                {hasAccess(
                  "all",
                  [permissions.USER_MANAGEMENT_VIEW],
                  getPermissionsFromUserRoles(userRoles)
                ) &&
                  organizationUsers?.length > 0 && (
                    <Box id="organization-users-list">
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="end"
                      >
                        <SecondaryText>
                          {getTranslation("SORT", t, i18n)}
                        </SecondaryText>
                        <IconButton
                          id="open-sorting-options"
                          onClick={handleClickSortOrder}
                        >
                          <NavigationActionIcon
                            active={true}
                            customfontsize={globalFontSize}
                          >
                            keyboard_arrow_down
                          </NavigationActionIcon>
                        </IconButton>
                      </Stack>

                      <StyledDivider />

                      <OrganizationUsersList
                        organizationUsers={organizationUsers}
                        organizationId={organizationId}
                        sortOrder={sortOrder}
                      />
                      <StyledDialog
                        fullWidth
                        open={openSort}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseSortOrder}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <StyledDialogContent>
                          <StyledDialogTitle>
                            {getTranslation("SORT", t, i18n)}
                          </StyledDialogTitle>
                          <Divider />
                          <List>
                            <ListItem
                              disablePadding
                              secondaryAction={
                                SORT_ORDER_NAME.FIRST_NAME === sortOrder ? (
                                  <StickySearchListOptionsCheckIcon
                                    customfontsize={globalFontSize * 1.5}
                                  />
                                ) : (
                                  <StickySearchListOptionsHiddenCheckIcon
                                    customfontsize={globalFontSize * 1.5}
                                  />
                                )
                              }
                              onClick={() =>
                                handleSortOrderMembers(
                                  SORT_ORDER_NAME.FIRST_NAME
                                )
                              }
                            >
                              <StickySearchStyledDialogListItemButton>
                                <StickySearchListOptionsHiddenCheckIcon
                                  customfontsize={globalFontSize * 1.5}
                                />
                                <ListItemText align="center">
                                  {getTranslation(
                                    SORT_ORDER_NAME.FIRST_NAME,
                                    t,
                                    i18n
                                  )}
                                </ListItemText>
                              </StickySearchStyledDialogListItemButton>
                            </ListItem>
                            <ListItem
                              disablePadding
                              secondaryAction={
                                SORT_ORDER_NAME.LAST_NAME === sortOrder ? (
                                  <StickySearchListOptionsCheckIcon
                                    customfontsize={globalFontSize * 1.5}
                                  />
                                ) : (
                                  <StickySearchListOptionsHiddenCheckIcon
                                    customfontsize={globalFontSize * 1.5}
                                  />
                                )
                              }
                              onClick={() =>
                                handleSortOrderMembers(
                                  SORT_ORDER_NAME.LAST_NAME
                                )
                              }
                            >
                              <StickySearchStyledDialogListItemButton>
                                <StickySearchListOptionsHiddenCheckIcon
                                  customfontsize={globalFontSize * 1.5}
                                />
                                <ListItemText align="center">
                                  {getTranslation(
                                    SORT_ORDER_NAME.LAST_NAME,
                                    t,
                                    i18n
                                  )}
                                </ListItemText>
                              </StickySearchStyledDialogListItemButton>
                            </ListItem>
                          </List>
                        </StyledDialogContent>
                        <CancelButton onClick={handleCloseSortOrder}>
                          {getTranslation("CANCEL", t, i18n)}
                        </CancelButton>
                      </StyledDialog>
                    </Box>
                  )}

                {hasAccess(
                  "all",
                  [permissions.USER_MANAGEMENT_VIEW],
                  getPermissionsFromUserRoles(userRoles)
                ) && (
                  <Box>
                    {invitedUsers?.filter((i) => i.status === "SENT")?.length >
                      0 && (
                      <>
                        <SectionTitle variant="h6">
                          {getTranslation("invited", t, i18n)}
                        </SectionTitle>
                        <StyledDivider />
                      </>
                    )}
                    <InvitationTable
                      organizationId={organizationId}
                      userId={user.id}
                      invitedUsers={invitedUsers}
                    />
                  </Box>
                )}
              </Stack>
            </HomePagePadding>
          </PageTransition>
        </ErrorHandling>
      </Access>
    </AppAccess>
  );
};

export default UserManagementPage;
