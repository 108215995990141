import { useTranslation } from "react-i18next";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DialogPaperProps } from "../../components/styles/general/General.styles";
import {
  MaxContentButton,
  TopLevelDialog,
} from "../../components/styles/confirm-alert/ConfirmAlert.styles";
import { getTranslation } from "../../util/utils";
import { CancelButton } from "../../components/styles/assets/asset-form/CreateAsset.styles";

export default function OrganizationDiscardChangesAlert({
  alert,
  isOpen,
  setIsOpen,
  handleConfirm,
  handleDiscard,
  label,
  disabled,
}) {
  // General Hooks
  const { t, i18n } = useTranslation();

  // Handlers
  const handleClose = () => setIsOpen(false);

  const { title, content, confirmTitle, discardTitle, showConfirm } = alert;

  return (
    <div>
      <TopLevelDialog
        fullWidth
        open={isOpen}
        onClose={handleClose}
        PaperProps={DialogPaperProps}
        keepMounted
      >
        <DialogTitle id="alert-dialog-title" fontWeight="bolder">
          {title}
        </DialogTitle>
        <DialogContent id="alert-dialog-description">{content}</DialogContent>
        <DialogActions>
          {showConfirm && (
            <MaxContentButton
              id={`confirm-${label}`}
              disabled={disabled}
              onClick={() => {
                handleConfirm();
                handleClose();
              }}
            >
              {confirmTitle ?? getTranslation("YES", t, i18n)}
            </MaxContentButton>
          )}

          <CancelButton
            variant="outlined"
            id={`cancel-${label}`}
            onClick={() => {
              handleDiscard();
              handleClose();
            }}
          >
            {discardTitle ?? getTranslation("NO", t, i18n)}
          </CancelButton>
        </DialogActions>
      </TopLevelDialog>
    </div>
  );
}
