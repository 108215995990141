import { StyledContainer } from "../components/styles/general/General.styles";

const Container = (props) => {
  // Props
  const { leftSpace, bottomSpace, children } = props;

  return (
    <StyledContainer
      bottomspace={bottomSpace}
      leftspace={leftSpace}
      className="container"
      id="container"
    >
      {children}
    </StyledContainer>
  );
};

export default Container;
