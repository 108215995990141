import { useState, forwardRef, useMemo } from "react";
import {
  Grid,
  Slide,
  ListItem,
  Divider,
  useTheme,
  Box,
  IconButton,
} from "@mui/material";
import {
  SortingOptionArrowDown,
  SortingOptionText,
  StickySearchListItemText,
  StickySearchListOptions,
  StickySearchListOptionsCheckIcon,
  StickySearchListOptionsHiddenCheckIcon,
  StickySearchStyledDialogListItemButton,
} from "../../styles/assets/StickySearch.styles";
import { useDispatch, useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { useTranslation } from "react-i18next";
import {
  CancelButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "../../styles/general/General.styles";
import {
  selectTypeSearchTerm,
  selectCategory,
  setTypeQuery,
  setTypeSearchTerm,
  setCategory,
  setFunction,
  selectFunction,
  RECENTS_TYPES_SORTING_OPTIONS,
} from "../../../store/slices/typeSearchSlice";
import { useGetResourceFunctionsQuery } from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import SelectInput from "../../SelectInput";
import { RouletteSpinnerOverlay } from "react-spinner-overlay";
import {
  CATEGORY_SELECT_LIST,
  RESOURCE_CATEGORIES,
  getTranslation,
} from "../../../util/utils";
import { useSearchParams } from "react-router-dom";
import {
  SearchTextField,
  SortingOptionContainer,
  TypeStickySearchContainer,
} from "../../styles/types/type-list/TypeStickySearch.styles";
import ErrorHandling from "../../common/ErrorHandling";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TypeRecentStickySearch = ({ sortingOption, setSortingOption }) => {
  // General hooks
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const typeSearchTerm = useSelector(selectTypeSearchTerm);
  const category = useSelector(selectCategory);
  const functionId = useSelector(selectFunction);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Queries
  const {
    data: resourceFunctionsData,
    isLoading,
    isFetching,
    isError,
  } = useGetResourceFunctionsQuery(
    {
      category,
      organizationId: user?.organizations?.find((o) => o.default).id,
      onlyWithType: false,
    },
    { skip: !Boolean(category), refetchOnMountOrArgChange: true }
  );

  // Other variables
  const functions = useMemo(
    () =>
      resourceFunctionsData?.map((f) => ({
        label: f.name,
        value: f.id,
      })) ?? [],
    [resourceFunctionsData]
  );

  // State
  const [open, setOpen] = useState(false);

  // Handlers
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCategory = (value) => {
    const newCategory = value;
    dispatch(setCategory(newCategory));
    dispatch(setFunction(null));
    if (value) {
      searchParams.set("category", newCategory);
    } else {
      searchParams.delete("category");
    }
    searchParams.delete("functionId");

    setSearchParams(searchParams);
  };

  const handleChangeFunctionId = (value) => {
    const newFunctionId = value;
    dispatch(setFunction(newFunctionId));
    if (value) {
      searchParams.set("functionId", newFunctionId);
    } else {
      searchParams.delete("functionId");
    }

    setSearchParams(searchParams);
  };

  const handleTypeSearch = (e) => {
    dispatch(setTypeSearchTerm(e.target.value));

    setTimeout(() => {
      dispatch(setTypeQuery(e.target.value));
    }, 2000);
  };

  return (
    <ErrorHandling isLoading={isLoading || isFetching} isError={isError}>
      <Box>
        <StyledDialog
          fullWidth
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          id="type-options-dialog"
        >
          <StyledDialogContent>
            <StyledDialogTitle>
              {getTranslation("SORT", t, i18n)}:
            </StyledDialogTitle>
            <Divider />
            <StickySearchListOptions id="sorting-options-list">
              {RECENTS_TYPES_SORTING_OPTIONS.map((option) => {
                const { value } = option;

                return (
                  <div key={value}>
                    <ListItem
                      onClick={() => {
                        setSortingOption(option);
                        handleClose();
                      }}
                      id="change-sorting-option"
                      secondaryAction={
                        sortingOption.value === value ? (
                          <StickySearchListOptionsCheckIcon
                            customfontsize={globalFontSize * 1.5}
                          />
                        ) : (
                          <StickySearchListOptionsHiddenCheckIcon
                            customfontsize={globalFontSize * 1.5}
                          />
                        )
                      }
                      disablePadding
                    >
                      <StickySearchStyledDialogListItemButton>
                        <StickySearchListOptionsHiddenCheckIcon
                          customfontsize={globalFontSize * 1.5}
                        />
                        <StickySearchListItemText
                          primary={getTranslation(value, t, i18n)}
                        />
                      </StickySearchStyledDialogListItemButton>
                    </ListItem>
                  </div>
                );
              })}
            </StickySearchListOptions>
          </StyledDialogContent>

          <CancelButton id={`cancel-button`} onClick={handleClose}>
            {getTranslation("CANCEL", t, i18n)}
          </CancelButton>
        </StyledDialog>
        <TypeStickySearchContainer container spacing={2}>
          <Grid item xs={6}>
            <SelectInput
              fullWidth
              selectLabelId="resourceCategory-type-label"
              label={getTranslation("RESOURCE_CATEGORY", t, i18n)}
              handleChange={handleChangeCategory}
              data={CATEGORY_SELECT_LIST.filter(
                (r) => r.value !== RESOURCE_CATEGORIES.LOCATION
              )}
              value={category}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              disabled={functions.length === 0 || !Boolean(category)}
              fullWidth
              selectLabelId="function-type-label"
              label={getTranslation("FUNCTION", t, i18n)}
              value={functionId}
              handleChange={handleChangeFunctionId}
              data={functions}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <SearchTextField
              inputProps={{ "data-testid": "manufacturer-field" }}
              id="manufacturer-field"
              variant="outlined"
              label={getTranslation("SEARCH_TERM", t, i18n)}
              value={typeSearchTerm}
              onChange={handleTypeSearch}
            />
          </Grid>
          <SortingOptionContainer item xs={6}>
            <SortingOptionText>{t(sortingOption.value)}</SortingOptionText>
            <IconButton id="open-sorting-options" onClick={handleClickOpen}>
              <SortingOptionArrowDown customfontsize={globalFontSize * 1.5} />
            </IconButton>
          </SortingOptionContainer>
        </TypeStickySearchContainer>
      </Box>
    </ErrorHandling>
  );
};

export default TypeRecentStickySearch;
