import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/system";

export const StyledListItem = styled(ListItem)(({ theme, active }) => ({
  backgroundColor: active
    ? theme.palette.mode === "light"
      ? "#EEEEEE"
      : theme.palette.secondary.dark
    : theme.palette.primary.contrastText,
  display: "block",
}));

export const StyledListItemButton = styled(ListItemButton)(
  ({ isdraweropen }) => ({
    minHeight: "48px",
    justifyContent: isdraweropen ? "initial" : "center",
    paddingInline: "12px",
  })
);

export const StyledListItemIcon = styled(ListItemIcon)(({ isdraweropen }) => ({
  minWidth: 0,
  justifyContent: "center",
}));

export const StyledListItemText = styled(ListItemText)(
  ({ theme, active, isdraweropen }) => ({
    opacity: isdraweropen ? 1 : 0,
    marginLeft: isdraweropen ? "16px" : "auto",
    color: active ? theme.palette.secondary.contrastText : "",
  })
);
