import { useParams } from "react-router-dom";
import OrganizationEditDesktop from "../../../components/profile/OrganizationEditDesktop";
import ProfileDesktopHeader from "../../../navigation/header/profile/desktop/ProfileDesktopHeader";
import AppAccess from "../../../components/common/AppAccess";

const OrganizationEdit = () => {
  // General hooks
  const { organizationId } = useParams();

  return (
    <AppAccess>
      <ProfileDesktopHeader />
      <OrganizationEditDesktop organizationId={organizationId} />;
    </AppAccess>
  );
};

export default OrganizationEdit;
