import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { InviteNewMembersButton } from "../profile/Profile.styles";

export const NoAssetsContainer = styled(Box)(({ resourceid }) => ({
  marginTop: Boolean(resourceid) ? "50px" : "150px",
}));

export const NoTypesContainer = styled(Box)({
  marginTop: "100px",
});

export const CreateAssetButton = styled(InviteNewMembersButton)(
  ({ theme }) => ({
    color: theme.palette.primary.contrastText,
  })
);
