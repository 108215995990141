import React, { useEffect, useState } from "react";

const useShakeDetector = (threshold) => {
  // States
  const [isShaking, setIsShaking] = useState(false);

  // Handlers
  const handleShake = (event) => {
    if (
      Math.abs(event.accelerationIncludingGravity.x) > threshold ||
      Math.abs(event.accelerationIncludingGravity.y) > threshold ||
      Math.abs(event.accelerationIncludingGravity.z) > threshold
    ) {
      setIsShaking(true);
    }
  };

  // Effects
  useEffect(() => {
    if (typeof window.DeviceMotionEvent.requestPermission === "function") {
      // iOS 13+
      window.DeviceMotionEvent.requestPermission().then((response) => {
        if (response === "granted") {
          window.addEventListener("devicemotion", handleShake, true);
        }
      });
    } else {
      // non iOS 13+
      window.addEventListener("devicemotion", handleShake, true);
    }

    return () => {
      window.removeEventListener("devicemotion", handleShake);
    };
  }, []);

  return [isShaking, setIsShaking];
};

export default useShakeDetector;
