import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import ErrorHandling from "../../common/ErrorHandling";
import AssetDetailList from "./AssetDetailList";
import {
  useAddFavoriteMutation,
  useDeleteFavoriteMutation,
  useGetAllFunctionsQuery,
  useGetFavoritesQuery,
  useGetResourceDetailsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import AssetDetailCard from "./AssetDetailCard";
import { useUserRolePermissionsQuery } from "../../../store/slices/api/userManagementSlice";
import {
  useGetResourceTagsQuery,
  useGetTagsQuery,
} from "../../../store/slices/api/tagsSlice";
import { clearList, setList } from "../../../store/slices/resourceImageSlice";
import {
  clearAttachmentList,
  setAttachmentList,
} from "../../../store/slices/resourceAttachmentSlice";
import {
  setGlobalTags,
  setInitialTags,
  setTags,
} from "../../../store/slices/tagsSlice";
import { useGetResourceAttachmentsQuery } from "../../../store/slices/api/resourceAttachmentsApiSlice";
import Slider from "react-slick";
import {
  AssetDetailCardMedia,
  AssetDetailCardMediaHeaderContainer,
  AssetDetailCardMediaHeaderTextContainer,
  AssetHeaderText,
} from "../../styles/assets/asset-detail/AssetDetailCard.styles";
import CarouselImage from "./CarouselImage";
import {
  selectGlobalFontSize,
  selectTheme,
} from "../../../store/slices/appSlice";
import {
  RESOURCE_CATEGORIES,
  getFallbackIcon,
  getPermissionsFromUserRoles,
  hasAccess,
  permissions,
} from "../../../util/utils";
import { NavigationActionIcon } from "../../styles/menu/Menu.styles";
import { IconButton } from "@mui/material";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import EditAsset from "../asset-form/EditAsset";

const AssetDetails = ({ resourceId }) => {
  // Selectors
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const organizationId = user.organizations.find((o) => o.default).id;
  const currentTheme = useSelector(selectTheme);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [imageIndex, setImageIndex] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);

  // Mutations
  const [addFavorite, { isLoading: isLoadingAddFavorite }] =
    useAddFavoriteMutation();
  const [deleteFavorite, { isLoading: isLoadingDeleteFavorite }] =
    useDeleteFavoriteMutation();

  // Queries
  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery({
    userId: user.id,
    organizationId,
  });

  const {
    data: resourceData,
    isLoading: isLoadingResource,
    isError: isErrorResource,
  } = useGetResourceDetailsQuery(
    {
      resourceid: resourceId,
      organizationId,
    },
    {
      skip: !Boolean(resourceId),
    }
  );

  const {
    data: resourceAttachments,
    isLoading: isLoadingAttachments,
    isError: isErrorAttachments,
  } = useGetResourceAttachmentsQuery({
    resourceid: resourceId,
    organizationId: user.organizations.find((o) => o.default).id,
  });

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isSuccess: isSuccessFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  const {
    data: tags,
    isLoading: isLoadingTags,
    isError: isErrorTags,
  } = useGetTagsQuery({
    organizationId,
  });

  const {
    data: resourceTags,
    isLoading: isLoadingResourceTags,
    isErrorResourceTags,
  } = useGetResourceTagsQuery({
    resourceid: resourceId,
    organizationId,
  });

  const {
    data: favorites,
    isLoading: isLoadingFavorites,
    isSuccess: isSuccessFavorites,
    isError: isErrorFavorites,
  } = useGetFavoritesQuery({
    organizationId,
  });

  // Other variables
  const typeImages =
    resourceData?.type?.images.map((img) => {
      return {
        ...img,
        uri: `/organizations/${user?.organizations.find((o) => o.default).id}${
          img.uri
        }`,
      };
    }) ?? [];

  const resourceImages = [...(resourceData?.images ?? []), ...typeImages];
  const slidesToShow = 1;
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
  };
  const resourceFunction = allFunctionsData?.find(
    (f) => f.id === resourceData?.functionId
  );
  const isFavorite = favorites?.data?.some((f) => f.resource.id === resourceId);

  // Handlers
  const handleImageChange = (oldIndex, newIndex) => {
    setImageIndex(newIndex);
  };

  const handleAddFavorite = async (e) => {
    e.stopPropagation();

    try {
      await addFavorite({
        resourceid: resourceId,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      }).unwrap();
    } catch (error) {
      console.error("Failed to add favorite", error);
    }
  };

  const handleDeleteFavorite = async (e) => {
    e.stopPropagation();

    try {
      await deleteFavorite({
        resourceid: resourceId,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      }).unwrap();
    } catch (error) {
      console.error("Failed to delete favorite", error);
    }
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  // Effects
  // Here, we will fetch the tags and put them into the Redux store (similar to resource images and attachments)
  useEffect(() => {
    if (tags) {
      const mappedTags = tags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setGlobalTags(mappedTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  useEffect(() => {
    if (resourceTags) {
      const mappedResourceTags = resourceTags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setInitialTags(mappedResourceTags));
      dispatch(setTags(mappedResourceTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceTags]);

  return (
    <ErrorHandling
      isLoading={
        isLoadingAttachments ||
        isLoadingResource ||
        isLoadingResourceTags ||
        isLoadingTags
      }
      isError={
        isErrorAttachments ||
        isErrorResource ||
        isErrorResourceTags ||
        isErrorTags
      }
    >
      {openEdit && (
        <EditAsset
          resourceId={resourceId}
          open={openEdit}
          setOpen={setOpenEdit}
        />
      )}

      {resourceImages && resourceImages.length > 0 ? (
        <Slider
          className={"carousel-theme--" + currentTheme}
          id="carousel-list"
          beforeChange={handleImageChange}
          {...settings}
        >
          {resourceImages?.map((resourceImage, index) => (
            <CarouselImage
              id="carousel-image"
              key={resourceImage.id}
              resourceImage={resourceImage}
              currentIndex={imageIndex}
              index={index}
              slidesToShow={slidesToShow}
            />
          ))}
        </Slider>
      ) : (
        <AssetDetailCardMedia
          id="asset-image-big"
          component="img"
          image={`${process.env.PUBLIC_URL}/icons/${getFallbackIcon(
            resourceFunction?.category
          )}`}
        ></AssetDetailCardMedia>
      )}
      <AssetDetailCardMediaHeaderContainer>
        <AssetDetailCardMediaHeaderTextContainer>
          <SecondaryText variant="h5" id="asset-name">
            {resourceData?.name}
          </SecondaryText>
        </AssetDetailCardMediaHeaderTextContainer>
        {hasAccess(
          "all",
          [
            permissions[
              `ASSET_MANAGEMENT_${
                resourceFunction?.category ===
                RESOURCE_CATEGORIES.HARDWARE_ASSET
                  ? "HARDWARE"
                  : resourceFunction?.category
              }_EDIT`
            ],
          ],
          getPermissionsFromUserRoles(userRoles)
        ) && (
          <IconButton onClick={handleOpenEdit}>
            <NavigationActionIcon active={true} customfontsize={globalFontSize}>
              edit
            </NavigationActionIcon>
          </IconButton>
        )}

        {isFavorite ? (
          <IconButton onClick={handleDeleteFavorite}>
            <NavigationActionIcon active={true} customfontsize={globalFontSize}>
              star
            </NavigationActionIcon>
          </IconButton>
        ) : (
          <IconButton onClick={handleAddFavorite}>
            <NavigationActionIcon customfontsize={globalFontSize}>
              star
            </NavigationActionIcon>
          </IconButton>
        )}
      </AssetDetailCardMediaHeaderContainer>

      <AssetDetailList resourceData={resourceData} />
    </ErrorHandling>
  );
};

export default AssetDetails;
