import {
  ActionTitle,
  HeaderGridContainer,
  HeaderWrapper,
  PageTitle,
  RightAlignedActionTitle,
} from "../../../components/styles/header/Header.styles";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import { useSelector } from "react-redux";
import { selectPageInfo } from "../../../store/slices/appSlice";

const UpdateUserNameHeader = ({ handleAction, handleCancelAction }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const currentPageInfo = useSelector(selectPageInfo);

  return (
    <HeaderWrapper id="sticky-header">
      <HeaderGridContainer container>
        <Grid item xs={3}>
          <ActionTitle
            tabIndex="0"
            data-testid="action-title-cancel"
            id="action-title-cancel"
            onClick={handleCancelAction}
          >
            {getTranslation("CANCEL", t, i18n)}
          </ActionTitle>
        </Grid>
        <Grid item xs={6}>
          <PageTitle variant="h6" id="title">
            {getTranslation("name", t, i18n)}
          </PageTitle>
        </Grid>
        <Grid display="flex" justifyContent="end" item xs={3}>
          <RightAlignedActionTitle
            tabIndex="0"
            onClick={() => {
              if (!currentPageInfo.isActionButtonDisabled) {
                handleAction();
              }
            }}
            aria-disabled={currentPageInfo.isActionButtonDisabled}
            disabled={currentPageInfo.isActionButtonDisabled ? 1 : 0}
            id="action-title-done"
            data-testid="action-title-done"
          >
            {getTranslation("DONE", t, i18n)}
          </RightAlignedActionTitle>
        </Grid>
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default UpdateUserNameHeader;
