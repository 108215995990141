import { useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import useOrientation from "../../../hooks/useOrientation";
import { selectTheme } from "../../../store/slices/appSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import TypeCarouselImage from "./TypeCarouselImage";

const TypeCarousel = ({ typeImages }) => {
  // General hooks
  const currentTheme = useSelector(selectTheme);
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);

  // Custom hooks
  const orientation = useOrientation();

  // States
  const [currentIndex, setCurrentIndex] = useState(0);

  // Other variables
  const slidesToShow = tabletMatches && orientation === "landscape" ? 2 : 1;
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
  };

  // Handlers
  const handleImageChange = (oldIndex, newIndex) => {
    setCurrentIndex(newIndex);
  };

  return (
    <Slider
      className={"carousel-theme--" + currentTheme}
      id="carousel-list"
      beforeChange={handleImageChange}
      {...settings}
    >
      {typeImages?.map((image, index) => (
        <TypeCarouselImage
          key={image.id}
          image={image}
          currentIndex={currentIndex}
          index={index}
          slidesToShow={slidesToShow}
        />
      ))}
    </Slider>
  );
};

export default TypeCarousel;
