import {
  BackButtonGridItem,
  BackTitle,
  HeaderGridContainer,
  HeaderWrapper,
  LeftActionButton,
  PageTitle,
  StyledChevronLeftIcon,
} from "../../../components/styles/header/Header.styles";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";

const OrganizationDetailsHeader = ({ goBackHandler, title }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  return (
    <HeaderWrapper id="sticky-header">
      <HeaderGridContainer container columnSpacing={4}>
        <BackButtonGridItem item xs={2}>
          <LeftActionButton id="back-button" onClick={goBackHandler}>
            <StyledChevronLeftIcon
              id="header-back-button-icon"
              customfontsize={globalFontSize * 2}
            />
            <BackTitle component="span" id="previous_page-title">
              {getTranslation("back", t, i18n)}
            </BackTitle>
          </LeftActionButton>
        </BackButtonGridItem>
        <Grid item xs={8}>
          <PageTitle variant="h6" id="title">
            {title}
          </PageTitle>
        </Grid>
        <Grid item xs={2}></Grid>
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default OrganizationDetailsHeader;
