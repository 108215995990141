import {
  patch_resource_tags_url,
  resource_tags_url,
  tags_url,
} from "../../../Constants";
import KeycloakService from "../../../services/KeycloakService";
import { baseApiSlice } from "./baseApiSlice";

export const resourceImagesApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTags: builder.query({
      query: ({ organizationId }) => ({
        url: tags_url.replace(":organizationId", organizationId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["Tags"],
    }),
    getResourceTags: builder.query({
      query: ({ organizationId, resourceid }) => ({
        url: resource_tags_url
          .replace(":organizationId", organizationId)
          .replace(":resourceid", resourceid),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["Tags"],
    }),
    patchResourceTags: builder.mutation({
      query: ({ tags, resourceid, organizationId, resourceCategory }) => ({
        url: patch_resource_tags_url
          .replace(":resourceid", resourceid)
          .replace(":resourceCategory", resourceCategory)
          .replace(":organizationId", organizationId),
        method: "PATCH",
        body: tags,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["Tags", "Search"],
    }),
  }),
});

export const {
  useGetTagsQuery,
  useGetResourceTagsQuery,
  usePatchResourceTagsMutation,
} = resourceImagesApiSlice;
