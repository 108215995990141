import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TagsInput from "../../components/assets/tags/TagsInput";
import ErrorHandling from "../../components/common/ErrorHandling";
import PageTransition from "../../components/PageTransition";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import EditTagsHeader from "../../navigation/header/assets/EditTagsHeader";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import {
  useGetAllFunctionsQuery,
  useGetResourceDetailsQuery,
} from "../../store/slices/api/assetManagementSlice";
import {
  useGetResourceTagsQuery,
  useGetTagsQuery,
  usePatchResourceTagsMutation,
} from "../../store/slices/api/tagsSlice";
import { selectUser } from "../../store/slices/authSlice";
import {
  DEFAULT_COLOR,
  removeTags,
  resetTags,
  selectAllTags,
  selectHaveChanges,
  setAutocompleteOpen,
  setCurrentColor,
  setError,
  setGlobalTags,
  setInitialTags,
  setInputValue,
  setMarkedTag,
  setTags,
} from "../../store/slices/tagsSlice";
import { messageSuccess } from "../../util/notification";
import {
  getTranslation,
  RESOURCE_CATEGORIES,
  showValidationError,
  transitionDirections,
} from "../../util/utils";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const EditTags = () => {
  // General hooks
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { resourceid } = useParams();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const tags = useSelector(selectAllTags);
  const haveChanges = useSelector(selectHaveChanges);

  // Queries
  const {
    data: resourceData,
    isLoading: isLoadingResource,
    isError: isErrorResource,
  } = useGetResourceDetailsQuery(
    {
      resourceid,
      organizationId,
    },
    {
      skip: !Boolean(resourceid) || resourceid === "undefined",
    }
  );

  const {
    data: tagsData,
    isLoading: isLoadingTags,
    isErrorTags,
  } = useGetTagsQuery({
    organizationId,
  });

  const {
    data: resourceTags,
    isLoading: isLoadingResourceTags,
    isErrorResourceTags,
  } = useGetResourceTagsQuery({
    resourceid,
    organizationId,
  });

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Mutations
  const [patchResourceTags] = usePatchResourceTagsMutation();

  // State
  const [openConfirm, setOpenConfirm] = useState(false);

  // Other variables
  const resourceFunction = allFunctionsData?.find(
    (f) => f.id === resourceData?.functionId
  );

  const direction = searchParams.get("direction");

  const alert = {
    content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),

    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  // Handlers
  const handleReset = () => {
    dispatch(setInputValue(""));
    dispatch(setError(""));
    dispatch(setAutocompleteOpen(false));
    dispatch(setCurrentColor(DEFAULT_COLOR));
    dispatch(setMarkedTag(null));
  };

  const handleNavigateToResourceEdit = () =>
    navigate(
      `/resources/${resourceid}?direction=${transitionDirections.TOP_TO_BOTTOM}&comingFrom=edit-tags`
    );

  const handleCancel = () => {
    if (haveChanges) {
      setOpenConfirm(true);
      dispatch(setAutocompleteOpen(false));
    } else {
      handleReset();
      handleNavigateToResourceEdit();
    }
  };

  const handleConfirmAlert = () => {
    handleReset();
    dispatch(resetTags());
    handleNavigateToResourceEdit();
  };

  const handleDone = () => {
    handleReset();
    dispatch(removeTags());
    handleNavigateToResourceEdit();
    messageSuccess(getTranslation("TAGS_UPDATED", t, i18n));
  };

  const handleSubmit = async () => {
    // For add, send tags where 'new' = true AND 'deleted' = false
    const tagsToBeAdded = tags
      .filter((t) => t.new && !t.deleted)
      .map((t) => {
        return {
          displayName: t.name,
          color: t.color,
        };
      });

    // For edit, send tags where 'new' = false AND 'deleted' = false
    const tagsToBeEdited = tags
      .filter((t) => !t.new && !t.deleted)
      .map((t) => {
        return {
          id: t.id,
          displayName: t.name,
          color: t.color,
        };
      });

    // For delete, send tags where 'new' = false AND 'deleted' = true
    // const tagsToBeDeleted = tags.filter((t) => !t.new && t.deleted);

    const resourceCategory = `${
      resourceFunction?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET
        ? "hardware-asset"
        : resourceFunction?.category.toLowerCase()
    }s`;

    const tagsPayload = [...tagsToBeAdded, ...tagsToBeEdited];

    try {
      await patchResourceTags({
        resourceid,
        organizationId: user?.organizations?.find((o) => o.default).id,
        resourceCategory,
        tags: tagsPayload,
      }).unwrap();

      handleDone();
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to patch resource tags", error);
    }
  };

  // Here, we will fetch the tags and put them into the Redux store (similar to resource images and attachments)
  useEffect(() => {
    if (tagsData) {
      const mappedTags = tagsData.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setGlobalTags(mappedTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsData]);

  useEffect(() => {
    if (resourceTags) {
      const mappedResourceTags = resourceTags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setInitialTags(mappedResourceTags));
      dispatch(setTags(mappedResourceTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceTags]);

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling
          isLoading={
            isLoadingFunctions ||
            isLoadingResource ||
            isLoadingResourceTags ||
            isLoadingTags
          }
          isError={
            isErrorFunctions ||
            isErrorResource ||
            isErrorResourceTags ||
            isErrorTags
          }
        >
          <PageTransition direction={direction}>
            <ConfirmAlert
              isOpen={openConfirm}
              setIsOpen={setOpenConfirm}
              alert={alert}
              handleConfirm={handleConfirmAlert}
            />
            <EditTagsHeader
              handleAction={handleSubmit}
              handleCancelAction={handleCancel}
            />
            <HomePagePadding>
              <TagsInput id={resourceid} shouldFocus={true} />
            </HomePagePadding>
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default EditTags;
