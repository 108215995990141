import React from "react";
import { PieStack, StyledPie } from "../../../styles/reports/ChartWidgets.styles";

const PieChartWidgetContent = ({ data }) => {
  return (
    <PieStack>
      <StyledPie
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
            },
          },
        }}
        data={data}
      />
    </PieStack>
  );
};

export default PieChartWidgetContent;
