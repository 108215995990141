import { Box, IconButton, styled } from "@mui/material";

export const UploadImageContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const UploadButton = styled(IconButton)(({ theme }) => ({
  alignItems: "center",
  color: theme.palette.secondary.contrastText,
  fontSize: theme.typography.h6.fontSize,
}));
