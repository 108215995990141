import { useTranslation } from "react-i18next";
import { getTranslation } from "../../util/utils";
import {
  StyledRefreshButton,
  UpdateAlertActionsContainer,
  UpdateAlertContainer,
  UpdateAlertContentContainer,
} from "../styles/general/General.styles";
import { SecondaryText } from "../styles/assets/ListInlineView.styles";

const UpdateAlert = ({ clickHandler, title, action }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  return (
    <UpdateAlertContainer>
      <UpdateAlertContentContainer>
        <SecondaryText id="update-alert-message">
          {getTranslation(title, t, i18n)}
        </SecondaryText>
      </UpdateAlertContentContainer>

      <UpdateAlertActionsContainer>
        <StyledRefreshButton
          id="refresh-page"
          size="small"
          onClick={clickHandler}
        >
          {getTranslation(action, t, i18n)}
        </StyledRefreshButton>
      </UpdateAlertActionsContainer>
    </UpdateAlertContainer>
  );
};

export default UpdateAlert;
