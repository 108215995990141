import React from "react";
import { Box } from "@mui/material";
import {
  BenefitCardContainer,
  BenefitDescription,
  BenefitName,
} from "./styles/onboarding/BenefitCard.styles";

const BenefitCard = ({ icon, title, description }) => {
  return (
    <BenefitCardContainer>
      {icon}
      <Box>
        <BenefitName variant="body1">{title}</BenefitName>
        <BenefitDescription variant="body1">{description}</BenefitDescription>
      </Box>
    </BenefitCardContainer>
  );
};

export default BenefitCard;
