import { Box, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { styled } from "@mui/system";
import { StyledDivider } from "../../profile/Profile.styles";
import { RemoveFromOrganizationButton } from "../../general/General.styles";

export const AttachmentItemContainer = styled(Box)(
  ({ theme, mobileMatches }) => ({
    marginTop: "2px",
    backgroundColor: theme.palette.secondary.dark,
    width: mobileMatches ? "100%" : "50%",
  })
);

export const AttachmentNameText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  flexGrow: 1,
  marginLeft: "10px",
}));

export const StyledDownloadIcon = styled(DownloadIcon)(
  ({ theme, customfontsize }) => ({
    marginRight: "10px",
    fontSize: `${customfontsize}px`,
  })
);

export const StyledMoreIcon = styled(MoreHorizIcon)(
  ({ theme, customfontsize }) => ({
    color: theme.palette.primary.main,
    fontSize: `${customfontsize}px`,
  })
);

export const StyledDeleteIcon = styled(DeleteIcon)(
  ({ theme, customfontsize }) => ({
    marginRight: "10px",
    fontSize: `${customfontsize}px`,
  })
);

export const StyledVisibilityIcon = styled(VisibilityIcon)(
  ({ theme, customfontsize }) => ({
    marginRight: "10px",
    fontSize: `${customfontsize}px`,
  })
);

export const StyledEditIcon = styled(EditIcon)(({ theme, customfontsize }) => ({
  marginRight: "10px",
  fontSize: `${customfontsize}px`,
}));

export const StyledDividerWithSpace = styled(StyledDivider)({
  marginBottom: "10px",
});

export const DeleteAttachmentButton = styled(RemoveFromOrganizationButton)({
  margin: 0,
});
