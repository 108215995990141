import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  invitations: [],
};

// Slice
const invitationsSlice = createSlice({
  name: "invitations",
  initialState,
  reducers: {
    addInvitation: (state, action) => {
      const newInvitation = action.payload;
      state.invitations.push(newInvitation);
    },
    editInvitation: (state, action) => {
      const updatedInvitation = action.payload;
      const updatedInvitationIndex = state.invitations.findIndex(
        (i) => i.email === updatedInvitation.email
      );
      state.invitations.splice(updatedInvitationIndex, 1, updatedInvitation);
    },
    deleteInvitation: (state, action) => {
      const invitationToBeDeletedEmail = action.payload;
      state.invitations = state.invitations.filter(
        (i) => i.email !== invitationToBeDeletedEmail
      );
    },
    clearInvitations: (state) => {
      state.invitations = [];
    },
  },
});

// Export slice and its corresponding actions
export default invitationsSlice.reducer;
export const {
  addInvitation,
  editInvitation,
  deleteInvitation,
  clearInvitations,
} = invitationsSlice.actions;

// selectors
export const selectInvitations = (state) => state.invitations.invitations;
