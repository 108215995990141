import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import { RecentlyUsedText } from "../../styles/assets/tags/Tags.styles";

const DefaultTagResults = ({
  formattedRecents,
  formattedValue,
  handleClickTag,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();

  return (
    <Box>
      <RecentlyUsedText>
        {getTranslation("RECENTLY_USED", t, i18n)}
      </RecentlyUsedText>
      <List>
        {formattedRecents.map((recent) => (
          <ListItem key={"recent-" + recent.value} disablePadding>
            <ListItemButton onMouseDown={(e) => handleClickTag(recent)}>
              <Typography>{recent.label}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {formattedRecents.length > 0 && formattedValue.length > 0 && (
        <Divider variant="middle" />
      )}
      <List>
        {formattedValue.map((tag) => (
          <ListItem key={"all-" + tag.value} disablePadding>
            <ListItemButton onMouseDown={() => handleClickTag(tag)}>
              <Typography>{tag.label}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default DefaultTagResults;
