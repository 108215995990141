import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { transitionDirections, getTranslation } from "../../util/utils";
import PageTransition from "../../components/PageTransition";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { selectUser, setUser } from "../../store/slices/authSlice";
import {
  useGetRegionsQuery,
  useUpdateUserRegionalPreferenceMutation,
} from "../../store/slices/api/userManagementSlice";
import { messageError, messageSuccess } from "../../util/notification";
import { useTranslation } from "react-i18next";
import SelectInput from "../../components/SelectInput";
import RegionalPreferenceHeader from "../../navigation/header/profile/RegionalPreferenceHeader";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";

export const transformRegionsData = (regions, t, i18n) =>
  regions?.map((region) => {
    return {
      value: region.code,
      label: getTranslation(region.code, t, i18n),
    };
  });

const RegionalPreference = () => {
  // General hooks
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // Selectors
  const user = useSelector(selectUser);

  // Queries
  const {
    data: regionsData,
    isLoading: isLoadingRegions,
    isError: isErrorRegions,
  } = useGetRegionsQuery();

  // Mutation
  const [
    updateRegionalPreference,
    { isLoading: isLoadingUpdateRegionalPreference },
  ] = useUpdateUserRegionalPreferenceMutation();

  // States
  const [regionalPreference, setRegionalPreference] = useState(user.region);

  // Handlers
  const handleChangeRegion = (value) => setRegionalPreference(value);

  const handleSubmit = async () => {
    try {
      await updateRegionalPreference({
        userId: user?.id,
        regionalPreference,
      }).unwrap();

      dispatch(
        setUser({
          ...user,
          region: regionalPreference,
        })
      );

      navigate(
        `/profile/preferences?direction=${transitionDirections.LEFT_TO_RIGHT}`
      );
      messageSuccess(
        getTranslation("successfulUpdateUserRegionalPreference", t, i18n)
      );
    } catch (error) {
      messageError(
        getTranslation("failedUpdateUserRegionalPreference", t, i18n)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const goBackHandler = () =>
    navigate(
      `/profile/preferences?direction=${transitionDirections.LEFT_TO_RIGHT}`
    );

  // Other variables
  const transitionDirection = searchParams.get("direction");

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={isLoadingRegions || isLoadingUpdateRegionalPreference}
        isError={isErrorRegions}
      >
        <PageTransition direction={transitionDirection}>
          <RegionalPreferenceHeader
            handleAction={handleSubmit}
            goBackHandler={goBackHandler}
          />
          <HomePagePadding>
            <SelectInput
              fullWidth
              label="regionalPreference"
              selectLabelId="regional-preference-select"
              value={regionalPreference || "us"}
              handleChange={handleChangeRegion}
              data={transformRegionsData(regionsData, t, i18n)}
            />
          </HomePagePadding>
        </PageTransition>
      </ErrorHandling>
    </AppAccess>
  );
};

export default RegionalPreference;
