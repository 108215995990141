export const VIEWPORT_MEDIA_QUERIES = {
  MOBILE: "(max-width:599px)",
  TABLET: "(min-width:600px) and (max-device-width: 1199px)",
  DESKTOP: "(min-device-width: 1200px)",
};

// Helper Mobile constants
export const STANDARD_BOTTOM_SPACE = 69;
export const IOS_BOTTOM_SPACE = 93;

export const STANDARD_BOTTOM_PADDING = "8px";
export const IOS_BOTTOM_PADDING = "24px";
