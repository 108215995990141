import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import {
  selectDescription,
  selectName,
  selectIsPrivate,
  setDescription,
  setName,
  setIsPrivate,
} from "../../../store/slices/reportsSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import {
  BasicDataWizardStepStack,
  BasicDataWizardStepTextField,
} from "../../styles/reports/Reports.styles";

const BasicDataWizardStep = ({ showPrivate }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const name = useSelector(selectName);
  const description = useSelector(selectDescription);
  const isPrivate = useSelector(selectIsPrivate);

  // Handlers
  const handleChangeName = (event) => {
    const newName = event.target.value;
    dispatch(setName(newName));
  };

  const handleChangeDescription = (event) => {
    const newDescription = event.target.value;
    dispatch(setDescription(newDescription));
  };

  const handlePrivate = (event) => {
    const newIsPrivate = event.target.checked;
    dispatch(setIsPrivate(newIsPrivate));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <BasicDataWizardStepStack>
          <BasicDataWizardStepTextField
            id="report-name-field"
            value={name}
            onChange={handleChangeName}
            label={getTranslation("REPORT_NAME", t, i18n)}
          />
          {showPrivate && (
            <FormControlLabel
              id="report-private-checkbox"
              control={
                <Checkbox checked={isPrivate} onChange={handlePrivate} />
              }
              label={getTranslation("REPORT_SET_PRIVATE", t, i18n)}
            />
          )}
        </BasicDataWizardStepStack>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="report-description-field"
          value={description}
          onChange={handleChangeDescription}
          multiline
          rows={6}
          label={getTranslation("REPORT_DESCRIPTION", t, i18n)}
        />
      </Grid>
    </Grid>
  );
};

export default BasicDataWizardStep;
