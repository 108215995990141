import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectResourceInput } from "../store/slices/resourceInputSlice";

// Custom hook to traverse the tree-like structure
const useTreeTraversal = (
  initialTreeData,
  disabledOptions,
  disabledOptionsData
) => {
  // General hooks
  const { resourceid } = useParams();

  // Selectors
  const resourceInput = useSelector(selectResourceInput);

  // State
  const [treeData, setTreeData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Handlers
  const traverseTree = (node) => {
    if (node.children && node.children.length > 0) {
      const childrenObjects = [];

      for (const child of node.children) {
        childrenObjects.push({
          value: child?.displayId || child?.value || null,
          title: child?.displayId || child?.title || null,
          disabled: Boolean(
            disabledOptions.some((option) => option?.value === child?.id) ||
              disabledOptionsData?.some((option) => option.id === child?.id)
          ),
          children: traverseTree(child),
        });
      }

      return childrenObjects;
    } else {
      return [];
    }
  };

  // Effects
  useEffect(() => {
    if (initialTreeData) {
      const parentsObjects = [];

      for (const parent of initialTreeData) {
        parentsObjects.push({
          value: parent?.displayId || parent?.value || null,
          title: parent?.displayId || parent?.title || null,
          disabled: Boolean(
            disabledOptions.some((option) => option?.value === parent?.id) ||
              disabledOptionsData?.some((option) => option.id === parent?.id)
          ),
          children: traverseTree(parent),
        });
      }

      const newTreeData = parentsObjects
        ?.slice()
        ?.sort((a, b) => b.children.length - a.children.length);

      setTreeData(newTreeData);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initialTreeData,
    disabledOptionsData,
    resourceInput.category,
    resourceid,
  ]);

  return { treeData, isLoading };
};

export default useTreeTraversal;
