import ResourceImageForm from "../../components/assets/asset-form/ResourceImageForm";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import {
  addResourceImage,
  DEFAULT_RESOURCE_IMAGE,
} from "../../store/slices/resourceImageSlice";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { messageError, messageSuccess } from "../../util/notification";
import PageTransition from "../../components/PageTransition";
import {
  transitionDirections,
  getTranslation,
  showValidationError,
} from "../../util/utils";
import ChooseAssetImageHeader from "../../navigation/header/assets/ChooseAssetImageHeader";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";
import { useUploadResourceImageMutation } from "../../store/slices/api/resourceImagesApiSlice";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const AddLocalResourceImagePage = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { resourceId } = useParams();

  // Selectors
  const user = useSelector(selectUser);

  // Mutations
  const [uploadResourceImage, { isLoading: isLoadingUpload }] =
    useUploadResourceImageMutation();

  // State
  const [resourceImageInput, setResourceImageInput] = useState(
    DEFAULT_RESOURCE_IMAGE
  );

  const [name, setName] = useState("");
  const [extension, setExtension] = useState("");

  // Other variables
  const imageCategory = searchParams.get("imageCategory") ?? "FRONT";
  const direction = searchParams.get("direction");

  // Handlers
  const cancelHandler = () => {
    if (resourceId) {
      navigate(
        `/resources/${resourceId}/edit?direction=${transitionDirections.TOP_TO_BOTTOM}&previousPage=add-local-image`
      );
    } else {
      navigate(
        `/resources/add-new?direction=${transitionDirections.TOP_TO_BOTTOM}&previousPage=add-local-image`
      );
    }
  };

  // Other variables
  const isValid =
    (resourceImageInput.file || resourceImageInput.selectedFile) && name;

  const isDisabled = !isValid;

  const handleSubmit = useCallback(async () => {
    try {
      if (!resourceImageInput.selectedFile) {
        messageError(getTranslation("UPLOAD_IMAGE", t, i18n));
        return;
      }

      if (resourceId) {
        const formData = new FormData();

        const { file, description } = resourceImageInput;
        const finalImageCategory =
          imageCategory === "OTHER" ? "MISC" : imageCategory;

        formData.append("file", file);
        formData.append("fileName", name + extension);
        formData.append("imageCategory", finalImageCategory);
        formData.append("description", description);

        await uploadResourceImage({
          formData,
          resourceid: resourceId,
          organizationId: user?.organizations?.find((o) => o.default)?.id,
        }).unwrap();

        messageSuccess(
          getTranslation("RESOURCE_IMAGE_CREATED_SUCCESSFULLY", t, i18n)
        );
      } else {
        const finalImageCategory =
          imageCategory === "OTHER" ? "MISC" : imageCategory;

        const input = {
          ...resourceImageInput,
          name: name + extension,
          imageCategory: finalImageCategory,
        };

        dispatch(addResourceImage(input));
      }

      cancelHandler();
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to upload resource image", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceImageInput, name]);

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling isLoading={isLoadingUpload}>
          <PageTransition direction={direction}>
            <ChooseAssetImageHeader
              handleAction={handleSubmit}
              handleCancelAction={cancelHandler}
              isDisabled={isDisabled}
            />

            <HomePagePadding>
              <ResourceImageForm
                resourceImageInput={resourceImageInput}
                setResourceImageInput={setResourceImageInput}
                name={name}
                setName={setName}
                setExtension={setExtension}
              />
            </HomePagePadding>
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default AddLocalResourceImagePage;
