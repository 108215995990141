import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  useMediaQuery,
} from "@mui/material";
import {
  CancelButton as MobileCancelButton,
  DialogPaperProps,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "../../components/styles/general/General.styles";
import {
  ConfirmDialogButton,
  MaxContentButton,
  StyledDialogText,
  TopLevelDialog,
} from "../../components/styles/confirm-alert/ConfirmAlert.styles";
import { StyledDivider } from "../../components/styles/profile/Profile.styles";
import { getTranslation } from "../../util/utils";
import { StyledDividerWithSpace } from "../../components/styles/assets/asset-attachments/AssetResourceAttachment.styles";
import { CancelButton } from "../../components/styles/assets/asset-form/CreateAsset.styles";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmAlert({
  alert,
  isOpen,
  setIsOpen,
  handleConfirm,
  label,
}) {
  // General Hooks
  const { t, i18n } = useTranslation();
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Handlers
  const handleClose = () => setIsOpen(false);

  const { title, content, confirmTitle, closeTitle, showConfirm } = alert;

  return (
    <div>
      {desktopMatches ? (
        <TopLevelDialog
          fullWidth
          open={isOpen}
          onClose={handleClose}
          PaperProps={DialogPaperProps}
          keepMounted
        >
          <DialogTitle id="alert-dialog-title" fontWeight="bolder">
            {title}
          </DialogTitle>
          <DialogContent id="alert-dialog-description">{content}</DialogContent>
          <DialogActions>
            <CancelButton
              variant="outlined"
              id={`cancel-${label}`}
              onClick={handleClose}
            >
              {closeTitle ?? getTranslation("NO", t, i18n)}
            </CancelButton>
            {showConfirm && (
              <MaxContentButton
                id={`confirm-${label}`}
                onClick={() => {
                  handleConfirm();
                  handleClose();
                }}
              >
                {confirmTitle ?? getTranslation("YES", t, i18n)}
              </MaxContentButton>
            )}
          </DialogActions>
        </TopLevelDialog>
      ) : (
        <StyledDialog
          fullWidth
          open={isOpen}
          onClose={handleClose}
          TransitionComponent={Transition}
          keepMounted
        >
          <StyledDialogContent>
            {title && (
              <>
                <StyledDialogTitle id="alert-dialog-title">
                  {title}
                </StyledDialogTitle>
                <StyledDivider />
              </>
            )}
            {content && (
              <StyledDialogText id="alert-dialog-description">
                {content}
              </StyledDialogText>
            )}
            <StyledDividerWithSpace />
            {showConfirm && (
              <ConfirmDialogButton
                id={`confirm-${label}`}
                data-testid="confirm"
                color="error"
                onClick={() => {
                  handleConfirm();
                  handleClose();
                }}
                autoFocus
              >
                {confirmTitle ? confirmTitle : getTranslation("YES", t, i18n)}
              </ConfirmDialogButton>
            )}
          </StyledDialogContent>
          <MobileCancelButton id={`cancel-${label}`} onClick={handleClose}>
            {closeTitle ? closeTitle : getTranslation("NO", t, i18n)}
          </MobileCancelButton>
        </StyledDialog>
      )}
    </div>
  );
}
