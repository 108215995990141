import { motion } from "framer-motion";
import { transitionDirections } from "../util/utils";

const PageTransition = ({ direction, children }) => {
  // Other variables
  const horizontalTransition = {
    opacity: 0,
    x: direction === transitionDirections.RIGHT_TO_LEFT ? 100 : -100,
  };

  const verticalTransition = {
    opacity: 0,
    y: direction === transitionDirections.TOP_TO_BOTTOM ? -100 : 100,
  };

  const horizontalAnimation = {
    opacity: 1,
    x: 0,
    transition: { duration: 0.5 },
  };

  const verticalAnimation = { opacity: 1, y: 0, transition: { duration: 0.5 } };

  const initial =
    direction === transitionDirections.RIGHT_TO_LEFT ||
    direction === transitionDirections.LEFT_TO_RIGHT
      ? horizontalTransition
      : verticalTransition;

  const animate =
    direction === transitionDirections.RIGHT_TO_LEFT ||
    direction === transitionDirections.LEFT_TO_RIGHT
      ? horizontalAnimation
      : verticalAnimation;

  return direction ? (
    <motion.div initial={initial} animate={animate}>
      {children}
    </motion.div>
  ) : (
    <div>{children}</div>
  );
};

export default PageTransition;
