import { styled } from "@mui/system";
import { Box, Stack, Typography } from "@mui/material";
import PersonAddAltRounded from "@mui/icons-material/PersonAddAltRounded";
import HorizontalRule from "@mui/icons-material/HorizontalRule";
import LocalLibrary from "@mui/icons-material/LocalLibrary";
import { ItemName } from "../../assets/ListInlineView.styles";

export const IndicatorsContainer = styled(Stack)({
  paddingBottom: "8px",
});

export const Indicator = styled(Box)({
  display: "flex",
  alignItems: "center",
});

export const UserCreatedTypeIcon = styled(PersonAddAltRounded)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const SystemCreatedTypeIcon = styled(LocalLibrary)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const HorizontalLineIcon = styled(HorizontalRule)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const IndicatorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const PagingText = styled(ItemName)(({ theme }) => ({
  marginBottom: 1,
  color: theme.palette.secondary.main,
}));
