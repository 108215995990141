import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const BenefitCardContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  marginTop: 15,
  justifyContent: "center",
});

export const BenefitName = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
}));

export const BenefitDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 300,
  maxWidth: "400px",
}));
