import { Box, IconButton, InputAdornment, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StickySearchTextField } from "../../../components/styles/assets/StickySearch.styles";
import {
  BackInfoContainer,
  BackPageTitle,
  HeaderGridContainer,
  HeaderWrapper,
} from "../../../components/styles/header/Header.styles";
import {
  selectGlobalFontSize,
  selectIsSearchOpen,
  selectSearchTerm,
  setIsSearchOpen,
  setSearchTerm,
  setShouldSearch,
} from "../../../store/slices/appSlice";
import { getTranslation, transitionDirections } from "../../../util/utils";
import OptionsMenu from "../../options-menu/OptionsMenu";
import { useLocation, useNavigate } from "react-router-dom";
import { messageError } from "../../../util/notification";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import {
  OptionsMenuContainer,
  SearchContainer,
  SecondaryContrastColorSearchIcon,
} from "../../../components/styles/header/assets/AssetListHeader.styles";
import { NavigationActionIcon } from "../../../components/styles/menu/Menu.styles";

const RecentsHeader = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const searchTerm = useSelector(selectSearchTerm);
  const isSearchOpen = useSelector(selectIsSearchOpen);

  // Handlers
  const handleSearchClick = () => {
    if (isSearchOpen) {
      if (searchTerm?.length < 3) {
        messageError(getTranslation("SEARCH_TERM_TOO_SHORT", t, i18n));
      } else {
        dispatch(setShouldSearch(true));

        navigate(
          `/search?from=recents&direction=${transitionDirections.LEFT_TO_RIGHT}`
        );

        dispatch(setIsSearchOpen(false));
      }
    } else {
      dispatch(setIsSearchOpen(true));
    }
  };

  const handleClearSearch = () => {
    dispatch(setIsSearchOpen(false));
    dispatch(setSearchTerm(""));
    dispatch(setShouldSearch(false));

    if (pathname.includes("search")) {
      navigate(`/recents?direction=${transitionDirections.LEFT_TO_RIGHT}`);
    }
  };

  const handleSearch = (e) => {
    dispatch(setSearchTerm(e.target.value));
    dispatch(setShouldSearch(false));
  };

  return (
    <HeaderWrapper id="sticky-header">
      <HeaderGridContainer>
        <BackInfoContainer>
          <BackPageTitle variant="h4" id="title">
            {getTranslation("LAST_USED", t, i18n)}
          </BackPageTitle>
        </BackInfoContainer>
        <Box>
          {tabletMatches && (
            <OptionsMenuContainer>
              <OptionsMenu />
              {isSearchOpen ? (
                <SearchContainer>
                  <StickySearchTextField
                    inputProps={{ "data-testid": "search-field" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton id="search" onClick={handleSearchClick}>
                            <NavigationActionIcon
                              active={true}
                              customfontsize={globalFontSize}
                            >
                              search
                            </NavigationActionIcon>
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton id="clear" onClick={handleClearSearch}>
                            <NavigationActionIcon
                              active={true}
                              customfontsize={globalFontSize}
                            >
                              clear
                            </NavigationActionIcon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    id="search-field"
                    variant="outlined"
                    placeholder={getTranslation("SEARCH_TERM", t, i18n)}
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </SearchContainer>
              ) : (
                <IconButton id="search" onClick={handleSearchClick}>
                  <SecondaryContrastColorSearchIcon
                    customfontsize={globalFontSize * 2}
                  />
                </IconButton>
              )}
            </OptionsMenuContainer>
          )}
        </Box>
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default RecentsHeader;
