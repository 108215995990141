import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import { ListItemContainer } from "../../styles/assets/asset-detail/AssetDetail.styles";
import {
  PrimaryText,
  SecondaryText,
  SectionTitle,
} from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { ListContainer } from "../../styles/assets/asset-detail/AssetDetailCharacteristicGroup.styles";

const TypeInfo = ({ typeData }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Other variables
  const { name, displayId, description, version } = typeData;

  return (
    <div>
      <ListContainer>
        <SectionTitle id="type-details-title">
          {getTranslation("TYPE", t, i18n)}
        </SectionTitle>
      </ListContainer>

      <ListItemContainer>
        <SecondaryText id="type-name-label">
          {getTranslation("NAME", t, i18n)}
        </SecondaryText>
        <PrimaryText id="type-name">{name}</PrimaryText>
      </ListItemContainer>

      <ListItemContainer>
        <SecondaryText id="type-display-id-label">
          {getTranslation("DISPLAY_ID", t, i18n)}
        </SecondaryText>
        <PrimaryText id="type-display-id">{displayId}</PrimaryText>
      </ListItemContainer>

      {description && (
        <ListItemContainer>
          <SecondaryText id="type-description-label">
            {getTranslation("NOTES", t, i18n)}
          </SecondaryText>
          <PrimaryText id="type-description">{description}</PrimaryText>
        </ListItemContainer>
      )}
      {version && (
        <ListItemContainer>
          <SecondaryText id="description-label">
            {getTranslation("VERSION", t, i18n)}
          </SecondaryText>
          <PrimaryText id="description">{version}</PrimaryText>
        </ListItemContainer>
      )}
    </div>
  );
};

export default TypeInfo;
