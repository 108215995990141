import React, { useState } from "react";
import {
  Divider,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ViewButton } from "../../../../components/styles/assets/DesktopHeader.styles";
import {
  selectGlobalFontSize,
  selectIsSearchOpen,
  selectPageView,
  selectSearchTerm,
  selectSortingOption,
  setIsSearchOpen,
  setPageView,
  setSearchTerm,
  setShouldSearch,
  setSortingOption,
  SORTING_OPTIONS,
} from "../../../../store/slices/appSlice";
import { NavigationActionIcon } from "../../../../components/styles/menu/Menu.styles";
import { getTranslation, transitionDirections } from "../../../../util/utils";
import { useTranslation } from "react-i18next";
import { messageError } from "../../../../util/notification";
import { useLocation, useNavigate } from "react-router-dom";
import {
  selectParentId,
  setParentId,
  setTrail,
} from "../../../../store/slices/columnViewSlice";
import {
  HeaderContainer,
  NavigationOptionsContainer,
  SearchTextField,
  SortingMenuOptions,
} from "../../../../components/styles/header/assets/desktop/AssetListDesktopHeader.styles";
import DesktopAssetHeading from "../../../../components/assets/asset-list/DesktopAssetHeading";
import { DynamicVisibilityItemIcon } from "../../../../components/styles/types/type-list/DesktopTypeStickySearch.styles";
import { useGetFavoritesQuery } from "../../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../../store/slices/authSlice";
import { setTabletTabValue } from "../../../../store/slices/assetListSlice";
import ErrorHandling from "../../../../components/common/ErrorHandling";

const TagsFilterDesktopHeader = ({ resourceId }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const sortingOption = useSelector(selectSortingOption);
  const pageView = useSelector(selectPageView);
  const isSearchOpen = useSelector(selectIsSearchOpen);
  const searchTerm = useSelector(selectSearchTerm);
  const parentId = useSelector(selectParentId);

  //  Queries
  const {
    data: favoritesData,
    isLoading,
    isError,
  } = useGetFavoritesQuery({
    organizationId: user?.organizations?.find((o) => o.default).id,
    index: 1,
    size: 5,
    sortBy: "favoured",
    order: "desc",
  });

  // States
  const [anchorEl, setAnchorEl] = useState(null);

  // Other variables
  const open = Boolean(anchorEl);

  // Handlers
  const handleOpenSortingOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSortingOptions = () => {
    setAnchorEl(null);
  };

  const handleSearchClick = () => {
    if (isSearchOpen) {
      if (searchTerm.length < 3) {
        messageError(getTranslation("SEARCH_TERM_TOO_SHORT", t, i18n));
      } else {
        dispatch(setShouldSearch(true));
      }

      dispatch(setIsSearchOpen(false));
    } else {
      dispatch(setIsSearchOpen(true));
    }
  };

  const handleSearch = (e) => {
    dispatch(setTrail([]));

    if (Boolean(e.target.value)) {
      dispatch(setParentId(null));
    }

    dispatch(setSearchTerm(e.target.value));
    dispatch(setShouldSearch(false));
  };

  const handleClearSearch = () => {
    dispatch(setIsSearchOpen(false));
    dispatch(setSearchTerm(""));
    dispatch(setShouldSearch(false));
    dispatch(setParentId(null));

    if (pathname.includes("search")) {
      navigate(`/resources?direction=${transitionDirections.LEFT_TO_RIGHT}`);
    }
  };

  const handleFormatClick = (view) => {
    dispatch(setPageView(view));

    if (pageView === "column") {
      navigate(
        `/resources${parentId ? `/${parentId}` : ""}?direction=${
          transitionDirections.LEFT_TO_RIGHT
        }`
      );
    }
  };

  const handleSortingOptionClick = (option) => {
    dispatch(setSortingOption(option));
  };

  const handleOrderClick = (e) => {
    e.stopPropagation();

    dispatch(
      setSortingOption({
        ...sortingOption,
        order: sortingOption.order === "asc" ? "desc" : "asc",
      })
    );
  };

  const handleGoBack = () => {
    dispatch(setTabletTabValue(1));
    navigate(
      `/resources/${resourceId}?direction=${transitionDirections.LEFT_TO_RIGHT}`
    );
  };

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <HeaderContainer>
        <NavigationOptionsContainer>
          <IconButton onClick={handleGoBack}>
            <NavigationActionIcon
              id="back-column-view"
              active={true}
              customfontsize={globalFontSize}
            >
              navigate_before
            </NavigationActionIcon>
          </IconButton>

          <IconButton disabled={true}>
            <NavigationActionIcon
              id="forward-column-view"
              active={false}
              customfontsize={globalFontSize}
            >
              navigate_next
            </NavigationActionIcon>
          </IconButton>

          <DesktopAssetHeading
            resourceId={resourceId}
            favorites={favoritesData?.data}
          />
        </NavigationOptionsContainer>
        <Stack direction="row" spacing={2}>
          <Stack direction="row">
            <ViewButton
              active={pageView === "app"}
              onClick={() => handleFormatClick("app")}
            >
              <NavigationActionIcon
                id="grid-view"
                active={true}
                customfontsize={globalFontSize}
              >
                apps
              </NavigationActionIcon>
            </ViewButton>
            <Divider orientation="vertical" />
            <ViewButton
              active={pageView === "inline"}
              onClick={() => handleFormatClick("inline")}
            >
              <NavigationActionIcon
                active={true}
                id="list-view"
                customfontsize={globalFontSize}
              >
                format_align_left
              </NavigationActionIcon>
            </ViewButton>
          </Stack>
          <Stack direction="row" alignItems="center">
            <NavigationActionIcon active={true} customfontsize={globalFontSize}>
              dialpad
            </NavigationActionIcon>
            <IconButton onClick={handleOpenSortingOptions}>
              <NavigationActionIcon
                active={true}
                customfontsize={globalFontSize}
              >
                expand_more
              </NavigationActionIcon>
            </IconButton>
            <SortingMenuOptions
              id="options-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseSortingOptions}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {SORTING_OPTIONS.filter((option) => option.value !== "Z-A").map(
                (option) => {
                  const { value } = option;
                  return (
                    <MenuItem
                      onClick={() => handleSortingOptionClick(option)}
                      key={value}
                    >
                      <DynamicVisibilityItemIcon
                        isvisible={sortingOption.value === value}
                      >
                        <NavigationActionIcon customfontsize={globalFontSize}>
                          check
                        </NavigationActionIcon>
                      </DynamicVisibilityItemIcon>
                      <ListItemText>
                        {value === "A-Z"
                          ? getTranslation("NAME", t, i18n)
                          : getTranslation(value, t, i18n)}
                      </ListItemText>
                      <DynamicVisibilityItemIcon
                        isvisible={sortingOption.value === value}
                        onClick={handleOrderClick}
                      >
                        <NavigationActionIcon customfontsize={globalFontSize}>
                          {`keyboard_arrow_${
                            sortingOption.order === "asc" ? "up" : "down"
                          }`}
                        </NavigationActionIcon>
                      </DynamicVisibilityItemIcon>
                    </MenuItem>
                  );
                }
              )}
            </SortingMenuOptions>
          </Stack>
          <Stack direction="row">
            {isSearchOpen && (
              <SearchTextField
                inputProps={{
                  "data-testid": "search-field",
                  style: {
                    padding: 0,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={handleSearchClick}>
                        <NavigationActionIcon
                          id="search"
                          active={true}
                          customfontsize={globalFontSize}
                        >
                          searchTerm
                        </NavigationActionIcon>
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearSearch}>
                        <NavigationActionIcon
                          id="clear"
                          active={true}
                          customfontsize={globalFontSize}
                        >
                          clear
                        </NavigationActionIcon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id="search-field"
                variant="outlined"
                placeholder={getTranslation("SEARCH_TERM", t, i18n)}
                value={searchTerm}
                onChange={handleSearch}
              />
            )}
            {!isSearchOpen && (
              <IconButton onClick={handleSearchClick}>
                <NavigationActionIcon
                  id="search"
                  active={true}
                  customfontsize={globalFontSize}
                >
                  search
                </NavigationActionIcon>
              </IconButton>
            )}
          </Stack>
        </Stack>
      </HeaderContainer>
    </ErrorHandling>
  );
};

export default TagsFilterDesktopHeader;
