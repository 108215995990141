import { IconButton } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddFavoriteMutation,
  useDeleteFavoriteMutation,
  useGetAllFunctionsQuery,
  useGetResourceDetailsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import {
  DEFAULT_INDEX,
  selectGlobalFontSize,
  selectPageView,
  setIndex,
} from "../../../store/slices/appSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { selectParentId } from "../../../store/slices/columnViewSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  RESOURCE_CATEGORIES,
} from "../../../util/utils";
import ErrorHandling from "../../common/ErrorHandling";
import {
  AssetName,
  HeadingContainer,
  DesktopAssetHeadingActionsContainer,
} from "../../styles/assets/asset-list/DesktopAssetHeading.styles";
import { NavigationActionIcon } from "../../styles/menu/Menu.styles";
import GridViewCardMedia from "./GridViewCardMedia";
import CategoryMenu from "../asset-form/CategoryMenu";
import CreateAsset from "../asset-form/CreateAsset";
import { useUserRolePermissionsQuery } from "../../../store/slices/api/userManagementSlice";
import { setFitScreen } from "../../../store/slices/floorplanSlice";
import { PageTitle } from "../../styles/header/assets/desktop/AssetListDesktopHeader.styles";
import EditAsset from "../asset-form/EditAsset";

const DesktopAssetHeading = ({ resourceId, favorites }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const globalFontSize = useSelector(selectGlobalFontSize);
  const currentpageview = useSelector(selectPageView);
  const parentId = useSelector(selectParentId);
  // const resourceImages = useSelector(selectResourceImages);

  // States
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [initializedCategory, setInitializedCategory] = useState(null);

  // Mutations
  const [addFavorite] = useAddFavoriteMutation();
  const [deleteFavorite] = useDeleteFavoriteMutation();

  // Queries
  const {
    data: currentResourceData,
    isLoading,
    isError,
  } = useGetResourceDetailsQuery(
    {
      resourceid: currentpageview === "column" ? parentId : resourceId,
      organizationId,
    },
    {
      skip:
        currentpageview === "column"
          ? !Boolean(parentId)
          : !Boolean(resourceId),
    }
  );

  const { data: userRoles } = useUserRolePermissionsQuery({
    userId: user.id,
    organizationId,
  });

  const { data: allFunctionsData } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Other variables
  const {
    id,
    displayId,
    name,
    functionId,
    images: resourceImages,
  } = currentResourceData ?? {};

  const combinationDisplayIdAndName = displayId || name;
  const isFavorite = favorites?.some((f) => f.resource.id === id);
  const open = Boolean(anchorEl);
  const currentResourceFunction = allFunctionsData?.find(
    (f) => f.id === functionId
  );

  // Handlers
  const handleAddFavorite = async (e) => {
    e.stopPropagation();

    try {
      await addFavorite({
        resourceid: id,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      }).unwrap();

      dispatch(setIndex(DEFAULT_INDEX));
    } catch (error) {
      console.error("Failed to add favorite", error);
    }
  };

  const handleDeleteFavorite = async (e) => {
    e.stopPropagation();

    try {
      await deleteFavorite({
        resourceid: id,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      }).unwrap();

      dispatch(setIndex(DEFAULT_INDEX));
    } catch (error) {
      console.error("Failed to delete favorite", error);
    }
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleOpenCategoryMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseCategoryMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenCreate = (category) => {
    dispatch(setFitScreen(false));
    setInitializedCategory(category);
    setOpenCreate(true);
  };

  const handleClose = () => {
    dispatch(setFitScreen(false));
    setOpenCreate(false);
  };

  if (!currentResourceData) {
    return (
      <PageTitle variant="h5">{getTranslation("ASSETS", t, i18n)}</PageTitle>
    );
  }

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <HeadingContainer>
        <GridViewCardMedia
          isIcon={
            !resourceImages || !resourceImages.length
              ? true
              : resourceImages[0]?.name?.endsWith(".svg")
              ? true
              : false
          }
          category={currentResourceFunction?.category}
          resourceImages={resourceImages}
        />

        <AssetName variant="h5">{combinationDisplayIdAndName}</AssetName>

        <DesktopAssetHeadingActionsContainer>
          {isFavorite ? (
            <IconButton onClick={(e) => handleDeleteFavorite(e)}>
              <NavigationActionIcon
                active={true}
                customfontsize={globalFontSize}
              >
                star
              </NavigationActionIcon>
            </IconButton>
          ) : (
            <IconButton onClick={(e) => handleAddFavorite(e)}>
              <NavigationActionIcon
                active={true}
                customfontsize={globalFontSize}
              >
                star_outline
              </NavigationActionIcon>
            </IconButton>
          )}

          {hasAccess(
            "all",
            [
              permissions[
                `ASSET_MANAGEMENT_${
                  currentResourceFunction?.category ===
                  RESOURCE_CATEGORIES.HARDWARE_ASSET
                    ? "HARDWARE"
                    : currentResourceFunction?.category
                }_EDIT`
              ],
            ],
            getPermissionsFromUserRoles(userRoles)
          ) && (
            <>
              <IconButton onClick={handleOpenEdit}>
                <NavigationActionIcon
                  active={true}
                  customfontsize={globalFontSize}
                >
                  edit
                </NavigationActionIcon>
              </IconButton>

              {openEdit && (
                <EditAsset
                  resourceId={id}
                  open={openEdit}
                  setOpen={setOpenEdit}
                />
              )}
            </>
          )}

          {hasAccess(
            "all",
            [
              permissions.ASSET_MANAGEMENT_HARDWARE_ADD,
              permissions.ASSET_MANAGEMENT_LOCATION_ADD,
              permissions.ASSET_MANAGEMENT_RACK_ADD,
            ],
            getPermissionsFromUserRoles(userRoles)
          ) && (
            <>
              <IconButton onClick={handleOpenCategoryMenu}>
                <NavigationActionIcon
                  active={true}
                  customfontsize={globalFontSize}
                >
                  add
                </NavigationActionIcon>
              </IconButton>

              {openCreate && (
                <CreateAsset
                  initializedParentId={currentResourceData?.id}
                  initializedCategory={initializedCategory}
                  open={openCreate}
                  handleClose={handleClose}
                />
              )}
            </>
          )}

          <CategoryMenu
            open={open}
            handleClose={handleCloseCategoryMenu}
            anchorEl={anchorEl}
            parentCategory={currentResourceFunction?.category}
            categoryActionHandler={handleOpenCreate}
          />
        </DesktopAssetHeadingActionsContainer>
      </HeadingContainer>
    </ErrorHandling>
  );
};

export default DesktopAssetHeading;
