import React from "react";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import { Grid, Stack, Box } from "@mui/material";
import {
  DarkGreyText,
  FirstBox,
  FlexSpacedContainer,
  FlexUnShrinkableText,
  NotesText,
  QuarterWidthNotesText,
  SecondBox,
  TopFlexSpacedContainer,
} from "../../styles/types/type-details/DesktopTypeDetails.styles";

const DesktopTypeDetailsList = ({ type }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Other variables
  const {
    name,
    displayId,
    description: notes,
    lifecycleStatus,
    organizationId,
  } = type ?? {};

  return (
    <Grid container spacing={1}>
      <Grid item md={6}>
        <Stack direction="row" columnGap={2} marginBottom={1}>
          <FirstBox>
            <DarkGreyText>{getTranslation("NAME", t, i18n)}</DarkGreyText>
          </FirstBox>
          <SecondBox>
            <SecondaryText sx={{ wordBreak: "break-all" }}>
              {name}
            </SecondaryText>
          </SecondBox>
        </Stack>
        <Stack direction="row" columnGap={2}>
          <FirstBox>
            <DarkGreyText>{getTranslation("DISPLAY_ID", t, i18n)}</DarkGreyText>
          </FirstBox>
          <SecondBox>
            <SecondaryText sx={{ wordBreak: "break-all" }}>
              {displayId}
            </SecondaryText>
          </SecondBox>
        </Stack>
      </Grid>
      <Grid item md={6}>
        <Stack direction="row" columnGap={2} marginBottom={1}>
          <FirstBox>
            <DarkGreyText>
              {getTranslation("LIFECYCLE_STATUS", t, i18n)}
            </DarkGreyText>
          </FirstBox>
          <SecondBox>
            <SecondaryText>
              {getTranslation(lifecycleStatus, t, i18n)}
            </SecondaryText>
          </SecondBox>
        </Stack>
        <Stack direction="row" columnGap={2}>
          <FirstBox>
            <DarkGreyText>
              {getTranslation("CREATION_TYPE", t, i18n)}
            </DarkGreyText>
          </FirstBox>
          <SecondBox>
            <SecondaryText>
              {organizationId
                ? getTranslation("USER_CREATED_TYPE", t, i18n)
                : getTranslation("SYSTEM_CREATED_TYPE", t, i18n)}
            </SecondaryText>
          </SecondBox>
        </Stack>
      </Grid>
      <Grid item md={12}>
        <Stack direction="row" columnGap={2}>
          <Box>
            <DarkGreyText>{getTranslation("NOTES", t, i18n)}</DarkGreyText>
          </Box>
          <Box>
            <NotesText>{notes}</NotesText>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DesktopTypeDetailsList;
