import { styled } from "@mui/system";
import { Box, Grid, Menu, Paper, Tabs, Typography } from "@mui/material";
import { HighlightedText } from "../assets/tags/Tags.styles";

export const TabsContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

export const ColorOutputPalette = styled(Paper)(
  ({ background, foreground }) => ({
    backgroundColor: background,
    color: foreground,
    height: "50px",
    borderRadius: 0,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  })
);

export const ColorPalette = styled(Paper)(({ palette }) => ({
  backgroundColor: palette.backgroundColor,
  color: palette.foregroundColor,
  aspectRatio: "1 / 1",
  borderRadius: 0,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const ColorPaletteContainer = styled(Grid)({
  maxHeight: "75px",
  maxWidth: "75px",
});

export const ColorPickerWrapper = styled(Box)({
  position: "relative",
});

export const ColorPickerButtonContainer = styled(Box)(({ color }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: color,
  height: "20px",
  width: "20px",
  padding: "10px",
}));

export const ColorPickerButtonText = styled(Typography)(({ color }) => ({
  color,
  cursor: "pointer",
}));

export const ColorPickerMenu = styled(Menu)({
  ".MuiMenu-paper": { maxWidth: "250px" },
});

export const ColorPaletteTabs = styled(Tabs)({
  width: "100%",
});

export const RecentColorsText = styled(HighlightedText)({
  color: "#CECECE",
  margin: "10px 0",
});

export const ColorPickerTabBoxContainer = styled(Box)({
  padding: "24px",
});
