import { Card, CardMedia, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const CardContainer = styled(Card)({
  height: "250px",
  width: "150px",
});

export const FixedHeightCardMedia = styled(CardMedia)({
  height: "175px",
});

export const TextBrokenWord = styled(Typography)({
  wordBreak: "normal",
  textAlign: "center",
});
