import { useTheme } from "@mui/material";
import { BounceLetterLoader } from "react-spinner-overlay";

const BounceLetterSpinner = () => {
  // General hooks
  const theme = useTheme();

  return (
    <div id="loading-spinner">
      <BounceLetterLoader
        loading={true}
        color={theme.palette.secondary.contrastText}
      />
    </div>
  );
};

export default BounceLetterSpinner;
