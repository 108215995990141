import { Grid } from "@mui/material";
import { ActionTitle, PageTitle } from "../../styles/header/Header.styles";
import { getTranslation } from "../../../util/utils";
import { useDispatch } from "react-redux";
import {
  resetWizard,
  setMobileDialogOpen,
} from "../../../store/slices/reportsSlice";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";

const MobileCreateReportDialogHeader = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // State
  const [openConfirm, setOpenConfirm] = useState(false);

  // Other variables
  const alert = {
    content: "After this action, report wizard will be reset",
    confirmTitle: "Close",
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  // Handlers
  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleConfirm = () => {
    handleClose();
    setOpenConfirm(false);
  };

  // Handlers
  const handleClose = () => {
    dispatch(setMobileDialogOpen(false));
    dispatch(resetWizard());
  };

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          <ActionTitle
            tabIndex="0"
            data-testid="action-title-cancel"
            id="action-title-cancel"
            onClick={handleOpenConfirm}
          >
            {getTranslation("CANCEL", t, i18n)}
          </ActionTitle>
        </Grid>

        <Grid item xs={6}>
          <PageTitle variant="h6" id="title">
            New report
          </PageTitle>
        </Grid>

        <Grid item xs={3}></Grid>
      </Grid>

      <ConfirmAlert
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        alert={alert}
        label="close-mobile-reports-wizard"
        handleConfirm={handleConfirm}
      />
    </>
  );
};

export default MobileCreateReportDialogHeader;
