import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectPageView,
} from "../../store/slices/appSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
} from "../../util/utils";
import { SecondaryContrastButton } from "../styles/general/General.styles";
import {
  ButtonContainer,
  StyledBox,
  StyledNavigationIcon,
} from "../styles/buttons/CreateNewButton.styles";

const CreateNewButton = ({ handler, userRoles, name }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const view = useSelector(selectPageView);

  return (
    <StyledBox>
      <ButtonContainer view={view}>
        <SecondaryContrastButton
          disabled={
            !hasAccess(
              "all",
              [
                permissions.ASSET_MANAGEMENT_HARDWARE_ADD,
                permissions.ASSET_MANAGEMENT_LOCATION_ADD,
                permissions.ASSET_MANAGEMENT_RACK_ADD,
              ],
              getPermissionsFromUserRoles(userRoles)
            )
          }
          onClick={handler}
        >
          <StyledNavigationIcon customfontsize={globalFontSize}>
            add
          </StyledNavigationIcon>
          {getTranslation(name, t, i18n)}
        </SecondaryContrastButton>
      </ButtonContainer>
    </StyledBox>
  );
};

export default CreateNewButton;
