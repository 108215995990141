import { TagsListContainer } from "../../styles/assets/tags/Tags.styles";
import TagsItem from "./TagsItem";

const TagsList = ({ tags }) => {
  return (
    <TagsListContainer id="tags-list">
      {tags.map((tag) => (
        <TagsItem key={tag.id} tag={tag} />
      ))}
    </TagsListContainer>
  );
};

export default TagsList;
