import { Box, Divider } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { styled } from "@mui/system";

export const InvitationFormContainer = styled(Box)({
  padding: "0 10px",
  marginTop: "40px",
});

export const InvitationsContainer = styled(Box)({
  marginTop: "30px",
  padding: "0 10px",
});

export const InvitationListContainer = styled(Box)({
  marginTop: "10px",
});

export const StyledDivider = styled(Divider)({
  borderBottomWidth: "3px",
  marginBottom: "10px",
});

export const ArrowDropDownIconSized = styled(ArrowDropDownIcon)(
  ({ globalFontSize }) => ({
    fontSize: `${globalFontSize * 1.5}px`,
  })
);
