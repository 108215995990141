import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { setShouldExecute } from "../../../store/slices/reportsSlice";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import { useState } from "react";
import { getTranslation } from "../../../util/utils";
import { useTranslation } from "react-i18next";
import { SecondaryContrastDeleteIcon } from "../../styles/reports/Reports.styles";

const CustomRemoveRuleButton = ({ handleOnClick }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // State
  const [openConfirm, setOpenConfirm] = useState(false);

  // Handlers
  const handleClick = () => {
    handleOnClick();
    dispatch(setShouldExecute(false));
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleConfirm = () => {
    handleClick();
    setOpenConfirm(false);
  };

  // Other variables
  const alert = {
    content: getTranslation("REMOVE_CONDITION_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("REMOVE_CONDITION_ALERT_TITLE", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  return (
    <>
      <ConfirmAlert
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        alert={alert}
        label="delete-rule"
        handleConfirm={handleConfirm}
      />

      <IconButton id="remove-rule-button" onClick={handleOpenConfirm}>
        <SecondaryContrastDeleteIcon />
      </IconButton>
    </>
  );
};

export default CustomRemoveRuleButton;
