import {
  BackInfoContainer,
  BackPageTitle,
  HeaderGridContainer,
  HeaderWrapper,
} from "../../../components/styles/header/Header.styles";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";

const FavoritesHeader = ({ goBackHandler, userRoles }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  return (
    <HeaderWrapper id="sticky-header">
      <HeaderGridContainer container>
        <BackInfoContainer>
          <BackPageTitle variant="h4" id="title">
            {getTranslation("FAVORITE_LIBRARY", t, i18n)}
          </BackPageTitle>
        </BackInfoContainer>
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default FavoritesHeader;
