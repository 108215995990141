import { useSelector } from "react-redux";
import { NavigationActionIcon } from "../../components/styles/menu/Menu.styles";
import {
  StyledListItem,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
} from "../../components/styles/navigation/drawer/DrawerMenuItem.styles";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../util/utils";
import { Tooltip } from "@mui/material";

const DrawerMenuItem = ({ handleNavigate, isDrawerOpen, active, menu }) => {
  const { name, icon, variant } = menu;

  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Handlers
  const getClassVariant = () => {
    if (variant === "outlined") {
      return "material-icons-outlined";
    } else if (variant === "material-icons-two-tone") {
      return "material-icons-two-tone";
    } else if (variant === "round") {
      return "material-icons-round";
    } else if (variant === "sharp") {
      return "material-icons-sharp";
    } else {
      return "material-icons";
    }
  };

  const classVariant = getClassVariant();

  return (
    <Tooltip
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
      title={getTranslation(name, t, i18n)}
      placement="right"
    >
      <StyledListItem active={active} onClick={handleNavigate} disablePadding>
        <StyledListItemButton isdraweropen={isDrawerOpen}>
          <StyledListItemIcon isdraweropen={isDrawerOpen}>
            <NavigationActionIcon
              className={classVariant}
              active={active}
              customfontsize={globalFontSize}
            >
              {icon}
            </NavigationActionIcon>
          </StyledListItemIcon>
          <StyledListItemText
            active={active}
            isdraweropen={isDrawerOpen}
            primary={getTranslation(name, t, i18n)}
          />
        </StyledListItemButton>
      </StyledListItem>
    </Tooltip>
  );
};

export default DrawerMenuItem;
