import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export const HeaderWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.primary.contrastText,
  position: "sticky !important",
  zIndex: "100 !important",
  paddingTop: "8px",
  "@supports (padding-top: max(0px))": {
    paddingTop: "max(8px, env(safe-area-inset-top))",
  },
  top: 0,
  left: 0,
  right: 0,
}));

export const HeaderGridContainer = styled(Grid)({
  display: "flex",
  padding: "10px",
  justifyContent: "center",
  alignItems: "center",
});

export const StyledChevronLeftIcon = styled(ChevronLeftIcon)(
  ({ theme, customfontsize }) => ({
    color: theme.palette.secondary.contrastText,
    fontSize: `${customfontsize}px`,
  })
);

export const BackButtonGridItem = styled(Grid)({
  paddingLeft: "24px !important",
});

export const BackTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  flexGrow: 1,
}));

export const StyledAvatar = styled(Avatar)({
  cursor: "pointer",
});

export const PageTitle = styled(Typography)({
  whiteSpace: "initial",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  wordBreak: "break-word",
  paddingInline: 1,
  textAlign: "center",
});

export const ActionTitle = styled(Typography)(({ theme, disabled }) => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  color: disabled === 1 ? "grey" : theme.palette.secondary.contrastText,
}));

export const LeftActionButton = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  padding: 0,
}));

export const StyledAddIcon = styled(AddIcon)(({ theme, customfontsize }) => ({
  cursor: "pointer",
  color: theme.palette.secondary.contrastText,
  fontSize: `${customfontsize}px`,
}));

export const BackPageTitle = styled(PageTitle)({
  textAlign: "left",
});

export const BackInfoContainer = styled(Box)({
  flexGrow: 1,
});

export const RightAlignedActionTitle = styled(ActionTitle)({
  textAlign: "right",
});
