import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { useDispatch, useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import {
  addRecentColor,
  setCurrentColor,
  selectRecentColors,
  selectMode,
} from "../../store/slices/tagsSlice";
import { extractTextColor, userLocalStorageKey } from "../../util/utils";
import { ColorOutputPalette } from "../styles/color-picker/ColorPicker.styles";

const MoreTab = ({ setColor, color }) => {
  // General hooks
  const dispatch = useDispatch();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const recentColors = useSelector(selectRecentColors);
  const mode = useSelector(selectMode);

  // Other variables
  const backgroundColor = color;
  const hexColorPickerStyle = { width: "100%" };

  const hexColorInputStyle = {
    width: "-webkit-fill-available",
    height: "100%",
    fontSize: `${globalFontSize * 1.5}px`,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    paddingLeft: "8px",
  };

  // Handlers
  const handleChangeColor = (color) => {
    dispatch(setCurrentColor(color));

    const newColor = {
      backgroundColor: color,
      foregroundColor: extractTextColor(color),
    };

    dispatch(addRecentColor(newColor));

    localStorage.setItem(
      userLocalStorageKey("recentColors"),
      JSON.stringify([
        ...recentColors.filter((rc) => rc.backgroundColor !== color),
        newColor,
      ])
    );

    if (mode === "edit") {
      setColor(color);
    }
  };

  return (
    <Stack gap={2}>
      <HexColorPicker
        style={hexColorPickerStyle}
        color={backgroundColor}
        onChange={handleChangeColor}
      />
      <Grid container gap={2}>
        <Grid item xs={7}>
          <HexColorInput
            style={hexColorInputStyle}
            color={backgroundColor}
            onChange={handleChangeColor}
          />
        </Grid>
        <Grid item xs={4}>
          <ColorOutputPalette
            background={backgroundColor}
            foreground={extractTextColor(backgroundColor)}
          >
            <Typography>Aa</Typography>
          </ColorOutputPalette>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default MoreTab;
