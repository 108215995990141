import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import PageTransition from "../../components/PageTransition";
import TableReport from "../../components/reports/TableReport";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import ReportHeader from "../../navigation/header/reports/ReportHeader";
import { useReportExecutionQuery } from "../../store/slices/api/reportsApiSlice";
import { selectUser } from "../../store/slices/authSlice";
import { transitionDirections } from "../../util/utils";
import { handleMapDynamicColumns } from "../../util/reports-utils";
import { HARDWARE_ASSETS_BY_VENDOR_WIDGET_REPORT_DEFINITION_ID } from "../../Constants";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const ReportsByHardwareAssetsByVendor = () => {
  // General hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Selectors
  const user = useSelector(selectUser);

  // Queries
  const {
    data: hardwareAssetsByVendorData,
    isLoading: isLoadingHardwareAssetsByVendorData,
    isError: isErrorHardwareAssetsByVendorData,
  } = useReportExecutionQuery({
    organizationId: user?.organizations?.find((o) => o.default).id,
    reportDefinitionId: HARDWARE_ASSETS_BY_VENDOR_WIDGET_REPORT_DEFINITION_ID,
  });

  // Other variables
  const transitionDirection = searchParams.get("direction");

  const filteredData =
    hardwareAssetsByVendorData?.data?.filter((item) =>
      Boolean(item["resource.characteristic.manufacturer"])
    ) ?? [];

  // Handlers
  const handleGoBack = () => {
    navigate(
      `/reports?direction=${transitionDirections.LEFT_TO_RIGHT}&quick-access=true`
    );
  };

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling
          isLoading={isLoadingHardwareAssetsByVendorData}
          isError={isErrorHardwareAssetsByVendorData}
        >
          <PageTransition direction={transitionDirection}>
            <ReportHeader
              goBackHandler={handleGoBack}
              title="HARDWARE_ASSETS_BY_VENDOR"
            />
            <HomePagePadding>
              <TableReport
                rows={filteredData}
                columns={handleMapDynamicColumns(filteredData)}
              />
            </HomePagePadding>
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default ReportsByHardwareAssetsByVendor;
