import { useEffect, useState } from "react";

const useOrientation = () => {
  const [orientation, setOrientation] = useState(getInitialOrientation());

  function getInitialOrientation() {
    return window.innerWidth > window.innerHeight ? "landscape" : "portrait";
  }

  useEffect(() => {
    const handleOrientationChange = () => {
      const newOrientation = getInitialOrientation();
      setOrientation(newOrientation);
    };

    window.addEventListener("orientationchange", handleOrientationChange);
    window.addEventListener("resize", handleOrientationChange);

    // Clean up the event listeners on hook unmount
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  return orientation;
};

export default useOrientation;
