import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getTranslation,
  TAGS_LABEL_AUTOCOMPLETE_REGEX,
  TAGS_MAX_CHARACTERS,
  TAGS_MIN_CHARACTERS,
  transitionDirections,
} from "../../../util/utils";
import {
  selectInputValue,
  setInputValue,
  selectAvailableTags,
  selectError,
  setError,
  selectAutocompleteOpen,
  setAutocompleteOpen,
  addTag,
  setCurrentColor,
  DEFAULT_COLOR,
  selectCurrentColor,
  selectMarkedTag,
  deleteTag,
  setMarkedTag,
  selectMode,
  selectIsInDialog,
} from "../../../store/slices/tagsSlice";
import TagsList from "./TagsList";
import { useLocation, useNavigate } from "react-router-dom";
import TagsMenu from "./TagsMenu";
import { forwardRef, useEffect } from "react";
import { messageError } from "../../../util/notification";
import { TagsListWrapper } from "../../styles/assets/tags/Tags.styles";
import {
  selectOriginalInput,
  selectResourceInput,
} from "../../../store/slices/resourceInputSlice";
import { checkResourceChanged } from "../../../util/asset-utils";
import { selectUser } from "../../../store/slices/authSlice";

const ForwardTagsMenu = forwardRef((props, ref) => {
  return <TagsMenu {...props} innerRef={ref} />;
});

const CustomPaper = () => {
  return <ForwardTagsMenu />;
};

const TagsInput = ({
  id,
  shouldFocus,
  setOpenConfirm,
  setRedirectRoute,
  handleClick,
  autocomplete = true,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Selectors
  const user = useSelector(selectUser);
  const tags = useSelector(selectAvailableTags);
  const inputValue = useSelector(selectInputValue);
  const error = useSelector(selectError);
  const autocompleteOpen = useSelector(selectAutocompleteOpen);
  const currentColor = useSelector(selectCurrentColor);
  const markedTag = useSelector(selectMarkedTag);
  const mode = useSelector(selectMode);
  const originalInput = useSelector(selectOriginalInput);
  const resourceInput = useSelector(selectResourceInput);
  const isInDialog = useSelector(selectIsInDialog);

  // Other variables
  const routeCheck =
    pathname.includes("resources") && pathname.includes("edit");

  const tagsRouteCheck = pathname.includes("tags") && pathname.includes("edit");

  const formattedValue =
    tags?.map((option) => {
      return {
        ...option,
        label: option.name,
        value: option.id,
      };
    }) ?? [];

  const additionalOption = {
    value: tags.length + 1,
    label: `${inputValue} (${getTranslation("NEW_LABEL", t, i18n)})`,
  };

  const options = [...formattedValue, additionalOption];
  const redirectRoute = `/tags/${id}/edit?direction=${transitionDirections.BOTTOM_TO_TOP}`;
  const isAutocompleteOpen =
    (autocompleteOpen && autocomplete && isInDialog) ||
    (autocompleteOpen && autocomplete && tagsRouteCheck);
  const isAutocompleteDisabled = Boolean(markedTag) || !autocomplete;

  // Handlers
  const handleInputClick = () => {
    if (handleClick) {
      handleClick();
      return;
    }

    if (routeCheck) {
      if (checkResourceChanged(originalInput, resourceInput, user?.region)) {
        setRedirectRoute(redirectRoute);
        setOpenConfirm(true);
      } else {
        navigate(redirectRoute);
      }

      return;
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;

    dispatch(setCurrentColor(DEFAULT_COLOR));

    if (!newValue.match(TAGS_LABEL_AUTOCOMPLETE_REGEX)) {
      if (newValue.length > TAGS_MAX_CHARACTERS) {
        dispatch(setError(getTranslation("TAGS_MAX_CHARACTERS", t, i18n)));
        return;
      }

      dispatch(setError(""));
      dispatch(setInputValue(newValue)); // only set when successful
    } else {
      dispatch(setError(getTranslation("ALLOWED_CHARACTERS", t, i18n)));
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.code === "Space") {
      if (inputValue.length < TAGS_MIN_CHARACTERS) {
        messageError(getTranslation("TAGS_MIN_CHARACTERS", t, i18n));
        return;
      }

      dispatch(addTag({ name: inputValue, color: currentColor }));
      dispatch(setCurrentColor(DEFAULT_COLOR));
      dispatch(setInputValue(""));
      dispatch(setMarkedTag(null));
    }
  };

  const handleBackspaceKeyDown = (e) => {
    if (e.code === "Backspace" && inputValue.length === 0) {
      if (Boolean(markedTag)) {
        dispatch(deleteTag(markedTag));
        dispatch(setMarkedTag(null));
      } else {
        const lastTag = formattedValue[formattedValue?.length - 1] ?? null;
        dispatch(setMarkedTag(lastTag));
      }
    }
  };

  const handleIsOptionEqualToValue = (option, value) => {
    return option.value.toString() === value.value.toString();
  };

  const handleRenderTags = (value, props) => <TagsList tags={value} />;

  const handleRenderInput = (params) => (
    <TextField
      autoFocus={shouldFocus}
      onClick={handleInputClick}
      onChange={handleInputChange}
      onKeyDown={handleInputKeyDown}
      value={inputValue}
      label={getTranslation("TAGS", t, i18n)}
      helperText={error}
      error={!!error}
      placeholder={
        tagsRouteCheck ? getTranslation("TAGS_LABEL_PLACEHOLDER", t, i18n) : ""
      }
      {...params}
    />
  );

  const handleAutocompleteFocus = () => dispatch(setAutocompleteOpen(true));

  // Effects
  useEffect(() => {
    window.addEventListener("keydown", handleBackspaceKeyDown);

    return () => window.removeEventListener("keydown", handleBackspaceKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markedTag, inputValue, formattedValue]);

  return mode === "edit" ? (
    <TagsListWrapper>
      <TagsList tags={formattedValue} />
    </TagsListWrapper>
  ) : (
    <Autocomplete
      disabled={isAutocompleteDisabled}
      open={isAutocompleteOpen}
      openOnFocus={shouldFocus}
      onFocus={handleAutocompleteFocus}
      clearIcon={false}
      value={formattedValue}
      isOptionEqualToValue={handleIsOptionEqualToValue}
      options={options}
      freeSolo
      multiple
      renderTags={handleRenderTags}
      renderInput={handleRenderInput}
      PaperComponent={CustomPaper}
      inputValue={inputValue}
    />
  );
};

export default TagsInput;
