import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DEFAULT_INDEX,
  selectPageView,
  selectSortingOption,
  setIndex,
  setIsSearchOpen,
  setPageView,
} from "../../store/slices/appSlice";
import {
  HomePagePadding,
  NoDataTextContainer,
} from "../../components/styles/general/General.styles";
import StickySearch from "../../components/assets/asset-list/StickySearch";
import { SecondaryText } from "../../components/styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getTranslation } from "../../util/utils";
import { CreateAssetButton } from "../../components/styles/assets/AssetList.styles";
import { selectItems } from "../../store/slices/assetListSlice";
import { useGetResourceDetailsQuery } from "../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../store/slices/authSlice";
import PageTransition from "../../components/PageTransition";
import TagsFilterHeader from "../../navigation/header/assets/TagsFilterHeader";
import TagsFilterRenderer from "../../components/assets/asset-list/TagsFilterRenderer";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import TagsFilterDesktopHeader from "../../navigation/header/assets/desktop/TagsFilterDesktopHeader";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const TagsFilterPage = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  const { resourceid: resourceId, tagid: tagId } = useParams();
  const [searchParams] = useSearchParams();

  // Selectors
  const currentpageview = useSelector(selectPageView);
  const sortingOption = useSelector(selectSortingOption);
  const items = useSelector(selectItems);
  const user = useSelector(selectUser);

  // Other variables
  const direction = searchParams.get("direction");
  const tagName = searchParams.get("tagName");

  // Queries
  const {
    data: resourceData,
    isLoading: isLoadingResource,
    isError,
  } = useGetResourceDetailsQuery(
    {
      resourceid: resourceId,
      organizationId: user?.organizations?.find((o) => o.default).id,
    },
    {
      skip: !Boolean(resourceId) || resourceId === "undefined",
    }
  );

  // Handlers
  const formatClickHandler = (value) => {
    dispatch(setPageView(value));
  };

  const handleNavigateToAssets = () => navigate("/resources");

  // Effects
  useEffect(() => {
    dispatch(setIndex(DEFAULT_INDEX));
    dispatch(setPageView("inline"));
    dispatch(setIsSearchOpen(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setIndex(DEFAULT_INDEX));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingOption.value]);

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling isLoading={isLoadingResource} isError={isError}>
          <PageTransition direction={direction}>
            {mobileMatches ? (
              <TagsFilterHeader
                tagId={tagId}
                tagName={tagName}
                resourceData={resourceData}
              />
            ) : (
              <TagsFilterDesktopHeader resourceId={resourceId} />
            )}

            <HomePagePadding>
              {/* Search */}
              {mobileMatches && (
                <StickySearch
                  currentpageview={currentpageview}
                  formatClickHandler={formatClickHandler}
                  from="tags-filter"
                  tagId={tagId}
                  tagName={tagName}
                  resourceData={resourceData}
                />
              )}
              {items?.length <= 0 && (
                <NoDataTextContainer>
                  <SecondaryText align="center">
                    {getTranslation("NO_RESULTS_FOUND", t, i18n)}
                  </SecondaryText>
                  <CreateAssetButton onClick={handleNavigateToAssets}>
                    {getTranslation("OPEN_ASSETS", t, i18n)}
                  </CreateAssetButton>
                </NoDataTextContainer>
              )}

              <TagsFilterRenderer tagId={tagId} tagName={tagName} />
            </HomePagePadding>
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default TagsFilterPage;
