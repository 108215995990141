import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePWAInstall } from "react-use-pwa-install";
import { selectUser } from "../store/slices/authSlice";
import { useUserRolePermissionsQuery } from "../store/slices/api/userManagementSlice";
import {
  getPermissionsFromUserRoles,
  hasAccess,
  permissions,
  transitionDirections,
  getTranslation,
} from "../util/utils";
import { MenuList } from "@mui/material";
import MoreHeader from "../navigation/header/assets/MoreHeader";
import ProfilePreferenceItem from "../components/ProfilePreferenceItem";
import { StyledDivider } from "../components/styles/profile/Profile.styles";
import ProfilePreferenceMenuItem from "../components/ProfilePreferenceItem";
import OrganizationIcon from "@mui/icons-material/CorporateFare";
import PageTransition from "../components/PageTransition";
import GetAppIcon from "@mui/icons-material/GetApp";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import useCheckOrganizationRestricted from "../hooks/useCheckOrganizationRestricted";
import AppAccess from "../components/common/AppAccess";

const MorePage = () => {
  // General hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const install = usePWAInstall();

  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const activeOrganization = user?.organizations?.find((o) => o.default);
  const hasOrganizations =
    user?.organizations && user?.organizations?.length > 0;

  // Queries
  const {
    data: userRolePermissionsData,
    isLoading: isLoadingUserRolePermissions,
  } = useUserRolePermissionsQuery(
    {
      organizationId: user?.organizations?.find((o) => o.default).id,
      userId: user?.id,
    },
    { skip: !activeOrganization }
  );

  // Custom hooks
  const { isRestricted } = useCheckOrganizationRestricted(activeOrganization);

  // Other variables
  let transitionDirection = searchParams.get("direction");

  const showNavigation = hasOrganizations && !isRestricted;

  // Handlers
  const onProfileClickHandler = () =>
    navigate(`/profile?direction=${transitionDirections.RIGHT_TO_LEFT}`);

  const onCommunityPortal = async () => {
    navigate("/community");
  };

  const onLastUsedClickHandler = () => navigate("/recents");

  const onAboutClickHandler = () =>
    navigate(`/about?direction=${transitionDirections.RIGHT_TO_LEFT}`);

  const onLibraryClickHandler = () =>
    navigate(`/library?direction=${transitionDirections.RIGHT_TO_LEFT}`);

  const handleOrganizationClick = (organizationId) =>
    navigate(
      "/profile/organization/" +
        organizationId +
        `?direction=${transitionDirections.RIGHT_TO_LEFT}&quick_access=true`,
      {
        state: { from: location.pathname + location.search },
      }
    );

  // Other variables
  const requiredPermissions = [permissions.ORG_MANAGEMENT_EDIT];
  const defaultOrganization = user?.organizations?.find((o) => o.default);

  return (
    <AppAccess>
      <PageTransition direction={transitionDirection}>
        <MoreHeader />
        <MenuList dense>
          <ProfilePreferenceItem
            handleClick={onProfileClickHandler}
            icon={<PersonOutlineOutlinedIcon id="profile-icon" />}
            label="profile"
            labelId="btn-more-profile"
            menuId="btn-menu-item-more-profile"
          />

          {showNavigation && (
            <>
              {!isLoadingUserRolePermissions &&
                hasAccess(
                  "all",
                  requiredPermissions,
                  getPermissionsFromUserRoles(userRolePermissionsData)
                ) && (
                  <>
                    <StyledDivider />
                    <ProfilePreferenceMenuItem
                      handleClick={() =>
                        handleOrganizationClick(defaultOrganization?.id)
                      }
                      icon={<OrganizationIcon id="organization-icon" />}
                      label={getTranslation("organization", t, i18n)}
                      labelId="btn-organization-name"
                      menuId="btn-menu-item-organization-name"
                      value={defaultOrganization?.name}
                    />
                  </>
                )}
              <StyledDivider />
              <ProfilePreferenceItem
                handleClick={onLibraryClickHandler}
                icon={<LocalLibraryOutlinedIcon id="library-icon" />}
                label="MODEL_LIBRARY"
                labelId="btn-more-library"
                menuId="btn-menu-item-more-library"
              />
              <StyledDivider />
              <ProfilePreferenceItem
                handleClick={onLastUsedClickHandler}
                icon={<TimerOutlinedIcon id="last-used-icon" />}
                label="LAST_USED"
                labelId="btn-more-last-used"
                menuId="btn-menu-item-more-last-used"
              />
            </>
          )}
          <StyledDivider />
          <ProfilePreferenceItem
            handleClick={onCommunityPortal}
            icon={<PeopleOutlinedIcon id="people-icon" />}
            label="FORUM"
            labelId="btn-more-community-portal"
            menuId="btn-menu-item-community-portal"
          />
          <StyledDivider />
          <ProfilePreferenceItem
            handleClick={onAboutClickHandler}
            icon={<InfoOutlinedIcon id="about-icon" />}
            label="ABOUT"
            labelId="btn-more-about"
            menuId="btn-menu-item-more-about"
          />
          <StyledDivider />
          {install && (
            <ProfilePreferenceItem
              handleClick={install}
              icon={<GetAppIcon id="install-icon" />}
              label="INSTALL"
              labelId="btn-more-install"
              menuId="btn-menu-item-more-install"
              showrighticon={false}
            />
          )}

          {install && <StyledDivider />}
        </MenuList>
      </PageTransition>
    </AppAccess>
  );
};

export default MorePage;
