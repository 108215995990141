import AnimatedRoutes from "./AnimatedRoutes";

const Authenticated = () => {
  return (
    <div id="routes">
      <AnimatedRoutes />
    </div>
  );
};

export default Authenticated;
