import { Grid, Stack, Typography, styled } from "@mui/material";
import { SecondaryText } from "../ListInlineView.styles";

export const StackContainer = styled(Stack)(({ theme }) => ({
  position: "relative",
  rowGap: "24px",
  height: "calc(100vh - 144px)",
  overflow: "hidden",
  overflowY: "auto",
}));

export const FullScreenWrapper = styled(Stack)({
  justifyContent: "space-between",
  alignItems: "center",
});

export const TitleSection = styled(Typography)({
  color: "#8B8B8B",
});

export const StickyActionGridContainer = styled(Grid)(({ theme }) => ({
  position: "sticky",
  zIndex: 1,
  bottom: 0,
  justifyContent: "space-between",
  backgroundColor: theme.palette.primary.contrastText,
}));

export const SecondaryTextOverflowWrap = styled(SecondaryText)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});
