import { List, Stack } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import CreditsCard from "../components/about/CreditsCard";
import PageTransition from "../components/PageTransition";
import { SecondaryText } from "../components/styles/assets/ListInlineView.styles";
import { HomePagePadding } from "../components/styles/general/General.styles";
import CreditsHeader from "../navigation/header/about/CreditsHeader";
import { CREDITS, GROUP } from "../util/about-utils";
import { getTranslation, transitionDirections } from "../util/utils";
import AppAccess from "../components/common/AppAccess";

const CreditsPage = () => {
  // General hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();

  // Other variables
  const transitionDirection = searchParams.get("direction");
  const groupList = Object.keys(GROUP)
    .map((key) => {
      const credits = CREDITS.filter((c) => c.groupId === GROUP[key]).sort(
        (a, b) => {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          }

          return 0;
        }
      );

      return {
        id: GROUP[key],
        name: key,
        credits,
      };
    })
    .sort((a, b) => {
      const translatedNameA = getTranslation(a.name, t, i18n);
      const translatedNameB = getTranslation(b.name, t, i18n);

      if (translatedNameA > translatedNameB) {
        return 1;
      } else if (translatedNameA < translatedNameB) {
        return -1;
      }

      return 0;
    });

  // Handlers
  const goBackHandler = () =>
    navigate(`/about?direction=${transitionDirections.LEFT_TO_RIGHT}`);

  return (
    <AppAccess>
      <PageTransition direction={transitionDirection}>
        <CreditsHeader goBackHandler={goBackHandler} />
        <HomePagePadding>
          <Stack spacing={1}>
            {groupList.map((group) => {
              const { id, name, credits } = group;

              return (
                <Fragment key={id}>
                  <SecondaryText>{getTranslation(name, t, i18n)}</SecondaryText>
                  <List>
                    {credits.map((credit, index) => (
                      <CreditsCard key={`credit-${index}`} credit={credit} />
                    ))}
                  </List>
                </Fragment>
              );
            })}
          </Stack>
        </HomePagePadding>
      </PageTransition>
    </AppAccess>
  );
};

export default CreditsPage;
