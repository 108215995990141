import { Grid } from "@mui/material";
import { ActionTitle, PageTitle } from "../../../styles/header/Header.styles";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../util/utils";

const AddConditionDialogHeader = ({
  handleClose,
  handleDone,
  title,
  disabled,
  showDone = true,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={3}>
        <ActionTitle
          tabIndex="0"
          data-testid="action-title-cancel"
          id="action-title-cancel"
          onClick={handleClose}
        >
          {getTranslation("CANCEL", t, i18n)}
        </ActionTitle>
      </Grid>

      <Grid item xs={6}>
        <PageTitle variant="h6" id="title">
          {title}
        </PageTitle>
      </Grid>

      {showDone && (
        <Grid display="flex" justifyContent="end" item xs={3}>
          <ActionTitle
            disabled={disabled}
            tabIndex="0"
            data-testid="action-title-cancel"
            id="action-title-cancel"
            onClick={disabled === 1 ? null : handleDone}
          >
            {getTranslation("DONE", t, i18n)}
          </ActionTitle>
        </Grid>
      )}
    </Grid>
  );
};

export default AddConditionDialogHeader;
