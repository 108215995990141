import { FormControlLabel, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StackContainer = styled(Stack)({
  paddingInline: "16px",
});

export const SwitchControlLabel = styled(FormControlLabel)({
  maxWidth: "fit-content",
  marginRight: 0,
});
