import { useDispatch } from "react-redux";
import {
  REPORT_CONDITION_OPERATORS,
  REPORT_FIELDS,
} from "../../../../util/reports-utils";
import { useTranslation } from "react-i18next";
import { setShouldExecute } from "../../../../store/slices/reportsSlice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getTranslation } from "../../../../util/utils";

const RuleOperatorSelector = ({ field, value, options, handleOnChange }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Other varuables
  const IsDateField =
    field === REPORT_FIELDS["CREATED_AT"] ||
    field === REPORT_FIELDS["LIFECYCLE_DATE"] ||
    field === REPORT_FIELDS["RESOURCE_TAG_MATCH_DATE"] ||
    field === REPORT_FIELDS["RESOURCE_PARENT_LIFECYCLE_DATE"] ||
    field === REPORT_FIELDS["RESOURCE_PARENT_CREATED_AT"] ||
    field === REPORT_FIELDS["P_RESOURCE_TAG_MATCH_DATE"];

  const IsNumberField =
    field === REPORT_FIELDS["EXT_HIERARCHY_LEVEL"] ||
    field === REPORT_FIELDS["EXT_FIRST_LEVEL_CHILD_COUNT"] ||
    field === REPORT_FIELDS["EXT_TOTAL_CHILD_COUNT"] ||
    field === REPORT_FIELDS["CHARACTERISTIC_POWER_CONSUMPTION"] ||
    field === REPORT_FIELDS["CHARACTERISTIC_WIDTH"] ||
    field === REPORT_FIELDS["CHARACTERISTIC_HEIGHT"] ||
    field === REPORT_FIELDS["CHARACTERISTIC_LENGTH"] ||
    field === REPORT_FIELDS["CHARACTERISTIC_WEIGHT"] ||
    field === REPORT_FIELDS["CHARACTERISTIC_RACK_UNIT_CAPACITY"] ||
    field === REPORT_FIELDS["RESOURCE_LOCATION_HARDWARE_ASSET_COUNT"] ||
    field === REPORT_FIELDS["RESOURCE_LOCATION_RACK_COUNT"] ||
    field === REPORT_FIELDS["RESOURCE_RACK_HARDWARE_ASSET_COUNT"] ||
    field === REPORT_FIELDS["PARENT_CHILDREN_COUNT"] ||
    field === REPORT_FIELDS["CHARACTERISTIC_HEAT_EMISSION"];

  const IsStringField =
    !(IsDateField && IsNumberField) &&
    !(
      field === REPORT_FIELDS["FUNCTION_CATEGORY"] ||
      field === REPORT_FIELDS["P_FUNCTION_CATEGORY"] ||
      field === REPORT_FIELDS["TYPE_FUNCTION_CATEGORY"] ||
      field === REPORT_FIELDS["P_TYPE_FUNCTION_CATEGORY"]
    ) &&
    !(
      field === REPORT_FIELDS["FUNCTION_NAME"] ||
      field === REPORT_FIELDS["P_FUNCTION_NAME"] ||
      field === REPORT_FIELDS["TYPE_FUNCTION_NAME"] ||
      field === REPORT_FIELDS["P_TYPE_FUNCTION_NAME"]
    ) &&
    field !== REPORT_FIELDS["LIFECYCLE_STATUS"];

  // Handlers
  const handleChange = (e) => {
    handleOnChange(e.target.value);
    dispatch(setShouldExecute(false));
  };

  const handleRenderOperatorOptions = () => {
    if (IsDateField) {
      return options.filter(
        (option) =>
          !(
            option.name === REPORT_CONDITION_OPERATORS["STARTS_WITH"] ||
            option.name === REPORT_CONDITION_OPERATORS["ENDS_WITH"] ||
            option.name === REPORT_CONDITION_OPERATORS["LIKE"] ||
            option.name === REPORT_CONDITION_OPERATORS["IN"] ||
            option.name === REPORT_CONDITION_OPERATORS["NOT_IN"] ||
            option.name === REPORT_CONDITION_OPERATORS["LIKE_IGNORE_CASE"] ||
            option.name === REPORT_CONDITION_OPERATORS["EQUALS_IGNORE_CASE"]
          )
      );
    }

    if (IsNumberField) {
      return options.filter(
        (option) =>
          !(
            option.name === REPORT_CONDITION_OPERATORS["STARTS_WITH"] ||
            option.name === REPORT_CONDITION_OPERATORS["ENDS_WITH"] ||
            option.name === REPORT_CONDITION_OPERATORS["LIKE"] ||
            option.name === REPORT_CONDITION_OPERATORS["IN"] ||
            option.name === REPORT_CONDITION_OPERATORS["NOT_IN"] ||
            option.name === REPORT_CONDITION_OPERATORS["LIKE_IGNORE_CASE"] ||
            option.name === REPORT_CONDITION_OPERATORS["EQUALS_IGNORE_CASE"]
          )
      );
    }

    if (IsStringField) {
      return options.filter(
        (option) =>
          !(
            option.name === REPORT_CONDITION_OPERATORS["IN"] ||
            option.name === REPORT_CONDITION_OPERATORS["NOT_IN"]
          )
      );
    }

    return options;
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="operator-select-label">
        {getTranslation("CONDITION_OPERATOR", t, i18n)}
      </InputLabel>

      <Select
        labelId="operator-select-label"
        id="operator-select"
        label={getTranslation("CONDITION_OPERATOR", t, i18n)}
        value={value}
        onChange={handleChange}
        error={!Boolean(value)}
      >
        {handleRenderOperatorOptions().map((option) => (
          <MenuItem key={option.name} value={option.name}>
            {getTranslation(option.label, t, i18n)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RuleOperatorSelector;
