import {
  Box,
  Button,
  CardMedia,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import { styled } from "@mui/system";

export const CrudFormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
  padding: "0 20px",
});

export const NameInput = styled(TextField)({
  margin: "auto",
});

export const ImageUpload = styled(NameInput)({
  marginTop: "20px",
});

export const CrudFormCardMedia = styled(CardMedia)({
  height: 150,
  width: 150,
  margin: "auto",
  marginTop: "20px",
});

export const ClearText = styled(Typography)({
  cursor: "pointer",
  color: "#4fc1f2",
  margin: "auto",
});

export const ButtonContainer = styled("div")({
  padding: "0 20px",
});

export const DeleteButton = styled(Button)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  margin: "auto",
  borderRadius: "5px",
  height: "50px",
  "&:hover": {
    backgroundColor: theme.palette.primary.contrastText,
  },
}));
