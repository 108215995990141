import { Button, FormControl, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { PreferenceItemName } from "../profile/ProfilePreferenceItem.styles";

export const StyledTextField = styled(TextField)(({ theme }) =>
  theme.unstable_sx({
    color: theme.palette.primary.main,
  })
);

export const InviteButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  display: "flex",
  justifyContent: "end",
  width: "auto",
}));

export const InvitationSectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: "10px",
}));

export const InvitationEmail = styled(PreferenceItemName)(({ theme }) => ({
  flexGrow: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
  paddingRight: "10px",
  color: theme.palette.text.secondary,
  fontSize: "16px",
}));

export const InvitationInputFormControl = styled(FormControl)({
  minWidth: "unset",
});
