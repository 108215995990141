import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../../components/SelectInput";
import {
  OnboardingContainer,
  PrimaryText,
  SelectInputsContainer,
} from "../../components/styles/onboarding/Onboarding.styles";
import { useGetDataStorageLocationsQuery } from "../../store/slices/api/userManagementSlice";
import {
  selectUserData,
  setUserData,
} from "../../store/slices/onboardingSlice";
import { getTranslation } from "../../util/utils";
import PageTransition from "../../components/PageTransition";
import ErrorHandling from "../../components/common/ErrorHandling";
import { Box } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

const OnboardingPersonalData = ({ transitionDirection }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // Selectors
  const userData = useSelector(selectUserData);

  // States
  const [dirty, setDirty] = useState({});

  // Data storage locations
  const {
    data: dataStorageLocations,
    isLoading,
    isError,
  } = useGetDataStorageLocationsQuery();

  // Handlers
  const transformDataStorageLocationsData = (dataStorageLocations) =>
    dataStorageLocations?.map((instance) => {
      return {
        label: i18n.exists(instance.displayId)
          ? getTranslation(instance.displayId, t, i18n)
          : instance.name,
        value: instance.id,
      };
    });

  const handleChangeDataStorageLocation = (value) =>
    dispatch(setUserData({ ...userData, dataStorageLocationId: value }));

  const handleOnBlur = (e) =>
    setDirty({
      ...dirty,
      [e.target.name]: true,
    });

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <PageTransition direction={transitionDirection}>
        <PrimaryText sx={{ marginBottom: "10px" }} variant="h2" align="start">
          {getTranslation("DATA_LOCATION", t, i18n)}
        </PrimaryText>

        <PrimaryText sx={{ marginBottom: "20px" }} variant="body2">
          {getTranslation("DATA_LOCATION_CAPTION", t, i18n)}
        </PrimaryText>

        <OnboardingContainer>
          {/* Description explaining onboarding step*/}
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              marginBottom: "10px",
              //columnGap: "5px",
            }}
          >
            <WarningIcon
              sx={{ color: "#F4510A", marginTop: "5px" }}
              fontSize="small"
            />

            <PrimaryText
              sx={{ marginTop: "5px", textWrap: "wrap" }}
              variant="body2"
              align="center"
            >
              {getTranslation("userGeoLocationStorage", t, i18n)}
            </PrimaryText>
          </Box>

          <SelectInputsContainer>
            {/* Geo location select input for the storage of user data*/}
            <SelectInput
              label="geolocationStoring"
              selectLabelId="user-data-storage-select"
              value={userData.dataStorageLocationId}
              name="geolocationStoring"
              onBlur={handleOnBlur}
              error={
                dirty["geolocationStoring"] && !userData.dataStorageLocationId
              }
              handleChange={handleChangeDataStorageLocation}
              data={transformDataStorageLocationsData(dataStorageLocations)}
            />
          </SelectInputsContainer>
        </OnboardingContainer>
      </PageTransition>
    </ErrorHandling>
  );
};

export default OnboardingPersonalData;
