import { useDispatch } from "react-redux";
import {
  setShouldAddDefaultConditions,
  setShouldExecute,
} from "../../../../store/slices/reportsSlice";
import { AddGroupButton } from "../../../styles/reports/Reports.styles";
import AddIcon from "@mui/icons-material/Add";

const CustomAddGroupButton = ({ handleOnClick, level }) => {
  // General hooks
  const dispatch = useDispatch();

  // Handlers
  const handleClick = () => {
    handleOnClick();
    dispatch(setShouldAddDefaultConditions(true));
    dispatch(setShouldExecute(false));
  };

  const handleReturnLabel = () => {
    let labelText = "Add group";
    const nestingLevel = level + 1;

    if (nestingLevel > 3 && nestingLevel <= 9) {
      labelText = "Group";
    } else if (nestingLevel > 9) {
      labelText = "G";
    }

    return labelText;
  };

  return (
    <AddGroupButton id="add-filter-group" variant="text" onClick={handleClick}>
      <AddIcon />
      {handleReturnLabel()}
    </AddGroupButton>
  );
};

export default CustomAddGroupButton;
