import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  BasicDataWizardStepStack,
  BasicDataWizardStepTextField,
} from "../../styles/reports/Reports.styles";
import { getTranslation } from "../../../util/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDescription,
  selectIsPrivate,
  selectName,
  setDescription,
  setIsPrivate,
  setName,
} from "../../../store/slices/reportsSlice";

const BasicDataMobileStep = ({ showPrivate }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const name = useSelector(selectName);
  const description = useSelector(selectDescription);
  const isPrivate = useSelector(selectIsPrivate);

  // Handlers
  const handleChangeName = (event) => {
    const newName = event.target.value;
    dispatch(setName(newName));
  };

  const handleChangeDescription = (event) => {
    const newDescription = event.target.value;
    dispatch(setDescription(newDescription));
  };

  const handlePrivate = (event) => {
    const newIsPrivate = event.target.checked;
    dispatch(setIsPrivate(newIsPrivate));
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <BasicDataWizardStepStack>
          <Box>
            <Typography sx={{ fontWeight: "bold" }} variant="h5">
              {getTranslation("BASIC_DATA", t, i18n)}
            </Typography>
          </Box>

          <BasicDataWizardStepTextField
            id="report-name-field"
            value={name}
            onChange={handleChangeName}
            label={getTranslation("REPORT_NAME", t, i18n)}
          />
        </BasicDataWizardStepStack>
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="report-description-field"
          value={description}
          onChange={handleChangeDescription}
          multiline
          rows={6}
          label={getTranslation("REPORT_DESCRIPTION", t, i18n)}
        />

        {showPrivate && (
          <FormControlLabel
            id="report-private-checkbox"
            control={<Checkbox checked={isPrivate} onChange={handlePrivate} />}
            label={getTranslation("REPORT_SET_PRIVATE", t, i18n)}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default BasicDataMobileStep;
