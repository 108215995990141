export function stringToColor(inputString) {
  let hash = 0;
  const primeMultiplier = 97; // A prime number multiplier

  // Combine Unicode values for each character with prime multiplier
  for (let i = 0; i < inputString.length; i++) {
    const charCode = inputString.charCodeAt(i);
    hash = (hash << 5) - hash + charCode;
    hash *= primeMultiplier;
  }

  // Generate color components
  const r = (hash & 0xff0000) >> 16;
  const g = (hash & 0x00ff00) >> 8;
  const b = hash & 0x0000ff;

  // Create a color in hexadecimal format
  const color = `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")}`;

  return color;
}
