import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPageView, setPageView } from "../../store/slices/appSlice";
import {
  HomePagePadding,
  NoDataTextContainer,
} from "../../components/styles/general/General.styles";
import StickySearch from "../../components/assets/asset-list/StickySearch";
import { SecondaryText } from "../../components/styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getTranslation, transitionDirections } from "../../util/utils";
import SearchAssetsHeader from "../../navigation/header/assets/SearchAssetsHeader";
import PageTransition from "../../components/PageTransition";
import SearchAssetListRenderer from "../../components/assets/asset-list/SearchAssetListRenderer";
import { selectItems } from "../../store/slices/assetListSlice";
import { useGetResourceDetailsQuery } from "../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../store/slices/authSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import SearchAssetsDesktopHeader from "../../navigation/header/assets/desktop/SearchAssetsDesktopHeader";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const SearchAssetList = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);
  const [searchParams] = useSearchParams();
  const theme = useTheme();

  // Selectors
  const currentpageview = useSelector(selectPageView);
  const items = useSelector(selectItems);
  const user = useSelector(selectUser);

  // Other variables
  const from = searchParams.get("from");
  const direction = searchParams.get("direction");
  const tagId = searchParams.get("tagId");
  const tagName = searchParams.get("tagName");
  const resourceId = searchParams.get("resourceId");

  // Queries
  const {
    data: resourceData,
    isLoading: isLoadingResource,
    isError,
  } = useGetResourceDetailsQuery(
    {
      resourceid: resourceId,
      organizationId: user?.organizations?.find((o) => o.default).id,
    },
    {
      skip: !Boolean(resourceId) || resourceId === "undefined",
    }
  );

  // Handlers
  const formatClickHandler = (value) => {
    dispatch(setPageView(value));
  };

  const goBackHandler = () => {
    if (from === "resources") {
      navigate(`/resources?direction=${transitionDirections.LEFT_TO_RIGHT}`);
    } else if (from === "favorites") {
      navigate(`/favorites?direction=${transitionDirections.LEFT_TO_RIGHT}`);
    } else if (from === "recents") {
      navigate(`/recents?direction=${transitionDirections.LEFT_TO_RIGHT}`);
    } else if (from === "tags-filter") {
      navigate(
        `/tags-filter/${resourceId}/${tagId}?direction=${transitionDirections.RIGHT_TO_LEFT}&tagName=${tagName}`
      );
    } else if (from === "resource-child") {
      if (resourceData) {
        navigate(
          `/resources/${resourceData?.id}?direction=${transitionDirections.LEFT_TO_RIGHT}`
        );
      } else {
        navigate(`/resources?direction=${transitionDirections.LEFT_TO_RIGHT}`);
      }
    }
  };

  const getBackNameHandler = () => {
    if (from === "resources") {
      return "ASSETS";
    } else if (from === "favorites") {
      return "FAVORITES";
    } else if (from === "recents") {
      return "RECENTS";
    } else if (from === "tags-filter") {
      return "back";
    } else if (from === "resource-child") {
      if (resourceData) {
        const { displayId, name } = resourceData;
        const backName = displayId || name;
        return backName;
      }

      return "ASSETS";
    }
  };

  // Effects
  useEffect(() => {
    dispatch(setPageView("inline"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling isLoading={isLoadingResource} isError={isError}>
          <PageTransition direction={direction}>
            {mobileMatches ? (
              <SearchAssetsHeader
                goBackHandler={goBackHandler}
                back={getBackNameHandler()}
                from={from}
                tagId={tagId}
                tagName={tagName}
                resourceData={resourceData}
              />
            ) : (
              <SearchAssetsDesktopHeader
                goBackHandler={goBackHandler}
                resourceData={resourceData}
                from={from}
                tagId={tagId}
                tagName={tagName}
              />
            )}

            <HomePagePadding>
              {mobileMatches && (
                <StickySearch
                  currentpageview={currentpageview}
                  formatClickHandler={formatClickHandler}
                  from={from}
                  tagId={tagId}
                  tagName={tagName}
                  resourceData={resourceData}
                />
              )}

              <>
                {items?.length <= 0 && (
                  <NoDataTextContainer>
                    <SecondaryText align="center">
                      {getTranslation("NO_RESULTS_FOUND", t, i18n)}
                    </SecondaryText>
                  </NoDataTextContainer>
                )}

                <SearchAssetListRenderer
                  resourceId={resourceId}
                  tagId={tagId}
                />
              </>
            </HomePagePadding>
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default SearchAssetList;
