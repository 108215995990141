import {
  users_notifications_send_url,
  users_notifications_subscribe_url,
} from "../../../Constants";
import KeycloakService from "../../../services/KeycloakService";
import { baseApiSlice } from "./baseApiSlice";

export const notificationsApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    subscribe: builder.mutation({
      query: ({ notificationsSubscribeDto }) => ({
        url: users_notifications_subscribe_url,
        method: "POST",
        body: notificationsSubscribeDto,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["Notifications"],
    }),
    send: builder.mutation({
      query: ({ notificationsSendDto }) => ({
        url: users_notifications_send_url,
        method: "POST",
        body: notificationsSendDto,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["Notifications"],
    }),
  }),
});

export const { useSubscribeMutation, useSendMutation } = notificationsApiSlice;
