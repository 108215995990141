import { styled } from "@mui/system";
import { Box, Button, Typography } from "@mui/material";
import CameraAltTwoTone from "@mui/icons-material/CameraAltTwoTone";
import AttractionsTwoTone from "@mui/icons-material/AttractionsTwoTone";
import DevicesTwoTone from "@mui/icons-material/DevicesTwoTone";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import PhonelinkIcon from "@mui/icons-material/Phonelink";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";

const iconStyles = ({ theme }) => ({
  marginRight: 19,
  color: theme.palette.secondary.contrastText,
});

export const PageContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  paddingInline: "32px",
});

export const WelcomeFirstText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginTop: 46,
  textAlign: "center",
}));

export const WelcomeSecondText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: 700,
  textAlign: "center",
}));

export const BenefitsCardContainer = styled(Box)({
  flexGrow: 0.7,
});

export const ContinueButton = styled(Button)(({ theme }) => ({
  width: "311px",
  height: "42px",
  fontSize: theme.typography.body1.fontSize,
  margin: "0 auto",
  backgroundColor: theme.palette.primary.contrastText,
  color: theme.palette.secondary.contrastText,
}));

export const CameraAltIcon = styled(CameraAltTwoTone)(iconStyles);
export const AttractionsIcon = styled(AttractionsTwoTone)(iconStyles);
export const DeviceIcon = styled(DevicesTwoTone)(iconStyles);
export const AvailabilityIcon = styled(PhonelinkIcon)(iconStyles);
export const FlexibilityIcon = styled(DashboardCustomizeIcon)(iconStyles);
export const NextGenIcon = styled(AutoAwesomeIcon)(iconStyles);
