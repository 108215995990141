import Keycloak from "keycloak-js";
const _kc = new Keycloak("/keycloak.json");
export const TOKEN_MIN_VALIDITY = 10;

const doLogin = _kc.login;

const doLogout = _kc.logout;

let authUrl;

const getToken = () => _kc.token;

const getUsername = () => _kc.tokenParsed?.preferred_username;

const readAuthUrl = async () => {
  const data = process.env.PUBLIC_URL + "/keycloak.json";
  const jsonKeycloakConfig = await fetch(data)
    .then((response) => response.json())
    .then((jsonData) => jsonData);
  const url =
    jsonKeycloakConfig["auth-server-url"] +
    "/realms/" +
    jsonKeycloakConfig.realm +
    "/protocol/openid-connect/auth";
  authUrl = url;
  return url;
};

const getAuthUrl = () => {
  return authUrl;
};

const KeycloakService = {
  doLogin,
  doLogout,
  getToken,
  getUsername,
  getAuthUrl,
  readAuthUrl,
  keycloak: _kc,
};

export default KeycloakService;
