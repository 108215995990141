import { Box, MenuList } from "@mui/material";
import PreferenceItem from "../../components/PreferenceItem";
import { useDispatch } from "react-redux";
import {
  selectUserTheme,
  setTheme,
  setUserTheme,
} from "../../store/slices/appSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StyledDivider } from "../../components/styles/profile/Profile.styles";
import PageTransition from "../../components/PageTransition";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  transitionDirections,
  getTranslation,
  THEME,
  THEME_EXPIRATION_DAYS,
} from "../../util/utils";
import ThemeHeader from "../../navigation/header/profile/ThemeHeader";
import { setCookie } from "../../util/cookies";
import AppAccess from "../../components/common/AppAccess";

export const themes = [
  { id: THEME.AUTOMATIC, name: "AUTOMATIC" },
  { id: THEME.DARK, name: "darkTheme" },
  { id: THEME.LIGHT, name: "lightTheme" },
];

const Theme = (props) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Other variables
  const transitionDirection = searchParams.get("direction");

  const userTheme = useSelector(selectUserTheme);

  // Handlers
  const handleThemeChange = async (themeValue) => {
    try {
      // const data = await updateUserTheme({
      //   userId: user?.id,
      //   organizationId,
      //   theme: themeValue,
      // }).unwrap();

      // if (data) {
      //   dispatch(refreshUser(data));
      // }
      if (themeValue !== THEME.AUTOMATIC) {
        dispatch(setTheme(themeValue));
      } else {
        if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
          // Dark mode
          dispatch(setTheme(THEME.DARK));
        } else {
          // Light mode
          dispatch(setTheme(THEME.LIGHT));
        }
      }

      setCookie("theme", themeValue, THEME_EXPIRATION_DAYS);
      dispatch(setUserTheme(themeValue));
      navigate(
        `/profile/preferences?direction=${transitionDirections.LEFT_TO_RIGHT}`
      );
    } catch (error) {}
  };

  const goBackHandler = () =>
    navigate(
      `/profile/preferences?direction=${transitionDirections.LEFT_TO_RIGHT}`
    );

  return (
    <AppAccess>
      <PageTransition direction={transitionDirection}>
        <ThemeHeader goBackHandler={goBackHandler} />
        <div>
          <MenuList>
            <StyledDivider />
            {/* Themes list */}
            {themes.map((theme) => (
              <Box key={theme.id}>
                <PreferenceItem
                  handleClick={() => handleThemeChange(theme.id)}
                  menuId={`btn-menu-item-theme-${theme.id}`}
                  label={getTranslation(theme.name, t, i18n)}
                  isChecked={userTheme === theme.id}
                />
                <StyledDivider />
              </Box>
            ))}
          </MenuList>
        </div>
      </PageTransition>
    </AppAccess>
  );
};

export default Theme;
