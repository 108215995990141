import KeycloakService from "./KeycloakService";

const configure = () => {};

const sendRequest = async (config, headerParam) => {
  var header = {
    // "Content-Type": "application/json",
  };

  if (headerParam != null) {
    let headerName = headerParam.name;
    header = {
      ...header,
      [headerName]: headerParam.value,
    };
  }

  if (!config.preventSendToken) {
    header = {
      ...header,
      Authorization: "Bearer " + KeycloakService.getToken(),
    };
  }

  let requestParameters = {
    method: config.method ? config.method : "GET",
    headers: header,
  };

  if (config.data) {
    requestParameters = {
      ...requestParameters,
      //    body: config.data,
      body: JSON.stringify(config.data),
    };
  }

  try {
    const result = await fetch(config.url, requestParameters);

    if (!result.ok) {
      let errorData = null;
      errorData = await result.json();

      let resp = {
        ok: result.ok,
        status: result.status,
        error: errorData,
      };

      return resp;
    } else {
      let jsonResult = null;
      jsonResult = await result.json();

      let resp = {
        ok: result.ok,
        status: result.status,
        data: jsonResult,
      };

      if (config.isBlob) {
        const response = await fetch(config.url, requestParameters);
        const blob = await response.blob();
        const objectURL = URL.createObjectURL(blob);
        resp = { ...resp, data: objectURL };
      }

      return resp;
    }
  } catch (error) {}
};

const HttpService = {
  configure,
  sendRequest,
};

export default HttpService;
