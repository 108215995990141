import { useCallback, useState } from "react";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useSelector } from "react-redux";
import { StaticHeightDialogContent } from "../../styles/assets/ChooseAssetImagePage.styles";
import { DEFAULT_RESOURCE_IMAGE } from "../../../store/slices/resourceImageSlice";
import { useTranslation } from "react-i18next";
import { getTranslation, showValidationError } from "../../../util/utils";
import { messageError, messageSuccess } from "../../../util/notification";
import { selectPageInfo } from "../../../store/slices/appSlice";
import {
  CancelButton,
  CreateButton,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { selectUser } from "../../../store/slices/authSlice";
import { useUploadResourceImageMutation } from "../../../store/slices/api/resourceImagesApiSlice";
import ErrorHandling from "../../common/ErrorHandling";
import ResourceImageForm from "./ResourceImageForm";

const AddImageDialog = ({ resourceId, open, setOpen, imageCategory }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const { isActionButtonDisabled } = useSelector(selectPageInfo);

  // States
  const [resourceImageInput, setResourceImageInput] = useState(
    DEFAULT_RESOURCE_IMAGE
  );

  const [name, setName] = useState("");
  const [extension, setExtension] = useState("");

  // Mutations
  const [uploadResourceImage, { isLoading: isLoadingUpload }] =
    useUploadResourceImageMutation();

  // Handlers
  const handleClose = () => {
    setOpen(false);
    setResourceImageInput(DEFAULT_RESOURCE_IMAGE);
  };

  const handleSubmit = useCallback(async () => {
    try {
      if (!resourceImageInput.selectedFile) {
        messageError(getTranslation("UPLOAD_IMAGE", t, i18n));
        return;
      }

      const formData = new FormData();

      const { file, description } = resourceImageInput;
      const finalImageCategory =
        imageCategory === "OTHER" ? "MISC" : imageCategory;

      formData.append("file", file);
      formData.append("fileName", name + extension);
      formData.append("imageCategory", finalImageCategory);
      formData.append("description", description);

      await uploadResourceImage({
        formData,
        resourceid: resourceId,
        organizationId,
      }).unwrap();

      messageSuccess(
        getTranslation("RESOURCE_IMAGE_CREATED_SUCCESSFULLY", t, i18n)
      );
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to upload resource image", error);
    }

    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceImageInput, name]);

  return (
    <ErrorHandling isLoading={isLoadingUpload}>
      <Dialog fullWidth maxWidth="xs" onClose={handleClose} open={open}>
        <DialogTitle>{getTranslation("CHOOSE_IMAGE", t, i18n)}</DialogTitle>
        <StaticHeightDialogContent>
          <ResourceImageForm
            resourceImageInput={resourceImageInput}
            setResourceImageInput={setResourceImageInput}
            name={name}
            setName={setName}
            setExtension={setExtension}
          />
        </StaticHeightDialogContent>
        <DialogActions>
          <CancelButton variant="outlined" onClick={handleClose}>
            {getTranslation("CANCEL", t, i18n)}
          </CancelButton>
          <CreateButton
            disabled={isActionButtonDisabled || isLoadingUpload}
            onClick={handleSubmit}
          >
            {getTranslation("SUBMIT", t, i18n)}
          </CreateButton>
        </DialogActions>
      </Dialog>
    </ErrorHandling>
  );
};

export default AddImageDialog;
