import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextField, Box } from "@mui/material";
import {
  selectOrganizationData,
  setOrganizationData,
} from "../../store/slices/onboardingSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  InputContainer,
  ValidationText,
} from "../styles/inputs/NamesGroup.styles";
import { getTranslation, testInput } from "../../util/utils";

export const inputLabelProps = {
  shrink: true,
};

const BillingGroup = () => {
  // General hooks
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // Selectors
  const organizationData = useSelector(selectOrganizationData);

  // State
  const [billingAddressValid, setBillingAddressValid] = useState(true);
  const [vatNumberValid, setVatNumberValid] = useState(true);

  // Handlers
  const handleBillingAddressChange = (event) => {
    const isValid = testInput.BILLING_ADDRESS(event.target.value);

    dispatch(
      setOrganizationData({
        ...organizationData,
        billingAddress: event.target.value,
      })
    );

    setBillingAddressValid(isValid);
  };

  const handleVatNumberChange = (event) => {
    const isValid = testInput.VAT_NUMBER(event.target.value);

    dispatch(
      setOrganizationData({
        ...organizationData,
        vatNumber: event.target.value,
      })
    );

    setVatNumberValid(isValid);
  };

  return (
    <div>
      {/* Billing address input */}
      <InputContainer>
        <TextField
          id="billing-address-input"
          name="billingAddress"
          label={getTranslation("billingAddress", t, i18n)}
          type="text"
          placeholder={getTranslation("billingAddress", t, i18n)}
          value={organizationData.billingAddress}
          onChange={handleBillingAddressChange}
          error={!billingAddressValid}
          InputLabelProps={inputLabelProps}
          required
        />
        <Box>
          {!billingAddressValid && (
            <ValidationText variant="body5" align="center">
              {getTranslation("userInfoValidation", t, i18n)}
            </ValidationText>
          )}
        </Box>
      </InputContainer>

      {/* Vat number input */}
      <InputContainer>
        <TextField
          id="vat-number-input"
          name="vatNumber"
          label={getTranslation("VATnumber", t, i18n)}
          type="text"
          placeholder={getTranslation("VATnumber", t, i18n)}
          value={organizationData.vatNumber}
          onChange={handleVatNumberChange}
          error={!vatNumberValid}
          InputLabelProps={inputLabelProps}
          required
        />
        <Box>
          {!vatNumberValid && (
            <ValidationText variant="body5" align="center">
              {getTranslation("vatValidation", t, i18n)}
            </ValidationText>
          )}
        </Box>
      </InputContainer>
    </div>
  );
};

export default BillingGroup;
