import { Avatar } from "@mui/material";
import { styled } from "@mui/system";
import {
  PrimaryText,
  SecondaryText,
} from "../assets/asset-detail/AssetDetailBasicInfo.styles";

export const ProfileAvatar = styled(Avatar)({
  width: 64,
  height: 64,
});

export const EllipsePrimaryText = styled(PrimaryText)({
  marginLeft: "20px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontWeight: "600",
});

export const EllipseSecondaryText = styled(SecondaryText)({
  marginLeft: "20px",
  overflow: "hidden",
  textOverflow: "ellipsis",
});
