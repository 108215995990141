import { useTranslation } from "react-i18next";
import { getTranslation } from "../util/utils";
import {
  ContinueButton,
  FooterContainer,
  FooterWrapper,
} from "./styles/onboarding/Footer.styles";
import { EditInformationText } from "./styles/onboarding/OnboardingUserCredential.styles";
import { InviteNewMembersButton } from "./styles/profile/Profile.styles";

const Footer = ({
  activeStep,
  isButtonDisabled,
  handleNext,
  continueBtnName,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();

  return (
    <FooterWrapper>
      <FooterContainer>
        {(activeStep === 0 || activeStep === 1) && (
          <EditInformationText variant="body5" align="center">
            {getTranslation("userEditMsg", t, i18n)}
          </EditInformationText>
        )}

        {activeStep === 2 && (
          <EditInformationText variant="body5" align="center">
            {getTranslation("geolocationInfo", t, i18n)}
          </EditInformationText>
        )}

        {activeStep === 4 && (
          <EditInformationText variant="body5" align="center">
            {getTranslation("SUMMARY_INFO", t, i18n)}
          </EditInformationText>
        )}

        {activeStep === 4 ? (
          <InviteNewMembersButton
            data-testid="onboarding-stepped-button"
            id="onboarding-stepped"
            variant="contained"
            disabled={isButtonDisabled}
            onClick={handleNext}
          >
            {getTranslation(continueBtnName, t, i18n)}
          </InviteNewMembersButton>
        ) : (
          <ContinueButton
            isButtonDisabled={isButtonDisabled}
            data-testid="onboarding-stepped-button"
            id="onboarding-stepped"
            variant="contained"
            disabled={isButtonDisabled}
            onClick={handleNext}
          >
            {getTranslation(continueBtnName, t, i18n)}
          </ContinueButton>
        )}
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
