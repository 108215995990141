import FavoritesSection from "./FavoritesSection";
import ChartSection from "./ChartSection";
import RecentsSection from "./RecentsSection";
import {
  HomePagePadding,
  SectionSpacer,
} from "../../components/styles/general/General.styles";
import OrganizationsMenu from "../../components/OrganizationsMenu";
import HomeHeader from "../../navigation/header/home/HomeHeader";
import { useMediaQuery } from "@mui/material";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import Layer2Access from "../../components/common/Layer2Access";
import AppAccess from "../../components/common/AppAccess";

const HomePage = () => {
  // General hooks
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  return (
    <AppAccess>
      <Layer2Access>
        <HomePagePadding>
          {mobileMatches && (
            <>
              <HomeHeader />
              <OrganizationsMenu />
            </>
          )}

          <ChartSection />
          <SectionSpacer />
          <FavoritesSection />
          <SectionSpacer />
          <RecentsSection />
          <div id="end"></div>
        </HomePagePadding>
      </Layer2Access>
    </AppAccess>
  );
};

export default HomePage;
