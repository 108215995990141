import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGetResourceFunctionsQuery } from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import SelectInput from "../../SelectInput";
import { getTranslation } from "../../../util/utils";
import ErrorHandling from "../../common/ErrorHandling";

const FunctionInput = ({
  required,
  changeHandler,
  isFirstSubmitted,
  functionId,
  category,
  onlyWithType,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);

  // Functions data
  const {
    data: resourceFunctionsData,
    isLoading,
    isError,
  } = useGetResourceFunctionsQuery(
    {
      category,
      organizationId: user?.organizations?.find((o) => o.default).id,
      onlyWithType,
    },
    { skip: !category }
  );

  // Other variables
  const functions =
    resourceFunctionsData?.map((f) => ({
      label: f.name,
      value: f.id,
    })) ?? [];

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <div data-testid={functions[0]?.label}>
        <SelectInput
          fullWidth
          selectLabelId="function-label"
          label={getTranslation("FUNCTION", t, i18n)}
          value={functionId}
          handleChange={changeHandler}
          data={functions}
          error={isFirstSubmitted && !functionId}
          required={required}
          disabled={!Boolean(category)}
        />
      </div>
    </ErrorHandling>
  );
};

export default FunctionInput;
