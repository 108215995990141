import React from "react";
import { Stack } from "@mui/material";
import { FunnelChart, Funnel, Cell, Tooltip } from "recharts";

const FunnelChartWidgetContent = ({ data }) => {
  return (
    <Stack sx={{ flexGrow: 1, alignItems: "center", justifyContent: "center" }}>
      <FunnelChart width={120} height={120}>
        <Tooltip
          contentStyle={{
            background: "rgba(0, 0, 0, 0.8)",
            border: "none",
            borderRadius: "4px",
            padding: "4px",
          }}
          itemStyle={{
            color: "white",
            fontSize: 12,
            fontWeight: "bold",
            textAlign: "left",
          }}
        />
        <Funnel horizAdvX={180} dataKey="value" data={data}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Funnel>
      </FunnelChart>
    </Stack>
  );
};

export default FunnelChartWidgetContent;
