import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const UserManagementContainer = styled(Box)(({ theme }) => ({
  paddingInline: "10px",
}));

export const HeaderContainer = styled(Box)({
  marginTop: "20px",
});

export const HeadingTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const InviteButtonText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  marginLeft: "10px",
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginBottom: "10px",
}));

export const StyledAddIcon = styled(AddIcon)(({ globalFontSize }) => ({
  fontSize: `${globalFontSize * 1.5}px`,
}));
