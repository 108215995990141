import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { PrimaryText } from "../asset-detail/AssetDetailBasicInfo.styles";

export const HeadingContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginLeft: "16px",
  width: "100%",
  overflow: "hidden",
  maxHeight: "40px",
});

export const AssetName = styled(PrimaryText)({
  marginLeft: "16px",
  fontWeight: "bold",
});

export const DesktopAssetHeadingActionsContainer = styled(Box)({
  marginLeft: "40px",
  display: "flex",
  columnGap: "10px",
});
