import { Stack } from "@mui/material";
import { styled } from "@mui/system";
import { SecondaryText } from "../assets/asset-detail/AssetDetailBasicInfo.styles";

export const SettingsContainer = styled(Stack)({
  padding: "24px",
});

export const ThemeText = styled(SecondaryText)({
  marginTop: "24px",
});
