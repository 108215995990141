import { Grid, Stack } from "@mui/material";
import React from "react";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";

const MinMaxAverageWidgetContent = ({ title, min, max, avg }) => {
  return (
    <Stack
      sx={{
        flexGrow: "1",
        width: "100%",
        justifyContent: "center",
      }}
      spacing={2}
    >
      <Grid container sx={{ paddingInline: "16px" }}>
        <Grid item xs>
          <SecondaryText>Max</SecondaryText>
        </Grid>
        <Grid item>
          <SecondaryText>{max}</SecondaryText>
        </Grid>
      </Grid>
      <Grid container sx={{ paddingInline: "16px" }}>
        <Grid item xs>
          <SecondaryText fontWeight="bolder">Avg</SecondaryText>
        </Grid>
        <Grid item>
          <SecondaryText fontWeight="bolder">{avg}</SecondaryText>
        </Grid>
      </Grid>
      <Grid container sx={{ paddingInline: "16px" }}>
        <Grid item xs>
          <SecondaryText>Min</SecondaryText>
        </Grid>
        <Grid item>
          <SecondaryText>{min}</SecondaryText>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default MinMaxAverageWidgetContent;
