import { useEffect, useRef } from "react";
import { contain, cover } from "../../util/intrinsic-scale";

const CanvasImage = ({
  image,
  id,
  height,
  width,
  style,
  contains = true,
  offsetX = 0.5, // 0 - left-aligned; 0.5 - center-aligned; 1 - right-aligned
  offsetY = 0.5, // 0 - top-aligned; 0.5 - center-aligned; 1 - bottom-aligned
  scale = 1,
}) => {
  const canvasRef = useRef();

  useEffect(() => {
    if (image) {
      const canvas = canvasRef.current;
      if (canvas) {
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);

        const imageEl = new Image();
        imageEl.src = image;

        imageEl.onload = () => {
          if (contains) {
            const {
              offsetX: horizontalOffset,
              offsetY: verticalOffset,
              width: finalWidth,
              height: finalHeight,
            } = contain(
              width,
              height,
              imageEl.naturalWidth,
              imageEl.naturalHeight,
              scale,
              offsetX,
              offsetY
            );

            context.drawImage(
              imageEl,
              horizontalOffset,
              verticalOffset,
              finalWidth,
              finalHeight
            );
          } else {
            const {
              offsetX: horizontalOffset,
              offsetY: verticalOffset,
              width: finalWidth,
              height: finalHeight,
            } = cover(
              width,
              height,
              imageEl.naturalWidth,
              imageEl.naturalHeight,
              scale,
              offsetX,
              offsetY
            );

            context.drawImage(
              imageEl,
              horizontalOffset,
              verticalOffset,
              finalWidth,
              finalHeight
            );
          }
        };
      }
    }

    HTMLCanvasElement.prototype.toBlob = () => {};
    HTMLCanvasElement.prototype.toDataURL = () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <canvas
      id={id.includes(".") ? id.substring(0, id.indexOf(".")) : id}
      ref={canvasRef}
      style={style}
      width={width}
      height={height}
    />
  );
};

export default CanvasImage;
