import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useGetAddressByIdQuery,
  useGetAddressesByOrganizationIdQuery,
  usePatchAddressMutation,
  useSaveAddressMutation,
} from "../../../store/slices/api/addressesApiSlice";
import Access from "../../../components/common/Access";
import {
  getTranslation,
  permissions,
  testInput,
  transitionDirections,
} from "../../../util/utils";
import UpdateOrganizationAddressHeader from "../../../navigation/header/organization/UpdateOrganizationAddressHeader";
import ErrorHandling from "../../../components/common/ErrorHandling";
import PageTransition from "../../../components/PageTransition";
import { Box, Grid, TextField } from "@mui/material";
import { ValidationText } from "../../../components/styles/inputs/NamesGroup.styles";
import { HomePagePadding } from "../../../components/styles/general/General.styles";
import CountriesAutocomplete from "../../../components/inputs/CountriesAutocomplete";
import { messageSuccess } from "../../../util/notification";
import AppAccess from "../../../components/common/AppAccess";

const UpdateOrganizationAddress = () => {
  // General hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { organizationId, addressId } = useParams();

  // States
  const [address, setAddress] = useState({});

  // Queries
  const { data, isLoading, isError } = useGetAddressByIdQuery({
    organizationId,
    addressId,
  });

  const {
    data: addressesData,
    isLoading: isLoadingAddresses,
    isError: isErrorAddresses,
  } = useGetAddressesByOrganizationIdQuery({
    organizationId,
  });

  // Mutations
  const [patchAddress, { isLoading: isLoadingPatchAddress }] =
    usePatchAddressMutation();
  const [saveAddress, { isLoading: isLoadingSaveAddress }] =
    useSaveAddressMutation();

  // Other variables
  const transitionDirection = searchParams.get("direction");
  const isValidAddressLine1 = testInput.ORGANIZATION_NAME(address.addressLine1);
  const isValidPostcode = testInput.ORGANIZATION_NAME(address.postcode);
  const isValidCity = testInput.ORGANIZATION_NAME(address.city);
  const isValidCountry = Boolean(address.country);

  const isValid =
    isValidAddressLine1 && isValidPostcode && isValidCity && isValidCountry;

  // Handlers
  const cancelHandler = () => {
    navigate(
      "/profile/organization/" +
        organizationId +
        `?direction=${transitionDirections.TOP_TO_BOTTOM}`
    );
  };

  const handleSubmit = async () => {
    try {
      if (addressesData && addressesData[0]) {
        await patchAddress({
          address,
          organizationId,
          addressId,
        }).unwrap();
      } else {
        const addressData = {
          addressType: "BILLING",
          district: "District",
          ...address,
        };
        await saveAddress({
          address: addressData,
          organizationId,
        }).unwrap();
      }

      messageSuccess(getTranslation("successfulUpdateAddress", t, i18n));
      navigate(
        "/profile/organization/" +
          organizationId +
          `?direction=${transitionDirections.TOP_TO_BOTTOM}`
      );
    } catch (error) {
      messageSuccess(getTranslation("failedUpdateAddress", t, i18n));
    }
  };

  const handleAddressLine1Change = (event) => {
    const newAddressLine1 = event.target.value;

    setAddress({
      ...address,
      addressLine1: newAddressLine1,
    });
  };

  const handleAddressLine2Change = (event) => {
    const newAddressLine2 = event.target.value;

    setAddress({
      ...address,
      addressLine2: newAddressLine2,
    });
  };

  const handlePostcodeChange = (event) => {
    const newPostcode = event.target.value;

    setAddress({
      ...address,
      postcode: newPostcode,
    });
  };

  const handleCityChange = (event) => {
    const newCity = event.target.value;

    setAddress({
      ...address,
      city: newCity,
    });
  };

  const handleStateChange = (event) => {
    const newState = event.target.value;

    setAddress({
      ...address,
      state: newState,
    });
  };

  const handleCountryChange = (value) => {
    setAddress({
      ...address,
      country: value,
    });
  };

  // Effects
  useEffect(() => {
    if (data) {
      setAddress(data);
    }
  }, [data]);

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={isLoading || isLoadingAddresses || isLoadingSaveAddress}
        isError={isError || isErrorAddresses}
      >
        <Access oneOf={[permissions.ORG_MANAGEMENT_EDIT]}>
          <PageTransition direction={transitionDirection}>
            <UpdateOrganizationAddressHeader
              handleCancelAction={cancelHandler}
              handleAction={handleSubmit}
              disabledDone={!isValid}
            />
            <HomePagePadding>
              <TextField
                inputProps={{
                  "data-testid": "organization-address-line-1-input",
                }}
                id="organization-address-line-1-input"
                type="text"
                label={getTranslation("ADDRESS_LINE1", t, i18n)}
                required
                placeholder={getTranslation("ADDRESS_LINE1", t, i18n)}
                name="addressLine1"
                value={address.addressLine1}
                onChange={handleAddressLine1Change}
                error={!isValidAddressLine1}
                InputLabelProps={{ shrink: true }}
              />
              <Box>
                {!isValidAddressLine1 && (
                  <ValidationText
                    data-testid="validation-text-organization-address-line-1"
                    variant="body5"
                    align="center"
                  >
                    {getTranslation(
                      "ORGANIZATION_ADDRESS_LINE1_VALIDATION",
                      t,
                      i18n
                    )}
                  </ValidationText>
                )}
              </Box>
              <TextField
                inputProps={{
                  "data-testid": "organization-address-line-2-input",
                }}
                id="organization-address-line-2-input"
                type="text"
                label={getTranslation("ADDRESS_LINE2", t, i18n)}
                placeholder={getTranslation("ADDRESS_LINE2", t, i18n)}
                name="addressLine2"
                value={address.addressLine2}
                onChange={handleAddressLine2Change}
                InputLabelProps={{ shrink: true }}
              />
              <Grid container columnSpacing={1}>
                <Grid item xs={6}>
                  <TextField
                    inputProps={{
                      "data-testid": "organization-postal-code-input",
                    }}
                    id="organization-postal-code-input"
                    type="text"
                    label={getTranslation("POSTAL_CODE", t, i18n)}
                    required
                    placeholder={getTranslation("POSTAL_CODE", t, i18n)}
                    name="postalCode"
                    value={address.postcode}
                    onChange={handlePostcodeChange}
                    error={!isValidPostcode}
                    InputLabelProps={{ shrink: true }}
                  />
                  <Box>
                    {!isValidPostcode && (
                      <ValidationText
                        data-testid="validation-text-organization-postal-code"
                        variant="body5"
                        align="center"
                      >
                        {getTranslation(
                          "ORGANIZATION_POSTAL_CODE_VALIDATION",
                          t,
                          i18n
                        )}
                      </ValidationText>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    inputProps={{
                      "data-testid": "organization-city-input",
                    }}
                    id="organization-city-input"
                    type="text"
                    label={getTranslation("CITY", t, i18n)}
                    required
                    placeholder={getTranslation("CITY", t, i18n)}
                    name="city"
                    value={address.city}
                    onChange={handleCityChange}
                    InputLabelProps={{ shrink: true }}
                  />

                  <Box>
                    {!isValidCity && (
                      <ValidationText
                        data-testid="validation-text-organization-city"
                        variant="body5"
                        align="center"
                      >
                        {getTranslation(
                          "ORGANIZATION_CITY_VALIDATION",
                          t,
                          i18n
                        )}
                      </ValidationText>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <TextField
                inputProps={{ "data-testid": "organization-state-input" }}
                id="organization-state-input"
                type="text"
                label={getTranslation("STATE", t, i18n)}
                placeholder={getTranslation("STATE", t, i18n)}
                name="state"
                value={address.state}
                onChange={handleStateChange}
                InputLabelProps={{ shrink: true }}
              />
              <CountriesAutocomplete
                value={address.country}
                handleChange={handleCountryChange}
              />
            </HomePagePadding>
          </PageTransition>
        </Access>
      </ErrorHandling>
    </AppAccess>
  );
};

export default UpdateOrganizationAddress;
