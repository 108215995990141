import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../util/utils";
import {
  Autocomplete,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Option,
  SelectInputFormControl,
} from "./styles/inputs/SelectInput.styles";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../store/slices/appSlice";
import {
  StyledArrowDropdownIcon,
  StyledClearIcon,
} from "./styles/general/General.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../util/viewport-utils";

const SelectInput = ({
  id,
  selectLabelId,
  label,
  name,
  value,
  handleChange,
  data,
  error,
  required,
  disabledOptions,
  isSelect = true,
  disabled,
  ...other
}) => {
  // General Hooks
  const { t, i18n } = useTranslation();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // State
  const [selected, setSelected] = useState(
    data?.find((d) => d.value.toString() === value?.toString())
  );
  const [elementRect, setElementRect] = useState({ width: 0, left: 0 });
  const selectRef = useRef();

  // Other variables
  const autocompleteValue =
    selected && value
      ? {
          value: selected?.value.toString(),
          label: getTranslation(selected?.label, t, i18n),
        }
      : null;

  const autocompleteOptions = data?.map((option) => {
    return {
      ...option,
      label: getTranslation(option.label, t, i18n),
    };
  });

  const { width: elementWidth } = elementRect ?? {};
  const menuProps = mobileMatches
    ? {
        marginThreshold: 0,
        PaperProps: {
          sx: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            width: elementWidth ?? "",
            maxHeight: "200px",
            overflowX: "scroll",
          },
        },
        MenuListProps: {
          id,
        },
      }
    : {};

  // Handlers
  const handleIsOptionEqualToValue = (option, value) => {
    return option.value.toString() === value.value.toString();
  };

  const handleAutocompleteChange = (event, newValue) => {
    handleChange(newValue?.value?.toString());
    setSelected(newValue);
  };

  const handleGetOptionDisabled = (option) =>
    Boolean(disabledOptions) &&
    disabledOptions.some(
      (disabled) => disabled?.value?.toString() === option?.value?.toString()
    );

  const handleRenderOption = (props, option) => (
    <Option component="li" {...props}>
      <Typography>{option.label}</Typography>
    </Option>
  );

  const handleRenderInput = (params) => (
    <TextField
      {...params}
      name={name ?? ""}
      label={getTranslation(label, t, i18n)}
      required={required}
      error={error}
    />
  );

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    handleChange(newValue);
  };

  // Effects
  useEffect(() => {
    if (value) {
      setSelected(data?.find((d) => d.value.toString() === value?.toString()));
    }
  }, [data, value]);

  useEffect(() => {
    const elementRect = selectRef.current?.getBoundingClientRect();
    if (elementRect) {
      setElementRect(elementRect);
    }
  }, []);

  return isSelect ? (
    <SelectInputFormControl
      id={selectLabelId + "-form-control"}
      fullWidth
      ref={selectRef}
      required={required}
    >
      <InputLabel id={selectLabelId}>
        {getTranslation(label, t, i18n)}
      </InputLabel>

      <Select
        id={selectLabelId + "-select"}
        labelId={selectLabelId}
        value={value ?? ""}
        label={getTranslation(label, t, i18n)}
        onChange={handleSelectChange}
        MenuProps={menuProps}
        disabled={disabled}
      >
        {data?.map((item) => {
          const disabled = disabledOptions?.some(
            (option) => option.value === item.value
          );

          return (
            <MenuItem
              disabled={disabled}
              id={`menu-item-${item.value}`}
              key={item.value}
              value={item.value}
            >
              {getTranslation(item.label, t, i18n)}
            </MenuItem>
          );
        })}
      </Select>
    </SelectInputFormControl>
  ) : (
    <Autocomplete
      {...other}
      popupIcon={<StyledArrowDropdownIcon globalFontSize={globalFontSize} />}
      clearIcon={<StyledClearIcon globalFontSize={globalFontSize} />}
      isOptionEqualToValue={handleIsOptionEqualToValue}
      value={autocompleteValue}
      onChange={handleAutocompleteChange}
      data-testid={selectLabelId}
      id={selectLabelId + "-autocomplete"}
      options={autocompleteOptions}
      getOptionDisabled={handleGetOptionDisabled}
      renderOption={handleRenderOption}
      renderInput={handleRenderInput}
      disabled={disabled}
    />
  );
};

export default SelectInput;
