import { Grid, ListItemIcon, Menu, TextField } from "@mui/material";
import { styled } from "@mui/system";
import SelectInput from "../../../SelectInput";

export const StickySearchContainer = styled(Grid)({
  position: "sticky",
  marginBlock: "24px",
  "> .MuiGrid-item": {
    display: "flex",
    alignItems: "center",
  },
});

export const UnSpacedSelectInput = styled(SelectInput)({
  ".MuiOutlinedInput-root": {
    padding: 0,
  },
  ".MuiFormControl-root": {
    marginTop: 0,
  },
  ".MuiInputLabel-root": {
    top: "-20%",
  },
});

export const SortingOptionsMenu = styled(Menu)({
  marginTop: "24px",
  ".MuiMenu-paper": {
    borderRadius: "15px",
    outline: "none",
    marginTop: "16px",
  },
  ".MuiMenu-list": {
    width: "240px",
    borderRadius: "15px",
  },
});

export const DynamicVisibilityItemIcon = styled(ListItemIcon)(
  ({ isvisible }) => ({
    visibility: isvisible ? "visible" : "hidden",
  })
);

export const SearchTextField = styled(TextField)({
  marginBlock: "auto",
  ".MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  ".MuiInputBase-root:before": {
    bottom: "-8px",
  },
  ".MuiInputBase-root:after": {
    bottom: "-8px",
  },
});
