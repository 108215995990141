import { Container, Typography } from "@mui/material";
import PermIdentityTwoTone from "@mui/icons-material/PermIdentityTwoTone";
import { styled } from "@mui/system";

export const OnboardingUserCredentialContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingInline: 0,
});

export const OnboardingUserCredentialRectangle = styled("div")({
  width: 64,
  height: 64,
  borderRadius: 32,
  backgroundColor: "#121212",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const OnboardingUserCredentialTypography = styled(Typography)(
  ({ theme }) => ({
    height: 42,
    marginTop: 16,
    color: theme.palette.text.primary,
  })
);

export const PermIdentityTwoToneIcon = styled(PermIdentityTwoTone)({
  color: "#FFF",
});

export const EditInformationText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: theme.typography.body2.fontSize,
  marginBottom: 20,
}));
