import { Box } from "@mui/material";
import {
  StyledAvatar,
  StyledBox,
  StyledListItemContainer,
} from "../styles/about/About.styles";
import { SecondaryText } from "../styles/assets/ListInlineView.styles";

const CreditsCard = ({ credit }) => {
  const { name, avatarUri } = credit;

  return (
    <StyledListItemContainer>
      <StyledAvatar
        alt={name}
        src={`${process.env.PUBLIC_URL}/images/users/${avatarUri}`}
      />
      <SecondaryText>{name}</SecondaryText>
    </StyledListItemContainer>
  );
};

export default CreditsCard;
