import React, { forwardRef, memo, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import {
  selectIsFirstSubmitted,
  selectResourceInput,
  setResourceInput,
} from "../../../store/slices/resourceInputSlice";
import {
  datePickerFormat,
  getTranslation,
  measurementDisplay,
} from "../../../util/utils";
import { CharacteristicItemInput } from "../../styles/assets/asset-detail/AssetDetailCharacteristicInputGroup.styles";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import SelectInput from "../../SelectInput";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const NumericFormatCustom = forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

export const dynamicCharacteristicProps = (dataType, characteristicName) => {
  let props = {};

  if (dataType === "STRING") {
    props.text = "text";
  } else {
    props.InputProps = {
      inputComponent: NumericFormatCustom,
    };

    props.name = "characteristics-" + characteristicName;
  }

  return props;
};

const CharacteristicInput = ({ characteristic }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const {
    id,
    value,
    name,
    dataType,
    required,
    editable,
    measurementUnit,
    characteristicValues,
    defaultTypeValue,
    isEdited,
  } = characteristic;

  // Selectors
  const user = useSelector(selectUser);
  const resourceInput = useSelector(selectResourceInput);
  const isFirstSubmitted = useSelector(selectIsFirstSubmitted);

  // Other variables
  const characteristicValueSelectList = useMemo(() => {
    return characteristicValues?.map((cv) => ({
      label: cv,
      value: cv,
    }));
  }, [characteristicValues]);

  const label = useMemo(() => {
    return (
      getTranslation(name, t, i18n) +
      `${
        measurementUnit
          ? " (" +
            measurementDisplay({
              value: value,
              unit: measurementUnit,
              region: user.region,
            }).unit +
            ")"
          : ""
      }` +
      `${isEdited ? ` (${getTranslation("EDITED", t, i18n)})` : ""}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurementUnit]);

  // Handlers
  const handleChangeCharacteristic = (value, id) => {
    const newCharacteristics = resourceInput.characteristics.map(
      (characteristic) => {
        if (id !== characteristic.id) return characteristic;
        return {
          ...characteristic,
          value,
        };
      }
    );

    dispatch(
      setResourceInput({
        ...resourceInput,
        characteristics: newCharacteristics,
      })
    );
  };

  const handleChangeCharacteristicValueDropdown = (value) => {
    const newCharacteristicValue = value;

    const newCharacteristics = resourceInput.characteristics.map((c) => {
      if (id !== c.id) return c;

      return {
        ...c,
        value: newCharacteristicValue,
      };
    });

    dispatch(
      setResourceInput({
        ...resourceInput,
        characteristics: newCharacteristics,
      })
    );
  };

  const handleRenderInput = () => {
    if (characteristicValues?.length > 0) {
      return (
        <SelectInput
          disabled={!editable}
          selectLabelId={`characteristics-${id}-label`}
          value={value}
          handleChange={handleChangeCharacteristicValueDropdown}
          data={characteristicValueSelectList}
          label={getTranslation(name, t, i18n)}
          isSelect={false}
        />
      );
    }

    if (id === 160 || name === "LIFECYCLE_DATE" || dataType === "DATETIME") {
      return (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={datePickerFormat(user.region)}
        >
          <DatePicker
            data-testid={name}
            label={getTranslation("LIFECYCLE_DATE", t, i18n)}
            value={value || dayjs()}
            onChange={(value) => handleChangeCharacteristic(value, id)}
          />
        </LocalizationProvider>
      );
    }

    return (
      <CharacteristicItemInput
        error={isFirstSubmitted && required && !value}
        required={required}
        disabled={!editable}
        key={"characteristics-" + id}
        id={"characteristics-" + id}
        label={label}
        {...dynamicCharacteristicProps(dataType, name)}
        value={value || ""}
        onChange={(event) => handleChangeCharacteristic(event.target.value, id)}
      />
    );
  };

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={defaultTypeValue ? 7 : 12}>
        {editable ? (
          <>{handleRenderInput()}</>
        ) : (
          <Box sx={{ marginTop: "10px" }}>
            <SecondaryText sx={{ color: "#6F6F6F" }}>{label}</SecondaryText>

            <SecondaryText sx={{ marginTop: "10px" }}>{value}</SecondaryText>
          </Box>
        )}
      </Grid>

      {defaultTypeValue && (
        <Grid item xs={5}>
          <SecondaryText>
            {getTranslation("DEFAULT_VALUE", t, i18n)}
          </SecondaryText>

          <SecondaryText>{defaultTypeValue}</SecondaryText>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(CharacteristicInput);
