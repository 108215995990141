import { address_base_url, address_by_id_url } from "../../../Constants";
import KeycloakService from "../../../services/KeycloakService";
import { baseApiSlice } from "./baseApiSlice";

export const addressesApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAddressesByOrganizationId: builder.query({
      query: ({ organizationId }) => ({
        url: address_base_url.replace(":organizationId", organizationId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["ADDRESS"],
    }),
    getAddressById: builder.query({
      query: ({ addressId, organizationId }) => ({
        url: address_by_id_url
          .replace(":organizationId", organizationId)
          .replace(":addressId", addressId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["ADDRESS"],
    }),
    saveAddress: builder.mutation({
      query: ({ address, organizationId }) => ({
        url: address_base_url.replace(":organizationId", organizationId),
        method: "POST",
        body: address,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
          "content-type": "application/json",
        },
      }),
      invalidatesTags: ["ADDRESS"],
    }),
    patchAddress: builder.mutation({
      query: ({ address, organizationId, addressId }) => ({
        url: address_by_id_url
          .replace(":organizationId", organizationId)
          .replace(":addressId", addressId),
        method: "PATCH",
        body: address,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
          "content-type": "application/json",
        },
      }),
      invalidatesTags: ["ADDRESS"],
    }),
  }),
});

export const {
  useGetAddressesByOrganizationIdQuery,
  useGetAddressByIdQuery,
  useSaveAddressMutation,
  usePatchAddressMutation,
} = addressesApiSlice;
