import { ListItemIcon } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddRecentMutation,
  useGetAllFunctionsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import {
  selectColumnViewTrigger,
  selectGlobalFontSize,
  setColumnViewTrigger,
  setSearchTerm,
} from "../../../store/slices/appSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { setParentId } from "../../../store/slices/columnViewSlice";
import { getFallbackIcon } from "../../../util/utils";
import ErrorHandling from "../../common/ErrorHandling";
import {
  ChildrenCountText,
  ItemWrapper,
  NavigationResourceItemIcon,
  ResourceItemName,
  SelectedResourceItem,
} from "../../styles/assets/asset-list/ResourceItem.styles";

const ResourceItem = ({ isSelected, resource, isActive }) => {
  // General hooks
  const dispatch = useDispatch();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const globalFontSize = useSelector(selectGlobalFontSize);
  const columnViewTrigger = useSelector(selectColumnViewTrigger);

  // Queries
  const {
    data: allFunctionsData,
    isLoading,
    isError,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Mutations
  const [addRecent] = useAddRecentMutation();

  // Other variables
  const { name, id, functionId, displayId, childrenCount } = resource;
  const resourceFunction = allFunctionsData?.find((f) => f.id === functionId);
  const resourceName = displayId || name;

  // Handlers
  const handleClickItem = async (parentId) => {
    try {
      await addRecent({
        resourceid: parentId,
        organizationId,
      }).unwrap();

      dispatch(setParentId(parentId));
      dispatch(setSearchTerm(""));
      dispatch(setColumnViewTrigger(columnViewTrigger + 1));
    } catch (error) {
      console.error("Failed to add to recents", error);
    }
  };

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <SelectedResourceItem isselected={isSelected} isactive={isActive}>
        <ItemWrapper
          data-testid={`item-${resourceName}`}
          onClick={() => handleClickItem(id)}
        >
          <ListItemIcon>
            <img
              width={globalFontSize * 1.5}
              height={globalFontSize * 1.5}
              src={`${process.env.PUBLIC_URL}/icons/${getFallbackIcon(
                resourceFunction?.category
              )}`}
              alt="icon"
            />
          </ListItemIcon>

          {/* If display id is empty, display name instead */}
          <ResourceItemName variant="body2" isactive={isActive}>
            {resourceName}
          </ResourceItemName>

          {childrenCount > 0 && (
            <ChildrenCountText variant="body2">
              {childrenCount}
            </ChildrenCountText>
          )}

          <NavigationResourceItemIcon customfontsize={globalFontSize}>
            keyboard_arrow_right
          </NavigationResourceItemIcon>
        </ItemWrapper>
      </SelectedResourceItem>
    </ErrorHandling>
  );
};

export default ResourceItem;
