import React, { useState } from "react";
import { Checkbox, Paper, Stack, TextField } from "@mui/material";
import { Box } from "@react-three/drei";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";
import PercentageWidgetContent from "../categorized-widgets/PercentageWidgetContent";
import { StyledFormControlLabel } from "../../../styles/general/General.styles";
import { MuiColorInput } from "mui-color-input";

const PercentageWidgetForm = () => {
  // States
  const [title, setTitle] = useState();
  const [displayHome, setDisplayHome] = useState(false);
  const [color, setColor] = useState("#000");

  // Handlers
  const handleChangeTitle = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
  };

  const handleChangeColor = (newColor) => {
    setColor(newColor);
  };

  const handleChangeDisplayHome = (e) => {
    const newDisplayHome = e.target.checked;
    setDisplayHome(newDisplayHome);
  };

  return (
    <Stack spacing={3}>
      <SecondaryText gutterBottom>Configure Count</SecondaryText>
      <TextField
        label="Title"
        id="title"
        value={title}
        size="small"
        onChange={handleChangeTitle}
      />
      <MuiColorInput
        adornmentPosition="end"
        sx={{ marginTop: "0px" }}
        label="Color"
        id="color"
        value={color}
        size="small"
        onChange={handleChangeColor}
        fullWidth
      />
      <StyledFormControlLabel
        control={
          <Checkbox checked={displayHome} onChange={handleChangeDisplayHome} />
        }
        label="Show on homescreen"
      />
      <Box>
        <SecondaryText gutterBottom>Preview</SecondaryText>
        <Paper
          sx={{
            height: "180px",
            backgroundColor: "#F1F1F1",
          }}
        >
          <Stack sx={{ height: "100%" }}>
            <SecondaryText
              sx={{ padding: "8px" }}
              variant="body2"
              textAlign="center"
            >
              {title}
            </SecondaryText>
            <PercentageWidgetContent percentage={20} color={color} />
          </Stack>
        </Paper>
      </Box>
    </Stack>
  );
};

export default PercentageWidgetForm;
