import { useTranslation } from "react-i18next";
import PageTransition from "../../../components/PageTransition";
import { Divider } from "@mui/material";
import { OnboardingOrganizationPageContainer } from "../../../components/styles/onboarding/OnboardingOrganizationPage.styles";
import { getTranslation } from "../../../util/utils";
import { useSelector } from "react-redux";
import { selectOrganizationData } from "../../../store/slices/onboardingSlice";
import { PrimaryText } from "../../../components/styles/onboarding/Onboarding.styles";

const OnboardingOrganizationSummaryPage = ({ transitionDirection }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const organizationData = useSelector(selectOrganizationData);

  return (
    <PageTransition direction={transitionDirection}>
      <PrimaryText sx={{ marginBottom: "10px" }} variant="h2" align="start">
        {getTranslation("SUMMARY", t, i18n)}
      </PrimaryText>

      <PrimaryText sx={{ marginBottom: "20px" }} variant="body2">
        {getTranslation("SUMMARY_CAPTION", t, i18n)}
      </PrimaryText>

      <OnboardingOrganizationPageContainer>
        <PrimaryText>
          {organizationData.organizationName} (
          {getTranslation("LEGAL_NAME", t, i18n)})
        </PrimaryText>

        {organizationData.nickname && (
          <PrimaryText>
            {organizationData.nickname} (
            {getTranslation("ORG_NICKNAME", t, i18n)})
          </PrimaryText>
        )}

        <PrimaryText sx={{ marginBottom: "30px" }}>
          {organizationData.contactEmail} (
          {getTranslation("contactEmail", t, i18n)})
        </PrimaryText>

        <Divider />

        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            marginTop: "20px",
          }}
        >
          <Box>
            <Typography>
              {getTranslation(organizationData.subscription, t, i18n)}
            </Typography>
            <Typography>(1 user, 50 assets included)</Typography>
          </Box>

          <Typography fontWeight="bold">00,00 $</Typography>
        </Box>

        <Divider sx={{ marginTop: "20px" }} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            marginTop: "20px",
          }}
        >
          <Typography fontWeight="bold">Total</Typography>

          <Typography fontWeight="bold">00,00 $</Typography>
        </Box> */}
      </OnboardingOrganizationPageContainer>
    </PageTransition>
  );
};

export default OnboardingOrganizationSummaryPage;
