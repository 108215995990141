import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import {
  ErrorIcon,
  ErrorModalContentContainer,
} from "./styles/general/General.styles";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../util/utils";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ErrorModal() {
  // General hooks
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // Handlers
  const handleNavigateHome = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <div>
      <Modal
        hideBackdrop={false}
        open={true}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ErrorModalContentContainer spacing={2}>
            <ErrorIcon fontSize="large" />

            <Typography data-testid="error-message" variant="body1">
              {getTranslation("ERROR_OCCURRED", t, i18n)}
            </Typography>
            <Button variant="contained" onClick={handleNavigateHome}>
              {getTranslation("HOME", t, i18n)}
            </Button>
          </ErrorModalContentContainer>
        </Box>
      </Modal>
    </div>
  );
}
