import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { getTranslation, transitionDirections } from "../../../util/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  setIsSearchOpen,
} from "../../../store/slices/appSlice";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ArrowRightIcon } from "../../styles/assets/ListInlineView.styles";
import { clearHistory } from "../../../store/slices/assetListSlice";
import { useGetAllFunctionsQuery } from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  CategoryTableCell,
  EmptyCell,
  FlexBox,
  ListTable,
  NameTableCell,
  SecondaryContrastColorCell,
  TableCellText,
  SelectedTableRow,
  AssetListTableFlexContainer,
  ChildrenCountCellText,
} from "../../styles/assets/asset-list/AssetListTable.styles";
import ErrorHandling from "../../common/ErrorHandling";
import TableMedia from "./TableMedia";

const AssetListTable = ({ rows }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Queries
  const {
    data: allFunctionsData,
    isLoading,
    isError,
  } = useGetAllFunctionsQuery({
    organizationId: user?.organizations?.find((o) => o.default).id,
  });

  // Other variables
  const favoritesRecentsRouteCheck =
    pathname.includes("favorites") || pathname.includes("recents");

  const tagName = searchParams.get("tagName");
  const from = searchParams.get("from");
  const tagId = searchParams.get("tagId");
  const resourceId = searchParams.get("resourceId");

  // Handlers
  const handleNavigate = (id) => {
    navigate(
      `/resources/${id}?direction=${
        transitionDirections.RIGHT_TO_LEFT
      }&pathname=${pathname}${Boolean(tagName) ? `&tagName=${tagName}` : ""}${
        Boolean(from) ? `&from=${from}` : ""
      }${Boolean(tagId) ? `&tagId=${tagId}` : ""}${
        Boolean(resourceId) ? `&resourceId=${resourceId}` : ""
      }`
    );

    dispatch(setIsSearchOpen(false));
    dispatch(clearHistory());
  };

  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === id);
  };

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      {rows && rows.length && (
        <TableContainer component={Paper}>
          <ListTable sx={{ minWidth: "0px" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <EmptyCell></EmptyCell>

                <SecondaryContrastColorCell>
                  {getTranslation("NAME", t, i18n)}
                </SecondaryContrastColorCell>

                <SecondaryContrastColorCell>
                  {getTranslation("CATEGORY", t, i18n)}
                </SecondaryContrastColorCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                const resource = favoritesRecentsRouteCheck
                  ? row.resource
                  : row;
                if (!resource) {
                  return <></>;
                }
                const {
                  id,
                  displayId,
                  name,
                  functionId,
                  childrenCount,
                  images,
                } = resource;
                const combinationDisplayIdAndName = displayId || name;
                const resourceFunction = getFunction(functionId);

                return (
                  <SelectedTableRow
                    onClick={() => handleNavigate(id)}
                    key={combinationDisplayIdAndName}
                  >
                    <TableCell>
                      <FlexBox>
                        <TableMedia
                          category={resourceFunction?.category}
                          resourceImages={images}
                        />
                      </FlexBox>
                    </TableCell>

                    <NameTableCell>
                      <TableCellText>
                        {combinationDisplayIdAndName}
                      </TableCellText>
                    </NameTableCell>

                    <CategoryTableCell>
                      <TableCellText>
                        {getTranslation(resourceFunction?.category, t, i18n)}
                      </TableCellText>
                    </CategoryTableCell>

                    <TableCell>
                      <AssetListTableFlexContainer>
                        {childrenCount > 0 && (
                          <ChildrenCountCellText>
                            {childrenCount}
                          </ChildrenCountCellText>
                        )}

                        <ArrowRightIcon customfontsize={globalFontSize * 1.5} />
                      </AssetListTableFlexContainer>
                    </TableCell>
                  </SelectedTableRow>
                );
              })}
            </TableBody>
          </ListTable>
        </TableContainer>
      )}
    </ErrorHandling>
  );
};

export default AssetListTable;
