import { useEffect } from "react";
import { OnboardingOrganizationPageContainer } from "../../../components/styles/onboarding/OnboardingOrganizationPage.styles";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import {
  selectOrganizationData,
  setOrganizationData,
} from "../../../store/slices/onboardingSlice";
import UploadImage from "../../../components/UploadImage";
import PageTransition from "../../../components/PageTransition";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import OrganizationDataGroup from "../../../components/inputs/OrganizationDataGroup";
import { PrimaryText } from "../../../components/styles/onboarding/Onboarding.styles";

const OnboardingOrganizationPage = ({ transitionDirection }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const organizationData = useSelector(selectOrganizationData);

  // Effects
  useEffect(() => {
    if (!organizationData.contactPerson && !organizationData.contactEmail) {
      dispatch(
        setOrganizationData({
          ...organizationData,
          contactPerson: user.firstName + " " + user.lastName,
          contactEmail: user.email,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageTransition direction={transitionDirection}>
      <PrimaryText sx={{ marginBottom: "10px" }} variant="h2" align="start">
        {getTranslation("ORGANIZATION_DATA", t, i18n)}
      </PrimaryText>

      <PrimaryText sx={{ marginBottom: "20px" }} variant="body2">
        {getTranslation("ORGANIZATION_DATA_CAPTION", t, i18n)}
      </PrimaryText>

      <OnboardingOrganizationPageContainer>
        {/* Add logo icon */}
        <UploadImage
          initialFile={organizationData.selectedFile}
          isOrganizationOnboarding={true}
          uploadTitle={organizationData.file ? "EDIT_LOGO" : "ADD_LOGO"}
        />
        {/* Organization name and phone number inputs */}
        <OrganizationDataGroup />
      </OnboardingOrganizationPageContainer>
    </PageTransition>
  );
};

export default OnboardingOrganizationPage;
