import React from "react";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import { Grid } from "@mui/material";
import {
  BiggerGridBlockCard,
  ContentContainer,
  GridBlockCard,
  HeaderBlockCard,
  LogoIcon,
  TextBlockCard,
  ThemePaper,
} from "../styles/profile/ThemeCard.styles";

const ThemeCard = ({ theme, active, handleClick }) => {
  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const headerColor = theme === "dark" ? "#000000" : "#eeeeee";
  const contentColor = theme === "dark" ? "#343434" : "#ffffff";
  const elementsColor = theme === "dark" ? "#4b4b4b" : "#eeeeee";

  return (
    <ThemePaper
      id={`theme-${theme}`}
      onClick={handleClick}
      active={active}
      backgroundcolor={contentColor}
    >
      <HeaderBlockCard backgroundcolor={headerColor}>
        <LogoIcon
          customfontsize={globalFontSize}
          src={`${process.env.PUBLIC_URL}/twinalize-icon.svg`}
          alt="logo"
        />
      </HeaderBlockCard>
      <ContentContainer backgroundcolor={elementsColor} spacing={0.5}>
        <Grid container>
          <Grid item xs={3.6}>
            <TextBlockCard className="element" />
          </Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid gap={0.5} container>
          <GridBlockCard className="element" item xs></GridBlockCard>
          <GridBlockCard className="element" item xs></GridBlockCard>
          <GridBlockCard className="element" item xs></GridBlockCard>
          <BiggerGridBlockCard
            className="element"
            item
            xs={12}
          ></BiggerGridBlockCard>
          <GridBlockCard className="element" item xs></GridBlockCard>
          <GridBlockCard className="element" item xs></GridBlockCard>
          <GridBlockCard className="element" item xs></GridBlockCard>
        </Grid>
      </ContentContainer>
    </ThemePaper>
  );
};

export default ThemeCard;
