import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import { selectUser } from "../../../store/slices/authSlice";
import {
  selectIsFirstSubmitted,
  selectResourceInput,
  setResourceInput,
} from "../../../store/slices/resourceInputSlice";
import SelectInput from "../../SelectInput";
import { Grid } from "@mui/material";
import {
  AddImageButton,
  AddImageButtonContainer,
} from "../../styles/assets/asset-attachments/ResourceImagesList.styles";
import {
  getTranslation,
  RESOURCE_CATEGORIES,
  transitionDirections,
} from "../../../util/utils";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { useGetTypesQuery } from "../../../store/slices/api/typesApiSlice";
import { NavigationActionIcon } from "../../styles/menu/Menu.styles";
import { TopSpacedSecondaryDarkBackgroundCover } from "../../styles/assets/asset-form/TypeInput.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import CreateType from "../../types/type-form/CreateType";
import EditType from "../../types/type-form/EditType";
import ErrorHandling from "../../common/ErrorHandling";
import CategoryTypeMenu from "../../types/type-form/CategoryTypeMenu";

const TypeInput = ({ required }) => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { resourceid } = useParams();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const resourceInput = useSelector(selectResourceInput);
  const isFirstSubmitted = useSelector(selectIsFirstSubmitted);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [initializedCategory, setInitializedCategory] = useState("");

  // Types based on sub type id
  const {
    data: typesData,
    isLoading,
    isError,
  } = useGetTypesQuery(
    {
      functionId: resourceInput.functionId,
      organizationId,
    },
    { skip: !Boolean(resourceInput.functionId) }
  );

  // Other variables
  const types =
    typesData?.map((type) => ({
      label: type.description,
      value: type.id,
    })) ?? [];

  const isSystemType = !typesData?.find((c) => c.id === resourceInput.typeId)
    ?.organizationId;

  const openCategoryMenu = Boolean(anchorEl);

  // Handlers
  const handleChangeTypeId = (value) => {
    const newTypeId = value;
    dispatch(
      setResourceInput({
        ...resourceInput,
        typeId: newTypeId,
        hasTypeChanged: true,
        characteristics: [],
      })
    );
  };

  const handleCreateResourceType = (typeId, category, functionId) => {
    dispatch(
      setResourceInput({
        ...resourceInput,
        typeId,
        category,
        functionId,
        hasTypeChanged: true, // set to notify that created type will be pre-populated
      })
    );
  };

  const handleEditResourceType = (category, functionId) => {
    dispatch(
      setResourceInput({
        ...resourceInput,
        category,
        functionId,
      })
    );
  };

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleNavigateToCreateType = (category) => {
    navigate(
      `/library/add-new?category=${category}&direction=${
        transitionDirections.BOTTOM_TO_TOP
      }${resourceid ? `&resourceid=${resourceid}` : ""}`
    );
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCreateType = (category) => {
    if (!mobileMatches) {
      setInitializedCategory(category);
      handleOpenCreate();
    } else {
      handleNavigateToCreateType(category);
    }
  };

  const handleEditType = () => {
    if (!isSystemType) {
      if (!mobileMatches) {
        handleOpenEdit();
      } else {
        navigate(
          `/library/${resourceInput.typeId}/edit?category=${
            resourceInput.category
          }&direction=${transitionDirections.BOTTOM_TO_TOP}${
            resourceid ? `&resourceid=${resourceid}` : ""
          }`
        );
      }
    }
  };

  const handleOpenCategoryMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleCloseCategoryMenu = (e) => {
    setAnchorEl(null);
  };

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <CategoryTypeMenu
        open={openCategoryMenu}
        anchorEl={anchorEl}
        handleClose={handleCloseCategoryMenu}
        categoryActionHandler={handleCreateType}
      />

      <div data-testid={types[0]?.label}>
        {openCreate && (
          <CreateType
            open={openCreate}
            setOpen={setOpenCreate}
            resourceTypeHandler={handleCreateResourceType}
            category={initializedCategory}
          />
        )}

        {openEdit && (
          <EditType
            typeId={resourceInput.typeId}
            open={openEdit}
            setOpen={setOpenEdit}
            resourceTypeHandler={handleEditResourceType}
          />
        )}

        <Grid container alignItems="center" spacing={1}>
          <Grid item xs>
            <SelectInput
              fullWidth
              selectLabelId="type-label"
              label={getTranslation("TYPE", t, i18n)}
              value={resourceInput.typeId}
              handleChange={handleChangeTypeId}
              data={types}
              error={
                isFirstSubmitted &&
                !resourceInput.typeId &&
                Boolean(resourceInput.category) &&
                resourceInput.category !== RESOURCE_CATEGORIES.LOCATION
              }
              required={required}
              isSelect={false}
              disabled={
                !resourceInput.functionId ||
                resourceInput.category === RESOURCE_CATEGORIES.LOCATION
              }
            />
          </Grid>

          {resourceInput.typeId ? (
            <Grid item>
              <TopSpacedSecondaryDarkBackgroundCover
                sx={{ backgroundColor: "transparent" }}
                data-testid="edit-type"
                id="edit-type"
              >
                <AddImageButtonContainer>
                  <AddImageButton
                    onClick={handleEditType}
                    disabled={isSystemType}
                    align="center"
                  >
                    <NavigationActionIcon customfontsize={globalFontSize}>
                      edit
                    </NavigationActionIcon>
                  </AddImageButton>
                </AddImageButtonContainer>
              </TopSpacedSecondaryDarkBackgroundCover>
            </Grid>
          ) : (
            <Grid item>
              <TopSpacedSecondaryDarkBackgroundCover
                sx={{ backgroundColor: "transparent" }}
                data-testid="add-type"
                id="add-type"
              >
                <AddImageButtonContainer>
                  <AddImageButton
                    onClick={handleOpenCategoryMenu}
                    disabled={
                      resourceInput.category === RESOURCE_CATEGORIES.LOCATION
                    }
                    align="center"
                  >
                    <NavigationActionIcon customfontsize={globalFontSize}>
                      add
                    </NavigationActionIcon>
                  </AddImageButton>
                </AddImageButtonContainer>
              </TopSpacedSecondaryDarkBackgroundCover>
            </Grid>
          )}
        </Grid>
      </div>
    </ErrorHandling>
  );
};

export default TypeInput;
