import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ErrorHandling from "../../common/ErrorHandling";
import {
  DEFAULT_SORTING_OPTION,
  selectGlobalFontSize,
} from "../../../store/slices/appSlice";
import {
  Grid,
  ListItemIcon,
  TableBody,
  TableCell,
  TableContainer,
  useMediaQuery,
} from "@mui/material";
import {
  FlexBox,
  FlexContainer,
  ListTable,
  NameTableCell,
  Row,
  SelectedTableRow,
  TableCellText,
} from "../../styles/assets/asset-list/AssetListTable.styles";
import { useTranslation } from "react-i18next";
import InfiniteScrollLoader from "../../InfiniteScrollLoader";
import {
  LoadMoreWrapper,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/types/type-list/TypeListPage.styles";
import {
  NoDataTextContainer,
  SecondaryContrastButton,
} from "../../styles/general/General.styles";
import { getTranslation, transitionDirections } from "../../../util/utils";
import { useGetTypeRecentsQuery } from "../../../store/slices/api/typesApiSlice";
import { NavigationActionIcon } from "../../styles/menu/Menu.styles";
import { useGetAllFunctionsQuery } from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  ArrowRightIcon,
  Item,
  ItemContainer,
  ItemName,
  SecondaryText,
} from "../../styles/assets/ListInlineView.styles";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import { getComparator, stableSort } from "../../../util/reports-utils";
import { useNavigate } from "react-router-dom";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { CreateAssetButton } from "../../styles/assets/AssetList.styles";
import {
  LocalLibraryOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";

const PAGE_SIZE = 20;

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "NAME",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "CATEGORY",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: false,
    label: "resource.createdBy",
  },
];

const getIconCreationType = (organizationId, globalFontSize) => {
  return organizationId ? (
    <NavigationActionIcon
      id="user-created-icon"
      className="material-icons material-icons-outlined"
      customfontsize={globalFontSize * 1.25}
    >
      person_outlined
    </NavigationActionIcon>
  ) : (
    <NavigationActionIcon
      id="system-created-icon"
      className="material-icons material-icons-outlined"
      customfontsize={globalFontSize * 1.25}
    >
      local_library
    </NavigationActionIcon>
  );
};

const getIconCreationTypeDesktop = (organizationId) => {
  return organizationId ? (
    <PersonOutlineOutlined
      id="user-created-icon"
      sx={{ width: 50, height: 50 }}
    />
  ) : (
    <LocalLibraryOutlined
      id="system-created-icon"
      sx={{ width: 50, height: 50 }}
    />
  );
};

const RecentTypesAccordion = ({ handleClickItem, currentTypeId }) => {
  // General hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const user = useSelector(selectUser);
  const organizationId = user?.organizations.find((o) => o.default)?.id;

  // States
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(headCells[0].id);
  const [expand, setExpand] = useState(true);
  const [items, setItems] = useState([]);
  const [index, setIndex] = useState(1);
  const [fetchingItems, setFetchingItems] = useState(false);
  const [sortingOption] = useState(DEFAULT_SORTING_OPTION);

  // Queries
  const {
    data: typeRecentsData,
    isLoading: isLoadingTypeRecents,
    isFetching: isFetchingRecents,
    isError: isErrorRecents,
  } = useGetTypeRecentsQuery({
    organizationId,
    index,
    size: PAGE_SIZE,
    order: sortingOption.order,
    sortBy: getSortByType(),
  });

  const { data: allFunctionsData } = useGetAllFunctionsQuery({
    organizationId: user?.organizations.find((o) => o.default).id,
  });

  // Other variables
  const recents = typeRecentsData?.data;
  const totalRecord = typeRecentsData?.totalRecord;
  const fetching = isFetchingRecents || fetchingItems;
  const pageCount = typeRecentsData?.pageCount;

  const sortedItems = useMemo(() => {
    return stableSort(items, getComparator(order, orderBy));
  }, [order, orderBy, items]);

  // Handlers
  function getSortByType() {
    if (sortingOption.type === "displayIdAndNameCombination") {
      return "name";
    } else if (sortingOption.type === "favoured") {
      return "createdAt";
    } else {
      return sortingOption.type;
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const toggleExpand = () => {
    setExpand(!expand);
  };

  const fetchMoreData = () => {
    setFetchingItems(true);
    setTimeout(() => {
      setIndex(index + 1);
      setFetchingItems(false);
    }, 1000);
  };

  const handleNavigateToLibrary = () => {
    if (desktopMatches) {
      navigate(`/library`);
    } else {
      navigate(`/library?direction=${transitionDirections.RIGHT_TO_LEFT}`);
    }
  };

  // Effects
  useEffect(() => {
    if (typeRecentsData && allFunctionsData) {
      const newItems = [...items];
      const mappedTypes = recents.map((recent) => {
        const type = recent.type;
        const category = allFunctionsData.find(
          (f) => f.id === type.functionId
        )?.category;

        const createdBy = type.organizationId
          ? getTranslation("USER_CREATED_TYPE", t, i18n)
          : getTranslation("SYSTEM_CREATED_TYPE", t, i18n);

        return {
          ...type,
          category,
          createdBy,
        };
      });

      newItems.push(
        ...mappedTypes.filter((t) => !items.some((i) => i.id === t.id))
      );

      setItems(newItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeRecentsData, allFunctionsData]);

  return (
    <ErrorHandling isLoading={isLoadingTypeRecents} isError={isErrorRecents}>
      <StyledAccordion
        id="library-accordion"
        expanded={expand}
        onChange={toggleExpand}
      >
        <StyledAccordionSummary
          expandIcon={
            <NavigationActionIcon customfontsize={globalFontSize}>
              keyboard_arrow_right
            </NavigationActionIcon>
          }
        >
          <SecondaryText>{`${getTranslation("LIBRARY", t, i18n)} (${
            sortedItems.length
          }/${totalRecord})`}</SecondaryText>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {sortedItems.length > 0 ? (
            desktopMatches ? (
              <TableContainer>
                <ListTable sx={{ minWidth: "0px" }} aria-label="simple table">
                  <EnhancedTableHead
                    headCells={headCells}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {sortedItems.map((type) => {
                      const { id, name, createdBy, category, organizationId } =
                        type;

                      return (
                        <SelectedTableRow
                          isselected={currentTypeId === id}
                          key={id}
                          onClick={() => handleClickItem(id)}
                        >
                          <TableCell>
                            <FlexBox>
                              {getIconCreationTypeDesktop(organizationId)}
                            </FlexBox>
                          </TableCell>
                          <NameTableCell>
                            <TableCellText isSelected={currentTypeId === id}>
                              {name}
                            </TableCellText>
                          </NameTableCell>
                          <TableCell>
                            <TableCellText>
                              {getTranslation(category, t, i18n)}
                            </TableCellText>
                          </TableCell>
                          <TableCell>
                            <TableCellText>{createdBy}</TableCellText>
                          </TableCell>
                          <TableCell>
                            <FlexContainer>
                              <ArrowRightIcon
                                customfontsize={globalFontSize * 1.5}
                              />
                            </FlexContainer>
                          </TableCell>
                        </SelectedTableRow>
                      );
                    })}
                  </TableBody>
                </ListTable>
              </TableContainer>
            ) : (
              <Grid
                container
                display="flex"
                justifyContent="start"
                columns={36}
                rowGap={tabletMatches ? 2 : 0}
              >
                {sortedItems?.map((row) => {
                  const { id, name, organizationId } = row;

                  return (
                    <React.Fragment key={`type-${id}`}>
                      <Grid item xs={0} sm={1}></Grid>

                      <ItemContainer item xs={36} sm={10}>
                        <Item onClick={() => handleClickItem(id)}>
                          <ListItemIcon>
                            {getIconCreationType(
                              organizationId,
                              globalFontSize
                            )}
                          </ListItemIcon>
                          <ItemName>{name}</ItemName>
                          <ArrowRightIcon />
                        </Item>
                      </ItemContainer>

                      <Grid item xs={0} sm={1}></Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            )
          ) : (
            <NoDataTextContainer>
              <SecondaryText align="center">
                {getTranslation("NO_RECENT_LIBRARY", t, i18n)}
              </SecondaryText>
              <CreateAssetButton onClick={handleNavigateToLibrary}>
                {getTranslation("OPEN_LIBRARY", t, i18n)}
              </CreateAssetButton>
            </NoDataTextContainer>
          )}
          {fetching ? (
            <InfiniteScrollLoader />
          ) : (
            <>
              {index < pageCount && (
                <LoadMoreWrapper>
                  <SecondaryContrastButton onClick={fetchMoreData}>
                    {getTranslation("LOAD_MORE", t, i18n)}
                  </SecondaryContrastButton>
                </LoadMoreWrapper>
              )}
            </>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
    </ErrorHandling>
  );
};

export default RecentTypesAccordion;
