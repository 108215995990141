import { List, ListItem, ListItemButton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectInputValue } from "../../../store/slices/tagsSlice";
import { HighlightedText } from "../../styles/assets/tags/Tags.styles";

const BottomTagResults = ({ includingTags, handleClickTag }) => {
  // Selectors
  const inputValue = useSelector(selectInputValue);

  // Handlers
  const handleGetBottomResultsStartText = (tag) =>
    tag.label.substring(
      0,
      tag.label.toLowerCase().indexOf(inputValue.toLowerCase())
    );

  const handleGetBottomResultsMiddleText = (tag) =>
    tag.label.substring(
      tag.label.toLowerCase().indexOf(inputValue.toLowerCase()),
      tag.label.toLowerCase().indexOf(inputValue.toLowerCase()) +
        inputValue.length
    );

  const handleGetBottomResultsEndText = (tag) =>
    tag.label.substring(
      tag.label.toLowerCase().indexOf(inputValue.toLowerCase()) +
        inputValue.length
    );

  return (
    <List>
      {includingTags.map((tag) => (
        <ListItem key={"bottom-" + tag.value} disablePadding>
          <ListItemButton onMouseDown={() => handleClickTag(tag)}>
            <Typography>{handleGetBottomResultsStartText(tag)}</Typography>
            <HighlightedText>
              {handleGetBottomResultsMiddleText(tag)}
            </HighlightedText>
            <Typography>{handleGetBottomResultsEndText(tag)}</Typography>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default BottomTagResults;
