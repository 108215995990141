import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouletteSpinnerOverlay } from "react-spinner-overlay";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  useAddFavoriteMutation,
  useDeleteFavoriteMutation,
  useGetAllFunctionsQuery,
  useGetFavoritesQuery,
} from "../../../store/slices/api/assetManagementSlice";
import {
  DEFAULT_INDEX,
  selectGlobalFontSize,
  selectTheme,
  setIndex,
} from "../../../store/slices/appSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  AssetHeaderText,
  FavoriteIcon,
  NotFavoriteIcon,
  AssetDetailCardMedia,
  AssetDetailCardMediaHeaderContainer,
  AssetDetailCardMediaHeaderTextContainer,
  HiddenFavoriteIcon,
} from "../../styles/assets/asset-detail/AssetDetailCard.styles";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CarouselImage from "./CarouselImage";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  getFallbackIcon,
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  RESOURCE_CATEGORIES,
  transitionDirections,
} from "../../../util/utils";
import useOrientation from "../../../hooks/useOrientation";
import { useTranslation } from "react-i18next";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import {
  EditButton,
  EditButtonWrapper,
} from "../../styles/general/General.styles";
import ErrorHandling from "../../common/ErrorHandling";
import { NavigationActionIcon } from "../../styles/menu/Menu.styles";

const AssetDetailCard = ({
  resourceData,
  resourceImages,
  handleClick,
  userRoles,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const currentTheme = useSelector(selectTheme);
  const { pathname } = useLocation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET) ? 1 : 0;
  const { t, i18n } = useTranslation();

  // Custom hooks
  const orientation = useOrientation();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Queries
  const {
    data: favorites,
    isLoading: isLoadingFavorites,
    isSuccess: isSuccessFavorites,
    isError: isErrorFavorites,
  } = useGetFavoritesQuery({
    organizationId,
  });

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isSuccess: isSuccessFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Mutations
  const [addFavorite, { isLoading: isLoadingAddFavorite }] =
    useAddFavoriteMutation();
  const [deleteFavorite, { isLoading: isLoadingDeleteFavorite }] =
    useDeleteFavoriteMutation();

  const [currentIndex, setCurrentIndex] = useState(0);

  // Other variables
  const { id: resourceid, name } = resourceData;
  const isfavorite = favorites?.data?.some((f) => f.resource.id === resourceid);
  const slidesToShow = tabletMatches && orientation === "landscape" ? 2 : 1;

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
  };

  const functionDetails = allFunctionsData?.find(
    (f) => f.id === resourceData.functionId
  );

  // Handlers
  const handleAddFavorite = async (e) => {
    e.stopPropagation();

    try {
      await addFavorite({
        resourceid,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      }).unwrap();

      dispatch(setIndex(DEFAULT_INDEX));
    } catch (error) {
      console.error("Failed to add favorite", error);
    }
  };

  const handleDeleteFavorite = async (e) => {
    e.stopPropagation();

    try {
      await deleteFavorite({
        resourceid,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      }).unwrap();

      dispatch(setIndex(DEFAULT_INDEX));
    } catch (error) {
      console.error("Failed to delete favorite", error);
    }
  };

  const handleImageChange = (oldIndex, newIndex) => {
    setCurrentIndex(newIndex);
  };

  return (
    <ErrorHandling
      isLoading={
        isLoadingFavorites ||
        isLoadingFunctions ||
        isLoadingAddFavorite ||
        isLoadingDeleteFavorite
      }
      isError={isErrorFavorites || isErrorFunctions}
    >
      <div onClick={handleClick ? handleClick : null}>
        {resourceImages && resourceImages.length > 0 ? (
          <Slider
            className={"carousel-theme--" + currentTheme}
            id="carousel-list"
            beforeChange={handleImageChange}
            {...settings}
          >
            {resourceImages?.map((resourceImage, index) => (
              <CarouselImage
                id="carousel-image"
                key={resourceImage.id}
                resourceImage={resourceImage}
                currentIndex={currentIndex}
                slidesToShow={slidesToShow}
                index={index}
              />
            ))}
          </Slider>
        ) : (
          <AssetDetailCardMedia
            id="asset-image-big"
            component="img"
            image={`${process.env.PUBLIC_URL}/icons/${getFallbackIcon(
              functionDetails?.category
            )}`}
          ></AssetDetailCardMedia>
        )}
        <AssetDetailCardMediaHeaderContainer>
          {!tabletMatches && (
            <HiddenFavoriteIcon
              customfontsize={globalFontSize * 1.5}
              data-testid="StarIconEmpty"
            />
          )}
          {!tabletMatches ? (
            <>
              <AssetDetailCardMediaHeaderTextContainer>
                <AssetHeaderText
                  variant="h5"
                  istablet={tabletMatches}
                  id="asset-name"
                >
                  {name}
                </AssetHeaderText>
              </AssetDetailCardMediaHeaderTextContainer>
              {isfavorite ? (
                <FavoriteIcon
                  customfontsize={globalFontSize * 1.5}
                  onClick={handleDeleteFavorite}
                  istablet={tabletMatches}
                />
              ) : (
                <NotFavoriteIcon
                  customfontsize={globalFontSize * 1.5}
                  onClick={handleAddFavorite}
                  istablet={tabletMatches}
                />
              )}
            </>
          ) : (
            <EditButtonWrapper>
              {hasAccess(
                "all",
                [
                  permissions[
                    `ASSET_MANAGEMENT_${
                      functionDetails?.category ===
                      RESOURCE_CATEGORIES.HARDWARE_ASSET
                        ? "HARDWARE"
                        : functionDetails?.category
                    }_EDIT`
                  ],
                ],
                getPermissionsFromUserRoles(userRoles)
              ) && (
                <EditButton
                  startIcon={
                    <NavigationActionIcon
                      active={true}
                      customfontsize={globalFontSize}
                    >
                      edit
                    </NavigationActionIcon>
                  }
                  onClick={() =>
                    navigate(
                      `/resources/${resourceData.id}/edit?direction=${transitionDirections.RIGHT_TO_LEFT}`
                    )
                  }
                >
                  <Typography>{getTranslation("EDIT", t, i18n)}</Typography>
                </EditButton>
              )}
            </EditButtonWrapper>
          )}
        </AssetDetailCardMediaHeaderContainer>
      </div>
    </ErrorHandling>
  );
};

export default AssetDetailCard;
