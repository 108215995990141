import { Box, Grid, List, Paper, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/system";

const getMinWidth = (
  isdesktop,
  isfullwidth,
  islandscape,
  isgraphicalactivetab,
  isdetailstab
) => {
  let minWidth;
  if (isdesktop) {
    if (isfullwidth) {
      minWidth = 100;
    } else {
      minWidth = 16;
    }
  } else {
    if (islandscape) {
      minWidth = 25;
    } else {
      minWidth = 33;
    }
  }

  if ((isgraphicalactivetab || isdetailstab) && !isfullwidth) {
    minWidth *= 2;
  }
  return `${minWidth}%`;
};

export const ListColumnViewCardContainer = styled(Grid)(
  ({
    theme,
    isdesktop,
    isfullwidth,
    islandscape,
    isgraphicalactivetab,
    israck,
    isdetailstab,
  }) => ({
    paddingInline: "8px",
    minWidth: getMinWidth(
      isdesktop,
      isfullwidth,
      islandscape,
      isgraphicalactivetab,
      isdetailstab
    ),
    display: "flex",
    flexDirection: "column",
    minHeight: `calc(100vh - 110px)`,
    maxHeight: `calc(100vh - 110px)`,
    borderRight: isfullwidth
      ? ""
      : `2px solid ${
          theme.palette.mode === "light"
            ? "#EEEEEE"
            : theme.palette.secondary.dark
        }`,
    overflowY: isgraphicalactivetab ? "hidden" : "auto",
    transition: israck
      ? "flex-basis 1s ease-in-out 0ms, min-width 1s ease-in-out 0ms,  max-width 1s ease-in-out 0ms"
      : "",
  })
);

export const TabsContainer = styled(Tabs)(({ theme, fitScreen }) => ({
  ".MuiTabs-indicator": {
    backgroundColor: theme.palette.secondary.contrastText,
  },
  position: fitScreen ? "relative" : "static",
  zIndex: fitScreen ? 100 : 0,
  minHeight: "36px",
  height: "36px",
}));

export const TabsWrapper = styled(Paper)(
  ({ theme, isfullwidth, isdesktop, islandscape, isGraphicalView }) => ({
    maxWidth: isGraphicalView
      ? "275px"
      : isfullwidth
      ? isdesktop
        ? "20%"
        : islandscape
        ? "25%"
        : "33%"
      : "100%",
  })
);

export const StyledTab = styled(Tab)(({ theme, active, currentValue }) => ({
  "&.MuiTab-textColorPrimary": {
    color: active ? theme.palette.secondary.contrastText : "",
  },
  fontSize: "10px",
  maxWidth: currentValue === 2 ? "70px" : "60px",
  minWidth: "60px",
  minHeight: "36px",
  height: "36px",
}));

export const SliderWrapper = styled(Box)({
  overflowX: "hidden",
});

export const ListContainer = styled(List)({
  flexGrow: 1,
});
