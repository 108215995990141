import { List } from "@mui/material";
import AssetDetailBasicInfoColumnVersion from "./AssetDetailBasicInfoColumnVersion";
import AssetDetailCharacteristicGroupColumnVersion from "./AssetDetailCharacteristicGroupColumnVersion";
import AssetDetailLibraryInfoColumnVersion from "./AssetDetailLibraryInfoColumnVersion";
import ResourceAttachmentList from "../asset-attachments/ResourceAttachmentList";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import { useGetResourceAttachmentsQuery } from "../../../store/slices/api/resourceAttachmentsApiSlice";
import ErrorHandling from "../../common/ErrorHandling";
import AssetDetailTagList from "../tags/AssetDetailTagList";

const AssetDetailListColumnVersion = ({ resourceData, functionsData }) => {
  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  // Queries
  const {
    data: resourceAttachmentsData,
    isLoading: isLoadingAttachments,
    isError: isErrorAttachments,
  } = useGetResourceAttachmentsQuery(
    {
      resourceid: resourceData.id,
      organizationId,
    },
    { skip: !Boolean(resourceData) }
  );

  return (
    <ErrorHandling
      isLoading={isLoadingAttachments}
      isError={isErrorAttachments}
    >
      <List key={"asset-detail-main-list"} className="no-padding">
        <AssetDetailTagList resourceData={resourceData} />

        <AssetDetailBasicInfoColumnVersion
          functionsData={functionsData}
          resourceData={resourceData}
        />

        {resourceData?.type && (
          <AssetDetailLibraryInfoColumnVersion resourceData={resourceData} />
        )}

        <AssetDetailCharacteristicGroupColumnVersion
          resourceCharacteristics={resourceData?.characteristics}
          typeCharacteristics={resourceData?.type?.characteristics}
          functionId={resourceData?.functionId}
          resourceParentId={resourceData?.parent?.id}
        />

        {/* Resource attachments */}
        <ResourceAttachmentList attachments={resourceAttachmentsData} />
      </List>
    </ErrorHandling>
  );
};

export default AssetDetailListColumnVersion;
