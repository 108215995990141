import { List } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { getTranslation } from "../../../util/utils";
import InfiniteScrollLoader from "../../InfiniteScrollLoader";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import { SecondaryContrastButton } from "../../styles/general/General.styles";
import { NavigationActionIcon } from "../../styles/menu/Menu.styles";
import {
  AccordionHeaderContainer,
  LoadMoreWrapper,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/types/type-list/TypeListPage.styles";
import TypeItem from "./TypeItem";
import FavoriteTypeItem from "./FavoriteTypeItem";
import { setTypeId } from "../../../store/slices/typeSlice";

const TypeAccordionSection = ({
  sectionName,
  endAdornment,
  items,
  totalRecord,
  pageSize,
  index,
  isFetchingData,
  fetchMoreDataHandler,
  prefixLabelItem,
  isFavorites,
}) => {
  //General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [expand, setExpand] = useState(true);
  const [activeTypeId, setActiveTypeId] = useState(null);

  // Other variables
  const fetchedItemsSize = items?.length;
  const itemsPerPage = totalRecord / pageSize;

  // Handle Variables
  const handleCurrentType = (typeId) => {
    setActiveTypeId(typeId);
    dispatch(setTypeId(typeId));
  };

  return (
    <StyledAccordion expanded={expand} onChange={() => setExpand(!expand)}>
      <StyledAccordionSummary
        expandIcon={
          <NavigationActionIcon customfontsize={globalFontSize}>
            keyboard_arrow_right
          </NavigationActionIcon>
        }
      >
        <AccordionHeaderContainer>
          <SecondaryText>
            {`${sectionName} (${fetchedItemsSize}/${totalRecord})`}
          </SecondaryText>
          {endAdornment}
        </AccordionHeaderContainer>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <List id={`${prefixLabelItem}-type-list`}>
          {items.map((row, i) => {
            const { id } = row;

            return isFavorites ? (
              <FavoriteTypeItem
                key={`${prefixLabelItem}-${id}`}
                type={row}
                lastIndex={i === fetchedItemsSize - 1}
                currentTypeId={activeTypeId}
                currentTypeHandler={handleCurrentType}
              />
            ) : (
              <TypeItem
                key={`${prefixLabelItem}-${id}`}
                type={row}
                lastIndex={i === fetchedItemsSize - 1}
                currentTypeId={activeTypeId}
                currentTypeHandler={handleCurrentType}
              />
            );
          })}
        </List>
        {isFetchingData ? (
          <InfiniteScrollLoader />
        ) : (
          index < itemsPerPage && (
            <LoadMoreWrapper>
              <SecondaryContrastButton
                variant="outlined"
                onClick={fetchMoreDataHandler}
              >
                {getTranslation("LOAD_MORE", t, i18n)}
              </SecondaryContrastButton>
            </LoadMoreWrapper>
          )
        )}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default TypeAccordionSection;
