import { Box, Checkbox, Paper, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";
import { StyledFormControlLabel } from "../../../styles/general/General.styles";

const LinkWidgetForm = () => {
  // States
  const [title, setTitle] = useState();
  const [url, setUrl] = useState();
  const [displayHome, setDisplayHome] = useState(false);

  // Handlers
  const handleChangeUrl = (e) => {
    const newUrl = e.target.value;
    setUrl(newUrl);
  };

  const handleChangeTitle = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
  };

  const handleChangeDisplayHome = (e) => {
    const newDisplayHome = e.target.checked;
    setDisplayHome(newDisplayHome);
  };

  return (
    <Stack spacing={3}>
      <Box>
        <SecondaryText gutterBottom>Configure Link</SecondaryText>
        <Box sx={{ flexGrow: 1 }}>
          <TextField
            label="Title"
            id="title"
            value={title}
            size="small"
            onChange={handleChangeTitle}
          />
          <TextField
            label="Url"
            id="url"
            value={url}
            size="small"
            onChange={handleChangeUrl}
          />
        </Box>
      </Box>
      <StyledFormControlLabel
        control={
          <Checkbox checked={displayHome} onChange={handleChangeDisplayHome} />
        }
        label="Show on homescreen"
      />
      <Box>
        <SecondaryText gutterBottom>Preview</SecondaryText>
        <Paper
          sx={{
            height: "180px",
            backgroundColor: "#F1F1F1",
          }}
        >
          <SecondaryText
            sx={{ padding: "8px" }}
            variant="body2"
            textAlign="center"
          >
            {title}
          </SecondaryText>
        </Paper>
      </Box>
    </Stack>
  );
};

export default LinkWidgetForm;
