import {
  add_and_delete_type_favorite,
  add_type_recent,
  create_type,
  delete_type,
  get_types,
  get_types_with_pagination,
  get_type_characteristics,
  get_type_details,
  get_type_favorites,
  get_type_image,
  get_type_images,
  get_type_recents,
  update_type,
} from "../../../Constants";
import KeycloakService from "../../../services/KeycloakService";
import { baseApiSlice } from "./baseApiSlice";

export const typesApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTypes: builder.query({
      query: ({ functionId, organizationId }) => ({
        url: get_types.replace(":organizationId", organizationId),
        params: { function_id: functionId },
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["TYPES"],
    }),
    getTypesWithPagination: builder.query({
      query: ({
        functionId,
        organizationId,
        index,
        size,
        sortBy,
        order,
        query,
      }) => ({
        url: get_types_with_pagination.replace(
          ":organizationId",
          organizationId
        ),
        params: {
          function_id: functionId,
          index,
          size,
          order,
          q: query,
          sort_by: sortBy,
        },
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["TYPES"],
    }),
    getTypeCharacteristics: builder.query({
      query: ({ typeId, organizationId }) => ({
        url: get_type_characteristics
          .replace(":typeId", typeId)
          .replace(":organizationId", organizationId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["TYPE_CHARACTERISTICS"],
    }),
    getTypeDetails: builder.query({
      query: ({ typeId, organizationId }) => ({
        url: get_type_details
          .replace(":organizationId", organizationId)
          .replace(":typeId", typeId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["TYPES"],
    }),
    getTypeImages: builder.query({
      query: ({ typeId, organizationId }) => ({
        url: get_type_images
          .replace(":organizationId", organizationId)
          .replace(":typeId", typeId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
    }),
    getTypeImage: builder.query({
      query: ({ imageUri, organizationId }) => ({
        url: get_type_image
          .replace(":organizationId", organizationId)
          .replace(":imageUri", imageUri),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
          accept: "image/png",
        },
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: async (response) => {
        // Use response.blob() to create a Blob object
        const blob = await response;
        const objectURL = URL.createObjectURL(blob);
        return objectURL;
      },
    }),
    getTypeFavorites: builder.query({
      query: ({ organizationId, index, size, order, sortBy }) => ({
        url: get_type_favorites.replace(":organizationId", organizationId),
        params: {
          index,
          size,
          sort_by: sortBy,
          order,
        },
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["FAVORITE_TYPES"],
    }),
    getTypeRecents: builder.query({
      query: ({ organizationId, index, size, order, sortBy }) => ({
        url: get_type_recents.replace(":organizationId", organizationId),
        params: {
          index,
          size,
          order,
          sort_by: sortBy,
        },
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["RECENT_TYPES"],
    }),
    addTypeFavorite: builder.mutation({
      query: ({ typeId, organizationId }) => ({
        url: add_and_delete_type_favorite
          .replace(":organizationId", organizationId)
          .replace(":typeId", typeId),
        method: "POST",
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["FAVORITE_TYPES", "TYPES"],
    }),
    deleteTypeFavorite: builder.mutation({
      query: ({ typeId, organizationId }) => ({
        url: add_and_delete_type_favorite
          .replace(":organizationId", organizationId)
          .replace(":typeId", typeId),
        method: "DELETE",
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["FAVORITE_TYPES", "TYPES"],
    }),
    addTypeRecent: builder.mutation({
      query: ({ organizationId, typeId }) => ({
        url: add_type_recent
          .replace(":organizationId", organizationId)
          .replace(":typeId", typeId),
        method: "POST",
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["RECENT_TYPES"],
    }),
    createType: builder.mutation({
      query: ({ typeInput, organizationId }) => ({
        url: create_type.replace(":organizationId", organizationId),
        method: "POST",
        body: typeInput,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
          "content-type": "application/json",
        },
      }),
      invalidatesTags: ["TYPES", "FUNCTIONS"],
    }),
    updateType: builder.mutation({
      query: ({ typeInput, organizationId, typeId }) => ({
        url: update_type
          .replace(":organizationId", organizationId)
          .replace(":typeId", typeId),
        method: "PUT",
        body: typeInput,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
          "content-type": "application/json",
        },
      }),
      invalidatesTags: ["TYPES", "TYPE_CHARACTERISTICS", "DETAILS"],
    }),
    deleteType: builder.mutation({
      query: ({ typeId, organizationId }) => ({
        url: delete_type
          .replace(":organizationId", organizationId)
          .replace(":typeId", typeId),
        method: "DELETE",
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["TYPES", "FUNCTIONS", "FAVORITE_TYPES", "RECENT_TYPES"],
    }),
  }),
});

export const {
  useGetTypesQuery,
  useGetTypesWithPaginationQuery,
  useGetTypeDetailsQuery,
  useGetTypeCharacteristicsQuery,
  useGetTypeImagesQuery,
  useGetTypeImageQuery,
  useGetTypeFavoritesQuery,
  useGetTypeRecentsQuery,
  useAddTypeFavoriteMutation,
  useDeleteTypeFavoriteMutation,
  useAddTypeRecentMutation,
  useCreateTypeMutation,
  useUpdateTypeMutation,
  useDeleteTypeMutation,
} = typesApiSlice;
