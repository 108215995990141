import { memo, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetAllCharacteristicsQuery,
  useGetAllFunctionsQuery,
  useGetCharacteristicSetTemplatesQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { measurementDisplay, RESOURCE_CATEGORIES } from "../../../util/utils";
import {
  selectOriginalInput,
  setCharacteristics,
} from "../../../store/slices/resourceInputSlice";
import { Stack } from "@mui/material";
import { useGetTypeCharacteristicsQuery } from "../../../store/slices/api/typesApiSlice";
import {
  groupCharacteristics,
  setUpCharacteristics,
} from "../../../util/asset-utils";
import ErrorHandling from "../../common/ErrorHandling";
import CharacteristicSetTemplateAccordion from "./CharacteristicSetTemplateAccordion";

const AssetCharacteristicInputGroup = ({
  characteristics: resourceCharacteristics,
  functionId,
  typeId,
  hasTypeChanged,
}) => {
  // General hooks
  const dispatch = useDispatch();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default).id;
  const originalInput = useSelector(selectOriginalInput);

  // Other variables
  const { characteristics: originalCharacteristics } = originalInput;

  // Queries
  const {
    data: setTemplatesData,
    isLoading: isLoadingSetTemplates,
    isSuccess: isSuccessSetTemplates,
    isError: isErrorSetTemplates,
  } = useGetCharacteristicSetTemplatesQuery(
    {
      resourceFunctionId: functionId,
      organizationId,
    },
    { skip: !Boolean(functionId) }
  );

  const {
    data: characteristicDefinitionsData,
    isLoading: isLoadingCharacteristicDefinitions,
    isError: isErrorCharacteristicDefinitions,
  } = useGetAllCharacteristicsQuery({
    organizationId,
  });

  const {
    data: functionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Type characteristics data
  const {
    data: typeCharacteristicsData,
    isLoading: isLoadingTypeCharacteristics,
    isSuccess: isSuccessTypeCharacteristics,
    isError: isErrorTypeCharacteristics,
  } = useGetTypeCharacteristicsQuery(
    {
      typeId: typeId,
      organizationId,
    },
    {
      skip: !Boolean(typeId),
    }
  );

  // Other variables
  const characteristicGroup = useMemo(() => {
    if (characteristicDefinitionsData?.length > 0) {
      return groupCharacteristics(
        resourceCharacteristics ?? [],
        setTemplatesData ?? []
      );
    }

    return { setTemplates: [], other: [] };
  }, [
    resourceCharacteristics,
    setTemplatesData,
    characteristicDefinitionsData,
  ]);

  // Effects
  useEffect(() => {
    let allCharacteristics = [];

    const hasSetTemplatesLoaded =
      functionId && setTemplatesData && isSuccessSetTemplates;
    const hasCategoryLocation =
      functionId &&
      functionsData?.find((f) => f.id === Number(functionId))?.category ===
        RESOURCE_CATEGORIES.LOCATION;
    const hasTypeCharacteristicsLoaded =
      typeCharacteristicsData && isSuccessTypeCharacteristics;
    const hasCharacteristicDefinitionsLoaded = characteristicDefinitionsData;

    if (
      !hasCharacteristicDefinitionsLoaded ||
      !hasSetTemplatesLoaded ||
      (!hasTypeCharacteristicsLoaded && !hasCategoryLocation)
    ) {
      dispatch(setCharacteristics(allCharacteristics));

      return;
    }

    const setTemplateCharacteristicIds = setTemplatesData?.reduce(
      (acc, setTemplate) => {
        return [...acc, ...setTemplate.characteristics];
      },
      []
    );

    allCharacteristics = setUpCharacteristics(
      typeCharacteristicsData ?? [],
      setTemplateCharacteristicIds ?? [],
      characteristicDefinitionsData ?? [],
      user?.region
    );

    if (!hasTypeChanged) {
      allCharacteristics = allCharacteristics.map((characteristic) => {
        const existingCharacteristic = originalCharacteristics.find(
          (chr) => chr.id === characteristic.id
        );

        if (!characteristic.editable || !existingCharacteristic) {
          return characteristic;
        }

        const defaultTypeValue = characteristic.value;
        const value =
          existingCharacteristic.value && characteristic.measurementUnit
            ? measurementDisplay({
                value: existingCharacteristic.value,
                unit: characteristic.measurementUnit,
                region: user.region,
                reverse: false,
              }).value
            : existingCharacteristic.value;
        const notEquallyEmpty =
          Boolean(characteristic.value) ||
          Boolean(existingCharacteristic.value);
        const isEdited = notEquallyEmpty && characteristic.value !== value;

        return {
          ...characteristic,
          value,
          defaultTypeValue,
          isEdited,
        };
      });
    }

    dispatch(setCharacteristics(allCharacteristics));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    characteristicDefinitionsData,
    functionsData,
    typeCharacteristicsData,
    setTemplatesData,
    hasTypeChanged,
    originalInput,
    functionId,
    typeId,
  ]);

  return (
    <ErrorHandling
      isLoading={
        isLoadingSetTemplates ||
        isLoadingCharacteristicDefinitions ||
        isLoadingTypeCharacteristics ||
        isLoadingFunctions
      }
      isError={
        isErrorSetTemplates ||
        isErrorCharacteristicDefinitions ||
        isErrorTypeCharacteristics ||
        isErrorFunctions
      }
    >
      <Stack spacing={3}>
        {characteristicGroup.setTemplates
          .sort((a, b) => {
            if (a.orderPriority > b.orderPriority) {
              return 1;
            } else {
              return -1;
            }
          })
          .map((setTemplate) => {
            const { characteristics, name } = setTemplate;
            const hasCharacteristics = characteristics.length > 0;

            const allCharacteristics = setUpCharacteristics(
              typeCharacteristicsData ?? [],
              characteristics.map((char) => char.id) ?? [],
              characteristicDefinitionsData ?? [],
              user?.region
            );

            const finalCharacteristics = characteristics.map((char) => {
              const fullCharacteristic = allCharacteristics.find(
                (c) => c.id === char.id
              );

              return {
                ...char,
                editable: fullCharacteristic?.editable,
              };
            });

            return (
              hasCharacteristics && (
                <CharacteristicSetTemplateAccordion
                  key={name}
                  name={name}
                  characteristics={finalCharacteristics}
                />
              )
            );
          })}

        {characteristicGroup.other.length > 0 && (
          <CharacteristicSetTemplateAccordion
            name="OTHER"
            characteristics={characteristicGroup.other}
          />
        )}
      </Stack>
    </ErrorHandling>
  );
};

export default memo(AssetCharacteristicInputGroup);
