import { FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { REPORT_LOGICAL_OPERATORS } from "../../../util/reports-utils";
import { getTranslation } from "../../../util/utils";
import { SecondaryContrastRadioButton } from "../../styles/reports/Reports.styles";

const LogicalOperatorRadioButton = ({ logicalOperator }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  return (
    <FormControlLabel
      id="logical-operator-radio-button"
      value={REPORT_LOGICAL_OPERATORS[logicalOperator]}
      control={<SecondaryContrastRadioButton />}
      label={getTranslation(REPORT_LOGICAL_OPERATORS[logicalOperator], t, i18n)}
    />
  );
};

export default LogicalOperatorRadioButton;
