import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import TableReport from "../../components/reports/TableReport";
import PageTransition from "../../components/PageTransition";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { selectUser } from "../../store/slices/authSlice";
import { transitionDirections } from "../../util/utils";
import ReportHeader from "../../navigation/header/reports/ReportHeader";
import { useReportExecutionQuery } from "../../store/slices/api/reportsApiSlice";
import { ZONE_LIST_REPORT_DEFINITION_ID } from "../../Constants";
import { handleMapDynamicColumns } from "../../util/reports-utils";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

export const columns = [
  { id: "name", label: "NAME", minWidth: 170 },
  { id: "displayId", label: "DISPLAY_ID", minWidth: 100, align: "left" },
  {
    id: "lifecycleStatus",
    label: "LIFECYCLE_STATUS",
    minWidth: 170,
    align: "right",
  },
  {
    id: "lifecycleDate",
    label: "LIFECYCLE_DATE",
    minWidth: 170,
    align: "right",
  },
  {
    id: "version",
    label: "Version",
    minWidth: 170,
    align: "right",
  },
];

const ReportsByZoneList = () => {
  // General hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  //  Selectors
  const user = useSelector(selectUser);

  // Queries
  const {
    data: zonesListData,
    isLoading: isLoadingZonesList,
    isError: isErrorZonesList,
  } = useReportExecutionQuery({
    organizationId: user?.organizations?.find((o) => o.default).id,
    reportDefinitionId: ZONE_LIST_REPORT_DEFINITION_ID,
  });

  // Other variables
  const transitionDirection = searchParams.get("direction");

  // Handlers
  const handleGoBack = () => {
    navigate(`/reports?direction=${transitionDirections.LEFT_TO_RIGHT}`);
  };

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling
          isLoading={isLoadingZonesList}
          isError={isErrorZonesList}
        >
          <PageTransition direction={transitionDirection}>
            <ReportHeader goBackHandler={handleGoBack} title="ZONE_LIST" />
            <HomePagePadding>
              <TableReport
                rows={zonesListData?.data}
                columns={handleMapDynamicColumns(zonesListData?.data)}
                isRowClickable={true}
              />
            </HomePagePadding>
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default ReportsByZoneList;
