import { useRef, useState } from "react";

const useLongPress = () => {
  // States
  const [action, setAction] = useState();

  // Refs
  const timerRef = useRef();
  const isLongPress = useRef();

  // Handlers
  const startPressTimer = () => {
    isLongPress.current = false;
    timerRef.current = setTimeout(() => {
      isLongPress.current = true;
      setAction("longpress");
    }, 500);
  };

  const handleOnClick = (e) => {
    if (isLongPress.current) return;
    setAction("click");
  };

  const handleOnMouseDown = () => {
    startPressTimer();
  };

  const handleOnMouseUp = () => {
    clearTimeout(timerRef.current);
  };

  const handleOnTouchStart = () => {
    startPressTimer();
  };

  const handleOnTouchEnd = () => {
    if (action === "longpress") return;
    clearTimeout(timerRef.current);
  };

  return {
    action,
    setAction,
    handlers: {
      onClick: handleOnClick,
      onMouseDown: handleOnMouseDown,
      onMouseUp: handleOnMouseUp,
      onTouchStart: handleOnTouchStart,
      onTouchEnd: handleOnTouchEnd,
    },
  };
};

export default useLongPress;
