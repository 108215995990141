import { forwardRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { getTranslation, SUBSCRIPTION_ERRORS } from "../../../util/utils";
import { messageError, messageSuccess } from "../../../util/notification";
import {
  Alert,
  alpha,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  CancelButton,
  SecondaryContrastButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledSwitch,
} from "../../styles/general/General.styles";
import { Item, SecondaryText } from "../../styles/assets/ListInlineView.styles";
import { ConfirmDialogButton } from "../../styles/confirm-alert/ConfirmAlert.styles";
import { selectUser } from "../../../store/slices/authSlice";
import {
  useOrganizationExportMutation,
  useOrganizationImportMutation,
} from "../../../store/slices/api/organizationsApiSlice";
import { NavigationActionIcon } from "../../styles/menu/Menu.styles";
import {
  StackContainer,
  SwitchControlLabel,
} from "../../styles/assets/asset-list/AssetImportExport.styles";
import { Error } from "@mui/icons-material";
import { RouletteSpinner, RouletteSpinnerOverlay } from "react-spinner-overlay";
import ErrorHandling from "../../common/ErrorHandling";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const IMPORT_STRATEGY = {
  OVERWRITE: "OVERWRITE",
  SKIP: "SKIP",
};

const AssetsImportExport = () => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [open, setOpen] = useState(false);
  const [importFile, setImportFile] = useState(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errors, setErrors] = useState([]);

  //Options both for import and export

  // Import options
  const [overwrite, setOverwrite] = useState(true);
  const [importImages, setImportImages] = useState(true);
  const [importAttachments, setImportAttachments] = useState(true);
  const [importGraphicalObject, setImportGraphicalObject] = useState(true);
  const [importTags, setImportTags] = useState(true);

  // Export options
  const [includeImages, setIncludeImages] = useState(true);
  const [includeAttachments, setIncludeAttachments] = useState(true);
  const [exportGraphicalObject, setExportGraphicalObject] = useState(true);
  const [includeTags, setIncludeTags] = useState(true);

  // Mutations
  const [organizationImport, { isLoading: isLoadingImport }] =
    useOrganizationImportMutation();
  const [organizationExport, { isLoading: isLoadingExport }] =
    useOrganizationExportMutation();

  // Other variables
  const organization = user?.organizations?.find((o) => o.default);

  // Handlers
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  const handleChange = (e) => {
    setImportFile(e.target.files[0]);
    e.target.value = null;
  };

  const handleChangeImportStrategy = (event) => {
    setOverwrite(event.target.checked);
  };

  const handleChangeImportGraphicalObject = (event) => {
    setImportGraphicalObject(event.target.checked);
  };

  const handleChangeImportTags = (event) => {
    setImportTags(event.target.checked);
  };

  const handleChangeImportImages = (event) => {
    setImportImages(event.target.checked);
  };

  const handleChangeImportAttachments = (event) => {
    setImportAttachments(event.target.checked);
  };

  const handleChangeExportGraphicalObject = (event) => {
    setExportGraphicalObject(event.target.checked);
  };

  const handleChangeIncludeImages = (event) => {
    setIncludeImages(event.target.checked);
  };

  const handleChangeIncludeAttachments = (event) => {
    setIncludeAttachments(event.target.checked);
  };

  const handleChangeIncludeTags = (event) => {
    setIncludeTags(event.target.checked);
  };

  const handleImport = async () => {
    try {
      const formData = new FormData();
      formData.append("file", importFile);
      formData.append("fileName", importFile.name);

      await organizationImport({
        organizationId: organization.id,
        includeGraphicalObjects: importGraphicalObject,
        includeTags: importTags,
        includeImages: importImages,
        includeAttachments: importAttachments,
        strategy: overwrite ? IMPORT_STRATEGY.OVERWRITE : IMPORT_STRATEGY.SKIP,
        formData,
      }).unwrap();

      setImportFile(null);
      setOverwrite(true);

      messageSuccess(getTranslation("successfulImport", t, i18n));
    } catch (error) {
      if (error) {
        const subscriptionError = Object.values(SUBSCRIPTION_ERRORS).find((v) =>
          error?.data?.description?.includes(v)
        );

        if (
          Object.keys(SUBSCRIPTION_ERRORS).some(
            (key) => key === error?.data?.error
          ) ||
          subscriptionError
        ) {
          messageError(
            getTranslation(error?.data?.error || subscriptionError, t, i18n)
          );
        } else {
          if (error.error) {
            // Error is not coming from be
            const { originalStatus } = error;
            messageError(
              getTranslation(`ERROR_STATUS_${originalStatus}`, t, i18n)
            );
          } else if (error.data) {
            // Error is coming from be
            const { aborted, details, description } = error.data;

            if (aborted) {
              if (details) {
                // in order to show detailed error messages for issued resources
                const errors = details
                  .filter((d) => d.hasError)
                  .map((d) => {
                    return {
                      issuedResource: d.name,
                      messages: d.messages,
                    };
                  });
                setOpenErrorDialog(true);
                // {name: string, messages: [{message}]}
                setErrors(errors);
              } else {
                // resource file is missing  then we show the description
                messageError(getTranslation(description, t, i18n));
              }
            }
          }
        }
      } else {
        messageError(getTranslation("failedImport", t, i18n));
      }
    }
  };

  const handleExport = async () => {
    const payload = {
      organizationId: organization.id,
      includeImages,
      includeAttachments,
      includeGraphicalObjects: exportGraphicalObject,
      includeTags,
      organizationName: organization.nickname || organization.name,
    };

    try {
      const file = await organizationExport(payload).unwrap();

      const link = document.createElement("a");
      link.href = file;
      link.target = "_blank";
      link.download = "export";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      messageSuccess(getTranslation("successfulExport", t, i18n));
    } catch (error) {
      console.log(error);
      messageError(getTranslation("failedExport", t, i18n));
    }
  };

  return (
    <ErrorHandling
      isLoading={isLoadingExport || isLoadingImport}
      isError={false}
    >
      <>
        <Tooltip
          title={`${getTranslation("IMPORT", t, i18n)} / ${getTranslation(
            "EXPORT",
            t,
            i18n
          )}`}
        >
          <IconButton onClick={handleClickOpen}>
            <NavigationActionIcon
              id="import-export"
              active={true}
              customfontsize={globalFontSize}
            >
              import_export
            </NavigationActionIcon>
          </IconButton>
        </Tooltip>
        {/* Error Dialog for failed import */}
        <StyledDialog
          fullWidth
          open={openErrorDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseErrorDialog}
          aria-describedby="alert-dialog-errors"
        >
          <StyledDialogContent>
            <StyledDialogTitle>
              {getTranslation("ISSUED_RESOURCES", t, i18n)}
            </StyledDialogTitle>
            <StackContainer spacing={1}>
              {errors.map((error, index) => {
                const { issuedResource, messages } = error;

                return (
                  <Box key={`issued-${index}`}>
                    <SecondaryText id={`issued-${index}`}>
                      {issuedResource}
                    </SecondaryText>

                    <Stack spacing={0.5}>
                      {messages.map((error, index) => (
                        <Alert
                          id={`message-${index}`}
                          key={`message-${index}`}
                          variant="standard"
                          severity="error"
                        >
                          {error.message}
                        </Alert>
                      ))}
                    </Stack>
                  </Box>
                );
              })}
            </StackContainer>
          </StyledDialogContent>
          <CancelButton id="close-button" onClick={handleCloseErrorDialog}>
            {getTranslation("CLOSE", t, i18n)}
          </CancelButton>
        </StyledDialog>
        {/* Dialog for import and export */}
        <StyledDialog
          fullWidth
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <StyledDialogContent>
            <StyledDialogTitle>{`${getTranslation(
              "IMPORT",
              t,
              i18n
            )} / ${getTranslation("EXPORT", t, i18n)}`}</StyledDialogTitle>
            <Stack rowGap={1}>
              <CancelButton
                id="export"
                onClick={() => {
                  handleExport();
                  handleClose();
                }}
              >
                {getTranslation("EXPORT", t, i18n)}
              </CancelButton>
              <StackContainer>
                <SwitchControlLabel
                  id="checkbox-export-images-label"
                  control={
                    <StyledSwitch
                      id="checkbox-export-images"
                      checked={includeImages}
                      onChange={handleChangeIncludeImages}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={getTranslation("IMAGES", t, i18n)}
                />
                <SwitchControlLabel
                  id="checkbox-export-attachments-label"
                  control={
                    <StyledSwitch
                      id="checkbox-export-attachments"
                      checked={includeAttachments}
                      onChange={handleChangeIncludeAttachments}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={getTranslation("ATTACHMENTS", t, i18n)}
                />
                <SwitchControlLabel
                  id="checkbox-export-graphical-objects-label"
                  control={
                    <StyledSwitch
                      id="checkbox-export-graphical-objects"
                      checked={exportGraphicalObject}
                      onChange={handleChangeExportGraphicalObject}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={getTranslation("GRAPHICAL_OBJECTS", t, i18n)}
                />
                <SwitchControlLabel
                  id="checkbox-export-tags-label"
                  control={
                    <StyledSwitch
                      id="checkbox-export-tags"
                      checked={includeTags}
                      onChange={handleChangeIncludeTags}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={getTranslation("TAGS", t, i18n)}
                />
              </StackContainer>
              <Divider />
              <SecondaryContrastButton id="import" component="label" fullWidth>
                <input
                  type="file"
                  onChange={handleChange}
                  hidden
                  id="import-file"
                  accept=".zip, .rar, .7zip"
                />
                {getTranslation("CHOOSE_FILE", t, i18n)}
              </SecondaryContrastButton>

              {importFile && (
                <Item>
                  <ListItemIcon>
                    <NavigationActionIcon
                      id="attach-file"
                      customfontsize={globalFontSize}
                    >
                      attach_file
                    </NavigationActionIcon>
                  </ListItemIcon>
                  <SecondaryText>{importFile.name}</SecondaryText>
                </Item>
              )}
              <StackContainer>
                <SwitchControlLabel
                  id="checkbox-import-overwrite-label"
                  control={
                    <StyledSwitch
                      id="checkbox-import-overwrite"
                      checked={overwrite}
                      onChange={handleChangeImportStrategy}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={getTranslation("OVERWRITE", t, i18n)}
                />
                <SwitchControlLabel
                  id="checkbox-import-images-label"
                  control={
                    <StyledSwitch
                      id="checkbox-import-images"
                      checked={importImages}
                      onChange={handleChangeImportImages}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={getTranslation("IMAGES", t, i18n)}
                />
                <SwitchControlLabel
                  id="checkbox-import-attachments-label"
                  control={
                    <StyledSwitch
                      id="checkbox-import-attachments"
                      checked={importAttachments}
                      onChange={handleChangeImportAttachments}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={getTranslation("ATTACHMENTS", t, i18n)}
                />
                <SwitchControlLabel
                  id="checkbox-import-graphical-objects-label"
                  control={
                    <StyledSwitch
                      id="checkbox-import-graphical-objects"
                      checked={importGraphicalObject}
                      onChange={handleChangeImportGraphicalObject}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={getTranslation("GRAPHICAL_OBJECTS", t, i18n)}
                />
                <SwitchControlLabel
                  id="checkbox-import-tags-label"
                  control={
                    <StyledSwitch
                      id="checkbox-import-tags"
                      checked={importTags}
                      onChange={handleChangeImportTags}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={getTranslation("TAGS", t, i18n)}
                />
              </StackContainer>
              <ConfirmDialogButton
                disabled={!importFile}
                data-testid="confirm"
                color="error"
                onClick={() => {
                  handleImport();
                  handleClose();
                }}
                autoFocus
              >
                {getTranslation("IMPORT", t, i18n)}
              </ConfirmDialogButton>
            </Stack>
          </StyledDialogContent>
          <CancelButton id={`cancel-button`} onClick={handleClose}>
            {getTranslation("CANCEL", t, i18n)}
          </CancelButton>
        </StyledDialog>
      </>
    </ErrorHandling>
  );
};

export default AssetsImportExport;

// import - existing_data_strategy, include_graphical_objects
// export - include_images, include_attachments, include_tags, include_graphical_objects
