import React from "react";
import { AutomaticThemePaper } from "../styles/profile/ThemeCard.styles";

const AutomaticThemeCard = ({ theme, active, handleClick }) => {
  return (
    <AutomaticThemePaper
      id={`theme-${theme}`}
      onClick={handleClick}
      active={active}
    ></AutomaticThemePaper>
  );
};

export default AutomaticThemeCard;
