import { useGetAllFunctionsQuery } from "../../store/slices/api/assetManagementSlice";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { useCallback } from "react";
import { selectUser } from "../../store/slices/authSlice";
import { useTranslation } from "react-i18next";
import { messageError, messageSuccess } from "../../util/notification";
import PageTransition from "../../components/PageTransition";
import {
  transitionDirections,
  permissions,
  getTranslation,
  showValidationError,
} from "../../util/utils";
import { useGetResourceDetailsQuery } from "../../store/slices/api/assetManagementSlice";
import Access from "../../components/common/Access";
import ErrorHandling from "../../components/common/ErrorHandling";
import { setMobileTabValue } from "../../store/slices/assetListSlice";
import { useDispatch } from "react-redux";
import {
  useGetResourceAttachmentQuery,
  useGetResourceAttachmentsQuery,
  useUpdateResourceAttachmentMutation,
} from "../../store/slices/api/resourceAttachmentsApiSlice";
import ResourceAttachmentForm from "../../components/assets/asset-form/ResourceAttachmentForm";
import AddAttachmentHeader from "../../navigation/header/assets/AddAttachmentHeader";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const EditResourceAttachmentPage = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { resourceId, resourceAttachmentId } = useParams();

  // Selectors
  const user = useSelector(selectUser);

  // Queries
  const {
    data: currentResourceData,
    isLoading: isLoadingCurrent,
    isError: isErrorCurrent,
  } = useGetResourceDetailsQuery(
    {
      resourceid: resourceId,
      organizationId: user?.organizations?.find((o) => o.default).id,
    },
    {
      skip: !Boolean(resourceId),
    }
  );

  const {
    data: resourceAttachmentsData,
    isLoading: isLoadingAttachments,
    isError: isErrorAttachments,
  } = useGetResourceAttachmentsQuery(
    {
      resourceid: currentResourceData?.id,
      organizationId: user?.organizations?.find((o) => o.default)?.id,
    },
    { skip: !Boolean(currentResourceData) }
  );

  const { data: allFunctionsData } = useGetAllFunctionsQuery({
    organizationId: user?.organizations?.find((o) => o.default)?.id,
  });

  // Other variables
  const singleResourceAttachment = resourceAttachmentsData?.find(
    (attachment) => attachment.id === resourceAttachmentId
  );

  const { data: resourceAttachmentUri, isLoading: isLoadingImage } =
    useGetResourceAttachmentQuery(
      {
        attachmentUri: singleResourceAttachment
          ? singleResourceAttachment?.uri
          : "",
        organizationId: user?.organizations?.find((o) => o.default).id,
      },
      {
        skip: !singleResourceAttachment,
      }
    );

  // Other variables
  const direction = searchParams.get("direction");

  const transformedAttachment = {
    selectedFile: resourceAttachmentUri,
    resourceid: resourceId,
    id: singleResourceAttachment?.id,
    name: singleResourceAttachment?.name,
    mimeType: singleResourceAttachment?.name?.substring(
      singleResourceAttachment.name?.lastIndexOf(".")
    ),
    description: singleResourceAttachment?.description,
    file: null,
  };

  // State
  const [resourceAttachmentInput, setResourceAttachmentInput] = useState(
    transformedAttachment
  );

  const [name, setName] = useState(
    resourceAttachmentInput?.name?.substring(
      0,
      resourceAttachmentInput?.name?.lastIndexOf(".")
    )
  );

  const [extension, setExtension] = useState(
    resourceAttachmentInput?.name?.substring(
      resourceAttachmentInput?.name?.lastIndexOf(".")
    )
  );

  // Handlers
  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === id);
  };

  const cancelHandler = () => {
    dispatch(setMobileTabValue(1));

    navigate(
      `/resources/${resourceId}?direction=${transitionDirections.TOP_TO_BOTTOM}&comingFrom=edit-attachment`
    );
  };

  // Other variables
  const isValid =
    (resourceAttachmentInput.file || resourceAttachmentInput.selectedFile) &&
    name;

  const isDisabled = !isValid;
  const resourceFunction = getFunction(currentResourceData?.functionId);

  const permissionsKey = `ASSET_MANAGEMENT_${
    resourceFunction?.category === "HARDWARE_ASSET"
      ? "HARDWARE"
      : resourceFunction?.category
  }_EDIT`;

  // Mutations
  const [updateResourceAttachment, { isLoading: isLoadingUpdate }] =
    useUpdateResourceAttachmentMutation();

  const handleSubmit = useCallback(async () => {
    try {
      if (!resourceAttachmentInput.selectedFile) {
        messageError(getTranslation("ATTACHMENT_IS_REQUIRED", t, i18n));
        return;
      }

      const formData = new FormData();

      const { file, description } = resourceAttachmentInput;

      formData.append("file", file);
      formData.append("fileName", name + extension);
      formData.append("description", description);

      await updateResourceAttachment({
        formData,
        resourceid: resourceId,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
        updateAttachment: Boolean(file),
        resourceAttachmentId: transformedAttachment?.id,
      }).unwrap();

      cancelHandler();

      messageSuccess(
        getTranslation("RESOURCE_ATTACHMENT_UPDATED_SUCCESSFULLY", t, i18n)
      );
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to update resource attachment", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceAttachmentInput, name]);

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={
          isLoadingCurrent ||
          isLoadingUpdate ||
          isLoadingImage ||
          isLoadingAttachments
        }
        isError={isErrorCurrent || isErrorAttachments}
      >
        <Access all={[permissions[permissionsKey]]}>
          <Layer2Access>
            <PageTransition direction={direction}>
              <AddAttachmentHeader
                handleAction={handleSubmit}
                handleCancelAction={cancelHandler}
                isDisabled={isDisabled}
              />

              <HomePagePadding>
                <ResourceAttachmentForm
                  resourceAttachmentInput={resourceAttachmentInput}
                  setResourceAttachmentInput={setResourceAttachmentInput}
                  name={name}
                  setName={setName}
                  setExtension={setExtension}
                />
              </HomePagePadding>
            </PageTransition>
          </Layer2Access>
        </Access>
      </ErrorHandling>
    </AppAccess>
  );
};

export default EditResourceAttachmentPage;
