import React, { useEffect, useState } from "react";
import { useGetTypeFavoritesQuery } from "../../store/slices/api/typesApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";
import {
  FAVORITE_TYPES_SORTING_OPTIONS,
  selectFavoritesIndex,
  selectFunction,
  selectTypeSearchTerm,
  setCategory,
  setFavoritesIndex,
  setFunction,
} from "../../store/slices/typeSearchSlice";
import {
  TYPE_ITEM_HEIGHT,
  TYPE_LANDSCAPE_PAGE_SIZE,
  TYPE_PORTRAIT_PAGE_SIZE,
  getPageSize,
  getTranslation,
  transitionDirections,
} from "../../util/utils";
import {
  DEFAULT_INDEX,
  DEFAULT_SORTING_OPTION,
} from "../../store/slices/appSlice";
import { RouletteSpinnerOverlay } from "react-spinner-overlay";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import PageTransition from "../../components/PageTransition";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FirstScreenContainer } from "../../components/styles/types/type-list/TypeListPage.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import {
  HomePagePadding,
  NoDataTextContainer,
} from "../../components/styles/general/General.styles";
import TypeStickySearch from "../../components/types/type-list/TypeStickySearch";
import TypeListInlineView from "../../components/types/type-list/TypeListInlineView";
import { CreateAssetButton } from "../../components/styles/assets/AssetList.styles";
import { SecondaryText } from "../../components/styles/assets/ListInlineView.styles";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import { useTranslation } from "react-i18next";
import DesktopTypeDetails from "../../components/types/type-detail/DesktopTypeDetails";
import DesktopTypeStickySearch from "../../components/types/type-list/DesktopTypeStickySearch";
import TypeAccordionSection from "../../components/types/type-list/TypeAccordionSection";
import FavoritesHeader from "../../navigation/header/library/FavoritesHeader";
import useOrientation from "../../hooks/useOrientation";
import { selectTypeId, setTypeId } from "../../store/slices/typeSlice";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const TypeFavoritesPage = () => {
  // General hooks
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Custom hooks
  const orientation = useOrientation();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  // List helper variables
  const favoritesIndex = useSelector(selectFavoritesIndex);
  const functionId = useSelector(selectFunction);
  const searchTerm = useSelector(selectTypeSearchTerm);
  const currentTypeId = useSelector(selectTypeId);

  // States
  const [items, setItems] = useState([]);
  const [fetchingItems, setFetchingItems] = useState(false);
  const [itemsTotalRecord, setItemsTotalRecord] = useState(0);
  const [sortingOption, setSortingOption] = useState(DEFAULT_SORTING_OPTION);

  const getSortByType = () => {
    if (sortingOption.type === "displayIdAndNameCombination") {
      return "name";
    } else if (sortingOption.type === "favoured") {
      return "createdAt";
    } else {
      return sortingOption.type;
    }
  };

  const pageSize = mobileMatches
    ? getPageSize(TYPE_ITEM_HEIGHT)
    : orientation === "landscape"
    ? TYPE_LANDSCAPE_PAGE_SIZE
    : TYPE_PORTRAIT_PAGE_SIZE;

  // Queries
  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery({
    userId: user.id,
    organizationId,
  });

  const {
    data: typeFavoritesData,
    isLoading: isLoadingTypeFavorites,
    isFetching: isFetchingFavorites,
    isError: isErrorFavorites,
  } = useGetTypeFavoritesQuery({
    organizationId,
    index: favoritesIndex,
    size: pageSize,
    order: sortingOption.order,
    sortBy: getSortByType(),
  });

  // Other variables
  const direction = searchParams.get("direction");
  const categoryParam = searchParams.get("category");
  const functionIdParam = searchParams.get("functionId");
  const desktopSortingOptions = FAVORITE_TYPES_SORTING_OPTIONS.filter(
    (option) => option.value !== "Z-A"
  );

  // Handlers

  const fetchMoreData = () => {
    setFetchingItems(true);

    setTimeout(() => {
      dispatch(setFavoritesIndex(favoritesIndex + 1));
      setFetchingItems(false);
    }, 1000);
  };

  const goBackHandler = () =>
    navigate(`/more?direction=${transitionDirections.LEFT_TO_RIGHT}`);

  const handleNavigateToLibrary = () =>
    navigate(`/library?direction=${transitionDirections.LEFT_TO_RIGHT}`);

  // Effects
  useEffect(() => {
    dispatch(setFavoritesIndex(DEFAULT_INDEX));

    if (categoryParam) {
      dispatch(setCategory(categoryParam));

      if (functionIdParam) {
        dispatch(setFunction(functionIdParam));
      }
    }

    return () => {
      dispatch(setTypeId(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeFavoritesData) {
      const { data: newLoadedItems, totalRecord } = typeFavoritesData;
      const mappedNewLoadedItems = newLoadedItems.map((item) => item.type);

      if (favoritesIndex <= 1) {
        setItems([...mappedNewLoadedItems]);
      } else {
        setItems([...items, ...mappedNewLoadedItems]);
      }

      setItemsTotalRecord(totalRecord);
    }
  }, [typeFavoritesData]);

  useEffect(() => {
    dispatch(setFavoritesIndex(DEFAULT_INDEX));
  }, [sortingOption]);

  const filteredItems = items.filter((i) => {
    const matchesQuery = searchTerm
      ? i.name.toLowerCase().includes(searchTerm.toLowerCase())
      : true;

    const matchesFunctionId = functionId
      ? i.functionId === Number(functionId)
      : true;

    return matchesQuery && matchesFunctionId;
  });

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling
          isLoading={
            isLoadingTypeFavorites || isLoadingUserRoles || isFetchingFavorites
          }
          isError={isErrorFavorites || isErrorUserRoles}
        >
          <PageTransition direction={direction}>
            {desktopMatches ? (
              <Grid container>
                <FirstScreenContainer item lg={6} xl={5}>
                  <DesktopTypeStickySearch
                    desktopSortingOptions={desktopSortingOptions}
                    sortingOption={sortingOption}
                    setSortingOption={setSortingOption}
                  />
                  {items.length <= 0 ? (
                    <NoDataTextContainer>
                      <SecondaryText align="center">
                        {getTranslation("NO_FAVORITE_LIBRARY", t, i18n)}
                      </SecondaryText>
                      <CreateAssetButton onClick={handleNavigateToLibrary}>
                        {getTranslation("OPEN_LIBRARY", t, i18n)}
                      </CreateAssetButton>
                    </NoDataTextContainer>
                  ) : (
                    <TypeAccordionSection
                      sectionName={getTranslation("FAVORITES", t, i18n)}
                      items={filteredItems}
                      totalRecord={typeFavoritesData?.totalRecord}
                      pageSize={pageSize}
                      index={favoritesIndex}
                      isFetchingData={isFetchingFavorites || fetchingItems}
                      fetchMoreDataHandler={fetchMoreData}
                      prefixLabelItem="favorites"
                      isFavorites={true}
                    />
                  )}
                </FirstScreenContainer>
                <Grid item lg={6} xl={7}>
                  {currentTypeId && (
                    <DesktopTypeDetails typeId={currentTypeId} />
                  )}
                </Grid>
              </Grid>
            ) : (
              <>
                <FavoritesHeader
                  goBackHandler={goBackHandler}
                  userRoles={userRoles}
                />
                <HomePagePadding>
                  <TypeStickySearch
                    sortingOptions={FAVORITE_TYPES_SORTING_OPTIONS}
                    sortingOption={sortingOption}
                    setSortingOption={setSortingOption}
                  />
                  <TypeListInlineView
                    list={filteredItems}
                    index={favoritesIndex}
                    fetchMoreData={fetchMoreData}
                    totalRecord={itemsTotalRecord}
                    pageSize={pageSize}
                  />
                  {items?.length <= 0 && (
                    <NoDataTextContainer>
                      <SecondaryText align="center">
                        {getTranslation("NO_FAVORITE_LIBRARY", t, i18n)}
                      </SecondaryText>
                      <CreateAssetButton onClick={handleNavigateToLibrary}>
                        {getTranslation("OPEN_LIBRARY", t, i18n)}
                      </CreateAssetButton>
                    </NoDataTextContainer>
                  )}
                </HomePagePadding>
              </>
            )}
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default TypeFavoritesPage;
