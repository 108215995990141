import React from "react";
import { BarStack, StyledBar } from "../../../styles/reports/ChartWidgets.styles";

const BarChartWidgetContent = ({ data, assignment = "x" }) => {
  return (
    <BarStack>
      <StyledBar
        options={{
          indexAxis: assignment,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
            },
          },
          scales: {
            x: {
              beginAtZero: true,
            },
          },
        }}
        data={data}
      />
    </BarStack>
  );
};

export default BarChartWidgetContent;
