import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import AppsIcon from "@mui/icons-material/Apps";
import CheckIcon from "@mui/icons-material/Check";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/system";
import { ListColumnViewIcon } from "../assets/StickySearch.styles";
import { Divider, ListItemIcon, ListItemText, Menu } from "@mui/material";

export const StyledFormatAlignLeftIcon = styled(FormatAlignLeftIcon)(
  ({ theme, globalFontSize }) => ({
    fontSize: `${globalFontSize * 2}px`,
    cursor: "pointer",
    color: theme.palette.secondary.contrastText,
  })
);

export const StyledAppsIcon = styled(AppsIcon)(({ theme, globalFontSize }) => ({
  fontSize: `${globalFontSize * 2}px`,
  cursor: "pointer",
  color: theme.palette.secondary.contrastText,
}));

export const StyledListColumnViewIcon = styled(ListColumnViewIcon)(
  ({ globalFontSize }) => ({
    fontSize: `${globalFontSize * 2}px`,
    cursor: "pointer",
  })
);

export const StyledOptionsMenu = styled(Menu)({
  marginTop: "15px",
  ".MuiMenu-paper": { borderRadius: "15px", outline: "none" },
  ".MuiMenu-list": {
    width: "240px",
    borderRadius: "15px",
  },
});

export const ColumnViewListItemIcon = styled(ListItemIcon)(
  ({ currentpageview }) => ({
    visibility: currentpageview === "column" ? "visible" : "hidden",
  })
);

export const StyledCheckIcon = styled(CheckIcon)(({ globalFontSize }) => ({
  fontSize: `${globalFontSize * 1.5}px`,
}));

export const EllipsisListItemText = styled(ListItemText)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

export const StyledViewColumnIcon = styled(ViewColumnIcon)(
  ({ globalFontSize }) => ({
    fontSize: `${globalFontSize * 1.5}px`,
  })
);

export const InlineViewListItemIcon = styled(ListItemIcon)(
  ({ currentpageview }) => ({
    visibility: currentpageview === "inline" ? "visible" : "hidden",
  })
);

export const AppViewListItemIcon = styled(ListItemIcon)(
  ({ currentpageview }) => ({
    visibility: currentpageview === "app" ? "visible" : "hidden",
  })
);

export const StyledFormatAlignLeftSmallerIcon = styled(FormatAlignLeftIcon)(
  ({ globalFontSize }) => ({
    fontSize: `${globalFontSize * 1.5}px`,
  })
);

export const StyledAppsSmallerIcon = styled(AppsIcon)(({ globalFontSize }) => ({
  fontSize: `${globalFontSize * 1.5}px`,
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  borderBottomWidth: 3,
}));

export const SortingOptionListItemIcon = styled(ListItemIcon)(
  ({ sortingOption, value }) => ({
    visibility: sortingOption.value === value ? "visible" : "hidden",
  })
);

export const StyledKeyboardUpIcon = styled(KeyboardArrowUpIcon)(
  ({ globalFontSize }) => ({
    fontSize: `${globalFontSize * 1.5}px`,
  })
);

export const StyledKeyboardDownIcon = styled(KeyboardArrowDownIcon)(
  ({ globalFontSize }) => ({
    fontSize: `${globalFontSize * 1.5}px`,
  })
);
