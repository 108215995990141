import React from "react";
import { Grid, ListItemIcon, useMediaQuery } from "@mui/material";
import {
  ArrowRightIcon,
  Item,
  ItemContainer,
  ItemName,
  SecondaryText,
} from "../../styles/assets/ListInlineView.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { getTranslation, transitionDirections } from "../../../util/utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import {
  FavoriteIcon,
  TypeItemContainer,
  TypeNameGridContainer,
} from "../../styles/types/type-list/TypeItem.styles";
import { NavigationActionIcon } from "../../styles/menu/Menu.styles";
import { useGetTypeFavoritesQuery } from "../../../store/slices/api/typesApiSlice";
import { selectUser } from "../../../store/slices/authSlice";
import ErrorHandling from "../../common/ErrorHandling";

const getIconCreationType = (organizationId, globalFontSize) => {
  return organizationId ? (
    <NavigationActionIcon
      id="user-created-icon"
      className="material-icons material-icons-outlined"
      customfontsize={globalFontSize * 1.25}
    >
      person_outlined
    </NavigationActionIcon>
  ) : (
    <NavigationActionIcon
      id="system-created-icon"
      className="material-icons material-icons-outlined"
      customfontsize={globalFontSize * 1.25}
    >
      local_library
    </NavigationActionIcon>
  );
};

const FavoriteTypeItem = ({
  type,
  lastIndex,
  currentTypeId,
  currentTypeHandler,
}) => {
  const { id, name, organizationId } = type;

  // General hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const user = useSelector(selectUser);
  const userOrganizationId = user?.organizations?.find((o) => o.default)?.id;
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Queries
  const {
    data: dataFavorites,
    isLoading,
    isError,
  } = useGetTypeFavoritesQuery({
    organizationId: userOrganizationId,
  });

  // Other variables
  const creationType = organizationId
    ? getTranslation("USER_CREATED_TYPE", t, i18n)
    : getTranslation("SYSTEM_CREATED_TYPE", t, i18n);

  // Handlers
  const handleClick = () => {
    if (desktopMatches) {
      currentTypeHandler(id);
    } else {
      navigate(
        `/library/${id}/details?direction=${transitionDirections.RIGHT_TO_LEFT}`
      );
    }
  };

  const isFavorite = dataFavorites?.data?.some((f) => f.type.id === id);

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      {desktopMatches ? (
        <TypeItemContainer
          lastindex={lastIndex}
          currentypeid={currentTypeId}
          id={id}
          onClick={handleClick}
        >
          <Grid py={1} container gap={1} alignItems="center">
            <Grid item alignItems="center" display="flex" position="relative">
              {getIconCreationType(organizationId, globalFontSize)}
              {isFavorite && (
                <FavoriteIcon
                  active={true}
                  customfontsize={globalFontSize * 0.95}
                >
                  star
                </FavoriteIcon>
              )}
            </Grid>
            <TypeNameGridContainer item xs={7}>
              <ItemName isSelected={currentTypeId === id}>{name}</ItemName>
            </TypeNameGridContainer>
            <Grid item xs>
              <SecondaryText>{creationType}</SecondaryText>
            </Grid>
            <Grid item display="flex" alignItems="center">
              <ArrowRightIcon />
            </Grid>
          </Grid>
        </TypeItemContainer>
      ) : (
        <ItemContainer>
          <Item key={`resource-${id}`} onClick={handleClick}>
            <ListItemIcon>
              {getIconCreationType(organizationId, globalFontSize)}
            </ListItemIcon>
            <ItemName>{name}</ItemName>
            <ArrowRightIcon />
          </Item>
        </ItemContainer>
      )}
    </ErrorHandling>
  );
};

export default FavoriteTypeItem;
