import {
  ActionTitle,
  HeaderGridContainer,
  HeaderWrapper,
  RightAlignedActionTitle,
  PageTitle,
} from "../../../components/styles/header/Header.styles";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";

const UpdateOrganizationPhoneHeader = ({
  handleAction,
  handleCancelAction,
  disableDone,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();

  return (
    <HeaderWrapper id="sticky-header">
      <HeaderGridContainer container>
        <Grid item xs={3}>
          <ActionTitle
            tabIndex="0"
            data-testid="action-title-cancel"
            id="action-title-cancel"
            onClick={handleCancelAction}
          >
            {getTranslation("CANCEL", t, i18n)}
          </ActionTitle>
        </Grid>
        <Grid item xs={6}>
          <PageTitle variant="h6" id="title">
            {getTranslation("updateOrganizationPhone", t, i18n)}
          </PageTitle>
        </Grid>
        <Grid display="flex" justifyContent="end" item xs={3}>
          <RightAlignedActionTitle
            tabIndex="0"
            onClick={() => {
              if (!disableDone) {
                handleAction();
              }
            }}
            aria-disabled={disableDone}
            disabled={disableDone ? 1 : 0}
            id="action-title-done"
            data-testid="action-title-done"
          >
            {getTranslation("DONE", t, i18n)}
          </RightAlignedActionTitle>
        </Grid>
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default UpdateOrganizationPhoneHeader;
