import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import PageTransition from "../../components/PageTransition";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import {
  setIsActionButtonDisabled,
  setIsLoadingAction,
} from "../../store/slices/appSlice";
import { selectUser } from "../../store/slices/authSlice";
import { messageError, messageSuccess } from "../../util/notification";
import {
  getTranslation,
  transitionDirections,
  permissions,
} from "../../util/utils";
import {
  resetForm,
  selectTypeInput,
  selectIsFirstSubmitted,
  setTypeInput,
  setIsFirstSubmitted,
  setOriginalTypeInput,
  selectOriginalTypeInput,
} from "../../store/slices/typeInputSlice";
import TypeForm from "../../components/types/type-form/TypeForm";
import {
  selectResourceInput,
  setResourceInput,
} from "../../store/slices/resourceInputSlice";
import { setCategory, setFunction } from "../../store/slices/typeSearchSlice";
import AddTypeHeader from "../../navigation/header/library/AddTypeHeader";
import { useCreateTypeMutation } from "../../store/slices/api/typesApiSlice";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import {
  checkTypeChanged,
  transformTypeInputRequest,
  validateTypeForm,
} from "../../util/type-utils";
import EventBus, { EVENT_DISCARD_CHANGES } from "../../util/EventBus";
import Access from "../../components/common/Access";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const AddTypePage = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const category = searchParams.get("category");
  const functionId = searchParams.get("functionId");
  const direction = searchParams.get("direction");
  const previousPage = searchParams.get("previousPage");
  const resourceid = searchParams.get("resourceid");

  // Selectors
  const user = useSelector(selectUser);
  const resourceInput = useSelector(selectResourceInput);
  const typeInput = useSelector(selectTypeInput);
  const originalTypeInput = useSelector(selectOriginalTypeInput);
  const isFirstSubmitted = useSelector(selectIsFirstSubmitted);

  // States
  const [open, setOpen] = useState(false);
  const [redirectRoute, setRedirectRoute] = useState("");

  // Mutations
  const [createType, { isLoading }] = useCreateTypeMutation();

  // Handlers
  const handleSubmit = async () => {
    dispatch(setIsFirstSubmitted(true));
    try {
      const { error: evaluatedError, firstError } = validateTypeForm(typeInput);

      const isValid = Object.keys(evaluatedError).length === 0;
      if (!isValid) {
        messageError(getTranslation(firstError, t, i18n));
        dispatch(setIsActionButtonDisabled(true));
        return;
      }

      const data = await createType({
        typeInput: transformTypeInputRequest(typeInput, user?.region),
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      }).unwrap();

      if (data) {
        // Used to set the resource input so when we get back to resource to pre-populate type
        dispatch(
          setResourceInput({
            ...resourceInput,
            typeId: data.id,
            category: typeInput.category,
            functionId: typeInput.functionId,
            hasTypeChanged: true, // set to notify that created type will be pre-populated
          })
        );
      }

      dispatch(setCategory(typeInput.category));
      dispatch(setFunction(typeInput.functionId));

      cancelHandler(getReturnUrl());
      messageSuccess(getTranslation("successfulCreateType", t, i18n));
      dispatch(setIsLoadingAction(false));
    } catch (error) {
      dispatch(setIsLoadingAction(false));
      messageError(getTranslation("failedCreateType", t, i18n));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getReturnUrl = () => {
    let returnUrl;

    if (previousPage === "library") {
      returnUrl = `/library?direction=${transitionDirections.TOP_TO_BOTTOM}`;
    } else {
      // Only resourceid will exists if we come from edit.
      if (resourceid) {
        returnUrl = `/resources/${resourceid}/edit?direction=${transitionDirections.TOP_TO_BOTTOM}&previousPage=add-type`;
      } else {
        returnUrl = `/resources/add-new?direction=${transitionDirections.TOP_TO_BOTTOM}&previousPage=add-type`;
      }
    }

    return returnUrl;
  };

  const cancelHandler = (redirectUrl) => {
    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      navigate(redirectRoute);
    }
  };

  // Effects
  useEffect(() => {
    const initialTypeInput = {
      ...typeInput,
      functionId,
      category,
    };

    dispatch(setTypeInput(initialTypeInput));
    dispatch(
      setOriginalTypeInput({ ...originalTypeInput, ...initialTypeInput })
    );

    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleNavigation = (data) => {
      if (checkTypeChanged(originalTypeInput, typeInput, user?.region)) {
        setRedirectRoute(data.route);
        setOpen(true);
      } else {
        cancelHandler(data.route);
      }
    };

    EventBus.on(EVENT_DISCARD_CHANGES, handleNavigation);

    return () => EventBus.remove(EVENT_DISCARD_CHANGES, handleNavigation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeInput, originalTypeInput]);

  // Validation Form control
  // After first form submit we validate the form
  useEffect(() => {
    if (!isFirstSubmitted) return;

    const { error: evaluatedError } = validateTypeForm(typeInput);
    const isValid = Object.keys(evaluatedError).length === 0;

    dispatch(setIsActionButtonDisabled(!isValid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeInput]);

  useEffect(() => {
    if (isLoading) {
      dispatch(setIsLoadingAction(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <AppAccess>
      <Access oneOf={[permissions.ASSET_MANAGEMENT_TYPE_ADD]}>
        <Layer2Access>
          <PageTransition direction={direction}>
            <ConfirmAlert
              isOpen={open}
              setIsOpen={setOpen}
              alert={{
                content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
                confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),
                closeTitle: getTranslation("CANCEL", t, i18n),
                showConfirm: true,
              }}
              label="discard-changes"
              handleConfirm={cancelHandler}
            />
            <AddTypeHeader
              handleAction={handleSubmit}
              handleCancelAction={() => {
                let returnUrl = getReturnUrl();

                if (
                  checkTypeChanged(originalTypeInput, typeInput, user?.region)
                ) {
                  setRedirectRoute(returnUrl);
                  setOpen(true);
                  return;
                }

                cancelHandler(returnUrl);
              }}
            />
            <HomePagePadding>
              <TypeForm mode="create" />
            </HomePagePadding>
          </PageTransition>
        </Layer2Access>
      </Access>
    </AppAccess>
  );
};

export default AddTypePage;
