import { Grid, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useGetAllFunctionsQuery,
  useGetResourceDetailsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { getTranslation, userLocalStorageKey } from "../../../util/utils";
import ErrorHandling from "../../common/ErrorHandling";
import {
  PrimaryText,
  SecondaryText,
} from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { ListItemContainerColumn } from "../../styles/assets/asset-detail/AssetDetailCard.styles";
import { LOCATION_DETAILS } from "../../../util/asset-utils";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";

const AssetDetailLocationColumnVersion = ({ parentId, resourceFunctionId }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  // States
  const [locations, setLocations] = useState([]);

  // Other variables
  const index = tabletMatches || desktopMatches ? 1 : 0;

  // Queries
  const {
    data: resourceData,
    isLoading,
    isError,
  } = useGetResourceDetailsQuery(
    {
      resourceid: parentId,
      organizationId,
    },
    {
      skip: !Boolean(parentId),
    }
  );

  const { data: allFunctionsData } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Handlers
  const getFunction = (functionId) => {
    return allFunctionsData?.find((f) => f.id === functionId);
  };

  // Other variables
  const resourceFunction = getFunction(resourceFunctionId);

  // Effects
  useEffect(() => {
    localStorage.removeItem(userLocalStorageKey("locations"));
    return () => localStorage.removeItem(userLocalStorageKey("locations"));
  }, []);

  useEffect(() => {
    if (resourceData) {
      const currentLocations =
        JSON.parse(localStorage.getItem(userLocalStorageKey("locations"))) ||
        [];

      const newItems = [...currentLocations, resourceData.name];

      localStorage.setItem(
        userLocalStorageKey("locations"),
        JSON.stringify(newItems)
      );

      setLocations(newItems);
    }
  }, [resourceData]);

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <Grid container>
        <Grid item xs={12} columnSpacing={2}>
          <ListItemContainerColumn index={index}>
            <SecondaryText variant="body2" id="asset-location-header">
              {getTranslation(
                LOCATION_DETAILS[resourceFunction?.category] ??
                  LOCATION_DETAILS.DEFAULT,
                t,
                i18n
              )}
            </SecondaryText>
            <PrimaryText id="asset-location-path">
              {locations.length > 0 ? (
                locations.reverse().join(" - ")
              ) : (
                <SecondaryText component="span" id="asset-location-header">
                  {getTranslation("NO_PARENT", t, i18n)}
                </SecondaryText>
              )}
            </PrimaryText>
          </ListItemContainerColumn>
        </Grid>
      </Grid>
    </ErrorHandling>
  );
};

export default AssetDetailLocationColumnVersion;
