import dayjs from "dayjs";
import { measurementDisplay } from "./utils";

export const checkTypeChanged = (originalInput, currentInput, region) => {
  const {
    category: originalCategory,
    functionId: originalFunctionId,
    name: originalName,
    displayId: originalDisplayId,
    description: originalDescription,
    version: originalVersion,
    characteristics: originalCharacteristics,
    lifecycleStatus: originalLifecycleStatus,
    lifecycleDate: originalLifecycleDate,
  } = originalInput;
  const {
    category: currentCategory,
    functionId: currentFunctionId,
    name: currentName,
    displayId: currentDisplayId,
    description: currentDescription,
    version: currentVersion,
    characteristics: currentCharacteristics,
    lifecycleStatus: currentLifecycleStatus,
    lifecycleDate: currentLifecycleDate,
  } = currentInput;

  const isResourceCategoryChanged =
    (Boolean(originalCategory) || Boolean(currentCategory)) &&
    originalCategory !== currentCategory;
  const isFunctionIdIdChanged =
    (Boolean(originalFunctionId) || Boolean(currentFunctionId)) &&
    Number(originalFunctionId) !== Number(currentFunctionId);
  const isNameChanged =
    (Boolean(originalName) || Boolean(currentName)) &&
    originalName !== currentName;
  const isDisplayIdChanged =
    (Boolean(originalDisplayId) || Boolean(currentDisplayId)) &&
    originalDisplayId !== currentDisplayId;
  const isDescriptionChanged =
    (Boolean(originalDescription) || Boolean(currentDescription)) &&
    originalDescription !== currentDescription;
  const isVersionChanged =
    (Boolean(originalVersion) || Boolean(currentVersion)) &&
    originalVersion !== currentVersion;
  const isLifecycleStatusChanged =
    (Boolean(originalLifecycleStatus) || Boolean(currentLifecycleStatus)) &&
    originalLifecycleStatus !== currentLifecycleStatus;
  const isLifecycleDateChanged =
    new Date(originalLifecycleDate).toLocaleDateString() !==
    new Date(currentLifecycleDate).toLocaleDateString();

  const currentCharacteristicsWithValues = currentCharacteristics.filter(
    (c) => c.value
  );
  let areCharacteristicsChanged = false;

  if (
    originalCharacteristics.length !== currentCharacteristicsWithValues.length
  ) {
    areCharacteristicsChanged = true;
  } else {
    areCharacteristicsChanged = originalCharacteristics.some(
      (originalCharacteristic) => {
        const existingCharacteristic = currentCharacteristicsWithValues.find(
          (currCharacteristic) =>
            currCharacteristic.id === originalCharacteristic.id
        );

        if (!existingCharacteristic) {
          return true;
        }

        if (existingCharacteristic.value || originalCharacteristic.value) {
          if (existingCharacteristic.dataType === "INTEGER") {
            const convertedOriginalCharacteristicValue =
              originalCharacteristic.value
                ? measurementDisplay({
                    value: originalCharacteristic.value,
                    unit: existingCharacteristic.measurementUnit,
                    region: region,
                    reverse: false,
                  }).value
                : originalCharacteristic.value;

            return (
              Number(existingCharacteristic.value) !==
                Number(convertedOriginalCharacteristicValue) ||
              existingCharacteristic.editable !==
                originalCharacteristic.editable
            );
          } else {
            return (
              existingCharacteristic.value !== originalCharacteristic.value ||
              existingCharacteristic.editable !==
                originalCharacteristic.editable
            );
          }
        }

        return (
          existingCharacteristic.editable !== originalCharacteristic.editable
        );
      }
    );
  }

  return (
    isResourceCategoryChanged ||
    isFunctionIdIdChanged ||
    isNameChanged ||
    isDisplayIdChanged ||
    isDescriptionChanged ||
    isVersionChanged ||
    isLifecycleStatusChanged ||
    isLifecycleDateChanged ||
    areCharacteristicsChanged
  );
};

export const validateTypeForm = (typeInput) => {
  const error = {};
  let firstError;

  if (!typeInput?.category) {
    error.category = "CATEGORY_VALIDATION";
    firstError = "CATEGORY_VALIDATION";
  }

  if (!typeInput?.functionId) {
    error.functionId = "FUNCTION_VALIDATION";
    if (!firstError) {
      firstError = "FUNCTION_VALIDATION";
    }
  }

  if (!typeInput?.displayId) {
    error.displayId = "DISPLAY_ID_VALIDATION";
    if (!firstError) {
      firstError = "DISPLAY_ID_VALIDATION";
    }
  }

  if (!typeInput?.name) {
    error.name = "RESOURCE_NAME_VALIDATION";
    if (!firstError) {
      firstError = "RESOURCE_NAME_VALIDATION";
    }
  }

  // Description(Notes)
  if (!typeInput?.description) {
    error.description = "NOTES_VALIDATION";
    if (!firstError) {
      firstError = "NOTES_VALIDATION";
    }
  }

  return {
    error,
    firstError,
  };
};

export const transformTypeResponse = (typeData) => {
  const allCharacteristics = [
    ...typeData.characteristics.filter((c) => c.value),
  ];

  const mappedCharacteristics = allCharacteristics.map((char) => {
    let characteristicValue = char?.value;

    if (
      char?.id === 160 ||
      char?.name === "LIFECYCLE_DATE" ||
      char?.dataType === "DATETIME"
    ) {
      characteristicValue = dayjs(new Date(char?.value).toISOString());
    }

    return {
      ...char,
      value: characteristicValue,
    };
  });

  return {
    ...typeData,
    characteristics: mappedCharacteristics,
  };
};

export const transformTypeInputRequest = (typeInput, region) => {
  const finalCharacteristics = typeInput.characteristics
    .filter((chr) => chr.value)
    .map((chr) => {
      let characteristicValue;
      if (chr?.value) {
        characteristicValue = chr?.measurementUnit
          ? measurementDisplay({
              value: chr?.value,
              unit: chr?.measurementUnit,
              region: region,
              reverse: true,
            }).value
          : chr?.value;
      }

      if (chr?.name === "LIFECYCLE_DATE" || chr?.dataType === "DATETIME") {
        characteristicValue = chr?.value
          ? new Date(chr?.value).toISOString()
          : null;
      }

      return {
        ...chr,
        value: characteristicValue,
      };
    });

  return {
    displayId: typeInput.displayId,
    functionId: typeInput.functionId,
    name: typeInput.name,
    description: typeInput.description,
    characteristics: finalCharacteristics.map((chr) => ({
      id: chr.id,
      editable: chr.editable,
      value: chr.value,
    })),
  };
};
