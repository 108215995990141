import { Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectMode, setCurrentColor } from "../../store/slices/tagsSlice";
import { COLOR_PALETTES } from "../../util/color-picker-utils";
import {
  ColorPalette,
  ColorPaletteContainer,
} from "../styles/color-picker/ColorPicker.styles";
import RecentColors from "./RecentColors";

const ColorPaletteTab = ({ setColor, handleClose }) => {
  // General hooks
  const dispatch = useDispatch();

  // Selectors
  const mode = useSelector(selectMode);

  // Handlers
  const handleChangeColor = (color) => {
    dispatch(setCurrentColor(color));

    if (mode === "edit") {
      setColor(color);
    }

    handleClose();
  };

  return (
    <Box>
      <Grid container spacing={2}>
        {COLOR_PALETTES.map((palette) => (
          <ColorPaletteContainer
            key={palette.backgroundColor}
            id="color"
            onClick={() => handleChangeColor(palette.backgroundColor)}
            item
            xs={4}
          >
            <ColorPalette palette={palette}>
              <Typography component="span">Aa</Typography>
            </ColorPalette>
          </ColorPaletteContainer>
        ))}
      </Grid>

      <RecentColors setColor={setColor} handleClose={handleClose} />
    </Box>
  );
};

export default ColorPaletteTab;
