import { Grid, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { PrimaryText } from "../../assets/asset-detail/AssetDetailBasicInfo.styles";

export const TypeStickySearchContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
});

export const SearchTextField = styled(TextField)({
  marginTop: 0,
});

export const SortingOptionContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const SortingOptionText = styled(PrimaryText)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  flexGrow: 1,
  marginLeft: "12px",
});
