import { Box, Button, MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const OrganizationInvitationCardContainer = styled(Box)({
  paddingBlock: "12px",
  paddingInline: "16px",
  alignItems: "center",
  justifyContent: "space-between",
  display: "flex",
  columnGap: 10,
});

export const OrganizationNameContainer = styled(Box)({
  flexGrow: 0.4,
  overflow: "hidden",
});

export const OrganizationName = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  paddingLeft: "36px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

export const ActionsContainer = styled(Box)({
  display: "flex",
  flex: 0.5,
});

export const DeclineButton = styled(Button)({
  color: "red",
  flex: 1,
});

export const AcceptButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  flex: 1,
}));

export const ResendInvitationMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));
