import LoadingSpinnerOverlay from "./LoadingSpinnerOverlay";

const ErrorHandling = ({
  isLoading,
  isSuccess,
  isError,
  shouldThrowError = true,
  children,
}) => {
  // Other variables
  let content = <></>;

  if (isLoading) {
    content = <LoadingSpinnerOverlay />;
  } else {
    content = children;
  }

  return content;
};

export default ErrorHandling;
