import {
  MenuItemContainer,
  PreferenceItemName,
} from "./styles/profile/PreferenceItem.styles";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../store/slices/appSlice";
import { StyledCheckIcon } from "./styles/general/General.styles";

const PreferenceItem = ({ menuId, label, isChecked, handleClick }) => {
  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  return (
    <MenuItemContainer onClick={handleClick} id={menuId} data-testid={menuId}>
      {/* Name of the preference */}
      <PreferenceItemName variant="body1">{label}</PreferenceItemName>
      {isChecked && (
        <StyledCheckIcon
          globalFontSize={globalFontSize}
          color="primary"
        ></StyledCheckIcon>
      )}
    </MenuItemContainer>
  );
};

export default PreferenceItem;
