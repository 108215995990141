import { styled } from "@mui/system";
import {
  Box,
  Button,
  Dialog,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { ListItemContainer } from "../asset-detail/AssetDetail.styles";
import { SecondaryText } from "../ListInlineView.styles";
import ClearIcon from "@mui/icons-material/Clear";
import ModeEditOutlined from "@mui/icons-material/ModeEditOutlined";

export const TagsListItemContainer = styled(ListItemContainer)({
  columnGap: "60px",
});

export const HighlightedText = styled(Typography)({
  fontWeight: "bold",
});

export const NewItemText = styled(HighlightedText)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

export const RecentlyUsedText = styled(SecondaryText)({
  color: "#CECECE",
  marginLeft: "10px",
  marginTop: "10px",
});

export const TagsItemContainer = styled(Box)(({ color, border, outline }) => ({
  backgroundColor: color,
  display: "flex",
  alignItems: "center",
  padding: "0 .75em",
  border,
  outline,
  maxWidth: `${window.outerWidth - 60}px`,
  cursor: "pointer",
}));

export const TagsItemLabel = styled(Box)(({ color }) => ({
  color,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

export const TagsItemClearIcon = styled(ClearIcon)(({ color }) => ({
  height: "25px",
  width: "25px",
  color,
  marginTop: "5px",
}));

export const TagsListContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: ".5em",
});

export const TagsMenuPaper = styled(Paper)(({ istablet }) => ({
  maxHeight: "300px",
  maxWidth: istablet === 1 ? "50%" : "100%",
  overflowY: "scroll",
}));

export const TagLabelInputEndAdornment = styled(InputAdornment)({
  marginBottom: "15px",
});

export const TagsDoneButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

export const TagsLabelInput = styled(TextField)({
  ".MuiInputBase-input": { marginBottom: "10px" },
});

export const TagsEditIconWrapper = styled(Box)({
  marginLeft: "10px",
});

export const TagsListWrapper = styled(Box)({
  border: "2px solid #CFCFCF",
  padding: "10px",
  borderRadius: "10px",
});

export const TagsRemoveButton = styled(Button)({
  justifyContent: "start",
  marginLeft: "10px",
});

export const EmptySpace = styled(Box)({
  flexGrow: 1,
});

export const TagsEditDialog = styled(Dialog)({
  ".MuiDialog-paper": { width: "100%" },
});

export const ModeEditIcon = styled(ModeEditOutlined)(({ color }) => ({
  color,
}));
