import { useTheme } from "@mui/material";
import BeatLoader from "react-spinners/BeatLoader";
import { InfiniteScrollLoaderContainer } from "./styles/general/General.styles";

const InfiniteScrollLoader = () => {
  // General hooks
  const theme = useTheme();

  // Other variables
  const loaderStyle = { marginTop: "30px" };

  return (
    <InfiniteScrollLoaderContainer>
      <BeatLoader
        color={theme.palette.secondary.contrastText}
        loading={true}
        cssOverride={loaderStyle}
      />
    </InfiniteScrollLoaderContainer>
  );
};

export default InfiniteScrollLoader;
