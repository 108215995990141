import { Stack } from "@mui/material";
import React from "react";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";

const CountWidgetContent = ({ count, recentCount }) => {
  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <SecondaryText fontWeight="bolder">{count}</SecondaryText>
      <SecondaryText>{recentCount}</SecondaryText>
    </Stack>
  );
};

export default CountWidgetContent;
