import { DialogContent, DialogActions, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  extractTextColor,
  getTranslation,
  TAGS_LABEL_TEXT_FIELD_REGEX,
  TAGS_MAX_CHARACTERS,
  TAGS_MIN_CHARACTERS,
  transitionDirections,
} from "../../../util/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTag,
  editTag,
  selectIsInDialog,
  selectMarkedTag,
  selectMode,
  selectSelectedEditTag,
  setInputValue,
  setMarkedTag,
  setMode,
  setSelectedEditTag,
} from "../../../store/slices/tagsSlice";
import {
  TagLabelInputEndAdornment,
  TagsItemContainer,
  TagsItemLabel,
  TagsDoneButton,
  TagsLabelInput,
  TagsEditIconWrapper,
  TagsRemoveButton,
  TagsEditDialog,
  EmptySpace,
  ModeEditIcon,
} from "../../styles/assets/tags/Tags.styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ColorPicker from "../../color-picker/ColorPicker";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import { useGetTagsQuery } from "../../../store/slices/api/tagsSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import ErrorHandling from "../../common/ErrorHandling";
import { selectParentId } from "../../../store/slices/columnViewSlice";

const AssetDetailTagItem = ({ tag }) => {
  // General hooks
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { resourceid: resourceId } = useParams();
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const markedTag = useSelector(selectMarkedTag);
  const mode = useSelector(selectMode);
  const selectedEditTag = useSelector(selectSelectedEditTag);
  const user = useSelector(selectUser);
  const isInDialog = useSelector(selectIsInDialog);
  const parentId = useSelector(selectParentId);

  // Queries
  const {
    data: tags,
    isLoading,
    isError,
  } = useGetTagsQuery({
    organizationId: user?.organizations?.find((o) => o.default).id,
  });

  // Other variables
  const color = extractTextColor(tag.color);
  const routeCheck = pathname.includes("tags") && pathname.includes("edit");
  const assetDetailsRouteCheck = pathname.includes("resources");

  const alert = {
    content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),

    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  // State
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState(tag.label);
  const [tagColor, setTagColor] = useState(tag.color);
  const [error, setError] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);

  // Handlers
  const handleDeleteTag = (e) => {
    e.stopPropagation();
    dispatch(deleteTag(tag));
    dispatch(setInputValue(""));
    handleClose();
  };

  const handleClickOpen = () => {
    if (isInDialog || routeCheck) {
      if (
        Boolean(markedTag) &&
        markedTag.name.toLowerCase() === tag.name.toLowerCase()
      ) {
        dispatch(setMarkedTag(null));
      } else {
        dispatch(setMode("edit"));
        dispatch(setSelectedEditTag(tag));
        setOpen(true);
      }

      return;
    }

    if (assetDetailsRouteCheck) {
      navigate(
        `/tags-filter/${resourceId || parentId}/${tag.id}?direction=${
          transitionDirections.RIGHT_TO_LEFT
        }&tagName=${tag.label}`
      );

      return;
    }
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(setMode(""));
    dispatch(setSelectedEditTag(null));
  };

  const handleLabelInputChange = (e) => {
    e.stopPropagation();
    const newValue = e.target.value;
    setLabel(newValue);

    if (!newValue.match(TAGS_LABEL_TEXT_FIELD_REGEX)) {
      if (newValue.length < TAGS_MIN_CHARACTERS) {
        setError(getTranslation("TAGS_MIN_CHARACTERS", t, i18n));
        return;
      }

      if (newValue.length > TAGS_MAX_CHARACTERS) {
        setError(getTranslation("TAGS_MAX_CHARACTERS", t, i18n));
        return;
      }

      setError("");
    } else {
      setError(getTranslation("ALLOWED_CHARACTERS", t, i18n));
    }
  };

  const handleLabelInputKeyDown = (e) => {
    e.stopPropagation();
  };

  const handleLabelInputClick = (e) => {
    e.stopPropagation();
  };

  const handleClickDone = () => {
    dispatch(editTag({ ...tag, name: label, color: tagColor }));
    handleClose();
  };

  const handleReset = () => {
    setLabel(tag.label);
    setTagColor(tag.color);
    handleClose();
  };

  const handleCancel = () => {
    if (label !== tag.label || tagColor !== tag.color) {
      setOpenConfirm(true);
    } else {
      handleReset();
    }
  };

  const handleConfirmAlert = () => {
    handleReset();
  };

  const handleGetTagBorder = (tag) => {
    if (desktopMatches) {
      if (
        isInDialog &&
        Boolean(markedTag) &&
        markedTag.name.toLowerCase() === tag.label.toLowerCase()
      ) {
        return `2px solid #fff`;
      } else {
        return "none";
      }
    } else {
      if (
        Boolean(markedTag) &&
        markedTag.name.toLowerCase() === tag.label.toLowerCase()
      ) {
        return `2px solid #fff`;
      } else {
        return "none";
      }
    }
  };

  const handleGetTagOutline = (tag) => {
    if (desktopMatches) {
      if (
        isInDialog &&
        Boolean(markedTag) &&
        markedTag.name.toLowerCase() === tag.label.toLowerCase()
      ) {
        return `2px solid #000`;
      } else {
        return "none";
      }
    } else {
      if (
        Boolean(markedTag) &&
        markedTag.name.toLowerCase() === tag.label.toLowerCase()
      ) {
        return `2px solid #000`;
      } else {
        return "none";
      }
    }
  };

  // Other variables
  const labelInputProps = {
    endAdornment: (
      <TagLabelInputEndAdornment
        id="tags-color-picker-adornment"
        position="end"
      >
        <ColorPicker setColor={setTagColor} color={tagColor}></ColorPicker>
      </TagLabelInputEndAdornment>
    ),
  };

  // Effects
  useEffect(() => {
    if (
      mode === "edit" &&
      selectedEditTag.name.toLowerCase() === tag.name.toLowerCase()
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [mode, selectedEditTag?.name, tag?.name]);

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <>
        {open && (
          <TagsEditDialog open={open} onClose={handleClose}>
            <DialogContent>
              <ConfirmAlert
                isOpen={openConfirm}
                setIsOpen={setOpenConfirm}
                alert={alert}
                handleConfirm={handleConfirmAlert}
              />

              <TagsLabelInput
                id="tag-label"
                placeholder={getTranslation("EDIT_TAG", t, i18n)}
                variant="standard"
                autoFocus
                defaultValue={label}
                onClick={handleLabelInputClick}
                onChange={handleLabelInputChange}
                onKeyDown={handleLabelInputKeyDown}
                helperText={error}
                error={!!error}
                InputProps={labelInputProps}
              />
            </DialogContent>
            <DialogActions>
              <TagsRemoveButton
                id="remove-tag"
                color="error"
                onClick={handleDeleteTag}
              >
                {getTranslation("REMOVE_TAG", t, i18n)}
              </TagsRemoveButton>
              <EmptySpace />
              <TagsDoneButton
                id="cancel-tag"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCancel();
                }}
              >
                {getTranslation("CANCEL", t, i18n)}
              </TagsDoneButton>
              <TagsDoneButton
                id="save-tag"
                disabled={
                  (label === tag.label && tagColor === tag.color) ||
                  !!error ||
                  tags
                    ?.filter((t) => t.name !== tag.label.toLowerCase())
                    ?.some((t) => t.name === label.toLowerCase())
                }
                onClick={handleClickDone}
              >
                {getTranslation("DONE", t, i18n)}
              </TagsDoneButton>
            </DialogActions>
          </TagsEditDialog>
        )}

        <TagsItemContainer
          id="tag"
          color={tag.color}
          border={handleGetTagBorder(tag)}
          outline={handleGetTagOutline(tag)}
          onClick={handleClickOpen}
        >
          <TagsItemLabel color={color} component="span">
            {tag.label}
          </TagsItemLabel>

          {(isInDialog || routeCheck) && (
            <TagsEditIconWrapper>
              <ModeEditIcon color={color} />
            </TagsEditIconWrapper>
          )}
        </TagsItemContainer>
      </>
    </ErrorHandling>
  );
};

export default AssetDetailTagItem;
