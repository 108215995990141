import { useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetResourceImageQuery } from "../../../store/slices/api/resourceImagesApiSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { getFallbackIcon } from "../../../util/utils";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import CanvasImage from "../../common/CanvasImage";
import ErrorHandling from "../../common/ErrorHandling";
import { ResponsiveSizeCardMedia } from "../../styles/assets/asset-list/GridViewCardMedia.styles";

const GridViewCardMedia = ({ resourceImages, category, isIcon }) => {
  // General hooks
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default).id;

  // Queries
  const {
    data: resourceImageUri,
    isLoading: isLoadingImage,
    isError,
    error,
  } = useGetResourceImageQuery(
    {
      imageUri: resourceImages ? resourceImages[0]?.uri : "",
      organizationId,
    },
    {
      skip: !Boolean(resourceImages) || resourceImages.length <= 0,
    }
  );

  // State
  const [image, setImage] = useState(null);

  // Effects
  useEffect(() => {
    if (resourceImageUri) {
      setImage(resourceImageUri);
    }
  }, [resourceImageUri]);

  return (
    <ErrorHandling isLoading={isLoadingImage} isError={isError}>
      {resourceImageUri && resourceImages?.length > 0 ? (
        <CanvasImage
          id={resourceImages[0]?.name}
          width={mobileMatches ? 100 : 50}
          height={mobileMatches ? 100 : 50}
          image={
            resourceImageUri && resourceImages?.length > 0
              ? resourceImages[0]?.name?.endsWith(".svg")
                ? `${process.env.PUBLIC_URL}/icons/${resourceImages[0]?.name}`
                : resourceImageUri
              : `${process.env.PUBLIC_URL}/icons/${getFallbackIcon(category)}`
          }
        />
      ) : (
        <ResponsiveSizeCardMedia
          ismobile={mobileMatches}
          isicon={isIcon}
          component="img"
          image={
            image && resourceImages?.length > 0
              ? resourceImages[0]?.name?.endsWith(".svg")
                ? `${process.env.PUBLIC_URL}/icons/${resourceImages[0]?.name}`
                : image
              : `${process.env.PUBLIC_URL}/icons/${getFallbackIcon(category)}`
          }
          id="asset-image"
        />
      )}
    </ErrorHandling>
  );
};

export default GridViewCardMedia;
