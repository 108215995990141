import React, { useState } from "react";
import { DeleteButton } from "../../styles/assets/CrudForm.styles";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import { messageError, messageSuccess } from "../../../util/notification";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import { useGetResourceChildrenQuery } from "../../../store/slices/api/assetManagementSlice";
import ErrorHandling from "../../common/ErrorHandling";
import { useDispatch } from "react-redux";
import { setTabletTabValue } from "../../../store/slices/assetListSlice";

const DeleteAsset = (props) => {
  const { parentId, resourceid, category, organizationId, deleteResource } =
    props;

  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // Queries
  const {
    data: resourceChildrenData,
    isLoading,
    isError,
  } = useGetResourceChildrenQuery(
    {
      resourceid: resourceid,
      organizationId: organizationId,
    },
    { skip: !Boolean(resourceid) }
  );

  // States
  const [open, setOpen] = useState(false);

  // Handlers
  const handleDeleteConfirm = () => {
    setOpen(true);
  };

  const deleteConfirm = async () => {
    try {
      navigate(`/resources${parentId ? `/${parentId}` : ""}`);

      await deleteResource({
        resourceid,
        organizationId,
        category,
      }).unwrap();

      dispatch(setTabletTabValue(0));
      messageSuccess(getTranslation("ASSET_DELETED_SUCCESSFULLY", t, i18n));
    } catch (error) {
      messageError(getTranslation("ASSET_DELETE_FAILED", t, i18n));
    }
  };

  // Other variables
  const haveChildren = resourceChildrenData?.totalRecord <= 0;
  const alert = {
    content: haveChildren
      ? getTranslation("ASSET_DELETE_MESSAGE", t, i18n)
      : getTranslation("ASSET_DELETE_WITH_CHILDREN_MESSAGE", t, i18n),
    confirmTitle: getTranslation("DELETE_ASSET", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <>
        <ConfirmAlert
          isOpen={open}
          setIsOpen={setOpen}
          alert={alert}
          handleConfirm={deleteConfirm}
          label="delete"
        />
        <div>
          <DeleteButton
            id="delete-button"
            data-testid="delete-button"
            onClick={handleDeleteConfirm}
            startIcon={<DeleteIcon />}
            variant="text"
            color="error"
          >
            {getTranslation("DELETE_ASSET", t, i18n)}
          </DeleteButton>
        </div>
      </>
    </ErrorHandling>
  );
};

export default DeleteAsset;
