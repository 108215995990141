import ThreeSixty from "@mui/icons-material/ThreeSixty";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
} from "@mui/material";
import { styled } from "@mui/system";
import { Item, SecondaryText } from "../ListInlineView.styles";

export const GraphicalListButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

export const GraphicalListDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: "calc(100% - 24px)",
    height: "calc(100% - 24px)",
    borderRadius: "8px",
  },
});

export const StyledDialogTitle = styled(DialogTitle)({
  margin: 0,
  padding: "16px",
});

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: 8,
  color: theme.palette.secondary.main,
}));

export const GraphicalListDialogContent = styled(DialogContent)({
  padding: 0,
});

export const GraphicalListContainer = styled(List)({
  paddingTop: 0,
});

export const NotPlacedObjectsContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginInline: "16px",
});

export const GraphicalItem = styled(Item)({
  "&:hover": { backgroundColor: "transparent" },
});

export const SectionText = styled(SecondaryText)({
  marginLeft: "16px",
});

export const RotateIcon = styled(ThreeSixty)(({ customfontsize }) => ({
  fontSize: `${customfontsize * 2}px`,
}));
