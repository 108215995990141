import { createSlice } from "@reduxjs/toolkit";
import { mapUser } from "../../util/UserUtil";

export const DEFAULT_USER = {
  refreshTime: null,
  loadCompleted: true,
  onboarding: {
    completed: false,
    targetPage: "",
  },
  organizations: [],
};

// Initial state
const initialState = {
  user: DEFAULT_USER,
};

// Slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const newUser = action.payload;
      state.user = newUser;
    },
    refreshUser: (state, action) => {
      const newUser = action.payload;
      state.user = mapUser(newUser);
    },
    changeOnboardingStatus: (state, action) => {
      const newOnboardingStatus = action.payload;
      state.user.onboarding.completed = newOnboardingStatus;
    },
    addOrganization: (state, action) => {
      const newOrganization = action.payload;

      if (!state.user.organizations) {
        state.user.organizations = [{ ...newOrganization }];
      } else {
        state.user?.organizations?.push(newOrganization);
      }
    },
    uploadOrganizationImage: (state, action) => {
      const { logoUri, organizationId } = action.payload;

      if (state.user?.organizations) {
        state.user.organizations.find((o) => o.id === organizationId).logoUri =
          logoUri;
      }
    },
  },
});

// Export slice and its corresponding actions
export default authSlice.reducer;
export const {
  setUser,
  refreshUser,
  changeOnboardingStatus,
  addOrganization,
  uploadOrganizationImage,
} = authSlice.actions;

// selectors
export const selectUser = (state) => state.auth.user;
export const selectOnboardingStatus = (state) =>
  state.auth.user?.onboarding?.completed;
