import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import { selectUser } from "../../store/slices/authSlice";
import { getComparator, stableSort } from "../../util/reports-utils";
import {
  getTranslation,
  localizeDate,
  transitionDirections,
} from "../../util/utils";
import {
  StyledTableCell,
  TableReportContainer,
  TableReportPaper,
} from "../styles/assets/reports/TableReport.styles";
import { visuallyHidden } from "@mui/utils";
import { EmptyRowTable, TableReportPagination } from "../styles/reports/Reports.styles";

const TableReport = ({ rows, columns, isRowClickable }) => {
  // General hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  // Other variables
  const pageParam = searchParams.get("page");

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [page, setPage] = useState(pageParam ? Number(pageParam) : 0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(
    columns && columns.length > 0 ? columns[0].id : ""
  );

  // Other variables
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  // Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  useEffect(() => {
    searchParams.set("page", page);
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <TableReportPaper>
      <TableReportContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  column={column}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={createSortHandler(column.id)}
                  >
                    {getTranslation(column.label, t, i18n)}

                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows?.map((row, index) => {
              return (
                <TableRow
                  onClick={() => {
                    if (isRowClickable) {
                      navigate(
                        `/resources/${
                          row["resource.id"] || row["resource.parent.id"]
                        }?quick_access=true&direction=${
                          transitionDirections.RIGHT_TO_LEFT
                        }`,
                        {
                          state: {
                            from: location.pathname + location.search,
                          },
                        }
                      );
                    }
                  }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                >
                  {columns.map((column) => {
                    const value = row[column.id];

                    let resultValue = value;
                    const sx = { fontStyle: value ? "" : "italic" };
                    if (column.id === "resource.lifecycleDate") {
                      resultValue = value
                        ? localizeDate(value, user?.region)
                        : "";
                    }

                    if (column.id === "resource.lifecycleStatus") {
                      resultValue = value
                        ? getTranslation(value, t, i18n)
                        : getTranslation("UNDEFINED", t, i18n);
                    }

                    return (
                      <TableCell key={column.id} align={column.align} sx={sx}>
                        {resultValue}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}

            {emptyRows > 0 && (
              <EmptyRowTable
                rowHeight={53 * emptyRows}
              >
                <TableCell colSpan={6} />
              </EmptyRowTable>
            )}
          </TableBody>
        </Table>
      </TableReportContainer>
      <TableReportPagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        labelRowsPerPage={getTranslation("ROWS_PER_PAGE", t, i18n)}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableReportPaper>
  );
};

export default TableReport;
