import React, { useEffect, useState } from "react";
import { MenuItem, Select, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import PageTransition from "../../components/PageTransition";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { useGetResourcesByCategoryQuery } from "../../store/slices/api/assetManagementSlice";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import { selectUser } from "../../store/slices/authSlice";
import { RESOURCE_CATEGORIES, transitionDirections } from "../../util/utils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ReportHeader from "../../navigation/header/reports/ReportHeader";
import TableReport from "../../components/reports/TableReport";
import { useReportExecutionQuery } from "../../store/slices/api/reportsApiSlice";
import { ASSET_LIST_PER_LOCATION_REPORT_DEFINITION_ID } from "../../Constants";
import { handleMapDynamicColumns } from "../../util/reports-utils";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const ReportsByAssetsListPerLocation = () => {
  // General hooks
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const reportId = searchParams.get("reportId");

  // States
  const [selectedLocationId, setSelectedLocationId] = useState(reportId ?? "");

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Queries
  const {
    data: locationsData,
    isLoading: isLoadingLocations,
    isError: isErrorLocations,
  } = useGetResourcesByCategoryQuery({
    organizationId: user?.organizations?.find((o) => o.default)?.id,
    category: RESOURCE_CATEGORIES.LOCATION,
  });

  const {
    data: assetListPerLocationData,
    isLoading: isLoadingAssetListPerLocation,
    isError: isErrorAssetListPerLocation,
  } = useReportExecutionQuery(
    {
      organizationId: user?.organizations?.find((o) => o.default).id,
      reportDefinitionId: ASSET_LIST_PER_LOCATION_REPORT_DEFINITION_ID,
      parentId: selectedLocationId,
    },
    { skip: !Boolean(selectedLocationId), refetchOnMountOrArgChange: true }
  );

  // Other variables
  const transitionDirection = searchParams.get("direction");

  // Handlers
  const handleSelectLocation = (e) => {
    setSelectedLocationId(e.target.value);
  };

  const handleGoBack = () => {
    navigate(
      `/reports?direction=${transitionDirections.LEFT_TO_RIGHT}&quick-access=true`
    );
  };

  // Effects
  useEffect(() => {
    if (selectedLocationId) {
      searchParams.set("reportId", selectedLocationId);
    }

    setSearchParams(searchParams, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationId]);

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling
          isLoading={isLoadingAssetListPerLocation || isLoadingLocations}
          isError={isErrorAssetListPerLocation || isErrorLocations}
        >
          <PageTransition direction={transitionDirection}>
            <ReportHeader
              goBackHandler={handleGoBack}
              title="ASSET_LIST_PER_LOCATION"
            />
            <HomePagePadding>
              <Stack spacing={2}>
                <Select
                  fullWidth
                  labelId="select-location-label"
                  id="select-location"
                  value={selectedLocationId}
                  onChange={handleSelectLocation}
                  IconComponent={() => (
                    <ArrowDropDownIcon
                      sx={{
                        fontSize: `${globalFontSize * 1.5}px`,
                      }}
                    />
                  )}
                >
                  {locationsData?.map((a) => (
                    <MenuItem value={a.id}>{a.name}</MenuItem>
                  ))}
                </Select>

                {selectedLocationId && (
                  <TableReport
                    rows={assetListPerLocationData?.data}
                    columns={handleMapDynamicColumns(
                      assetListPerLocationData?.data
                    )}
                    isRowClickable={true}
                  />
                )}
              </Stack>
            </HomePagePadding>
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default ReportsByAssetsListPerLocation;
