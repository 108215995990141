import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addRecentColor,
  selectMode,
  selectRecentColors,
  setCurrentColor,
} from "../../store/slices/tagsSlice";
import {
  extractTextColor,
  getTranslation,
  userLocalStorageKey,
} from "../../util/utils";
import {
  ColorPalette,
  ColorPaletteContainer,
  RecentColorsText,
} from "../styles/color-picker/ColorPicker.styles";

const RecentColors = ({ setColor, handleClose }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const recentColors = useSelector(selectRecentColors);
  const mode = useSelector(selectMode);

  // Handlers
  const handleChangeColor = (color) => {
    dispatch(setCurrentColor(color));

    const newColor = {
      backgroundColor: color,
      foregroundColor: extractTextColor(color),
    };

    dispatch(addRecentColor(newColor));

    localStorage.setItem(
      userLocalStorageKey("recentColors"),
      JSON.stringify([
        ...recentColors.filter((rc) => rc.backgroundColor !== color),
        newColor,
      ])
    );

    if (mode === "edit") {
      setColor(color);
    }

    handleClose();
  };

  return (
    recentColors &&
    recentColors.length > 0 && (
      <Box>
        <RecentColorsText>
          {getTranslation("RECENT_COLORS", t, i18n)}
        </RecentColorsText>
        <Grid container spacing={2}>
          {[...recentColors]
            .reverse()
            .slice(0, 3)
            .map((palette) => (
              <ColorPaletteContainer
                key={palette.backgroundColor}
                id="recent-color"
                onClick={() => handleChangeColor(palette.backgroundColor)}
                item
                xs={4}
              >
                <ColorPalette palette={palette}>
                  <Typography component="span">Aa</Typography>
                </ColorPalette>
              </ColorPaletteContainer>
            ))}
        </Grid>
      </Box>
    )
  );
};

export default RecentColors;
